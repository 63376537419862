import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';

const sideManuHelper = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'email-templates')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.list
      );

    if (routeName === 'email-templates-view')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.view
      );

    if (routeName === 'email-templates-edit')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.edit
      );

    if (routeName === 'email-templates-create')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.create
      );

    if (routeName === 'email-templates-duplicate')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.create
      );

    if (routeName === 'email-templates-market-preferences')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.view
      );

    if (routeName === 'email-templates-delete')
      return await menuHelper.getItems(
        appModel.emailTemplate,
        appPermission.delete
      );
    return [];
  }
};

export default sideManuHelper;
