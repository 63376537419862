import fieldLabel from 'assets/constants/fieldLabel';
import offerRejectReasons from 'assets/constants/offer-reject-reasons';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import { actionPerform } from 'event/action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import emptyFunction from 'helpers/empty-function-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { initialRejectOffer } from 'state/brokerage-actions/reject-offer';
import {
  ActionRecordViewPropTypes,
  RejectOfferEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

import { getInitialRejectOffer } from './helper';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<RejectOfferEntity>(initialRejectOffer);

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const fieldTovalidate = ['reject_reason', 'reject_date', 'offer_date_c'];

    const { isValid, errors } = validateBrokerageAction(data, fieldTovalidate);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    setIsLoading(true);

    const result = await brokerageActionService.update<RejectOfferEntity>(
      opportunity.id,
      data,
      'reject_offer'
    );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        actionPerform.reject_offer();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity({});
      }
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      ...getInitialRejectOffer(opportunity)
    }));
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{ height: 'calc(100vh - 45vh)', overflowY: 'auto', p: 2 }}
        >
          <FormContainer>
            <UnitSelect
              name="reject_reason"
              label={fieldLabel.rejectReason}
              records={getObjectEntriesAsArray(offerRejectReasons)}
              value={data.reject_reason ?? ''}
              onChange={handleChange}
              error={validation['reject_reason'] ?? ''}
              required
            />

            <UnitDate
              label={fieldLabel.rejectDate}
              name="reject_date"
              value={data.reject_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'reject_date', value: e }
                })
              }
              error={validation['reject_date'] ?? ''}
              required
            />

            <UnitText
              name={'competing_offer_count'}
              label={fieldLabel.howManyCompetingOffers}
              value={data.competing_offer_count ?? ''}
              onChange={handleChange}
            />

            <UnitPriceFormatter
              label={fieldLabel.highestCompetingOfferPrice}
              name="competing_offer_highest_price"
              value={data.competing_offer_highest_price ?? ''}
              onChange={handleChange}
            />

            <UnitDate
              label={fieldLabel.offerDate}
              name="offer_date_c"
              value={data.offer_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'offer_date_c', value: e }
                })
              }
              error={validation['offer_date_c'] ?? ''}
              required
            />

            <UnitText
              name={'reject_note'}
              label={fieldLabel.rejectNote}
              value={data.reject_note ?? ''}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </FormContainer>
        </PaperBoxContent>

        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
