import Box from '@mui/material/Box';
import ActionName from 'assets/constants/action-name';
import ModalComponent from 'components/modal';
import actionHelper from 'helpers/action-helper';
import eventBus from 'helpers/event-bus-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import { getItem } from 'helpers/local-storage-helper';
import { isEmpty } from 'helpers/misc-helper';
import upperCaseString from 'helpers/upper-case-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const Actions = () => {
  const { action, opportunity_id } = useParams<{
    action: string;
    opportunity_id: string;
  }>();
  const outletContext = useRouteName();
  const navigate = useNavigate();

  const [isModal, setIsModoal] = useState(false);

  const checkAction = () => {
    setIsModoal(true);
  };

  useEffect(() => {
    federalHolidayHelper.loadFederalHolidays();

    eventBus.on('show_action_modal', () => {
      checkAction();
    });
  }, []);

  const modalClose = () => {
    const prevUrl = getItem('prevUrl') ?? '';
    navigate(prevUrl);
    setIsModoal(false);
  };

  const title = upperCaseString(ActionName[action!]);

  return (
    <React.Fragment>
      {isModal ? (
        <ModalComponent
          title={title}
          Component={actionHelper.getActionModal(action!)}
          data={{
            id: opportunity_id,
            isModal: isModal,
            reload: () => {
              window.location.reload();
            },
            onClose: () => {
              modalClose();
            }
          }}
          onClose={() => {
            modalClose();
          }}
          size="md"
        ></ModalComponent>
      ) : (
        <Box mt={1}>
          <Outlet
            context={{
              routeName: !isEmpty(outletContext) ? outletContext.routeName : '',
              setRouteName: !isEmpty(outletContext)
                ? outletContext.setRouteName
                : ''
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default Actions;
