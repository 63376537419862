import { v4 as uuid } from 'uuid';
import Logout from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const profileTopMenuItems = (is_admin: boolean) => {
  let items = [
    {
      label: 'Profile',
      url: `/profile`,
      labelIcon: Person2Icon,
      nodeId: uuid()
    }
  ];

  if (is_admin) {
    items.push({
      label: 'Administration',
      url: `/admin/users`,
      labelIcon: AdminPanelSettingsIcon,
      nodeId: uuid()
    });
  }

  items.push({
    label: 'Logout',
    url: `/logout`,
    labelIcon: Logout,
    nodeId: uuid()
  });

  return items;
};

export default profileTopMenuItems;
