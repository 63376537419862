import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Breadcrumbs from 'features/breadcrumbs';
import ContainerRight from 'components/container/right';
import LayoutProvider from 'pages/common-layout/layout-provider-context';

const RolePage = () => {
  const [routeName, setRouteName] = useState('');
  const { setSideNavMenuItems } = useContext(LayoutProvider);

  useEffect(() => {
    setSideNavMenuItems({});
  }, []);

  return (
    <>
      <ContainerRight>
        <Outlet context={{ routeName, setRouteName }} />
      </ContainerRight>
    </>
  );
};

export default RolePage;
