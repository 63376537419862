import { ActionPropTypes } from 'types/brokerage-action-types';
import { initialOpportunity } from 'state/opportunity/initial-opportunity';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityEntity } from 'types/opportunity-types';
import { useParams } from 'react-router-dom';
import ActionName from 'assets/constants/action-name';
import BrokerageActinHeader from '../brokerage-action-header';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import NegotiatorNotes from '../negotiator-notes';
import opportunityService from 'services/opportunity-service';
import OpportunityTitle from 'features/opportunity-title';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import React, { useEffect, useState } from 'react';
import RecordView from './record-view';
import TabArea from 'components/tabs/tab-area';
import UnitItem from 'components/form/unit-item';
import UploadDocuments from 'features/documents/document-upload';
import useRouteName from 'pages/route-outlet-context';

const EnterDueDiligence = ({
  routeTag,
  onClose = emptyFunction,
  isModal = false
}: ActionPropTypes) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();

  const [opportunity, setOpportunity] =
    useState<OpportunityEntity>(initialOpportunity);
  const [loading, setLoading] = useState<boolean>(true);

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const tabId = 'enter-due-diligence';
  const outletContext = useRouteName();

  const getOpportunity = async (id: string) => {
    const result = await opportunityService.get(id);
    setLoading(false);
    setOpportunity(result.data);
  };

  const tabItems = () => {
    return {
      action: fieldLabel.action,
      negotiator_notes: fieldLabel.negotiationNotes,
      upload_documents: `${fieldLabel.upload} ${fieldLabel.documents}`
    };
  };

  useEffect(() => {
    if (opportunity_id) {
      getOpportunity(opportunity_id);
    }

    if (!isEmpty(outletContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <PaperBox evelationValue={0} sx={{ bgcolor: '#eff1f6' }}>
        {isModal && (
          <BrokerageActinHeader
            loading={loading}
            title={
              <OpportunityTitle
                oppurtunity={opportunity}
                action_name={ActionName.due_diligence_opportunity}
              />
            }
            onClose={onClose}
          />
        )}
        <PaperBoxContent sx={{ p: 0 }}>
          <FormContainer spacing={0}>
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <HorizontalTabs
                value={activeTab}
                handleChange={handleTabChange}
                tabItems={tabItems()}
                sx={{ ml: -1 }}
                tabId={tabId}
              />
            </UnitItem>

            <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
              <TabArea index={0} value={activeTab} border={false} tabId={tabId}>
                <RecordView
                  onClose={onClose}
                  opportunity={opportunity}
                  isModal={isModal}
                  loadingOpportunity={loading}
                />
              </TabArea>
              <TabArea index={1} value={activeTab} border={false} tabId={tabId}>
                <NegotiatorNotes id={opportunity_id} />
              </TabArea>
              <TabArea index={2} value={activeTab} border={false} tabId={tabId}>
                <PaperBoxContent
                  sx={{
                    height: 'calc(100vh - 35vh)',
                    overflowY: 'auto',
                    p: 0
                  }}
                >
                  <UploadDocuments
                    opportunity_id={opportunity_id}
                    opportunity={opportunity}
                  />
                </PaperBoxContent>
              </TabArea>
            </UnitItem>
          </FormContainer>
        </PaperBoxContent>
      </PaperBox>
    </React.Fragment>
  );
};

export default EnterDueDiligence;
