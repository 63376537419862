import DASHBOARDS from 'assets/constants/dashboards';
import fieldLabel from 'assets/constants/fieldLabel';
import DashboardHOC from 'components/dashboard';
import DashboardHOC2 from 'components/dashboard/index2';
import DashboradTabLabel from 'components/form/unit-dashboard-tab';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { recordCountDefault } from 'state/dashboard/closing';
import { ObjectType } from 'types';

import useRouteName from '../route-outlet-context';

const ClosingDashboard: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [recordCount, setRecordCount] = useState(recordCountDefault);

  const { setRouteName } = useRouteName();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const updateCount = (data: ObjectType) => {
    setRecordCount((prevRecordCount) => ({ ...prevRecordCount, ...data }));
  };

  const tabId = 'closing-tab';

  const tabItems = () => {
    return {
      diligence_period: (
        <DashboradTabLabel
          title={fieldLabel.diligencePeriod}
          count={recordCount.CLOSING_DASHBOARD_DILIGENCE_PERIOD}
        />
      ),
      sale_pending: (
        <DashboradTabLabel
          title={fieldLabel.salePending}
          count={recordCount.CLOSING_DASHBOARD_SALE_PENDING}
        />
      ),
      clear_to_close: (
        <DashboradTabLabel
          title={fieldLabel.clearToClose}
          count={recordCount.CLOSING_DASHBOARD_CLEAR_TO_CLOSE}
        />
      ),
      termination: (
        <DashboradTabLabel
          title={fieldLabel.termination}
          count={recordCount.CLOSING_DASHBOARD_TERMINATIONS}
        />
      ),
      closed_purchased: (
        <DashboradTabLabel
          title={fieldLabel.closedPurchased}
          count={recordCount.CLOSING_DASHBOARD_CLOSED_PURCHASED}
        />
      )
    };
  };

  useEffect(() => {
    setRouteName('closing');
  }, []);

  return (
    <>
      <HorizontalTabs
        value={activeTabIndex}
        handleChange={handleChange}
        tabItems={tabItems()}
        tabId={tabId}
      />

      <TabArea index={0} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC
          dashboard={DASHBOARDS.CLOSING_DASHBOARD_DILIGENCE_PERIOD}
          currentTab={activeTabIndex}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={1} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.CLOSING_DASHBOARD_SALE_PENDING}
          currentTab={activeTabIndex}
          tabIndex={1}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={2} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.CLOSING_DASHBOARD_CLEAR_TO_CLOSE}
          currentTab={activeTabIndex}
          tabIndex={2}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={3} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.CLOSING_DASHBOARD_TERMINATIONS}
          currentTab={activeTabIndex}
          tabIndex={3}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={4} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.CLOSING_DASHBOARD_CLOSED_PURCHASED}
          currentTab={activeTabIndex}
          tabIndex={4}
          updateCount={updateCount}
        />
      </TabArea>

      <Outlet />
    </>
  );
};

export default ClosingDashboard;
