import DASHBOARDS from 'assets/constants/dashboards';
import ClearButton from 'components/form/button-clear';
import SearchButton from 'components/form/button-search';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRow from 'components/stack/stack-row';
import Title from 'components/typography/title';
import ColumnChooser from 'features/dashboards/column-chooser';
import DefaultDashboardFields from 'assets/list/dashboard/default-column';
import fieldLabel from 'assets/constants/fieldLabel';
import { ChangeEvent, OnKeyDownEvent } from 'types/common-types';
import { PropertiesFilterEntity } from 'types/dashboard-type';
import initilaPropertiesFilter from 'state/properties/initial-filter';

const Filter = ({
  filter,
  changeFilter,
  saveFilterOnEnterPress,
  saveFilter
}: {
  filter: PropertiesFilterEntity;
  changeFilter: (e: ChangeEvent) => void;
  saveFilterOnEnterPress: (e: OnKeyDownEvent) => void;
  saveFilter: (val: PropertiesFilterEntity) => void;
}) => {
  return (
    <PaperBox sx={{ marginTop: 1 }}>
      <PaperBoxContent>
        <Title value={fieldLabel.searchQuery} sx={{ mt: -1, pb: 1 }} />

        <FormContainer>
          <UnitText
            name={'property_address_c'}
            label={fieldLabel.propertyAddress}
            value={filter.property_address_c}
            onChange={changeFilter}
            grid={{ xs: 12, sm: 4 }}
            onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
          />

          <UnitText
            name={'entera_opportunity_id'}
            label={fieldLabel.enteraOpportunitId}
            value={filter.entera_opportunity_id}
            onChange={changeFilter}
            onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
            grid={{ xs: 12, sm: 4 }}
          />

          <UnitText
            name={'mls_c'}
            label={fieldLabel.mls}
            value={filter.mls_c}
            onChange={changeFilter}
            onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
            grid={{ xs: 12, sm: 4 }}
          />

          <UnitText
            name={'street_name'}
            label={fieldLabel.streetName}
            value={filter.street_name}
            onChange={changeFilter}
            onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
            grid={{ xs: 12, sm: 4 }}
          />
        </FormContainer>

        <StackRow sx={{ pt: 2, pr: 0, pb: 0, pl: 0 }}>
          <SearchButton onClick={() => saveFilter(filter)} />
          <ClearButton
            onClick={() => {
              saveFilter(initilaPropertiesFilter);
            }}
          />
          <ColumnChooser
            title="QUERY RESULTS"
            category={DASHBOARDS.PROPERTIES_QUERY_RESULT}
            subcategory={DASHBOARDS.SELECTED_COLUMNS}
            dashboard={DASHBOARDS.PROPERTIES_QUERY_RESULT}
            currentTab={DASHBOARDS.PROPERTIES_QUERY_RESULT}
            defaultColumns={
              DefaultDashboardFields.NEGOTIATOR_DASHBOARD_OFFER_MAKE_OFFER
            }
          />
        </StackRow>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filter;
