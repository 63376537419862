import {
  MarketPreferenceBrokerageUserEntity,
  MarketPreferenceBrokerageUserListEntity,
  MarketPreferenceBrokerageUserPayloadEntity,
  MarketPreferenceBrokerageUserDeleteResponse,
  MarketPreferenceReplaceBrokerageUserPayloadEntity
} from 'types/market-preference-brokerage-user-types';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';
import httpService from './http-service';
import app from 'config/app';

const marketPreferenceBrokerageUserService = {
  url: `/${app.crmApiVersion1}/market_preference_brokerage_users`,

  getList: async (
    query: string
  ): Promise<GetListResponse<MarketPreferenceBrokerageUserListEntity>> => {
    const result =
      await httpService.list<MarketPreferenceBrokerageUserListEntity>(
        `${marketPreferenceBrokerageUserService.url}${query}`
      );
    return result;
  },

  get: async (
    id: string
  ): Promise<GetResponse<MarketPreferenceBrokerageUserEntity>> => {
    const result = await httpService.get<MarketPreferenceBrokerageUserEntity>(
      `${marketPreferenceBrokerageUserService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: MarketPreferenceBrokerageUserPayloadEntity
  ): Promise<CreateResponse<MarketPreferenceBrokerageUserPayloadEntity>> => {
    const result =
      await httpService.post<MarketPreferenceBrokerageUserPayloadEntity>(
        `${marketPreferenceBrokerageUserService.url}`,
        payload
      );
    return result;
  },

  update: async (
    id: string,
    payload: MarketPreferenceBrokerageUserPayloadEntity
  ): Promise<UpdateResponse<MarketPreferenceBrokerageUserPayloadEntity>> => {
    const result =
      await httpService.put<MarketPreferenceBrokerageUserPayloadEntity>(
        `${marketPreferenceBrokerageUserService.url}/${id}`,
        payload
      );
    return result;
  },

  replace: async (
    id: string,
    payload: MarketPreferenceReplaceBrokerageUserPayloadEntity
  ): Promise<
    UpdateResponse<MarketPreferenceReplaceBrokerageUserPayloadEntity>
  > => {
    const result =
      await httpService.put<MarketPreferenceReplaceBrokerageUserPayloadEntity>(
        `${marketPreferenceBrokerageUserService.url}/${id}/replace`,
        payload
      );
    return result;
  },

  terminate: async (
    id: string,
    payload: MarketPreferenceBrokerageUserPayloadEntity
  ): Promise<UpdateResponse<MarketPreferenceBrokerageUserPayloadEntity>> => {
    const result =
      await httpService.put<MarketPreferenceBrokerageUserPayloadEntity>(
        `${marketPreferenceBrokerageUserService.url}/${id}/terminate`,
        payload
      );
    return result;
  },

  delete: async (
    id: string
  ): Promise<DeleteResponse<MarketPreferenceBrokerageUserDeleteResponse>> => {
    const result =
      await httpService.del<MarketPreferenceBrokerageUserDeleteResponse>(
        `${marketPreferenceBrokerageUserService.url}/${id}`
      );
    return result;
  }
};

export default marketPreferenceBrokerageUserService;
