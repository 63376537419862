
enum sellerResponse {
  taking_other_offers = 'Taking Other Offers',
  verbal_acceptance = 'Verbal Acceptance',
  in_negotiation = 'In Negotiation',
  need_more_time = 'Need More Time',
  need_more_time_showing_pending = 'Need More Time - Showing Pending',
  need_more_time_holding_for_more_offers =
  'Need More Time - Holding for More Offers',
  need_more_time_waiting_for_lender_approval =
  'Need More Time - Waiting for Lender Approval',
  need_more_time_coming_soon = 'Need More Time - Coming Soon',
  highest_and_best_requested = 'Highest and Best Requested'
}

export default sellerResponse;
