import { OptionType } from 'types/option-type';

export const mls_status_c = (
  value: OptionType | OptionType[],
  action: string
) => {
  let result = ``;

  if (!value) return ``;

  if (action === 'dashboard' && !Array.isArray(value)) {
    result += `&filter[mls_status_c]=${value.value}`;
  } else if (action === 'properties' && Array.isArray(value)) {
    if (value.length === 0) return ``;

    for (const el of value) {
      result += `&filter[mls_status_c][]=${el.value}`;
    }
  }

  return result;
};
