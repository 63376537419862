import emptyFunction from "helpers/empty-function-helper";

const initialConfirmModal = {
  open: false,
  title: '',
  text: '',
  proceed: emptyFunction,
  cancel: emptyFunction
};

export default initialConfirmModal;