import RoleList from 'features/roles/roles-list';
import CallPage from 'pages/call';
import RolePage from 'pages/roles';
import { RouteObject } from 'react-router-dom';

const roleRoutes: RouteObject = {
  path: 'roles',
  element: <RolePage />,
  children: [
    {
      path: '',
      element: <RoleList />
    }
  ]
};

export default roleRoutes;
