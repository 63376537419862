import { TransactionHistoryEntity } from 'types/transaction-history-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import initialTransactionHistory from 'state/transaction-history/initial-transaction-history';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import transactionHistoryService from 'services/transaction-history-service';
import useRouteName from 'pages/route-outlet-context';
import { toastError } from 'event/toast-event';

const TransactionHistoryView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { transaction_history_id } = useParams<{
    transaction_history_id: string;
  }>();

  const [transactionHistory, setTransactionHistory] =
    useState<TransactionHistoryEntity>(initialTransactionHistory);

  const getTransactionHistory = async (transaction_history_id: string) => {
    const response = await transactionHistoryService.get(
      transaction_history_id
    );

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    setTransactionHistory(response.data);
  };

  useEffect(() => {
    if (transaction_history_id) getTransactionHistory(transaction_history_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView transactionHistory={transactionHistory} readOnly={true} />
      </PaperBoxContent>
    </PaperBox>
  );
};

export default TransactionHistoryView;
