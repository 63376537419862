import DateUtility from 'helpers/date-helper';
import userFullName from 'helpers/user-name-helper';
import { ObjectType } from 'types';

const negotiatorNoteHelper = {
  getNegotiatorNoteLineItem: (note: ObjectType) => {
    return `${note.name} -  ${DateUtility.getDateTimeString(
      DateUtility.convertUTCtoTimeZone(note.date_entered)
    )} - ${userFullName(note.get_created_by)}
    `;
  }
};
export default negotiatorNoteHelper;
