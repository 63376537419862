import ViewChangelog from 'features/view-changelog';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

const ServiceViewChangelog = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { service_id } = useParams<{ service_id: string }>();
  const [filter, setFilter] = useState<{ service_id: string }>({
    service_id: service_id!
  });
  const { setRouteName } = useRouteName();

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="service_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="services"
      />
    </>
  );
};

export default ServiceViewChangelog;
