import ConfirmBox, {
  initialConfirmModalState,
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import { CardHeader, CardContent, Card } from '@mui/material';
import { EditContainer } from '../record-view/container';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelButton from 'components/form/button-cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import HOAService from 'services/hoa-management-service';
import React, { useState } from 'react';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitButtonIcon from 'components/form/button-icon';
import UnitItem from 'components/form/unit-item';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import { toastError, toastSuccess } from 'event/toast-event';
import { HOAManagementCompanyEntity } from 'types/hoa-management-company-types';
import { ErrorValidation } from 'types/error-types';

interface HOAManagementProps {
  data: HOAManagementCompanyEntity;
  opportunityId: string;
  updateOpportunity?: (val: ObjectType) => void;
}

const HOAManagement = ({
  data,
  opportunityId,
  updateOpportunity = emptyFunction
}: HOAManagementProps) => {
  const [hoaManagementCompany, setHoaManagementComapany] =
    useState<HOAManagementCompanyEntity>({ ...data });
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleChange = (e: ObjectType) => {
    setHoaManagementComapany(
      Object.assign({}, hoaManagementCompany, {
        [e.target.name]: e.target.value
      })
    );
    setIsDataChanged(true);
  };

  const save = async () => {
    let requestBody = {
      is_self_managed: hoaManagementCompany.is_self_managed,
      contact_name: hoaManagementCompany.contact_name,
      contact_email: hoaManagementCompany.contact_email,
      contact_phone: hoaManagementCompany.contact_phone,
      contact_address: hoaManagementCompany.contact_address,
      company_phone: hoaManagementCompany.company_phone,
      name: hoaManagementCompany.name,
      opportunity_id: opportunityId
    };

    let result;
    if (hoaManagementCompany.id) {
      result = await HOAService.update(hoaManagementCompany.id, requestBody);
    } else {
      result = await HOAService.create(requestBody);
    }

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    toastSuccess(`${fieldLabel.hoaManagementCompany} Saved`);

    setHoaManagementComapany(result.data);
    updateOpportunity({
      hoa_management_company: result.data
    });
    setValidation({});
    setEditMode(!editMode);
  };

  const toggleConfirmBox = () => {
    setConfirmModal({
      open: true,
      title: fieldLabel.areYouSure,
      text: fieldLabel.changesWillDiscarded,
      proceed: () => {
        setEditMode(!editMode);
        setConfirmModal(initialConfirmModalState);
        setHoaManagementComapany(data);
        updateOpportunity({
          hoa_management_company: data
        });
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (isDataChanged) {
        toggleConfirmBox();
      } else {
        setEditMode(false);
        setIsDataChanged(false);
      }
    } else {
      setEditMode(true);
      setIsDataChanged(false);
    }
  };

  return (
    <React.Fragment>
      <Card variant="outlined">
        <CardHeader
          action={
            <UnitButtonIcon
              Icon={
                editMode
                  ? CloseIcon
                  : !isEmpty(hoaManagementCompany.id)
                  ? EditIcon
                  : AddCircleIcon
              }
              onClick={() => {
                toggleEditMode();
              }}
            />
          }
          title={fieldLabel.hoaManagementCompany}
        />
        <CardContent>
          <EditContainer>
            <FormContainer>
              <UnitText
                label={fieldLabel.hoaName}
                name="name"
                value={hoaManagementCompany.name}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
                error={validation['name'] ?? ''}
              />

              <UnitText
                label={fieldLabel.hoaManagementContactName}
                name="contact_name"
                value={hoaManagementCompany.contact_name}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 8, xs: 8 }}
                error={validation['contact_name'] ?? ''}
              />

              <UnitSwitch
                value={hoaManagementCompany.is_self_managed ?? 0}
                onChange={handleChange}
                name="is_self_managed"
                label={fieldLabel.isSelfManaged}
                disabled={!editMode}
                grid={{ sm: 4, xs: 4 }}
                error={validation['is_self_managed'] ?? ''}
              />

              <UnitText
                label={fieldLabel.phone}
                name="company_phone"
                value={hoaManagementCompany.company_phone}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
                error={validation['company_phone'] ?? ''}
              />

              <UnitText
                label={fieldLabel.hoaManagementContactEmail}
                name="contact_email"
                value={hoaManagementCompany.contact_email ?? 'N/A'}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
                error={validation['contact_email'] ?? ''}
              />

              <UnitText
                label={fieldLabel.hoaManagementContactPhone}
                name="contact_phone"
                value={hoaManagementCompany.contact_phone}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
                error={validation['contact_phone'] ?? ''}
              />

              <UnitText
                label={fieldLabel.hoaManagementContactAddress}
                name="contact_address"
                value={hoaManagementCompany.contact_address}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
                error={validation['contact_address'] ?? ''}
              />

              {editMode ? (
                <UnitItem grid={{ sm: 12, xs: 12 }}>
                  <StackRowWithDivider>
                    <SaveButton onClick={save} />
                    <CancelButton onClick={() => toggleEditMode()} />
                  </StackRowWithDivider>
                </UnitItem>
              ) : (
                <></>
              )}
            </FormContainer>
          </EditContainer>
        </CardContent>
      </Card>
      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </React.Fragment>
  );
};

export default HOAManagement;
