import Email from 'pages/email';
import ComposeEmail from 'features/email/compose';
import EmailInbox from 'features/email/email-inbox';
import EmailDraft from 'features/email/email-draft';
import EmailView from 'features/email/email-view';

const emailRoutes = {
  path: 'email',
  element: <Email />,
  children: [
    {
      path: 'compose',
      element: <ComposeEmail routeTag="compose-emails" key="compose-email" />
    },
    {
      path: 'inbox',
      element: <EmailInbox routeTag="inbox-emails" />
    },
    {
      path: 'inbox/:email_id/:action',
      element: <ComposeEmail routeTag="emails-action" key="emails-action" />
    },
    {
      path: 'inbox/:email_id',
      element: <EmailView routeTag="emails-view" />
    },
    { path: 'draft', element: <EmailDraft routeTag="draft-emails" /> },
    {
      path: 'draft/:email_id',
      element: <ComposeEmail routeTag="emails-draft-view" />
    }
  ]
};

export default emailRoutes;
