import { due_diligence_end_c } from './due_diligence_end_c';
import { isEmpty } from 'helpers/misc-helper';
import { missingField } from 'assets/validation-template';
import { ObjectType } from 'types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import fieldLabel from 'assets/constants/fieldLabel';
import DateUtility from 'helpers/date-helper';
import variableConfig from 'config/variable';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import opportunityHelper from 'helpers/opportunity-helper';

export const option_period_days_c = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: any = [];

    if (
      oppurtunity?.opportunity_status_c ===
        oppurtunityStatusList.closing_diligence_period &&
      isEmpty(oppurtunity?.option_period_days_c)
    ) {
      errors.push(missingField(fieldLabel.optionPeriodDays));
    }

    return errors;
  },
  handleChange: (opportunity: ObjectType, oldOpportunity: ObjectType) => {
    let ddEndDate = due_diligence_end_c.calculate(opportunity, oldOpportunity);

    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        ddEndDate,
        fieldLabel.dueDiligenceEndDate,
        opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
          opportunity?.option_days_type_c
        )
      );

    ddEndDate = DateUtility.addHourInDate(
      validDate,
      variableConfig.DUE_DILIGENCE_END_START_TIME
    );

    let result = {
      due_diligence_end_c: ddEndDate,
      due_diligence_end_c_federal_holiday_validation_message: message,
      ...due_diligence_end_c.handleChange({
        ...opportunity,
        due_diligence_end_c: ddEndDate
      })
    };
    return result;
  }
};
