import {
  TransactionHistoryFilterEntity,
  TransactionHistoryListEntity
} from 'types/transaction-history-types';
import { ChangeEvent } from 'types/common-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'helpers/misc-helper';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/transaction-history/default-sort';
import Filters from './filters';
import initialTransactionHistoryList from 'state/transaction-history/initial-transaction-history-list-filter';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import transactionHistoryColumn from 'assets/list/transaction-history/column';
import transactionHistoryService from 'services/transaction-history-service';
import useRouteName from 'pages/route-outlet-context';

const TransactionHistoriesList = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [filter, setFilter] = useState<TransactionHistoryFilterEntity>(
    initialTransactionHistoryList
  );

  const updateFilter = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getTransactionHistories = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<TransactionHistoryListEntity>> => {
    const filterInfo = isEmpty(opportunity_id)
      ? filter
      : { ...filter, opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await transactionHistoryService.getList(queryString);

    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
        isLoading={isLoading}
      />

      <DataGrid<TransactionHistoryListEntity>
        getData={getTransactionHistories}
        tableColumns={transactionHistoryColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </>
  );
};

export default TransactionHistoriesList;
