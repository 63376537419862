enum extensionRequest {
  seller_request = 'Seller request',
  buyer_request = 'Buyer request',
  legal_holiday = 'Legal Holiday',
  pending_title_work = 'Pending Title Work',
  delay_in_receiving_prelims = 'Delay in receiving Prelims',
  funding_delay = 'Funding Delay',
  pending_signed_seller_closing_package = 'Pending Signed Seller Closing Package',
  pending_buyer_signed_closing_package = 'Pending Buyer Signed Closing Package',
  dd_extended = 'DD Extended',
  overvalue = 'Overvalue',
  exceeds_rehab_budget = 'Exceeds Rehab Budget'
}

export default extensionRequest;
