import envConfig from 'config/env';
import httpService from './http-service';
import { CreateResponse, GetResponse, UpdateResponse } from 'types/api-response-types';
import { HOAManagementCompanyPayloadEntity, HOAManagementCompanyEntity } from 'types/hoa-management-company-types';
import initialHoaManagementCompany from 'state/hoa-management-company/initial-hoa-management-company';

const service = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/hoa_management_company`,

  get: async (query: string): Promise<GetResponse<HOAManagementCompanyEntity>> => {
    const result = await httpService.get<HOAManagementCompanyEntity>(`${service.url}${query}`);
    return result;
  },
  create: async (data: HOAManagementCompanyPayloadEntity): Promise<CreateResponse<HOAManagementCompanyEntity>> => {
    const result = await httpService.post<HOAManagementCompanyEntity, HOAManagementCompanyPayloadEntity>(`${service.url}`, data);
    return result;
  },

  update: async (id: string, data: HOAManagementCompanyPayloadEntity): Promise<UpdateResponse<HOAManagementCompanyEntity>> => {
    const result = await httpService.put<HOAManagementCompanyEntity, HOAManagementCompanyPayloadEntity>(`${service.url}/${id}`, data);
    return result;
  },

  getHoaDetails: (data: HOAManagementCompanyEntity): HOAManagementCompanyEntity => {
    return data?.id ? data : initialHoaManagementCompany;
  }
};

export default service;
