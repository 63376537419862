import { AsyncSelect } from 'components/async-select/async-select';
import { FormControl, Grid } from '@mui/material';
import { OptionType } from 'types/option-type';
import FieldLabel from './field-label';
import knowledgeBaseService from 'services/knowledge-base-service';
import React, { useState } from 'react';
import ValidationError from 'components/errors/validation-error';
import { KnowledgeBaseListEntity } from 'types/knowledge-base-types';

interface UnitKnowledgeBaseTitleInterface {
  grid?: {
    xs: number;
    sm: number;
  };
  value: OptionType;
  label: string;
  fullWidth?: boolean;
  onChange: (e: OptionType) => void;
  error?: [];
  disabled?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
}

const UnitKnowledgeBaseTitle = ({
  value,
  label,
  onChange,
  grid = { xs: 12, sm: 6 },
  fullWidth = true,
  error = [],
  readOnly = false,
  disabled = false,
  multiple
}: UnitKnowledgeBaseTitleInterface) => {
  const [options, setOptions] = useState<OptionType[]>([]);

  const onChangeHandle = async (value: string) => {
    let query = `?filter[name]=${value}`;
    const result = await knowledgeBaseService.getList(query);

    if (result.isSuccess) {
      const knowledgeBases = result.data.data;
      setOptions(
        knowledgeBases.map((knowledgeBase: KnowledgeBaseListEntity) => ({
          label: knowledgeBase.name,
          value: knowledgeBase.id,
          ...knowledgeBase
        }))
      );
    }
  };

  if (readOnly) {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth={fullWidth}>
          <AsyncSelect
            multiple={multiple || false}
            options={options}
            value={value || null}
            id="name"
            label=""
            name="name"
            setOptions={setOptions}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth>
          <AsyncSelect
            multiple={multiple || false}
            options={options}
            value={value || null}
            id="name"
            label=""
            name="name"
            setOptions={setOptions}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
            disabled={disabled}
          />
        </FormControl>
        {error.length > 0 && <ValidationError data={error} />}
      </Grid>
    );
  }
};
export default UnitKnowledgeBaseTitle;
