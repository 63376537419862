import { TaskListEntity } from 'types/task-types';
import app from 'config/app';
import { GetListResponse } from 'types/api-response-types';
import httpService from './http-service';

const tasksService = {
  url: `/${app.crmApiVersion1}/tasks`,

  getList: async (query: string): Promise<GetListResponse<TaskListEntity>> => {
    const result = await httpService.list<TaskListEntity>(
      `${tasksService.url}${query}`
    );
    return result;
  }
};

export default tasksService;
