import DraftsIcon from '@mui/icons-material/Drafts';

import buttonText from 'assets/constants/button';
import SecondaryButton from 'components/button/button-secondary';
import { ClickEvent } from 'types/common-types';

interface ButtonInterface {
  onClick: (e: ClickEvent) => void;
  disabled?: boolean;
}

const DraftButton = ({ disabled = false, ...props }: ButtonInterface) => {
  return (
    <SecondaryButton
      variant="contained"
      size="medium"
      name="btn-draft"
      color={'info'}
      onClick={(e: ClickEvent) => {
        if (props.onClick) props.onClick(e);
      }}
      startIcon={<DraftsIcon />}
      disabled={disabled}
    >
      {buttonText.draft}
    </SecondaryButton>
  );
};

export default DraftButton;
