const initialMarketPreferenceListFilter = {
  id: '',
  name: '',
  msa_id: '',
  msa_name: '',
  account_id:'',
  account_name: ''
};

export default initialMarketPreferenceListFilter;
