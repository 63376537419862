import { isChecked } from 'components/form/unit-switch';
import { ObjectType } from 'types';
import DateUtility from 'helpers/date-helper';

export const is_offer_blocked_resolved_at = {
  calculate: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    const currentUser = JSON.parse(localStorage.getItem('user') || 'false');
    if (
      isChecked(opportunity.is_offer_blocked_resolved) &&
      currentUser?.user?.id === originalOpportunity.offer_blocked_resolved_by
    ) {
      return originalOpportunity.is_offer_blocked_resolved_at;
    }
    if (!isChecked(opportunity.is_offer_blocked_resolved)) {
      return null;
    }
    if (isChecked(opportunity.is_offer_blocked_resolved)) {
      return DateUtility.getTodayDateTimeString();
    }
    return originalOpportunity.is_offer_blocked_resolved_at;
  }
};
