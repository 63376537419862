import { MenuItem } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import DropdownMenu from 'components/dropdown-menu';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import DateUtility from 'helpers/date-helper';
import { useParams } from 'react-router-dom';
import { ObjectType } from 'types';
import { EmailListEntity } from 'types/email-types';
import { v4 as uuid } from 'uuid';

export const Action = ({ email_id }: { email_id: string }) => {
  const { opportunity_id } = useParams<ObjectType>();

  const ddMenuItems = [];
  ddMenuItems.push(
    <MenuItem key={uuid()}>
      <RouteLinkBlack
        name={fieldLabel.replyAll}
        url={
          opportunity_id
            ? `/opportunities/${opportunity_id}/email/inbox/${email_id}/replyAll`
            : `/email/inbox/${email_id}/replyAll`
        }
      />
    </MenuItem>
  );
  ddMenuItems.push(
    <MenuItem key={uuid()}>
      <RouteLinkBlack
        name={fieldLabel.forward}
        url={
          opportunity_id
            ? `/opportunities/${opportunity_id}/email/inbox/${email_id}/forward`
            : `/email/inbox/${email_id}/forward`
        }
      />
    </MenuItem>
  );

  return (
    <>{ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}</>
  );
};

export const emailInboxColumns: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => (
      <Action email_id={row.id} />
    ),
    flex: 0.5,
    minWidth: 120
  },
  {
    headerName: fieldLabel.opportunity,
    field: 'opportunity_name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      return (
        <RouteLink
          url={`/opportunities/${row.opportunity_id}/view`}
          name={row.opportunity_name}
        />
      );
    }
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      const { opportunity_id } = useParams<ObjectType>();
      return (
        <RouteLink
          url={
            opportunity_id
              ? `/opportunities/${opportunity_id}/email/inbox/${row.id}`
              : `/email/inbox/${row.id}`
          }
          name={row.name}
        />
      );
    }
  },
  {
    headerName: fieldLabel.subject,
    field: 'subject',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      const { opportunity_id } = useParams<ObjectType>();
      return (
        <RouteLink
          url={
            opportunity_id
              ? `/opportunities/${opportunity_id}/email/inbox/${row.id}`
              : `/email/inbox/${row.id}`
          }
          name={row.name}
        />
      );
    }
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: false,
    flex: 1
  },

  {
    headerName: fieldLabel.createdDate,
    field: 'date_entered',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  }
];
