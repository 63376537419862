const initialProperityServiceProvider = {
    id: '',
    opportunity_id: '',
    electric_provider: '',
    water_well_provider: '',
    sewer_provider: '',
    gas_provider: '',
    trash_provider: '',
    is_septic: 0,
    is_well: 0,
    is_electric: 0
};

export default initialProperityServiceProvider;
