import { InputChangeEvent } from 'types/common-types';
import { KnowledgeBaseEntity } from 'types/knowledge-base-types';
import { ObjectType } from 'types';
import { OptionType } from 'types/option-type';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import knowledgeBaseStatus from 'assets/constants/knowledge-base-status-type';
import UnitKBCategories from 'components/form/unit-kb-categories';
import UnitMdEditor from 'components/form/unit-md-editor';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';

interface recordViewType {
  knowledgeBase: KnowledgeBaseEntity;
  validation?: ObjectType;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
}

const RecordView = ({
  knowledgeBase,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.title}
        name="name"
        value={knowledgeBase.name ?? ''}
        onChange={handleChange}
        error={valMessages['name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitSelect
        name="status"
        label={fieldLabel.status}
        records={getObjectEntriesAsArray(knowledgeBaseStatus)}
        value={knowledgeBase.status ? knowledgeBase.status : ''}
        onChange={handleChange}
        error={valMessages['status'] ?? ''}
        hasBlankOption={false}
        readOnly={readOnly}
      />

      <UnitKBCategories
        label={fieldLabel.knowledgeBaseCategory}
        value={{
          label: knowledgeBase.knowledge_base_category?.name,
          value: knowledgeBase.knowledge_base_category?.id
        }}
        readOnly={readOnly}
        onChange={(value: OptionType | ObjectType) => {
          handleChange({
            target: {
              name: 'knowledge_base_category',
              value: {
                name: value?.label ?? '',
                id: value?.value ?? ''
              }
            }
          });
        }}
      />

      <UnitMdEditor
        grid={{ xs: 12, sm: 12 }}
        pb={0.1}
        value={knowledgeBase.description}
        readOnly={readOnly}
        label="Body"
        onChange={(value: string) => {
          handleChange({
            target: { name: 'description', value: value || '' }
          });
        }}
        error={valMessages['description']}
        required
      />
    </FormContainer>
  );
};

export default RecordView;
