import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import { balance_to_close_c } from './balance_to_close_c';
import { OpportunityEntity } from 'types/opportunity-entity';

export const earnest_amount_c = {
  validate: function (oppurtunity: OpportunityEntity, status: string, oldOppurtunity: OpportunityEntity) {
    let errors: string[] = [];

    if (
      oppurtunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period &&
      isEmpty(oppurtunity?.earnest_amount_c)
    ) {
      errors.push(missingField('Earnest Amount'));
    }

    return errors;
  },
  handleChange: (opportunity: OpportunityEntity, originalOpportunity: OpportunityEntity) => {
    let result = {
      balance_to_close_c: balance_to_close_c.calculate(opportunity, originalOpportunity),
      earnest_amount_c: Math.round(convertNumber(opportunity.earnest_amount_c))
    };

    return result;
  }
};
