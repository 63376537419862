import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { validateMSA } from 'helpers/validation/msa-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import msaService from 'services/msa-service';
import initialMsa from 'state/msa/initial-msa';
import { MsaEntity } from 'types/msa-types';
import RecordView from '../record-view';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';
import { ObjectType } from 'types';

const MsaCreate = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { msa_id } = useParams<{ msa_id: string }>();
  const [msa, setMsa] = useState<MsaEntity>(initialMsa);
  const [validation, setValidation] = useState<ErrorValidation | ObjectType>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: InputChangeEvent): void => {
    setMsa(Object.assign({}, msa, { [e.target.name]: e.target.value }));
  };

  const loadMsa = async (msa_id: string): Promise<void> => {
    const response = await msaService.get(msa_id);
    setMsa(response.data);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const { status, ...errors } = validateMSA(msa);

    setValidation(errors);

    if (!status) return;

    let response;

    setIsLoading(true);

    const requestBody: MsaEntity = {
      name: msa.name,
      entera_market_id: msa.entera_market_id,
      code: msa.code,
      initial_commission: msa.initial_commission,
      state: msa.state,
      mls_code: msa.mls_code,
      has_municipal_inspections: msa.has_municipal_inspections
    };

    if (msa_id) {
      response = await msaService.update(msa_id, requestBody);
    } else {
      response = await msaService.create(requestBody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
    }

    if (response.isSuccess) {
      window.history.back();
    }
  };

  useEffect(() => {
    if (msa_id) loadMsa(msa_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <RecordView
              msa={msa}
              validation={validation}
              onChange={handleChange}
            />

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default MsaCreate;
