import { OpportunityEntity } from 'types/opportunity-entity';
import { UpdateResponse } from 'types/api-response-types';
import envConfig from 'config/env';
import httpService from './http-service';

const brokerageActionService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/actions`,

  update: async <T>(
    id: string,
    data: T,
    action: string
  ): Promise<UpdateResponse<OpportunityEntity>> => {
    const response = await httpService.put<OpportunityEntity, T>(
      `${brokerageActionService.url}/${action}/${id}`,
      data
    );
    return response;
  }
};

export default brokerageActionService;
