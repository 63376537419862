import ForgotForm from 'features/forgot/forgot-form';
import React from 'react';

const ForgotPage: React.FC = () => {
  return (
    <>
      <ForgotForm />
    </>
  );
};

export default ForgotPage;
