import Menu from './menu';
import Drawer from '@mui/material/Drawer';
import { useContext } from 'react';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import Icon from '@mui/material/Icon';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { setItem } from 'helpers/local-storage-helper';

interface MenuItemInterface {
  items: {};
}

const SideNavPanel = (props: MenuItemInterface) => {
  const {
    mobileSideNavOpen,
    handleMobileSideNav,
    sidePanelWidth,
    setSidePanelWidth
  } = useContext(LayoutProvider);

  const toggleSidePanel = () => {
    const width = sidePanelWidth == 230 ? 20 : 230;
    setSidePanelWidth(width);
    setItem('side-panel-width', `${width}`);
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileSideNavOpen}
        onClose={() => {
          toggleSidePanel();
          handleMobileSideNav();
        }}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sidePanelWidth
          }
        }}
      >
        {sidePanelWidth === 230 ? (
          <Menu items={props.items} handleCloseUserMenu={handleMobileSideNav} />
        ) : (
          <Menu></Menu>
        )}
      </Drawer>

      <Icon
        sx={{
          position: 'absolute',
          top: '72px',
          left: `${sidePanelWidth - 16}px`,
          height: '25px',
          width: '25px',
          borderRadius: '50%',
          zIndex: 1000,
          color: '#000',
          cursor: 'pointer',
          boxShadow: 'rgba(11, 11, 11, 0.2) 0px 2px 8px 0px',
          display: { xs: 'none', sm: 'block' },
          backgroundColor: 'white'
        }}
        onClick={toggleSidePanel}
      >
        {sidePanelWidth === 230 ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </Icon>

      <Drawer
        variant="permanent"
        sx={{
          width: sidePanelWidth,
          display: { xs: 'none', sm: 'block' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sidePanelWidth
          },
          '& .MuiPaper-elevation': {
            zIndex: '999',
            top: '7vh',
            height: 'calc(100vh - 7vh)',
            overflow: 'auto'
          }
        }}
        open
      >
        {sidePanelWidth === 230 ? (
          <Menu items={props.items} handleCloseUserMenu={handleMobileSideNav} />
        ) : (
          <Menu></Menu>
        )}
      </Drawer>
    </>
  );
};

export default SideNavPanel;
