import envConfig from 'config/env';
import httpService from './http-service';
import { OpportunityServiceProviderEntity, OpportunityServiceProviderPayloadEntity } from 'types/opportunity-service-provider-types';
import { isEmpty } from 'helpers/misc-helper';
import initialOpportuityServiceProvider from 'state/opportunity-service-provider/initial-opportunity-service-provider';
import { GetResponse } from 'types/api-response-types';

const opportunityServiceProvidersService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/opportunity_service_providers`,

  create: async (data: OpportunityServiceProviderPayloadEntity): Promise<GetResponse<OpportunityServiceProviderEntity>> => {
    const result = await httpService.post<OpportunityServiceProviderEntity, OpportunityServiceProviderPayloadEntity>(
      `${opportunityServiceProvidersService.url}`,
      data
    );
    return result;
  },

  update: async (id: string, data: OpportunityServiceProviderPayloadEntity): Promise<GetResponse<OpportunityServiceProviderEntity>> => {
    const result = await httpService.put<OpportunityServiceProviderEntity, OpportunityServiceProviderPayloadEntity>(
      `${opportunityServiceProvidersService.url}/${id}`,
      data
    );
    return result;
  },

  getList: async (query: string) => {
    const result = await httpService.list<OpportunityServiceProviderEntity>(
      `${opportunityServiceProvidersService.url}${query}`
    );
    return result;
  },

  getFeeAdvances: (data: OpportunityServiceProviderEntity[]): OpportunityServiceProviderEntity[] => {

    let fee_advances = data.filter((x) => x?.service_provider && x?.service_provider?.type == 'fee_advance');

    let result = [];

    for (let index = 0; index < 2; index++) {
      const fee_advance = fee_advances[index];
      if (!isEmpty(fee_advance)) {
        result.push(fee_advance)
      } else {
        result.push(initialOpportuityServiceProvider)
      }
    }

    return result;
  },

  getExpense: (data: OpportunityServiceProviderEntity[]): OpportunityServiceProviderEntity[] => {
    const expenses = data.filter(
      (x) => x.service_provider.type == 'expense'
    );

    let result = [];

    for (let index = 0; index < 2; index++) {
      const expense = expenses[index];
      if (!isEmpty(expense)) {
        result.push(expense)
      } else {
        result.push(initialOpportuityServiceProvider)
      }
    }

    return result;

  }
};

export default opportunityServiceProvidersService;
