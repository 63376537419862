import menuHelper from 'helpers/menu-helper';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import sideManuHelper from './side-menu-helper';
import ContainerRight from 'components/container/right';
import Breadcrumbs from 'features/breadcrumbs';
import { MenuItemType } from 'types/menu-types';

const Document = (): JSX.Element => {
  const { setSideNavMenuItems } = useContext(LayoutProvider);
  const [routeName, setRouteName] = useState('');
  const param = useParams<{ document_id: string }>();

  const loadMenuItems = async (): Promise<MenuItemType[]> => {
    const menus: MenuItemType[] = await sideManuHelper.getMenuItems(routeName);
    return menus;
  };

  const getMenus = async (): Promise<void> => {
    const menusToshow: MenuItemType[] = menuHelper.createMenusItems(
      await loadMenuItems(),
      param.document_id
    );

    setSideNavMenuItems(menusToshow);
  };

  useEffect(() => {
    getMenus();
  }, [routeName]);

  return (
    <>
      <Breadcrumbs />
      <ContainerRight>
        <Outlet context={{ routeName, setRouteName }} />
      </ContainerRight>
    </>
  );
};

export default Document;
