import { useGoogleLogin } from '@react-oauth/google';
import LoginWithGoogleButton from './button-login-with-google';
import { AuthenticationEntityResponse } from 'types/authentication';
import authService from 'services/auth-service';
import userService from 'services/user-service';
import { toastError } from 'event/toast-event';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import { AuthContext } from 'hooks/auth/auth-context';
import { useContext } from 'react';
import React from 'react';
import { DispatchSetState } from 'types/common-types';

const GoogleLoginButton = ({
  loading,
  setLoading,
  label
}: {
  loading: {
    manualLogin: boolean;
    googleLogin: boolean;
  };
  setLoading: DispatchSetState<{
    manualLogin: boolean;
    googleLogin: boolean;
  }>;
  label: string;
}) => {
  const { dispatch } = useContext(AuthContext);

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse: any) => {
      console.log('codeResponse', codeResponse);
      setLoading((prevLoading) => ({ ...prevLoading, googleLogin: true }));
      const authResult: AuthenticationEntityResponse =
        await authService.loginWithGoogle(codeResponse.code, codeResponse.hd);
      setLoading((prevLoading) => ({ ...prevLoading, googleLogin: false }));

      if (authResult.isError) {
        toastError(authResult.errorMessage);
        return;
      }

      const loggedInUser = await userService.getCurrentUser();

      eventBus.dispatch(EVENTS.HIDE_TOAST, {});
      eventBus.dispatch('LOAD_PERMISSION', {});
      dispatch({
        type: 'LOGIN',
        payload: {
          user: loggedInUser,
          token: authResult.data.access_token
        }
      });
    },
    onError: (error) => {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        message: 'Fail to load with Google',
        isError: true
      });
    }
  });

  return (
    <LoginWithGoogleButton
      fullWidth
      loading={loading.googleLogin}
      disabled={loading.googleLogin}
      sx={{ mt: 3, mb: 2 }}
      onClick={() => loginWithGoogle()}
      label={label}
    />
  );
};

export default GoogleLoginButton;
