import fieldLabel from 'assets/constants/fieldLabel';
import { isEmpty } from 'helpers/misc-helper';
import React, { useEffect } from 'react';

interface BreadcrumbsMetadata {
  label: string;
  skipBreadcrumbs?: boolean;
  to: string;
  type: string;
}

const PageTitle = ({
  breadcrumbsMetadata
}: {
  breadcrumbsMetadata: BreadcrumbsMetadata[];
}) => {
  useEffect(() => {
    const getPageTitle = (breadcrumbsMetadata: BreadcrumbsMetadata[]) => {
      if (
        breadcrumbsMetadata.length === 1 &&
        breadcrumbsMetadata[0].label === fieldLabel.compose
      ) {
        return breadcrumbsMetadata[0].label;
      }
      if (
        breadcrumbsMetadata.length > 1 &&
        breadcrumbsMetadata[0].label === fieldLabel.dashboard
      ) {
        return `${breadcrumbsMetadata[1].label} ${fieldLabel.dashboard}`;
      }

      const entity = breadcrumbsMetadata.find((x) => x.type === 'entity');

      if (!isEmpty(entity)) return entity?.label;

      const isCreate = breadcrumbsMetadata.find(
        (x: BreadcrumbsMetadata) =>
          x.type === 'action' && x.label === fieldLabel.create
      );

      const module = breadcrumbsMetadata.find(
        (x: BreadcrumbsMetadata) => x.type === 'list'
      );

      if (isCreate) return `${fieldLabel.create} ${module?.label}`;

      return module?.label;
    };

    document.title = `Entera Brokerage CRM`;

    const newPageTitle = getPageTitle(breadcrumbsMetadata);

    if (!isEmpty(newPageTitle)) {
      document.title = `${newPageTitle}`;
    }
  }, [breadcrumbsMetadata]);

  return <></>;
};

export default PageTitle;
