import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/side-menu-item-type';

const sideManuHelper: SideMenuHelperType  = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'knowledge-bases') {
      return await menuHelper.getItems('Knowledge_Base', 'list');
    }

    if (routeName === 'knowledge-bases-view') {
      return await menuHelper.getItems('Knowledge_Base', 'view');
  }

    if (routeName === 'knowledge-bases-edit') {
      return await menuHelper.getItems('Knowledge_Base', 'edit');
    }

    if (routeName === 'knowledge-bases-create') {
      return await menuHelper.getItems('Knowledge_Base', 'create');
    }

    return [];
  }
};

export default sideManuHelper;
