import { OpportunityEntity } from "types/opportunity-types";

export const getInitialState = (opportunity: OpportunityEntity) => {

    return {
        opportunity_status_c: opportunity.opportunity_status_c || '',
        close_date_c: opportunity.close_date_c || '',
        forecasted_dd_end_date: opportunity.forecasted_dd_end_date || '',
        forecasted_close_date: opportunity.forecasted_close_date || '',
        extension_request_reason: opportunity.extension_request_reason || '',
        original_close_of_escrow_date:
            opportunity.original_close_of_escrow_date || '',
        due_diligence_end_c: opportunity.due_diligence_end_c || ''
    }

}

export default { getInitialState }