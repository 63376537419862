import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import msaService from 'services/msa-service';
import initialMsa from 'state/msa/initial-msa';
import { MsaEntity } from 'types/msa-types';
import RecordView from '../record-view';
import { moduleLabels } from 'assets/list/tracker/constant';
import trackerService from 'services/tracker-service';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const MsaView = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { msa_id } = useParams<{ msa_id: string }>();
  const { setRouteName } = useRouteName();
  const [msa, setMsa] = useState<MsaEntity>(initialMsa);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadMsa = async (msa_id: string): Promise<void> => {
    const response = await msaService.get(msa_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setMsa(response.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Msa.label,
      item_id: msa_id,
      item_summary: response.data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (msa_id) loadMsa(msa_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView msa={msa} readOnly={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default MsaView;
