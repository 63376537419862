import ViewChangelog from 'features/view-changelog';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import useRouteName from 'pages/route-outlet-context';

const MarketPreferenceBrokerageUserViewChangelog = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { market_preference_brokerage_user_id } = useParams<{
    market_preference_brokerage_user_id: string;
  }>();
  const [filter, setFilter] = useState<{
    market_preference_brokerage_user_id: string;
  }>({
    market_preference_brokerage_user_id: market_preference_brokerage_user_id!
  });
  const { setRouteName } = useRouteName();

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="market_preference_brokerage_user_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="market_preference_brokerage_users"
      />
    </>
  );
};

export default MarketPreferenceBrokerageUserViewChangelog;
