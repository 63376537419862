import { CallsFilterEntity, CallsListEntity } from 'types/calls-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { ObjectType } from 'types';
import { useParams } from 'react-router-dom';
import callColumn from 'assets/list/call/column';
import callsService from 'services/calls-service';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/call/default-sort';
import Filters from './filters';
import initialCallListFilter from 'state/call/initial-calls-list-filter';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import { ChangeEvent } from 'types/common-types';

const CallsList = ({ routeTag }: { routeTag: string }) => {
  const { opportunity_id } = useParams<ObjectType>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const { setRouteName } = useRouteName();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<CallsFilterEntity>(
    initialCallListFilter
  );

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getCalls = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<CallsListEntity>> => {
    const filterInfo =
      opportunity_id === undefined ? filter : { ...filter, opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });
    setIsLoading(true);

    const result = await callsService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <React.Fragment>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
        isLoading={isLoading}
      />

      <DataGrid<CallsListEntity>
        tableColumns={callColumn}
        getData={getCalls}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default CallsList;
