

const initialOpportuityServiceProvider = {
    id: '',
    name: '',
    opportunity_id: '',
    service_provider_id: '',
    service_provider: {
        id: '',
        name: '',
        type: '',
    },
    service_type: '',
    service_amount: '',
    service_date: '',
    date_modified: ''
}

export default initialOpportuityServiceProvider;