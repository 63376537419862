import { Alert, Box, IconButton, List, ListItem } from '@mui/material';
import { ObjectType } from 'types';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import emptyFunction from 'helpers/empty-function-helper';
import FormContainer from 'components/form/container';
import ModalTitle from 'components/modal/modal-title';
import React, { ReactNode } from 'react';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitItem from 'components/form/unit-item';
import fieldLabel from 'assets/constants/fieldLabel';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import documentTags from 'assets/constants/document-tags';
import documentSubType from 'assets/constants/document-sub-type';
import { OptionType } from 'types/option-type';
import documentType from 'assets/constants/document-type';

type LabelsType = {
  [key: string]: string;
};

export const DocumentWarningBanner = ({
  notesFiles
}: {
  notesFiles: ObjectType[];
}) => {
  const requiredCategories = ['purchase_contract', 'proof_of_funds'] as const;
  const attachedCategories = notesFiles.map((doc) => doc.category_id);

  const missingCategories = requiredCategories.filter((category) => {
    return !attachedCategories.includes(category);
  });

  const labels: LabelsType = {
    purchase_contract: 'PSA',
    proof_of_funds: 'POF'
  };

  const getWarningMessage = () => {
    if (missingCategories.length === 0) return null;

    const missingLabels = missingCategories.map((category) => labels[category]);

    if (missingCategories.length === 2) {
      return `Warning: ${missingLabels.join(' and ')} Not Found`;
    }
    return `Warning: ${missingLabels[0]} Not Found`;
  };

  const warningMessage = getWarningMessage();

  return (
    <>{warningMessage && <Alert severity="warning">{warningMessage}</Alert>}</>
  );
};

interface FilePanelProps {
  readOnly: boolean;
  savedFiles?: ObjectType[];
  unSavedFiles?: File[];
  notesFiles: ObjectType[];
  removeFile?: (flag: number, index: number) => void;
  title?: string;
}

const FilePanel = ({
  readOnly = false,
  savedFiles = [],
  unSavedFiles = [],
  notesFiles,
  removeFile = emptyFunction,
  title = fieldLabel.crmDocuments
}: FilePanelProps) => {
  const FileListContainer = (props: {
    listId: string;
    title: string;
    children: ReactNode;
  }) => {
    return (
      <>
        <ModalTitle value={props.title} />
        <Box
          sx={{
            minHeight: '0px',
            maxHeight: '50vh',
            overflowY: 'auto',
            border: '1px solid  #ccc'
          }}
        >
          <List id={props.listId}>{props.children}</List>
        </Box>
      </>
    );
  };

  const FileListItem = (props: {
    index: number;
    file: ObjectType;
    flag: number;
  }) => {
    const { index, file, flag } = props;
    const rowDocumentType =
      documentSubType[file.category_id]
        ?.map((x: OptionType) =>
          x.value === file.document_subtype ? x.label : ''
        )
        .filter(Boolean) || [];

    const subType =
      rowDocumentType.length > 0 ? rowDocumentType : file.document_subtype;

    return (
      <ListItem
        className="mb-1"
        sx={{
          wordWrap: 'break-word'
        }}
        secondaryAction={
          !readOnly && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => removeFile(flag, index)}
              color="inherit"
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      >
        <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
          {`${file.name}`}
          <div style={{ marginTop: '4px', fontSize: '12px' }}>
            {`${getObjectKeyValue(documentType, file.category_id)}${
              subType ? ` • ${subType}` : ''
            }${
              file.document_tag
                ? ` • ${getObjectKeyValue(documentTags, file.document_tag)}`
                : ''
            }`}
          </div>
          <StackRowWithDivider />
        </UnitItem>
      </ListItem>
    );
  };

  return (
    <>
      <FormContainer>
        <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
          {(savedFiles.length > 0 || notesFiles.length > 0) && (
            <FileListContainer title={title} listId="crm_documents">
              {notesFiles.map((file: ObjectType, index: number) => (
                <FileListItem key={uuid()} file={file} flag={2} index={index} />
              ))}
              {savedFiles.map((file: ObjectType, index: number) => (
                <FileListItem key={uuid()} file={file} flag={1} index={index} />
              ))}
            </FileListContainer>
          )}
        </UnitItem>
        <UnitItem
          grid={{ xs: 12, sm: 12 }}
          p={0}
          sx={{
            paddingTop:
              savedFiles.length === 0 ? '0px !important' : '16px !important'
          }}
        >
          {unSavedFiles.length > 0 && (
            <>
              <FileListContainer
                title={fieldLabel.localDocuments}
                listId="local_documents"
              >
                {unSavedFiles.map((file: File, index: number) => (
                  <FileListItem
                    key={uuid()}
                    file={file}
                    flag={0}
                    index={index}
                  />
                ))}
              </FileListContainer>
            </>
          )}
        </UnitItem>
      </FormContainer>
    </>
  );
};

export default FilePanel;
