import partySubtype from 'assets/constants/party-subtype';
import { moduleLabels } from 'assets/list/tracker/constant';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import partyService from 'services/party-service';
import trackerService from 'services/tracker-service';
import initialParty from 'state/party/initial-party';
import { PartyEntity } from 'types/party-types';
import RecordView from '../record-view';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { OptionType } from 'types/option-type';

const PartyView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();
  const { party_id } = useParams<{
    party_id: string;
  }>();
  const [party, setParty] = useState<PartyEntity>(initialParty);
  const [subTypeOption, setSubTypeOption] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadParty = async (party_id: string) => {
    const response = await partyService.get(party_id);
    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }
    setParty(response.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Parties.label,
      item_id: party_id,
      item_summary: response.data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (party_id) loadParty(party_id);
  }, []);

  useEffect(() => {
    setSubTypeOption(partySubtype[party.type] || []);
  }, [party.type]);

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView
            party={party}
            readOnly={true}
            subTypeOption={subTypeOption}
          />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default PartyView;
