import fieldLabel from 'assets/constants/fieldLabel';
import { useEffect } from 'react';

const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = `${fieldLabel.entera} - ${title}`;
  }, [title]);
};

export default usePageTitle;
