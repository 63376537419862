
const initilaPropertiesFilter = {
    opportunity_status_c: [],
    property_address_c: '',
    entera_opportunity_id: '',
    mls_c: '',
    street_name: '',
    mls_status_c: [],
    account_id: [],
    msa_id: [],
    seller_offer_response: [],
    offer_date_c_range: '',
    offer_date_c: '',
    offer_date_to_c: '',
    next_seller_contact_date_range: '',
    next_seller_contact_date: '',
    next_seller_contact_date_to: '',
    close_date_c_range: '',
    close_date_c: '',
    close_date_to_c: '',
};


export default initilaPropertiesFilter