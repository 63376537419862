enum stateOfPreOffers {
  pre_offer_request = `Pre-Offer: Request`,
  pre_offer_incomplete = `Pre-Offer: Incomplete`,
  pre_offer_need_signature = `Pre-Offer: Need Signature`,
  pre_offer_make_loi = `Pre-Offer: Make LOI`,
  pre_offer_loi_sent_to_seller = `Pre-Offer: LOI Sent to Seller`,
  pre_offer_loi_seller_received = `Pre-Offer: LOI Seller Received`,
  pre_offer_loi_rejected = `Pre-Offer: LOI Rejected`,
  pre_offer_loi_ready_for_offer = `Pre-Offer: LOI Ready For Offer`
};

export default stateOfPreOffers;