import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import changelogColumn from 'assets/list/view-changlog/column';
import defaultSort from 'assets/list/view-changlog/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import { isEmpty } from 'helpers/misc-helper';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useState } from 'react';
import ViewChangeLogService from 'services/view-change-log-service';
import { GetListResponse } from 'types/api-response-types';
import { InputChangeEvent } from 'types/common-types';
import { OptionType } from 'types/option-type';
import { ViewChangelogEntity } from 'types/view-changelog-types';

import Filters from './filter';

interface ViewChangeLogProps<T> {
  filter: T;
  action: string;
  fieldAction: string;
  setFilter: (val: T) => void;
}

const ViewChangelog = <
  T extends Record<string, OptionType | OptionType[] | string>
>({
  setFilter,
  filter,
  action,
  fieldAction
}: ViewChangeLogProps<T>) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: InputChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getLogs = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<ViewChangelogEntity>> => {
    const { field_name, ...filterInfo } = filter;

    let filterString = '';
    if (!isEmpty(field_name)) {
      if (!isEmpty(field_name) && Array.isArray(field_name)) {
        for (const field of field_name) {
          filterString += `&filter[field_name][]=${field.value}`;
        }
      } else if (!Array.isArray(field_name) && typeof field_name !== 'string') {
        filterString += `&filter[field_name]=${field_name?.value}`;
      }
    }

    let queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    queryString = `${queryString}${filterString}`;
    setIsLoading(true);

    const result = await ViewChangeLogService.getList(action, queryString);

    setIsLoading(false);
    return result;
  };

  return (
    <>
      {fieldAction ? (
        <Filters
          filter={filter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          setFilter={setFilter}
          fieldAction={fieldAction}
        />
      ) : (
        <></>
      )}

      <DataGrid<ViewChangelogEntity>
        getData={getLogs}
        tableColumns={changelogColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </>
  );
};

export default ViewChangelog;
