
const initialHoaManagementCompany = {
    id: '',
    name: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    contact_address: '',
    company_phone: '',
    opportunity_id: '',
    is_self_managed: 0

}

export default initialHoaManagementCompany