import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import brokerageTransactionRole from 'assets/constants/brokerage-transaction-roles';
import crmStatusType from 'assets/constants/crm-status-type';
import fieldLabel from 'assets/constants/fieldLabel';
import UnitSwitch from 'components/form/unit-switch';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import ActionDropdown from 'features/opportunity-brokerage-users/action';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import userFullName from 'helpers/user-name-helper';
import { useParams } from 'react-router-dom';
import { OpportunityBrokerageUserListEntity } from 'types/opportunity-brokerage-user-types';

const Action = ({
  opportunityBrokerageUser
}: {
  opportunityBrokerageUser: OpportunityBrokerageUserListEntity;
}) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  return (
    <>
      {!isEmpty(opportunity_id) && (
        <ActionDropdown data={opportunityBrokerageUser} />
      )}

      {opportunity_id && (
        <RouteEditIconLink
          url={`/opportunities/${opportunity_id}/opportunity-brokerage-users/${opportunityBrokerageUser.id}/edit`}
        />
      )}

      <RouteOpenNewIconLink
        url={
          opportunity_id
            ? `/opportunities/${opportunity_id}/opportunity-brokerage-users/${opportunityBrokerageUser.id}/view`
            : `/opportunity-brokerage-users/${opportunityBrokerageUser.id}/view`
        }
      />
    </>
  );
};

const opportunityBrokerageUsersColumn: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => (
      <Action opportunityBrokerageUser={row} />
    ),
    flex: 1,
    minWidth: 100
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => (
      <RouteLink
        url={`/opportunity-brokerage-users/${row.id}/view`}
        name={row.name}
      />
    )
  },
  {
    headerName: fieldLabel.opportunity,
    field: 'opportunity_id',
    sortable: false,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<OpportunityBrokerageUserListEntity>) => {
      return `${row.opportunity ? row.opportunity.name : ''}`;
    }
  },
  {
    headerName: fieldLabel.marketPreference,
    field: 'market_preference_id',
    sortable: false,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<OpportunityBrokerageUserListEntity>) => {
      return `${row.market_preference.name}`;
    }
  },
  {
    headerName: fieldLabel.primary,
    field: 'primary_user',
    sortable: false,
    flex: 1,
    renderCell: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => (
      <UnitSwitch
        value={row.primary_user ?? 0}
        onChange={() => {
          return '';
        }}
        disabled
        name="primary_user"
        label=""
      />
    )
  },
  {
    headerName: fieldLabel.brokerageRole,
    field: 'brokerage_transaction_role_name',
    sortable: false,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<OpportunityBrokerageUserListEntity>) => {
      return `${
        brokerageTransactionRole[row.brokerage_transaction_role.name] ??
        row.brokerage_transaction_role.name ??
        ''
      }`;
    }
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: true,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<OpportunityBrokerageUserListEntity>) => {
      return `${crmStatusType[row.status]}`;
    }
  },
  {
    headerName: fieldLabel.user,
    field: 'brokerage_user_name',
    sortable: false,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<OpportunityBrokerageUserListEntity>) => {
      return `${userFullName(row.brokerage_user)}`;
    }
  },
  {
    headerName: fieldLabel.lastActivatedAt,
    field: 'last_activated_at',
    sortable: true,
    flex: 0.5,
    valueGetter: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.last_activated_at);
    }
  },
  {
    headerName: fieldLabel.lastDeactivatedAt,
    field: 'last_deactivated_at',
    sortable: true,
    flex: 0.5,
    valueGetter: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.last_deactivated_at);
    }
  },
  {
    headerName: fieldLabel.dateEntered,
    field: 'date_entered',
    sortable: true,
    flex: 0.5,
    valueGetter: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 0.5,
    valueGetter: ({
      row
    }: GridRenderCellParams<OpportunityBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default opportunityBrokerageUsersColumn;
