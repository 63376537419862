import {
  ActionRecordViewPropTypes,
  ExtensionConfirmationEntity,
  ExtensionConfirmationpayloadEntity
} from 'types/brokerage-action-types';
import { ErrorValidation } from 'types/error-types';
import { getInitialState } from './helper';
import { InputChangeEvent } from 'types/common-types';
import { useNavigate } from 'react-router-dom';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import brokerageActionService from 'services/brokerage-action-service';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import extensionRequest from 'assets/constants/extension-request';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import React, { useState, useEffect } from 'react';
import SaveButton from 'components/form/button-save';
import StackRow from 'components/stack/stack-row';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitSelect from 'components/form/unit-select';
import variableConfig from 'config/variable';
import { brokerageActionEvent } from 'event/brokerage-action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import opportunityHelper from 'helpers/opportunity-helper';
import moment from 'moment-timezone';
import UnitItem from 'components/form/unit-item';
import { isEmpty } from 'helpers/misc-helper';
import InfoMessage from 'components/errors/info-component';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ExtensionConfirmationEntity>(
    getInitialState(opportunity)
  );

  const handleChange = (e: InputChangeEvent) => {
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const validateData = () => {
    const { isValid, errors } = validateBrokerageAction(data, [
      'close_date_c',
      'due_diligence_end_c',
      'forecasted_close_date'
    ]);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const reqBody: ExtensionConfirmationpayloadEntity = {
      due_diligence_end_c: DateUtility.convertTimeZoneToUTC(
        data.due_diligence_end_c
      ),
      close_date_c: data.close_date_c,
      original_close_of_escrow_date: data.original_close_of_escrow_date
    };

    setIsLoading(true);

    const result =
      await brokerageActionService.update<ExtensionConfirmationpayloadEntity>(
        opportunity.id,
        reqBody,
        'approve_extension_request'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.extension_confirmation();
      } else {
        refreshOpportunity();
        navigate(`/opportunities/${opportunity.id}/view`);
      }
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      ...getInitialState(opportunity)
    }));
  }, [loadingOpportunity]);
  const getValidDate = (date: string, field: string, label: string) => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        label,
        field === 'close_date_c'
          ? true
          : opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
              opportunity?.option_days_type_c
            )
      );

    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}_federal_holiday_validation_message`]: message
    }));

    return DateUtility.getFormattedDateString(validDate);
  };

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitItem>
              <UnitDateTime
                label={fieldLabel.dueDiligenceEnd}
                name="due_diligence_end_c"
                value={data.due_diligence_end_c ?? ''}
                onChange={(value: string) => {
                  const validDate = getValidDate(
                    value,
                    'due_diligence_end_c',
                    fieldLabel.dueDiligenceEndDate
                  );

                  setData((prevData) => ({
                    ...prevData,
                    due_diligence_end_c: DateUtility.addHours(
                      moment(validDate),
                      variableConfig.DUE_DILIGENCE_END_START_TIME
                    )
                  }));
                }}
                error={validation['due_diligence_end_c'] ?? ''}
                grid={{ xs: 12, sm: 12 }}
              />
              {!isEmpty(
                validation?.due_diligence_end_c_federal_holiday_validation_message
              ) && (
                <InfoMessage
                  message={
                    validation?.due_diligence_end_c_federal_holiday_validation_message ??
                    ''
                  }
                />
              )}
            </UnitItem>
            <UnitItem>
              <UnitDate
                label={fieldLabel.closeDate}
                name="close_date_c"
                value={data.close_date_c ?? ''}
                onChange={(value: string) => {
                  setData((prevData) => ({
                    ...prevData,
                    close_date_c: getValidDate(
                      value,
                      'close_date_c',
                      fieldLabel.closeDate
                    )
                  }));
                }}
                error={validation['close_date_c'] ?? ''}
              />
              {!isEmpty(
                validation?.close_date_c_federal_holiday_validation_message
              ) && (
                <InfoMessage
                  message={
                    validation?.close_date_c_federal_holiday_validation_message ??
                    ''
                  }
                />
              )}
            </UnitItem>

            <UnitDate
              label={fieldLabel.forecastedDDEndDate}
              name="forecasted_dd_end_date"
              value={data.forecasted_dd_end_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_dd_end_date', value: e }
                })
              }
              disabled
            />

            <UnitDate
              label={fieldLabel.forecastedCloseDate}
              name="forecasted_close_date"
              value={data.forecasted_close_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_close_date', value: e }
                })
              }
              error={validation['forecasted_close_date']}
              disabled
            />

            <UnitSelect
              name="extension_request_reason"
              label={fieldLabel.extensionRequestReason}
              records={getObjectEntriesAsArray(extensionRequest)}
              value={data.extension_request_reason ?? ''}
              onChange={handleChange}
              disabled
            />

            <UnitDate
              label={fieldLabel.originalCloseOfEscrow}
              name="original_close_of_escrow_date"
              value={data.original_close_of_escrow_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: {
                    name: 'original_close_of_escrow_date',
                    value: e
                  }
                })
              }
              disabled={data.original_close_of_escrow_date ? true : false}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};
export default RecordView;
