import { DocumentEntity } from 'types/document-types';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { moduleLabels } from 'assets/list/tracker/constant';
import { OptionType } from 'types/option-type';
import { useParams } from 'react-router-dom';
import documentService from 'services/document-service';
import documentSubType from 'assets/constants/document-sub-type';
import ErrorComponent from 'components/errors/error-component';
import initialDocument from 'state/document/initital-document';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import trackerService from 'services/tracker-service';

const DocumentView = ({ routeTag }: { routeTag: string }) => {
  const { document_id } = useParams<{
    document_id: string;
  }>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [document, setDocument] = useState<DocumentEntity>(initialDocument);
  const [subTypeOptions, setSubtypeOption] = useState<OptionType[]>([]);
  const { setRouteName } = useRouteName();

  const loadDocument = async (document_id: string): Promise<void> => {
    setErrorMessage('');

    const result = await documentService.get(document_id);

    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }

    setDocument(result.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Documents.label,
      item_id: document_id,
      item_summary: result.data.document_name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (document_id) loadDocument(document_id);
  }, []);

  useEffect(() => {
    setSubtypeOption(documentSubType[document.category_id] || []);
  }, [document.category_id]);

  useEffect(() => {
    setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {!isEmpty(errorMessage) ? (
          <ErrorComponent message={errorMessage} />
        ) : (
          <RecordView
            document={document}
            readOnly={true}
            document_id={document_id}
            setDocument={setDocument}
            subTypeOptions={subTypeOptions}
          />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default DocumentView;
