import { DocumentsListEntity } from 'types/document-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid/models';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/document/default-sort';
import DocumentService from 'services/document-service';
import emailAttachmentColumns from 'assets/list/document/email-attachment-columns';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useState } from 'react';

const Documents = ({ opportunity_id }: { opportunity_id?: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDocuments = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<DocumentsListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: { opportunity_id }
    });

    setIsLoading(true);
    const result = await DocumentService.getList(queryString);
    setIsLoading(false);

    return result;
  };

  return (
    <React.Fragment>
      <DataGrid<DocumentsListEntity>
        getData={getDocuments}
        tableColumns={emailAttachmentColumns}
        isLoading={isLoading}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
      />
    </React.Fragment>
  );
};

export default Documents;
