import GroupIcon from '@mui/icons-material/Group';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { v4 as uuid } from 'uuid';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';

const users = {
  label: fieldLabel.users,
  url: `/admin/users`,
  labelIcon: GroupIcon,
  model: appModel.admin,
  permission: appPermission.list,
  nodeId: uuid()
};

const roles = {
  label: fieldLabel.roles,
  url: `/admin/roles`,
  labelIcon: AccessibilityIcon,
  model: appModel.admin,
  permission: appPermission.list,
  nodeId: uuid()
};

const adminMenuItems = {
  list: [users, roles]
};

export default adminMenuItems;
