import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'tasks') {
      return await menuHelper.getItems(appModel.task, appPermission.list);
    }

    return [];
  }
};

export default sideManuHelper;
