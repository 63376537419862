import CreateIcon from '@mui/icons-material/Create';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

const listSerice = {
  label: fieldLabel.list,
  url: () => `/services`,
  labelIcon: ListAltIcon,
  model: appModel.service,
  permission: appPermission.list,
  nodeId: uuid()
};

const viewService = {
  label: fieldLabel.view,
  url: (id: string) => `/services/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.service,
  permission: appPermission.view,
  nodeId: uuid()
};

const viewChangeLogService = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/services/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.service,
  permission: appPermission.view,
  nodeId: uuid()
};

const editService = {
  label: fieldLabel.edit,
  url: (id: string) => `/services/${id}/edit`,
  labelIcon: CreateIcon,
  model: appModel.service,
  permission: appPermission.edit,
  nodeId: uuid()
};

const serviceMenuItems = {
  list: [listSerice],
  edit: [listSerice, viewService, viewChangeLogService],
  view: [listSerice, editService, viewChangeLogService]
};

export default serviceMenuItems;
