import { ComposeMailTypeEntity, ContentType } from 'types/email-types';
import { toastError } from 'event/toast-event';
import { useParams } from 'react-router-dom';
import initialComposeMail from 'state/email/initial-compose-mail';
import mailService from 'services/email-service';
import React, { useEffect, useState } from 'react';
import RecordView from '../record-view';
import useRouteName from 'pages/route-outlet-context';

const EmailView = ({ routeTag }: { routeTag: string }) => {
  const { opportunity_id, email_id } = useParams<{
    opportunity_id: string;
    email_id: string;
  }>();

  const { setRouteName } = useRouteName();

  const [mail, setMail] = useState<ComposeMailTypeEntity>({
    ...initialComposeMail,
    opportunity_id: opportunity_id!
  });
  const [content, setContent] = useState<ContentType>({
    html: '',
    text: '',
    preview: ''
  });

  const loadEmail = async (email_id: string) => {
    const result = await mailService.get(email_id);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      setMail({
        ...mail,
        to: result.data.email_body.to_addrs,
        cc: result.data.email_body.cc_addrs,
        bcc: result.data.email_body.bcc_addrs,
        subject: result.data.name,
        template: result.data.email_template_id || '',
        email_id: result.data.id,
        notes: result.data.notes,
        from: result.data.email_body.from_addr,
        reply_to: result.data.email_body.reply_to_addr,
        status: result.data.status
      });

      setContent((prevContent) => ({
        ...prevContent,
        html: result.data.email_body.description_html,
        text: result.data.email_body.description
      }));
    }
  };

  useEffect(() => {
    if (email_id) {
      loadEmail(email_id!);
    }
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <React.Fragment>
      <RecordView mail={mail} content={content} readOnly={true} />
    </React.Fragment>
  );
};

export default EmailView;
