import {
  KnowledgeBaseEntity,
  KnowledgeBaseListEntity,
  KnowledgeBasePayloadEntity
} from 'types/knowledge-base-types';
import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import envConfig from 'config/env';
import httpService from './http-service';

const knowledgeBaseService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/knowledge-bases`,

  getList: async (
    query: string
  ): Promise<GetListResponse<KnowledgeBaseListEntity>> => {
    const response = await httpService.list<KnowledgeBaseListEntity>(
      `${knowledgeBaseService.url}${query}`
    );
    return response;
  },

  get: async (id: string): Promise<GetResponse<KnowledgeBaseEntity>> => {
    const response = await httpService.get<KnowledgeBaseEntity>(
      `${knowledgeBaseService.url}/${id}`
    );
    return response;
  },

  create: async (
    data: KnowledgeBasePayloadEntity
  ): Promise<CreateResponse<KnowledgeBaseEntity>> => {
    const response = await httpService.post<
      KnowledgeBaseEntity,
      KnowledgeBasePayloadEntity
    >(`${knowledgeBaseService.url}`, data);
    return response;
  },

  update: async <T = KnowledgeBaseEntity> (
    id: string,
    data: T
  ): Promise<UpdateResponse<KnowledgeBaseEntity>> => {
    const response = await httpService.put<
      KnowledgeBaseEntity,
      T
    >(`${knowledgeBaseService.url}/${id}`, data);
    return response;
  }
};

export default knowledgeBaseService;
