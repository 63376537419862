import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface H6Props {
  message: string | ReactNode;
}

const InfoMessage = (props: H6Props) => {
  const { message } = props;

  return (
    <>
      <Typography component={'span'} variant="caption" sx={{ color: 'blue' }}>
        <div style={{ display: 'flex' }}>
          <InfoIcon
            fontSize="small"
            sx={{ height: '15px', marginTop: '3px' }}
          />{' '}
          {message}
        </div>
      </Typography>
    </>
  );
};

export default InfoMessage;
