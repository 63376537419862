import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import changeLogHelper from 'helpers/change-log/change-log-helper';
import DateUtility from 'helpers/date-helper';

const changelogColumn: GridColDef[] = [
  {
    headerName: fieldLabel.field,
    field: 'field_label',
    sortable: false,
    flex: 0.5,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>{row?.field_label || row.field_name}</>
    )
  },
  {
    headerName: fieldLabel.oldValue,
    field: 'before_value_text',
    sortable: false,
    minWidth: 350,
    renderCell: ({ row }: GridRenderCellParams) => {
      const before_value = row?.before_value_text || row.before_value_string;
      return row.field_name === 'negotiation_notes_c'
        ? changeLogHelper.convertDateTimeoToUserTimezone(before_value)
        : before_value;
    },
    flex: 0.3
  },
  {
    headerName: fieldLabel.newValue,
    field: 'after_value_text',
    sortable: false,
    minWidth: 350,
    renderCell: ({ row }: GridRenderCellParams) => {
      const after_value = row?.after_value_text || row.after_value_string;
      return row.field_name === 'negotiation_notes_c'
        ? changeLogHelper.convertDateTimeoToUserTimezone(after_value)
        : after_value;
    },
    flex: 0.3
  },
  {
    headerName: fieldLabel.createdBy,
    field: 'created_by',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>{row.created_by?.user_name}</>
    ),
    flex: 0.5
  },
  {
    headerName: fieldLabel.changeDate,
    field: 'date_created',
    sortable: true,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>{DateUtility.convertUTCtoTimeZone(row.date_created)}</>
    ),
    flex: 0.5
  }
];

export default changelogColumn;
