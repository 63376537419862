import oppurtunityStatusList from "assets/constants/oppurtunity-status-list";
import initialHoaManagementCompany from "state/hoa-management-company/initial-hoa-management-company";
import initialPartyList from "state/party/initial-party-list";
import initialUtilityProvider from "state/utility-provider/initial-utility-provider";
import { EnterDueDiligenceEntity } from "types/brokerage-action-types";

const initialEnterDueDiligence: EnterDueDiligenceEntity = {
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
    contract_execution_date_c: '',
    contract_price_c: '',
    offer_price_c: '',
    balance_to_close_c: 0,
    escalation_clause_amount: '',
    due_diligence_days_confirm: '',
    due_diligence_start_c: '',
    due_diligence_end_c: '',
    close_date_c: '',
    option_period_days_c: '',
    option_days_type_c: '',
    earnest_money_due_date_c: '',
    earnest_amount_verified: '',
    option_fee_verified: '',
    earnest_amount_c: '',
    hoa_addendum_received: '',
    list_agent: initialPartyList[0],
    parties_escrow_company_id: '',
    escrow_company: initialPartyList[0],
    additional_access_information_c: '',
    has_post_occupancy: '',
    lease_type: '',
    lease_end_date: '',
    deposit_amount: 0,
    rental_amount: '',
    hvac_age: '',
    lockbox_code: '',
    access_type: '',
    seller_name_c: '',
    hoa_management_company: initialHoaManagementCompany,
    hoa_url: '',
    property_utility_provider: initialUtilityProvider,
    mo_photos_required: 0,
    sellers_disclosure_received: 0,
    agree_switch: 0,
    initial_due_diligence_end: ''
};


export default initialEnterDueDiligence;