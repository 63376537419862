import { ObjectType } from 'types';
import { isChecked } from 'components/form/unit-switch';

export const getVisibleFormFields = (fields: ObjectType): string[] => {
  let visibleFormFields: string[] = [];

  const priceFields = ['counter_offer_price_c', 'counter_offer'];

  const diligencePeriodFields = [
    'option_period_days_c',
    'option_days_type_c',
    'option_amount_c',
    'due_diligence_end_c'
  ];

  const exclusionInclusionFields = ['exclusion', 'inclusion'];

  const earnestMoneyFields = ['earnest_amount_c'];

  const closeDateFields = ['close_date_c'];

  const postOccupancy = [
    'lease_end_date',
    'deposit_amount',
    'rental_amount',
    'lease_type'
  ];

  const specialProvisionFields = ['additional_terms_comments_c'];

  const solarPanelsFields = [
    'solar_panel_leased_or_owned',
    'solar_panel_payoff_amount',
    'solar_panel_lease_date_expiration'
  ];

  const otherFields = ['other_counter_offer_terms'];

  if (isChecked(fields.price.checked)) {
    visibleFormFields = [...visibleFormFields, ...priceFields];
  }

  if (isChecked(fields.diligence_period.checked)) {
    visibleFormFields = [...visibleFormFields, ...diligencePeriodFields];
  }

  if (isChecked(fields.exclusions_inclusions.checked)) {
    visibleFormFields = [...visibleFormFields, ...exclusionInclusionFields];
  }

  if (isChecked(fields.earnest_money.checked)) {
    visibleFormFields = [...visibleFormFields, ...earnestMoneyFields];
  }

  if (isChecked(fields.close_date.checked)) {
    visibleFormFields = [...visibleFormFields, ...closeDateFields];
  }

  if (isChecked(fields.post_occupancy.checked)) {
    visibleFormFields = [...visibleFormFields, ...postOccupancy];
  }

  if (isChecked(fields.special_provisions.checked)) {
    visibleFormFields = [...visibleFormFields, ...specialProvisionFields];
  }

  if (isChecked(fields.solar_panels.checked)) {
    visibleFormFields = [...visibleFormFields, ...solarPanelsFields];
  }

  if (isChecked(fields.other.checked)) {
    visibleFormFields = [...visibleFormFields, ...otherFields];
  }

  return visibleFormFields;
};
