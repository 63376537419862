import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { ErrorValidation } from 'types/error-types';

export const validateParty = (
  data: ObjectType
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  const typeList = [
    'seller',
    'seller_representative',
    'lead_source',
    'lead_owner',
    'buyer_representative',
    'intended_buyer',
    'uploaded_by'
  ];

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField('Name')]
      }
    };
  }

  if (isEmpty(data.email) && data.type !== 'seller') {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: [missingField('Email')]
      }
    };
  }

  if (isEmpty(data.type)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        type: [missingField('Type')]
      }
    };
  }

  if (isEmpty(data.mobile) && data.type === 'escrow') {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        mobile: [missingField('Mobile')]
      }
    };
  }

  if (
    !isEmpty(data.type) &&
    typeList.includes(data.type) &&
    isEmpty(data.sub_type) &&
    data.type !== 'seller'
  ) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        sub_type: [missingField('Sub Type')]
      }
    };
  }

  return validation;
};


export const validateTenantParty = (
  data: ObjectType
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField('Name')]
      }
    };
  }

  if (isEmpty(data.email)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: [missingField('Email')]
      }
    };
  }

  return validation;
};

