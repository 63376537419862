import Box from '@mui/material/Box';

const RequiredFieldAsterisk = () => {
  return (
    <Box component="span" sx={{ color: 'red' }}>
      *
    </Box>
  );
};

export default RequiredFieldAsterisk;
