import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, IconButton, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import UnitItem from 'components/form/unit-item';
import React from 'react';

import StyledModalTooltip from './styled-modal-tooltip';

export type Placement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

interface TooltipModalPropTypes {
  data: string;
  height?: string;
  width?: string;
  placement?: Placement;
}

const TooltipModal = ({
  data,
  height = '50vh',
  width = '47.7vw',
  placement = 'left'
}: TooltipModalPropTypes) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <StyledModalTooltip
          placement={placement}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Box
              sx={{
                width: width,
                height: height,
                overflow: 'auto'
              }}
            >
              <UnitItem grid={{ xs: 12, sm: 12 }} p={2}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: data
                  }}
                ></Typography>
              </UnitItem>
            </Box>
          }
        >
          <IconButton onClick={handleTooltipOpen}>
            {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </StyledModalTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default TooltipModal;
