import oppurtunityStatusList from "assets/constants/oppurtunity-status-list";
import DateUtility from "helpers/date-helper";

export const initialCancelOffer = {
  id: '',
  offer_cancelled_date: DateUtility.getTodayDateString(),
  cancel_request_received_date: '',
  cancel_reason: '',
  cancellation_reason_subcategory: '',
  cancel_note: '',
  opportunity_status_c: oppurtunityStatusList.offer_canceled
}