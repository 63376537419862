import fieldLabel from 'assets/constants/fieldLabel';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import UnitText from 'components/form/unit-text';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import StackRow from 'components/stack/stack-row';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useState } from 'react';
import serviceProviderService from 'services/service-provider-service';
import ValidationService from 'services/validation-service';
import { ErrorValidation } from 'types/error-types';
import { CloseModal, InputChangeEvent } from 'types/common-types';
import emptyFunction from 'helpers/empty-function-helper';
import { ObjectType } from 'types';

interface VendorDataType {
  name: string;
  type: string;
}

const AddVendor = ({
  type,
  reloadVendor,
  onClose = emptyFunction
}: {
  type: string;
  reloadVendor: (e: string) => void;
  onClose: CloseModal;
}): JSX.Element => {
  const [data, setData] = useState<VendorDataType>({
    name: '',
    type: type
  });

  const [validation, setValidation] = useState<ErrorValidation | ObjectType>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const { isValid, errors } = validateBrokerageAction(data, ['vendor_name']);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    setIsLoading(true);

    const result = await serviceProviderService.create(data);

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(
        ValidationService.backendValidate(result.validationMessage)
      );
    }

    reloadVendor(result.data.id);
  };

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxHeader
          value={
            <>
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                <ModalHeader
                  title={fieldLabel.createNewServiceProvider}
                  onClose={onClose}
                />
              </UnitItem>
            </>
          }
        />
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 85vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitText
              label={fieldLabel.EnterName}
              name="name"
              value={data.name ?? ''}
              onChange={handleChange}
              error={
                validation['vendor_name']
                  ? validation['vendor_name']
                  : validation['name']
              }
              required
              grid={{ xs: 12, sm: 12 }}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ p: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
            <CancelButton onClick={onClose} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default AddVendor;
