import {
  MarketPreferencesListEntity,
  MarketPreferenceEntity,
  AssociatePDFTemplateMarketPreferenceEntity
} from 'types/market-preference-types';
import envConfig from 'config/env';
import { ObjectType } from 'types';
import { EmailTemplateEntity } from 'types/email-template-types';
import app from 'config/app';
import httpService from './http-service';
import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import { PdfTemplateEntity } from 'types/pdf-template-types';

const marketPreferenceService = {
  url: `/${app.crmApiVersion1}/market_preferences`,

  getList: async (
    query: string
  ): Promise<GetListResponse<MarketPreferencesListEntity>> => {
    const result = await httpService.list<MarketPreferencesListEntity>(
      `${marketPreferenceService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<MarketPreferenceEntity>> => {
    const result = await httpService.get<MarketPreferenceEntity>(
      `${marketPreferenceService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: MarketPreferenceEntity
  ): Promise<CreateResponse<MarketPreferenceEntity>> => {
    const result = await httpService.post<MarketPreferenceEntity>(
      `${marketPreferenceService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: MarketPreferenceEntity
  ): Promise<UpdateResponse<MarketPreferenceEntity>> => {
    const result = await httpService.put<MarketPreferenceEntity>(
      `${marketPreferenceService.url}/${id}`,
      payload
    );
    return result;
  },

  associatePDFTemplate: async (id: string, payload: ObjectType) => {
    const result = await httpService.post(
      `${marketPreferenceService.url}/${id}/pdf_templates`,
      payload
    );
    return result;
  },

  associateEmailTemplate: async (id: string, payload: ObjectType) => {
    const result = await httpService.post(
      `${marketPreferenceService.url}/${id}/email_templates`,
      payload
    );
    return result;
  },

  getAssociatedPDFTemplate: async (
    market_preference_id: string,
    pdf_template_id: string
  ): Promise<GetResponse<PdfTemplateEntity>> => {
    const result = await httpService.get<PdfTemplateEntity>(
      `${marketPreferenceService.url}/${market_preference_id}/pdf_templates/${pdf_template_id}`
    );

    return result;
  },

  getAssociatedEmailTemplate: async (
    market_preference_id: string,
    email_template_id: string
  ) => {
    const result = await httpService.get<EmailTemplateEntity>(
      `${marketPreferenceService.url}/${market_preference_id}/email_templates/${email_template_id}`
    );

    return result;
  },

  updateAssociatePDFTemplate: async (
    id: string,
    contract_id: string,
    payload: AssociatePDFTemplateMarketPreferenceEntity
  ): Promise<UpdateResponse<AssociatePDFTemplateMarketPreferenceEntity>> => {
    const result = await httpService.put(
      `${marketPreferenceService.url}/${id}/pdf_templates/${contract_id}`,
      payload
    );
    return result;
  },

  updateAssociateEmailTemplate: async (
    id: string,
    email_template_id: string,
    data: ObjectType
  ) => {
    const result = await httpService.put(
      `${marketPreferenceService.url}/${id}/email_templates/${email_template_id}`,
      data
    );
    return result;
  },

  deleteAssocationPDFTemplate: async (
    market_preference_id: string,
    pdf_template_id: string
  ) => {
    const result = await httpService.del(
      `${marketPreferenceService.url}/${market_preference_id}/pdf_templates/${pdf_template_id}`
    );

    return result;
  },

  deleteEmailTemplateAssociation: async (
    market_preference_id: string,
    email_template_id: string
  ) => {
    const result = await httpService.del(
      `${marketPreferenceService.url}/${market_preference_id}/email_templates/${email_template_id}`
    );

    return result;
  },

  deleteAssocationDocument: async (
    market_preference_id: string,
    document_id: string
  ) => {
    const result = await httpService.del(
      `/${envConfig.REACT_APP_API_VERSION_1}/market_preferences/${market_preference_id}/documents/${document_id}`
    );

    return result;
  }
};

export default marketPreferenceService;
