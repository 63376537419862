import {
  DispatchSetState,
  InputChangeEvent,
  ReducerActionType
} from 'types/common-types';
import { Action } from 'assets/list/email-inbox/column';
import { ComposeMailTypeEntity, ContentType } from 'types/email-types';
import { CreateResponse } from 'types/api-response-types';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { TemplatePreviewEntity } from 'types/email-template-types';
import { toastError } from 'event/toast-event';
import emailService from 'services/email-service';
import emailTemplateService from 'services/email-template-service';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FileAttachment from './file-attachment';
import FilePanel, { DocumentWarningBanner } from './file-panel';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import React, { SyntheticEvent, useEffect } from 'react';
import SendButton from 'components/form/button-send';
import UnitHTMLEditor from 'components/form/unit-html-editor';
import UnitItem from 'components/form/unit-item';
import UnitText from 'components/form/unit-text';
import ValidationError from 'components/errors/validation-error';
import { useMediaQuery } from '@mui/material';
import DraftButton from 'components/form/button-draft';
import PaperBox from 'components/paper-box';
import FormContainer from 'components/form/container';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import TabArea from 'components/tabs/tab-area';

const tabId = 'editor-tab';

interface ComposeFormPropsType {
  mail: ComposeMailTypeEntity;
  setMail: DispatchSetState<ComposeMailTypeEntity>;
  valMessages: ObjectType;
  handleChange: (e: InputChangeEvent) => void;
  readOnly: boolean;
  content: ContentType;
  setContent: DispatchSetState<ContentType>;
  savedAttachments: ObjectType[];
  unSavedAttachments: File[];
  dispatch?: React.Dispatch<ReducerActionType>;
  dispatch2?: React.Dispatch<ReducerActionType>;
  send: () => void;
  draft: () => void;
  loading: boolean;
}

const ComposeForm = ({
  mail,
  handleChange,
  setMail,
  valMessages,
  readOnly,
  draft,
  send,
  content,
  savedAttachments,
  unSavedAttachments,
  loading,
  dispatch,
  dispatch2,
  setContent
}: ComposeFormPropsType) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  const mobileView = useMediaQuery('(max-width:599px)');
  const foldMobileView = useMediaQuery('(max-width:280px)');

  const handleActiveTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setActiveTabIndex(newValue);
  };

  const isEmptyContent = () => {
    let valid = false;

    if (isEmpty(mail.opportunity_id)) {
      toastError(`${fieldLabel.opportunity} Required`);
      valid = true;
    }

    if (isEmpty(content.html)) {
      toastError(`${fieldLabel.emailBody} require`);
      valid = true;
    }

    return valid;
  };

  const loadPreview = async () => {
    setContent((prevContent) => ({
      ...prevContent,
      preview: ''
    }));

    let result: CreateResponse<TemplatePreviewEntity>;

    if (mail.template_type === 'mustache') {
      result = await emailTemplateService.getMustachePreView({
        opportunity_id: mail.opportunity_id,
        mustache_body_html: content.html
      });
    } else {
      result = await emailService.getEmailPreview({
        opportunity_id: mail.opportunity_id,
        body_html: content.html
      });
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }
    setContent((prevContent) => ({
      ...prevContent,
      html: content.html,
      preview: result.data.preview
    }));
  };

  useEffect(() => {
    if (activeTabIndex == 1) {
      loadPreview();
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (activeTabIndex == 1) {
      setActiveTabIndex(0);
    }
  }, [content.html]);

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <UnitText
            label={fieldLabel.from}
            name="from"
            value={mail.from ?? ''}
            onChange={handleChange}
            error={valMessages['from'] ?? ''}
            required
            disabled={true}
            variant="standard"
          />
          <UnitText
            label={fieldLabel.replyTo}
            name="reply_to"
            value={mail.reply_to ?? ''}
            onChange={handleChange}
            error={valMessages['reply_to'] ?? ''}
            required
            disabled={true}
            variant="standard"
            grid={readOnly ? { xs: 11, sm: 5 } : { xs: 12, sm: 6 }}
          />
          {readOnly && (
            <UnitItem grid={{ xs: 2, sm: 1 }} sx={{ textAlign: 'right' }}>
              <Action email_id={mail.email_id} />
            </UnitItem>
          )}
          <UnitText
            label={fieldLabel.to}
            name="to"
            value={mail.to ?? ''}
            onChange={handleChange}
            error={valMessages['to'] ?? ''}
            required
            readOnly={readOnly}
            grid={{ xs: 12, sm: 12 }}
            variant="standard"
          />
          <UnitText
            label={fieldLabel.cc}
            name="cc"
            value={mail.cc ?? ''}
            onChange={handleChange}
            error={valMessages['cc'] ?? ''}
            readOnly={readOnly}
            variant="standard"
          />
          <UnitText
            label={fieldLabel.bcc}
            name="bcc"
            value={mail.bcc ?? ''}
            onChange={handleChange}
            error={valMessages['bcc'] ?? ''}
            readOnly={readOnly}
            variant="standard"
          />
          <UnitText
            label={fieldLabel.subject}
            name="subject"
            value={mail.subject ?? ''}
            onChange={handleChange}
            error={valMessages['subject'] ?? ''}
            required
            readOnly={readOnly}
            grid={{ xs: 12, sm: 12 }}
            variant="standard"
          />
          {!readOnly && mail.email_id && (
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <DocumentWarningBanner
                notesFiles={[...mail.notes, ...savedAttachments]}
              />
            </UnitItem>
          )}

          {!readOnly && (
            <>
              <UnitItem
                grid={{
                  xs: 12,
                  sm: 12,
                  md: 6
                }}
                p={0}
              >
                <HorizontalTabs
                  value={activeTabIndex}
                  handleChange={(e: SyntheticEvent, newValue: number) => {
                    if (newValue == 1) {
                      if (!isEmptyContent()) handleActiveTabChange(e, newValue);
                    } else {
                      handleActiveTabChange(e, newValue);
                    }
                  }}
                  tabItems={{
                    write: 'Write',
                    preview: 'Preview'
                  }}
                  tabId={tabId}
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 12, md: 6 }} sx={{ mt: 0 }} p={0}>
                <SendButton onClick={send} disabled={loading} />
                {'  '}&nbsp;
                <DraftButton onClick={draft} disabled={loading} />
                <FileAttachment
                  addUnSavedFile={(file: File) => {
                    if (dispatch2 !== undefined) {
                      dispatch2({ type: 'ADD', payload: { file } });
                    }
                  }}
                />
              </UnitItem>
            </>
          )}
          {mail.status == '' && valMessages['opportunity_id'] != undefined && (
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <ValidationError data={valMessages['opportunity_id']} />
            </UnitItem>
          )}
          <UnitItem
            grid={{
              xs: 12,
              sm:
                savedAttachments.length > 0 ||
                unSavedAttachments.length > 0 ||
                mail.notes.length > 0
                  ? 8
                  : 12
            }}
            p={0}
            sx={{
              paddingTop: '0px !important'
            }}
          >
            {readOnly ? (
              <>
                <UnitHTMLEditor
                  grid={{ xs: 12, sm: 12 }}
                  value={readOnly ? content.html : content.preview}
                  theme={'bubble'}
                  onChange={emptyFunction}
                  readOnly={true}
                  label=""
                  pt={0}
                  pb={0}
                />
              </>
            ) : (
              <>
                <TabArea
                  index={0}
                  border={false}
                  tabId={tabId}
                  value={activeTabIndex}
                >
                  <UnitHTMLEditor
                    grid={{ xs: 12, sm: 12 }}
                    value={content.html}
                    theme={'material'}
                    onChange={(mail: ContentType) => {
                      setContent(mail);
                    }}
                    height="100vh"
                    readOnly={false}
                    label=""
                    pt={0}
                    pb={
                      (savedAttachments.length > 0 ||
                        unSavedAttachments.length > 0 ||
                        mail.notes.length > 0) &&
                      !mobileView
                        ? 10
                        : 8
                    }
                  />
                </TabArea>
                <TabArea
                  index={1}
                  border={false}
                  tabId={tabId}
                  value={activeTabIndex}
                >
                  <UnitHTMLEditor
                    grid={{ xs: 12, sm: 12 }}
                    value={content.preview}
                    theme={'material'}
                    onChange={emptyFunction}
                    readOnly={true}
                    label=""
                    height="100vh"
                    pt={0}
                    pb={1}
                  />
                </TabArea>
              </>
            )}
          </UnitItem>
          <UnitItem grid={{ xs: 12, sm: 4 }}>
            <UnitItem
              grid={{ xs: 12, sm: 12 }}
              sx={{ paddingTop: mobileView ? (foldMobileView ? 8 : 3) : 0 }}
            >
              {(savedAttachments.length > 0 ||
                unSavedAttachments.length > 0 ||
                mail.notes.length > 0) && (
                <>
                  <FilePanel
                    readOnly={readOnly}
                    savedFiles={savedAttachments}
                    unSavedFiles={unSavedAttachments}
                    notesFiles={mail.notes}
                    removeFile={(flag: number, index: number) => {
                      if (flag == 1) {
                        if (dispatch !== undefined) {
                          dispatch({
                            type: 'REMOVE',
                            payload: { index }
                          });
                        }
                      } else if (flag == 0) {
                        if (dispatch2 !== undefined) {
                          dispatch2({
                            type: 'REMOVE',
                            payload: { index }
                          });
                        }
                      } else {
                        setMail((prevMail) => ({
                          ...prevMail,
                          notes: [...mail.notes].filter((x, i) => i != index),
                          remove_note_ids: [
                            ...mail.remove_note_ids,
                            mail.notes[index].id
                          ]
                        }));
                      }
                    }}
                  />
                </>
              )}
            </UnitItem>
          </UnitItem>
          {valMessages['body_html'] != undefined && (
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <ValidationError data={valMessages['body_html']} />
            </UnitItem>
          )}
          {valMessages['body_plain'] != undefined && (
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <ValidationError data={valMessages['body_plain']} />
            </UnitItem>
          )}
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default ComposeForm;
