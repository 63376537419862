import httpService from './http-service';
import {
  GetListResponse,
  CreateResponse,
  GetResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';
import app from 'config/app';
import {
  Permission,
  Permissions,
  RoleAssociatedUserListEntity,
  RoleDeleteResponse,
  RoleEntity,
  RolePayloadEntity
} from 'types/role-types';

const rolePermissionService = {
  url: `/${app.crmApiVersion1}/roles`,

  getList: async (): Promise<GetListResponse<RoleEntity>> => {
    const result = await httpService.list<RoleEntity>(
      `${rolePermissionService.url}`
    );
    return result;
  },

  create: async (
    payload: RolePayloadEntity
  ): Promise<CreateResponse<RoleEntity>> => {
    const result = await httpService.post<RoleEntity, RolePayloadEntity>(
      `${rolePermissionService.url}`,
      payload
    );
    return result;
  },
  update: async (
    id: string,
    payload: RolePayloadEntity
  ): Promise<UpdateResponse<RoleEntity>> => {
    const result = await httpService.put<RoleEntity, RolePayloadEntity>(
      `${rolePermissionService.url}/${id}`,
      payload
    );
    return result;
  },
  delete: async (id: string): Promise<DeleteResponse<RoleDeleteResponse>> => {
    const result = await httpService.del<RoleDeleteResponse>(
      `${rolePermissionService.url}/${id}`
    );
    return result;
  },
  getAssociatedUserList: async (
    roleId: string
  ): Promise<GetListResponse<RoleAssociatedUserListEntity>> => {
    const result = await httpService.list<RoleAssociatedUserListEntity>(
      `${rolePermissionService.url}/${roleId}/users`
    );
    return result;
  },
  updateUserAssociation: async (
    roleId: string,
    userId: string
  ): Promise<CreateResponse<RoleAssociatedUserListEntity>> => {
    const result = await httpService.post<RoleAssociatedUserListEntity, {}>(
      `${rolePermissionService.url}/${roleId}/users/${userId}`,
      {}
    );
    return result;
  },
  getAssociatedPermissionList: async (
    roleId: string
  ): Promise<GetResponse<Permissions>> => {
    const result = await httpService.get<Permissions>(
      `${rolePermissionService.url}/${roleId}/permissions`
    );

    return result;
  },
  updatePermissionAssociation: async (
    roleId: string,
    permissionId: string
  ): Promise<CreateResponse<Permission>> => {
    const result = await httpService.post<Permission, {}>(
      `${rolePermissionService.url}/${roleId}/permissions/${permissionId}`,
      {}
    );
    return result;
  }
};

export default rolePermissionService;
