import { PropertiesFilterEntity } from 'types/dashboard-type';
export const close_date_to_c = (
  value: string,
  _: any,
  filter: PropertiesFilterEntity
) => {
  let result = ``;

  if (!value || value.length === 0) return ``;

  if (filter.close_date_c_range === 'date-range') {
    result += `&filter[close_date_c][to]=${value}`;
  }

  return result;
};
