import marketPreferenceService from 'services/market-preference-service';
import eventBus from 'helpers/event-bus-helper';
import documentService from 'services/document-service';
import oppurtunityStatusOptions from 'assets/constants/oppurtunity-status-options';
import pdfTemplateService from 'services/pdf-template-service';

const associateMpPDFTemplate = async (
  market_preference_id: string,
  pdf_template_id: string
) => {
  await marketPreferenceService.associatePDFTemplate(market_preference_id, {
    pdf_template_id: [pdf_template_id]
  });
  eventBus.dispatch('pdf_template_refresh', {});
};

const removeAssociationMpPDFTemplate = async (
  market_preference_id: string,
  pdf_template_id: string
) => {
  await marketPreferenceService.deleteAssocationPDFTemplate(
    market_preference_id,
    pdf_template_id
  );
  eventBus.dispatch(`pdf_template_refresh`, {});
};

const generateContract = async (
  pdf_template_id: string,
  opportunity_id: string,
  associate_with_opportunity: boolean
) => {
  const response = await documentService.generateContract({
    pdf_template_id,
    opportunity_id,
    associate_with_opportunity,
    url: ''
  });
  window.open(`${response.data.url}`);
};

const getCdaContractGenerateWarning = (opportunity_status_c: string) => {
  return `Warning: You are generating the CDA during the ${oppurtunityStatusOptions[opportunity_status_c]}. Generating the CDA at an improper status can result in incorrect commissions being mapped.`;
};

const deleteContract = async (pdf_template_id: string) => {
  const result = await pdfTemplateService.delete(pdf_template_id);

  eventBus.dispatch(`pdf_template_refresh`, {});

  return result;
};

const removeOpportunityFromTemplateVariables = (content: string) => {
  /*
    html (content) = <div> <span>$opportunities_name</span> $opportunities_opportunity_status_c </div>
    
    text = [
        "<div> <span>", 
        "name</span> ", 
        "opportunity_status_c </div>"
    ]

    output ( onlyText )= [
        "name</span> ",
        "opportunity_status_c </div>"
    ]
    */
  const onlyText = content
    .split('$opportunities_')
    .filter((x: string) => !x.trim().startsWith('<'));

  let templateVariables = [];

  for (const word of onlyText) {
    if (word.indexOf('<') !== -1) {
      /*
                check word contains '<' or not. if it contains then substring word from 0 to index of '<'
            */
      templateVariables.push(word.substring(0, word.indexOf('<')));
    } else if (word.indexOf('(') !== -1) {
      /*
               check word contains '(' or not. if it contains then substring word from 0 to index of '('
           */
      templateVariables.push(word.substring(0, word.indexOf('(')));
    } else if (word.indexOf(' ') !== -1) {
      /*
               check word contains space(' ') or not. if it contains then substring word from 0 to index of space(' ')
           */
      templateVariables.push(word.substring(0, word.indexOf(' ')));
    } else if (word.indexOf('.') !== -1) {
      /*
               check word contains dot('.') or not. if it contains then substring word from 0 to index of dot('.')
           */
      templateVariables.push(word.substring(0, word.indexOf('.')));
    }
  }

  // if template variable contains any space then template variable will be subtracted from 0 to index of spece('')
  templateVariables = templateVariables.map((v: string) =>
    v.indexOf(' ') > 0 ? v.substring(0, v.indexOf(' ')) : v
  );

  // This will replace opportunity variables with template variables.
  for (const variable of templateVariables) {
    content = content.replace(
      `$opportunities_${variable}`,
      `<div class="variable-chip-container"> <span class="variable-chip">${variable}</span> </div>`
    );
  }

  return content;
};

export {
  associateMpPDFTemplate,
  removeAssociationMpPDFTemplate,
  generateContract,
  getCdaContractGenerateWarning,
  removeOpportunityFromTemplateVariables,
  deleteContract
};
