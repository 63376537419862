import { ObjectType } from 'types';
import { commission_buyer_rep } from './commission-buyer-rep';

export const parties_buyer_representative_name = {
  handleChange: function (
    opportunity: ObjectType,
    originalOpportunity: ObjectType
  ) {
    let result = {
      commission_buyer_rep: commission_buyer_rep.calculate(
        opportunity,
        originalOpportunity
      )
    };
    return result;
  }
};
