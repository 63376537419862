import { MenuItem } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import DropdownMenu from 'components/dropdown-menu';
import RouteLink from 'components/link/route-link';
import RouteLinkBlack from 'components/link/route-link-black';
import DateUtility from 'helpers/date-helper';
import { deleteEmail } from 'helpers/email/email-action-helper';
import { useState } from 'react';
import { useParams } from 'react-router';
import { ObjectType } from 'types';
import { EmailListEntity } from 'types/email-types';
import { v4 as uuid } from 'uuid';

const Action = ({ email }: { email: EmailListEntity }) => {
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );
  const ddMenuItems = [];

  ddMenuItems.push(
    <>
      <MenuItem
        key={uuid()}
        onClick={() => {
          setConfirmModal({
            open: true,
            title: fieldLabel.removeEmail,
            text: MessageDeleteRecordConfirmation(email.name, 'Email'),
            proceed: () => {
              deleteEmail(email.id);
              setConfirmModal(initialConfirmModalState);
            },
            cancel: () => {
              setConfirmModal(initialConfirmModalState);
            }
          });
        }}
      >
        <RouteLinkBlack name={fieldLabel.removeEmail} url={`#`} />
      </MenuItem>
      {confirmModal.open && <ConfirmBox key={uuid()} {...confirmModal} />}
    </>
  );

  return (
    <>{ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}</>
  );
};

export const emailDraftColumns: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => (
      <Action email={row} />
    ),
    flex: 0.3,
    minWidth: 100
  },
  {
    headerName: fieldLabel.opportunity,
    field: 'opportunity_name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      const { opportunity_id } = useParams<ObjectType>();

      let url;

      if (opportunity_id) {
        url = `/opportunities/${opportunity_id}/email/draft/${row.id}`;
      } else {
        url = `/email/draft/${row.id}`;
      }

      return <RouteLink url={url} name={row.opportunity_name} />;
    }
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.subject,
    field: 'subject',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams<EmailListEntity>) => {
      return row.name;
    }
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.createdDate,
    field: 'date_entered',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<EmailListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  }
];
