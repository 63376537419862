import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

/** @TODO add Types */

const documents = {
  label: fieldLabel.list,
  url: () => `/documents`,
  labelIcon: ListAltIcon,
  model: appModel.documents,
  permission: appPermission.list,
  nodeId: uuid()
};

const createDocument = {
  label: fieldLabel.createDocument,
  url: () => `/documents/create`,
  labelIcon: CreateIcon,
  model: appModel.documents,
  permission: appPermission.edit,
  nodeId: uuid()
};

const editDocument = {
  label: fieldLabel.edit,
  url: (id: string) => `/documents/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.documents,
  permission: appPermission.edit,
  nodeId: uuid()
};

const viewDocument = {
  label: fieldLabel.view,
  url: (id: string) => `/documents/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.documents,
  permission: appPermission.view,
  nodeId: uuid()
};

const docRevisions = {
  label: fieldLabel.revisions,
  url: (id: string) => `/documents/${id}/revisions`,
  labelIcon: ListAltIcon,
  model: appModel.documents,
  permission: appPermission.view,
  nodeId: uuid()
};

const documentMenuItems = {
  list: [documents, createDocument],
  edit: [documents, viewDocument],
  create: [documents],
  view: [documents, viewDocument, editDocument, docRevisions]
};

export default documentMenuItems;
