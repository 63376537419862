import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import serviceColumn from 'assets/list/service/column';
import defaultSort from 'assets/list/service/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import vendorService from 'services/vendor-service';
import { GetListResponse } from 'types/api-response-types';
import { VendorServiceListEntity } from 'types/vendor-service-types';

const ServiceList = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();

  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setRouteName } = useRouteName();

  const getServices = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<VendorServiceListEntity>> => {
    const queryString: string = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: opportunity_id ? { opportunity_id } : {}
    });

    setIsLoading(true);

    const result = await vendorService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <React.Fragment>
      <DataGrid<VendorServiceListEntity>
        getData={getServices}
        tableColumns={serviceColumn}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="service"
      />
    </React.Fragment>
  );
};

export default ServiceList;
