import envConfig from 'config/env';
import { GetListResponse } from 'types/api-response-types';
import { ReportListEntity } from 'types/report-types';

import httpService from './http-service';

const reportService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/reports`,

  getList: async (
    query: string
  ): Promise<GetListResponse<ReportListEntity>> => {
    const result = await httpService.list<ReportListEntity>(
      `${reportService.url}${query}`
    );
    return result;
  }
};

export default reportService;
