import app from 'config/app';
import { TemplatePreviewEntity } from 'types/email-template-types';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  DeleteResponse,
} from 'types/api-response-types';
import { EmailEntity, EmailListEntity, EmailPreviewPayloadEntity } from 'types/email-types';
import { ObjectType } from 'types';

const emailService = {
  url: `/${app.crmApiVersion1}/emails`,

  getList: async (
    query: string
  ): Promise<GetListResponse<EmailListEntity>> => {
    const result = await httpService.list<EmailListEntity>(
      `${emailService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<EmailEntity>> => {
    const result = await httpService.get<EmailEntity>(
      `${emailService.url}/${id}`
    );
    return result;
  },

  delete: async (id: string): Promise<DeleteResponse<ObjectType>> => {
    const response = await httpService.del<ObjectType>(`${emailService.url}/${id}`);
    return response;
  },

  sendEmail: async (
    payload: FormData
  ): Promise<CreateResponse<EmailEntity>> => {
    const result = await httpService.post<EmailEntity, FormData>(
      `${emailService.url}/send`,
      payload
    );
    return result;
  },

  draftMail: async (
    payload: FormData
  ): Promise<CreateResponse<EmailEntity>> => {
    const result = await httpService.post<EmailEntity, FormData>(
      `${emailService.url}/draft`,
      payload
    );
    return result;
  },


  getEmailPreview: async (data: EmailPreviewPayloadEntity): Promise<CreateResponse<TemplatePreviewEntity>> => {
    const response = await httpService.post<TemplatePreviewEntity, EmailPreviewPayloadEntity>(`${emailService.url}/preview`, data);
    return response;
  },

};

export default emailService;
