import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { ClearDueDiligenceEntity } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export const getInitialState = (
  opportunity: OpportunityEntity
): ClearDueDiligenceEntity => {
  return {
    ...opportunity,
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
    buyer_contract_name_c: opportunity.buyer_contract_name_c,
    contract_price_c: opportunity.contract_price_c,
    due_diligence_end_c: opportunity.due_diligence_end_c,
    close_date_c: opportunity.close_date_c,
    actual_rehab_c: opportunity.actual_rehab_c,
    purchase_price_c: opportunity.purchase_price_c,
    entera_contribution: opportunity.entera_contribution || '',
    entera_contribution_amount: opportunity.entera_contribution_amount || '',
    reduction_type: opportunity.reduction_type || '',
    commission_reduction_type2: opportunity.commission_reduction_type2 || '',
    commision_reduction: opportunity.commision_reduction || '',
    commision_reduction2: opportunity.commision_reduction2 || ''
  };
};

export default { getInitialState };
