import MenuItem from '@mui/material/MenuItem';
import RouteLinkBlack from 'components/link/route-link-black';
import { v4 as uuid } from 'uuid';
import DropdownMenu from 'components/dropdown-menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import documentStatus from 'assets/constants/document-status';
import documentSubType from 'assets/constants/document-sub-type';
import documentType from 'assets/constants/document-type';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import DateUtility from 'helpers/date-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { DocumentsListEntity } from 'types/document-types';
import { useLocation, useParams } from 'react-router-dom';
import documentState from 'assets/constants/document-state';
import {
  removeAssociatedDocument,
  removeAssociatedOpportunityDocument
} from 'helpers/document/document-action-helper';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import { useState } from 'react';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import { OptionType } from 'types/option-type';
import documentTags from 'assets/constants/document-tags';

const Action = ({ document }: { document: DocumentsListEntity }) => {
  const { market_preference_id, opportunity_id } = useParams();
  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);
  const location = useLocation();

  const toggleModal = (proceed: () => void) => {
    setConfirmModal({
      open: true,
      title: fieldLabel.removeDocument,
      text: MessageDeleteRecordConfirmation(document.document_name, 'Document'),

      proceed: () => {
        proceed();
      },
      cancel: () => {
        setConfirmModal(initialConfirmModal);
      }
    });
  };

  const ddMenuItems = [];

  if (market_preference_id) {
    ddMenuItems.push(
      <>
        <MenuItem>
          <RouteLinkBlack
            name={fieldLabel.removeDocument}
            url={`/market-preferences/${market_preference_id}/documents`}
            handleClick={() =>
              toggleModal(() => {
                return removeAssociatedDocument(
                  market_preference_id!,
                  document.id
                );
              })
            }
          />
        </MenuItem>
        {confirmModal.open && <ConfirmBox {...confirmModal} />}
      </>
    );
  }

  if (opportunity_id) {
    ddMenuItems.push(
      <>
        <MenuItem>
          <RouteLinkBlack
            name={fieldLabel.removeDocument}
            url={`/opportunities/${opportunity_id}/documents`}
            handleClick={(event) => {
              event.preventDefault();
              toggleModal(() => {
                return removeAssociatedOpportunityDocument(
                  opportunity_id!,
                  document.id
                );
              });
            }}
          />
        </MenuItem>
        {confirmModal.open && <ConfirmBox {...confirmModal} />}
      </>
    );
  }
  return (
    <>
      {!location.pathname.endsWith('generate_contract') && (
        <>
          {ddMenuItems.length > 0 && <DropdownMenu menuItems={ddMenuItems} />}
          <RouteEditIconLink
            url={
              market_preference_id
                ? `/market-preferences/${market_preference_id}/documents/${document?.id}/edit`
                : `/documents/${document.id}/edit`
            }
          />
          <RouteOpenNewIconLink
            url={
              market_preference_id
                ? `/market-preferences/${market_preference_id}/documents/${document?.id}/view`
                : `/documents/${document.id}/view`
            }
          />
        </>
      )}
    </>
  );
};

const documentColumns: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    renderCell: (param: GridRenderCellParams<DocumentsListEntity>) => (
      <>
        <Action document={param.row} />
        <RouteLink
          url={`/documents/${param.row.id}/revisions/${param.row.document_revision_id}/viewer`}
          name={
            <IconButton>
              <VisibilityIcon />
            </IconButton>
          }
          target={true}
        />
      </>
    ),
    sortable: false,
    flex: 1,
    minWidth: 160
  },
  {
    headerName: fieldLabel.documentName,
    field: 'document_name',
    sortable: true,
    flex: 3,
    minWidth: 400,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => (
      <Box style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
        <RouteLink url={`/documents/${row.id}/view`} name={row.document_name} />
      </Box>
    )
  },
  {
    headerName: fieldLabel.documentType,
    field: 'category_id',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentType, row.category_id);
    }
  },
  {
    headerName: fieldLabel.documentSubType,
    field: 'document_subtype',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      const rowDocumentType =
        documentSubType[row.category_id] !== undefined &&
        documentSubType[row.category_id]
          .map((x: OptionType) =>
            x.value === row.document_subtype ? x.label : ''
          )
          .filter(function (el: string) {
            return el != '';
          });
      if (rowDocumentType.length > 0) {
        return rowDocumentType;
      } else {
        return row.document_subtype;
      }
    }
  },
  {
    headerName: fieldLabel.documentTag,
    field: 'document_tag',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentTags, row.document_tag);
    }
  },
  {
    headerName: fieldLabel.documentState,
    field: 'document_state',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentState, row.document_state);
    }
  },
  {
    headerName: fieldLabel.status,
    field: 'status_id',
    sortable: true,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentStatus, row.status_id);
    }
  },
  {
    headerName: fieldLabel.publishDate,
    field: 'active_date',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return DateUtility.getDateString(row.active_date);
    }
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default documentColumns;
