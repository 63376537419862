import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material';
import useColorToken from 'hooks/useColorToken';

const ButtonTab = styled(MuiButton)(({ theme }: any) => {
  const colors = useColorToken();

  return {
    color: colors.grey[900],
    backgroundColor: colors.white[900],
    border: '1px solid #e0e0e0',
    fontWeight: '700px',
    borderRadius: '4px',
    minHeight: '48px',
    '&:hover': {
      backgroundColor: colors.white[800],
      boxShadow: `inset 0 0 0 1px #0000001f, inset 0 -1px 0 #0000001f`
    },
    '&:disabled': {
      backgroundColor: colors.grey[400]
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      minWidth: 32,
      paddingLeft: 10,
      paddingRight: 10,
      marginRight: 10,
      '& .MuiSvgIcon-root': {
        fontSize: '14px !important'
      },
      '& .MuiButton-startIcon': {
        marginRight: '2px'
      }
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      minWidth: 32,
      paddingLeft: 10,
      paddingRight: 10,
      '& .MuiSvgIcon-root': {
        fontSize: '12px'
      },
      '& .MuiButton-startIcon': {
        marginRight: '8px'
      }
    }
  };
});

export default ButtonTab;
