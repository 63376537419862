import { Paper, Stack } from '@mui/material';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import fieldLabel from 'assets/constants/fieldLabel';
import reportColumn from 'assets/list/report/column';
import defaultSort from 'assets/list/report/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import LinkExtenalOpenNewIcon from 'components/link/link-external-open-new-icon';
import Title from 'components/typography/title';
import envConfig from 'config/env';
import { getAuthToken } from 'helpers/auth-helper';
import { getItem } from 'helpers/local-storage-helper';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import ReportService from 'services/reports-service';
import initialReportListFilter from 'state/report/initial-report-list-filter';
import { GetListResponse } from 'types/api-response-types';
import { ReportFilterEntity, ReportListEntity } from 'types/report-types';

import Filters from './filters';
import { ChangeEvent } from 'types/common-types';

const ReportList = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<ReportFilterEntity>(
    initialReportListFilter
  );
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const user = JSON.parse(getItem('user') || 'false');

  const token = getAuthToken();

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getReports = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<ReportListEntity>> => {
    const filterInfo = {
      ...filter,
      assigned_to: user.user.is_admin !== 1 && user.user.id
    };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await ReportService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stack direction="row">
          <Title value={fieldLabel.myReports} />
          <LinkExtenalOpenNewIcon
            url={`${envConfig.REACT_APP_CRM_URL}/index.php?module=AOR_Reports&action=index&source=${token}`}
          />
        </Stack>

        <Filters
          filter={filter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          setFilter={setFilter}
          isLoading={isLoading}
        />

        <DataGrid<ReportListEntity>
          getData={getReports}
          tableColumns={reportColumn}
          isFilterChanged={isFilterChanged}
          setIsFilterChanged={setIsFilterChanged}
          initialLoad={initialLoad}
          setInitialLoad={setInitialLoad}
          isLoading={isLoading}
        />
      </Paper>
    </>
  );
};

export default ReportList;
