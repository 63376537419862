import ViewChangelog from 'features/view-changelog';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import useRouteName from 'pages/route-outlet-context';

const MarketViewChangelog = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { msa_id } = useParams<{ msa_id: string }>();
  const { setRouteName } = useRouteName();
  const [filter, setFilter] = useState<{ market_id: string }>({
    market_id: msa_id!
  });

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="msa_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="msa"
      />
    </>
  );
};

export default MarketViewChangelog;
