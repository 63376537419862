import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityEntity } from 'types/opportunity-types';

export const offer_date_c = {
  validate: function (opportunity: OpportunityEntity) {
    let errors: string[] = [];

    if (
      new Date(opportunity?.offer_date_c || '').getTime() > new Date().getTime()
    ) {
      errors.push('Offer Date should not be in future');
    }

    const stateList = [
      oppurtunityStatusList.offer_rejected,
      oppurtunityStatusList.offer_accepted
    ];

    if (
      stateList.includes(opportunity?.opportunity_status_c) && isEmpty(opportunity?.offer_date_c)
    ) {
      errors.push(missingField(fieldLabel.offerDate));
    }
    return errors;
  }
};
