import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/document-revision/default-sort';
import documentRevisionColumns from 'assets/list/document-revision/columns';
import documentRevisionService from 'services/document-revision-service';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import { DocumentRevisionListEntity } from 'types/document-revision-types';

const DocumentRevisions = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { setRouteName } = useRouteName();

  const { document_id } = useParams<{ document_id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const getRevisions = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<DocumentRevisionListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: { document_id }
    });

    setIsLoading(true);
    const result = await documentRevisionService.getList(queryString);
    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <DataGrid<DocumentRevisionListEntity>
        getData={getRevisions}
        tableColumns={documentRevisionColumns}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="document"
      />
    </React.Fragment>
  );
};

export default DocumentRevisions;
