import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
/** @TODO add Types */

const listPdfTemplate = {
  label: fieldLabel.list,
  url: () => `/pdf-templates`,
  labelIcon: ListAltIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.list,
  nodeId: uuid()
};

const createPdfTemplate = {
  label: fieldLabel.create,
  url: () => `/pdf-templates/create`,
  labelIcon: CreateIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.edit,
  nodeId: uuid()
};

const viewPdfTemplate = {
  label: fieldLabel.view,
  url: (id: string) => `/pdf-templates/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.view,
  nodeId: uuid()
};

const editPdfTemplate = {
  label: fieldLabel.edit,
  url: (id: string) => `/pdf-templates/${id}/edit`,
  labelIcon: CreateIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.edit,
  nodeId: uuid()
};

const viewAsPdf = {
  label: fieldLabel.viewAsPdf,
  url: (id: string) => `/pdf-templates/${id}/pdf-view`,
  labelIcon: PictureAsPdfIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.view,
  nodeId: uuid(),
  routeTag: 'pdf-templates-pdf-view'
};

const viewAsReadablePdf = {
  label: fieldLabel.viewAsReadablePdf,
  url: (id: string) => `/pdf-templates/${id}/readable-pdf-view`,
  labelIcon: PictureAsPdfIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.view,
  nodeId: uuid(),
  routeTag: 'pdf-templates-readable-pdf-view'
};

const deletePdfTemplate = {
  label: fieldLabel.delete,
  url: (id: string) => `/pdf-templates/${id}/delete`,
  labelIcon: DeleteIcon,
  model: appModel.AOSPdfTemplates,
  permission: appPermission.delete,
  nodeId: uuid()
};

const pdfTemplateMenuItems = {
  list: [listPdfTemplate, createPdfTemplate],
  create: [listPdfTemplate, createPdfTemplate],
  edit: [
    listPdfTemplate,
    viewPdfTemplate,
    editPdfTemplate,
    viewAsPdf,
    viewAsReadablePdf,
    deletePdfTemplate
  ],
  view: [
    listPdfTemplate,
    viewPdfTemplate,
    editPdfTemplate,
    viewAsPdf,
    viewAsReadablePdf,
    deletePdfTemplate
  ]
};

export default pdfTemplateMenuItems;
