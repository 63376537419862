import { OptionType } from 'types/option-type';

export const seller_offer_response = (
  value: OptionType | OptionType[],
  action: string
) => {
  let result = ``;
  if (!value) return ``;

  if (action === 'dashboard' && !Array.isArray(value)) {
    result += `&filter[seller_offer_response]=${value.value}`;
  } else if (action === 'properties' && Array.isArray(value)) {
    if (value.length === 0) return ``;

    for (const el of value) {
      result += `&filter[seller_offer_response][]=${el.value}`;
    }
  }

  return result;
};
