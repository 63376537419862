import envConfig from 'config/env';
import httpService from './http-service';
import { ServiceProviderListEntity, ServiceProviderPayloadEntity } from 'types/service-provider-types';

const serviceProviderService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/service_providers`,

  getList: async (query: string) => {
    const response = await httpService.list<ServiceProviderListEntity>(`${serviceProviderService.url}${query}`);
    return response;
  },
  create: async (data: ServiceProviderPayloadEntity) => {
    const response = await httpService.post<ServiceProviderListEntity, ServiceProviderPayloadEntity>(`${serviceProviderService.url}`, data);
    return response;
  }
};

export default serviceProviderService;
