import buttonText from 'assets/constants/button';
import AddIcon from '@mui/icons-material/Add';
import PrimaryButton from 'components/button/button-primary';
import { ClickEvent } from 'types/common-types';
import { SxProps } from '@mui/material';

interface AddButtonInterface {
  onClick: (e: ClickEvent) => void;
  disabled?: boolean;
  sx?: SxProps;
}

const AddButton = ({ onClick, disabled = false, sx }: AddButtonInterface) => {
  return (
    <PrimaryButton
      variant="contained"
      size="medium"
      name="btn-add"
      onClick={onClick}
      startIcon={<AddIcon />}
      sx={{ ...sx }}
      disabled={disabled}
    >
      {buttonText.add}
    </PrimaryButton>
  );
};

export default AddButton;
