import ModalComponent from 'components/modal';
import React from 'react';

import ConfirmBoxContent from './confirm-box-form';
import emptyFunction from 'helpers/empty-function-helper';

export interface ConfirmModalProps {
  open: boolean;
  text: string | JSX.Element;
  title: string;
  proceed: () => void;
  cancel: () => void;
}

export const initialConfirmModalState = {
  open: false,
  text: '',
  title: '',
  proceed: emptyFunction,
  cancel: emptyFunction
};

const ConfirmBox = ({
  title,
  text,
  proceed,
  cancel,
  isLoading = false
}: {
  title: string;
  text: JSX.Element | string;
  onClose?: () => void;
  proceed: () => void;
  cancel: () => void;
  isLoading?: boolean;
}) => {
  return (
    <>
      <ModalComponent
        Component={ConfirmBoxContent}
        data={{
          title,
          text,
          cancel,
          proceed
        }}
        onClose={cancel}
        loading={isLoading}
        size="md"
        isConfirmationBox={true}
      />
    </>
  );
};

export default ConfirmBox;
