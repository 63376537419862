import DASHBOARDS from 'assets/constants/dashboards';
import React from 'react';
import Filter from './filter';
import PropertiesHoc from 'components/properties/properties-hoc';
import propertiesFilterHelper from 'pages/properties/helper';

const OfferProperties = () => {
  return (
    <>
      <PropertiesHoc
        dashboard={DASHBOARDS.PROPERTIES_OFFERS}
        Filter={Filter}
        prepareFilterString={propertiesFilterHelper.offers}
      />
    </>
  );
};

export default OfferProperties;
