import { PartyListEntity } from "types/party-types";

const initialPartyList: PartyListEntity[] = [
  {
    id: '',
    email: '',
    name: '',
    type: '',
    sub_type: '',
    name_2: '',
    company: '',
    address: '',
    brokerage_license: '',
    mobile: '',
    license: '',
    date_entered: ''
  }
];

export default initialPartyList;
