import fieldLabel from 'assets/constants/fieldLabel';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import UnitLabel from 'components/form/unit-label';
import UnitRead from 'components/form/unit-read';
import UnitText from 'components/form/unit-text';
import ModalComponent from 'components/modal';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import emptyFunction from 'helpers/empty-function-helper';
import eventBus from 'helpers/event-bus-helper';
import { useState, useEffect, Fragment } from 'react';
import { ObjectType } from 'types';
import { ChangeEvent } from 'types/common-types';
import oppurtunityService from 'services/oppurtunity-service';
import { toastSuccess } from 'event/toast-event';
import UnitEmpty from 'components/form/unit-empty';
import CopyToClipboard from 'components/copy-to-clipboard';

interface ComponentProps {
  data: ObjectType;
  handleSubmit: () => void;
  handleChange: () => void;
  onClose: () => void;
}

const Component = ({
  onClose,
  data,
  handleSubmit,
  handleChange
}: ComponentProps) => {
  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxHeader
          value={
            <>
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                <ModalHeader title="Escrow Alert" onClose={onClose} />
              </UnitItem>
            </>
          }
        />
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 40vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitRead
              label="Old Escrow"
              value={data.oldEscrow?.escrow_company_contact_c ?? ''}
            />
            <UnitRead
              label="New Escrow"
              value={data.newEscrow?.escrow_company_contact_c ?? ''}
            />

            <UnitEmpty />

            <UnitText
              label={fieldLabel.email}
              name={'email'}
              value={data?.newEscrow?.escrow_company_email_c}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    value={data?.newEscrow?.escrow_company_email_c}
                  />
                )
              }}
              readOnly={true}
            />

            <UnitLabel
              grid={{ xs: 12, sm: 12 }}
              label="The Executed contract email will be sent to"
              fontWeight="bolder"
            />

            <UnitText
              grid={{ xs: 12, sm: 12 }}
              label={fieldLabel.to}
              name={'executed_contract_email_to'}
              value={data.executed_contract_email_to}
              onChange={handleChange}
            />

            <UnitText
              grid={{ xs: 12, sm: 12 }}
              label={fieldLabel.cc}
              name={'executed_contract_email_cc'}
              value={data.executed_contract_email_cc}
              onChange={handleChange}
            />

            <UnitText
              grid={{ xs: 12, sm: 12 }}
              label={fieldLabel.bcc}
              name={'executed_contract_email_bcc'}
              value={data.executed_contract_email_bcc}
              onChange={handleChange}
            />
          </FormContainer>
          <StackRowWithDivider>
            <SaveButton onClick={handleSubmit} />
            <CancelButton
              onClick={() => {
                onClose();
              }}
            />
          </StackRowWithDivider>
        </PaperBoxContent>
      </PaperBox>
    </>
  );
};

const EscrowChangeAlert = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState<ObjectType>({});

  const updateState = (value: ObjectType) => {
    setData(value);
  };

  const handleChange = (e: ChangeEvent) => {
    setData((prevData: ObjectType) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async () => {
    const requestBody = {
      parties_escrow_company_id: data.newEscrow.parties_escrow_company_id,
      executed_contract_email_to: data.executed_contract_email_to,
      executed_contract_email_cc: data.executed_contract_email_cc,
      executed_contract_email_bcc: data.executed_contract_email_bcc
    };

    const response = await oppurtunityService.update(
      data.opportunity_id,
      requestBody
    );

    if (response.isSuccess) {
      eventBus.dispatch('SET_ESCROW_PARTY', {
        ...data.newEscrow,
        executed_contract_email_to: data.executed_contract_email_to,
        executed_contract_email_cc: data.executed_contract_email_cc,
        executed_contract_email_bcc: data.executed_contract_email_bcc
      });
      toastSuccess(`Escrow Saved`);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    eventBus.on('ESCROW_CHANGE', (data: ObjectType) => {
      updateState(data);
      setIsModalOpen(true);
    });

    return () => {
      eventBus.remove('ESCROW_CHANGE', emptyFunction);
    };
  }, []);

  return (
    <Fragment>
      {isModalOpen === true && (
        <ModalComponent
          Component={Component}
          data={{
            data: data,
            handleChange: handleChange,
            handleSubmit: handleSubmit,
            onClose: () => {
              eventBus.dispatch('SET_ESCROW_PARTY', data.oldEscrow);
              setIsModalOpen(false);
            }
          }}
          onClose={() => {
            eventBus.dispatch('SET_ESCROW_PARTY', data.oldEscrow);
            setIsModalOpen(false);
          }}
        ></ModalComponent>
      )}
    </Fragment>
  );
};

export default EscrowChangeAlert;
