import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import { validateEmailTemplate } from 'helpers/validation/email-template-helper';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import emailTemplateService from 'services/email-template-service';
import marketPreferenceService from 'services/market-preference-service';
import initialEmailTemplate from 'state/email-template/initial-email-template';
import {
  EmailTemplateEntity,
  EmailTemplatePayloadEntity,
  HtmlContentType
} from 'types/email-template-types';
import RecordView from '../record-view';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import { GetResponse } from 'types/api-response-types';
import CircularLoader from 'components/dog-loader/dog-lodar';

const EmailTemplateCreate = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { email_template_id, market_preference_id } = useParams<{
    email_template_id: string;
    market_preference_id: string;
  }>();
  const navigate = useNavigate();

  const [emailTemplate, setEmailTemplate] =
    useState<EmailTemplateEntity>(initialEmailTemplate);

  const { routeName, setRouteName } = useRouteName();

  const [validation, setValidation] = useState<ErrorValidation>({
    crm_opportunity: ['Please select opportunity'],
    mustache_opportunity: ['Please select opportunity']
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [content, setContent] = useState<Partial<HtmlContentType>>({
    html: '',
    text: '',
    preview: '',
    opportunity: { id: '', name: '' }
  });

  const [mustache, setMustache] = useState<Partial<HtmlContentType>>({
    html: '',
    text: '',
    preview: '',
    opportunity: {
      id: '',
      name: ''
    }
  });

  const handleChange = (e: InputChangeEvent) => {
    setEmailTemplate(
      Object.assign({}, emailTemplate, { [e.target.name]: e.target.value })
    );
  };

  const loadEmailTemplate = async (
    email_template_id: string
  ): Promise<void> => {
    let emailTemplate: GetResponse<EmailTemplateEntity>;
    setIsLoading(true);
    if (market_preference_id) {
      emailTemplate = await marketPreferenceService.getAssociatedEmailTemplate(
        market_preference_id,
        email_template_id
      );
    } else {
      emailTemplate = await emailTemplateService.get(email_template_id);
    }
    setIsLoading(false);

    setEmailTemplate(emailTemplate.data);
    setContent({ html: emailTemplate.data.body_html });
    setMustache((prevMustache) => ({
      ...prevMustache,
      html: emailTemplate.data.mustache_body_html
    }));
  };

  const prepareRequestBody = (): EmailTemplatePayloadEntity => {
    return {
      name: emailTemplate.name || '',
      related_event: emailTemplate.related_event || '',
      type: emailTemplate.type || '',
      description: emailTemplate.description || '',
      subject: emailTemplate.subject,
      body_html: content.html || '',
      mustache_body_html: mustache.html || '',
      template_type: emailTemplate.template_type,
      mustache_subject: emailTemplate.mustache_subject
    };
  };

  const handleSubmit = async (): Promise<void> => {
    const requestbody: EmailTemplatePayloadEntity = prepareRequestBody();

    const { hasValidationError, ...errors } = validateEmailTemplate(
      emailTemplate,
      market_preference_id
    );
    setValidation(errors);

    if (hasValidationError) return;

    let response;

    setValidation({});

    setIsLoading(true);

    if (routeName === 'email-templates-duplicate') {
      response = await emailTemplateService.create(requestbody);
    } else if (
      !isEmpty(email_template_id) &&
      !isEmpty(market_preference_id) &&
      routeName == 'market-preferences-email-templates-edit'
    ) {
      const associatedReqBody = {
        email_inspection: emailTemplate.email_inspection
      };
      response = await marketPreferenceService.updateAssociateEmailTemplate(
        market_preference_id!,
        email_template_id!,
        associatedReqBody
      );
    } else if (!isEmpty(email_template_id)) {
      response = await emailTemplateService.update(
        email_template_id!,
        requestbody
      );
    } else {
      response = await emailTemplateService.create(requestbody);
    }
    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
    }

    if (response?.isSuccess) {
      if (market_preference_id) {
        navigate(`/market-preferences/${market_preference_id}/email-templates`);
      } else {
        navigate(`/email-templates/${response.data.id}/view`);
      }
    }
  };

  useEffect(() => {
    if (email_template_id !== undefined) loadEmailTemplate(email_template_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  if (isLoading) return <CircularLoader />;

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView
          emailTemplate={emailTemplate}
          validation={validation}
          onChange={handleChange}
          content={content}
          setContent={setContent}
          mustache={mustache}
          setMustache={setMustache}
          theme="snow"
          isCreate={true}
          setValidation={setValidation}
          setEmailTemplate={setEmailTemplate}
        />

        <StackRowWithDivider>
          <SaveButton onClick={handleSubmit} disabled={isLoading} />
          <CancelButton />
        </StackRowWithDivider>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default EmailTemplateCreate;
