import DashboardIcon from '@mui/icons-material/Dashboard';
import { v4 as uuid } from 'uuid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HouseIcon from '@mui/icons-material/House';
import StoreIcon from '@mui/icons-material/Store';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleIcon from '@mui/icons-material/Article';
import DraftsIcon from '@mui/icons-material/Drafts';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MailIcon from '@mui/icons-material/Mail';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SurfingIcon from '@mui/icons-material/Surfing';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import QueueIcon from '@mui/icons-material/Queue';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HistoryIcon from '@mui/icons-material/History';
import SchoolIcon from '@mui/icons-material/School';
import CallIcon from '@mui/icons-material/Call';
import fieldLabel from 'assets/constants/fieldLabel';

const topMenuItems = [
  {
    label: fieldLabel.dashboards,
    labelIcon: DashboardIcon,
    showInTopbar: true,
    nodeId: uuid(),
    subMenuItems: [
      {
        label: fieldLabel.negotiator,
        url: `/dashboard/negotiator`,
        labelIcon: SurfingIcon,
        nodeId: uuid()
      },
      {
        label: fieldLabel.closing,
        url: `/dashboard/closing`,
        labelIcon: ShoppingCartIcon,
        nodeId: uuid()
      },
      {
        label: fieldLabel.transaction,
        url: `/dashboard/transaction`,
        labelIcon: MonetizationOnIcon,
        nodeId: uuid()
      }
    ]
  },
  {
    label: `${fieldLabel.offers}-${fieldLabel.closings}`,
    url: '/properties',
    labelIcon: HouseIcon,
    showInTopbar: true,
    nodeId: uuid()
  },
  {
    label: fieldLabel.market,
    url: '/msa',
    labelIcon: StoreIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.accounts,
    url: '/accounts',
    labelIcon: CorporateFareIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.documents,
    url: '/documents',
    labelIcon: ArticleIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.emailTemplates,
    url: '/email-templates',
    labelIcon: DraftsIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.knowledgeBase,
    url: '/knowledge-bases',
    labelIcon: SchoolIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.marketPreferences,
    url: '/market-preferences',
    labelIcon: ManageAccountsIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.marketPreferencesBrokerageUsers,
    url: '/market-preferences-brokerage-users',
    labelIcon: DirectionsRunIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.opportunityBrokerageUsers,
    url: '/opportunity-brokerage-users',
    labelIcon: Diversity3Icon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.emails,
    url: '/email/inbox',
    labelIcon: MailIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.contracts,
    url: '/pdf-templates',
    labelIcon: RequestPageIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.parties,
    url: '/parties',
    labelIcon: PeopleIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.services,
    url: '/services',
    labelIcon: MiscellaneousServicesIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.sqs,
    url: '/sqs',
    labelIcon: QueueIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.tasks,
    url: '/tasks',
    labelIcon: AssignmentIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.calls,
    url: '/calls',
    labelIcon: CallIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.transactionHistory,
    url: '/transaction-histories',
    labelIcon: HistoryIcon,
    nodeId: uuid()
  },
  {
    label: fieldLabel.reports,
    url: '/reports',
    showInTopbar: true,
    labelIcon: SummarizeIcon,
    nodeId: uuid()
  }
];

export default topMenuItems;
