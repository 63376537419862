import { isChecked } from 'components/form/unit-switch';
import { ObjectType } from 'types';

export const offer_blocked_resolved_by = {
  calculate: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    if (isChecked(opportunity.is_offer_blocked_resolved)) {
      const currentUser = JSON.parse(localStorage.getItem('user') || 'false');
      return {
        id: currentUser?.user?.id,
        name: `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`
      };
    }

    return opportunity.offer_blocked_resolved_by;
  }
};
