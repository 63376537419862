export const fieldOptions = {
  earnest_money_status_c: {
    name: 'earnest_money_status_c',
    title: 'Earnest Fee',
    checked: true
  },
  option_fee_status_c: {
    name: 'option_fee_status_c',
    title: 'Option Fee',
    checked: false
  }
};
