import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { ProfileEntity } from 'types/user';

const EMAIL_REGEX = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
const MOBILE_REGEX = /^(\+1\s?)?(\(?\d{3}\)?)[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const validateProfile = (
  data: ProfileEntity
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation: {
    status: boolean;
    errors: ErrorValidation;
  } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.id) && isEmpty(data.user_name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        user_name: [missingField(fieldLabel.userName)]
      }
    };
  }

  if (isEmpty(data.first_name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        first_name: [missingField(fieldLabel.firstName)]
      }
    };
  }

  if (isEmpty(data.id) && isEmpty(data.email)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: [missingField(fieldLabel.email)]
      }
    };
  } else if (!EMAIL_REGEX.test(data.email)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: ['Invalid email']
      }
    };
  } else if (
    !(
      data.email.endsWith('entera.ai') ||
      data.email.endsWith('enterareality.com')
    )
  ) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: [
          `Email must have domain of 'entera.ai' or 'enterareality.com'  `
        ]
      }
    };
  }

  if (isEmpty(data.last_name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        last_name: [missingField(fieldLabel.lastName)]
      }
    };
  }

  if (isEmpty(data.status)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        user_status: [missingField(fieldLabel.status)]
      }
    };
  }

  if (isEmpty(data.crm_user_type)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        crm_user_type: [missingField(fieldLabel.brokerageUserType)]
      }
    };
  }

  if (!isEmpty(data.phone_work) && !MOBILE_REGEX.test(data.phone_work)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        phone_work: ['Invalid Phone']
      }
    };
  }

  if (!isEmpty(data.phone_mobile) && !MOBILE_REGEX.test(data.phone_mobile)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        phone_mobile: ['Invalid Mobile']
      }
    };
  }

  return validation;
};

export const preparePayload = (data: ProfileEntity) => {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    phone_work: data.phone_work,
    phone_mobile: data.phone_mobile,
    title: data.title,
    user_name: data.user_name,
    email: data.email,
    status: data.status,
    crm_user_type: data.crm_user_type,
    allow_login_with_crm_cred: data.allow_login_with_crm_cred
  };
};

export const getAlertMessage = (routeTag: string) => {
  if (routeTag == 'user-add') {
    return 'User added Successfully';
  } else if (routeTag === 'user-edit') {
    return 'User updated Successfully';
  } else {
    return 'Your profile Updated.';
  }
};
