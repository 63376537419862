import { useLocation } from 'react-router-dom';
import MuiBreadcrumbs from 'components/breadcrumbs';
import setUrlMetadata, {
  getBreadcrumbsMetadata,
  getDataForBreadcrumbsUrl
} from 'helpers/breadcrumbs-url-helper';
import BreadcrumbItem from 'components/breadcrumbs/item';
import { v4 as uuid } from 'uuid';
import BreadcrumbItemHome from 'components/breadcrumbs/item-home';
import React, { useEffect } from 'react';
import PageTitle from 'features/page-title';

interface BreadcrumbsMetadata {
  label: string;
  skipBreadcrumbs?: boolean;
  to: string;
  type: string;
}

const Breadcrumbs = ({ borderBottom = 1 }: { borderBottom?: number }) => {
  const location = useLocation();

  const [breadcrumbsMetadataState, setbreadcrumbsMetadataState] =
    React.useState<BreadcrumbsMetadata[]>([]);

  const breadcrumbs = breadcrumbsMetadataState.map(
    (urlElement, index: number) => {
      if (
        urlElement['type'] === 'action' ||
        index === breadcrumbsMetadataState.length - 1
      ) {
        return (
          <BreadcrumbItem key={uuid()} type="title" title={urlElement.label} />
        );
      }

      return (
        <BreadcrumbItem
          key={uuid()}
          to={urlElement.to}
          title={urlElement.label}
        />
      );
    }
  );

  const loadBreadcrumbsMeta = async () => {
    const urlMetadata = setUrlMetadata(location);

    await getDataForBreadcrumbsUrl(urlMetadata);
    let breadcrumbsMetadata = getBreadcrumbsMetadata(urlMetadata);

    breadcrumbsMetadata = breadcrumbsMetadata.filter(
      (urlElement: BreadcrumbsMetadata) =>
        !(urlElement.skipBreadcrumbs === true)
    );

    setbreadcrumbsMetadataState(breadcrumbsMetadata);
  };

  useEffect(() => {
    loadBreadcrumbsMeta();
  }, [location.pathname]);

  return (
    <>
      <MuiBreadcrumbs sx={{ borderBottom: borderBottom, p: 1 }}>
        {[<BreadcrumbItemHome key={uuid()} />].concat(breadcrumbs)}{' '}
      </MuiBreadcrumbs>
      <PageTitle breadcrumbsMetadata={breadcrumbsMetadataState} />
    </>
  );
};

export default Breadcrumbs;
