import {
  TransactionHistoryListEntity,
  TransactionHistoryEntity
} from 'types/transaction-history-types';
import { GetListResponse, GetResponse } from 'types/api-response-types';
import envConfig from 'config/env';
import httpService from './http-service';

const transactionHistoryService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/transaction_history`,

  getList: async (
    query: string
  ): Promise<GetListResponse<TransactionHistoryListEntity>> => {
    const response = await httpService.list<TransactionHistoryListEntity>(
      `${transactionHistoryService.url}${query}`
    );
    return response;
  },

  get: async (id: string): Promise<GetResponse<TransactionHistoryEntity>> => {
    const response = await httpService.get<TransactionHistoryEntity>(
      `${transactionHistoryService.url}/${id}`
    );
    return response;
  },
};

export default transactionHistoryService;
