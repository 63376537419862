import { MenuItemType } from 'types/menu-types';
import { Outlet, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContainerRight from 'components/container/right';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import menuHelper from 'helpers/menu-helper';
import sideManuHelper from './side-menu-helper';
import Breadcrumbs from 'features/breadcrumbs';

const PartyPage = (): JSX.Element => {
  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const [routeName, setRouteName] = useState<string>('');
  const pararms = useParams<{ party_id: string, msa_id: string }>();

  const loadMenuItems = async (): Promise<MenuItemType[]> => {
    const menus: MenuItemType[] = await sideManuHelper.getMenuItems(routeName);
    return menus;
  };

  const getMenus = async (): Promise<void> => {
    const menusToshow: MenuItemType[] = menuHelper.createMenusItems(
      await loadMenuItems(),
      pararms.party_id
    );
    setSideNavMenuItems(menusToshow);
  };

  useEffect(() => {
    getMenus();
  }, [routeName]);

  return (
    <>
      <Breadcrumbs />
      <ContainerRight>
        <Outlet context={{ routeName, setRouteName }} />
      </ContainerRight>
    </>
  );
};

export default PartyPage;
