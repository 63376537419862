import { ObjectType } from 'types';
import { MarketPreferenceEntity } from 'types/market-preference-types';

const setRequest = (
  marketPreferences: MarketPreferenceEntity
): MarketPreferenceEntity => {
  return {
    id: marketPreferences.id,
    name: marketPreferences.name,
    account_id: marketPreferences.account_id,
    msa_id: marketPreferences.msa_id,
    account_name: marketPreferences.account_name,
    msa_name: marketPreferences.msa_name,
    broker_rep_firm: marketPreferences.broker_rep_firm,
    broker_rep_firm_license_number:
      marketPreferences.broker_rep_firm_license_number,
    broker_rep_mlsid: marketPreferences.broker_rep_mlsid,
    broker_rep_agent_license_number:
      marketPreferences.broker_rep_agent_license_number,
    broker_rep_agent_email: marketPreferences.broker_rep_agent_email,
    sqs_status_trigger: marketPreferences.sqs_status_trigger_text_display
      .map((x: ObjectType) => x.value)
      .join(', '),
    sqs_status_trigger_text_display:
      marketPreferences.sqs_status_trigger_text_display,
    enable_mp_wise_contract: marketPreferences.enable_mp_wise_contract,
    enable_generate_cgm_contract:
      marketPreferences.enable_generate_cgm_contract,
    enable_cgm_preview_contract: marketPreferences.enable_cgm_preview_contract,
    enable_cgm_multiple_generate_contract:
      marketPreferences.enable_cgm_multiple_generate_contract,
    pre_close_notification: marketPreferences.pre_close_notification,
    pre_close_notification_days: marketPreferences.pre_close_notification_days,
    pre_close_notification_to_recipient:
      marketPreferences.pre_close_notification_to_recipient,
    pre_close_notification_cc_recipient:
      marketPreferences.pre_close_notification_cc_recipient,
    entera_offer_defaults: marketPreferences.entera_offer_defaults,
    generate_loi_email: marketPreferences.generate_loi_email,
    crm_status: marketPreferences.crm_status,
    should_attach_psa_hoa_document:
      marketPreferences.should_attach_psa_hoa_document,
    enable_to_upload_document_revision:
      marketPreferences.enable_to_upload_document_revision,
    get_msa: marketPreferences.get_msa,
    get_account: marketPreferences.get_account
  };
};

export default setRequest;
