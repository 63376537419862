import React, { ReactNode } from 'react';
import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';

const Tooltip: React.FC<TooltipProps> = ({ title, children, ...restProps }) => {
  return (
    <MUITooltip title={title} {...restProps}>
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
