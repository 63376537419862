import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';

const sideMenuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'sqs' || routeName === 'sqs-create') {
      return await menuHelper.getItems(appModel.sqs, appPermission.list);
    }

    if (routeName === 'sqs-view' || routeName === 'sqs-change-log') {
      return await menuHelper.getItems(appModel.sqs, appPermission.view);
    }

    if (routeName === 'sqs-edit') {
      return await menuHelper.getItems(appModel.sqs, appPermission.edit);
    }

    return [];
  }
};

export default sideMenuHelper;
