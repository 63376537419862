import React from 'react';
import MDEditor, {
  ContextStore,
  ICommand,
  commands
} from '@uiw/react-md-editor';
import './style.css';

interface PropsTypes {
  value: string;
  onChange: (val: string) => void;
  readOnly: boolean;
}

const MdEditor = (props: PropsTypes) => {
  const { value, onChange, readOnly } = props;

  const handleInsertTable = () => {
    const tableMarkdown =
      '| Header 1 | Header 2 |\n| --- | --- |\n| Cell 1 | Cell 2 |';
    onChange(value + '\n\n' + tableMarkdown);
  };

  let tableIcon = (
    <svg width="12" height="12" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 18H4V6h16v12zM4 20h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2z" />
      <path d="M8 4h2v16H8zm4 0h2v16h-2z" />
    </svg>
  );

  const tableCommand: ICommand = {
    name: 'insert-table',
    keyCommand: 'ctrl',
    buttonProps: { 'aria-label': 'Insert Table' },
    icon: tableIcon,
    execute: () => handleInsertTable()
  };
  return (
    <MDEditor
      className="w-md-editor-text-input"
      value={value}
      onChange={(
        e?: string | undefined,
        event?: React.ChangeEvent<HTMLTextAreaElement> | undefined,
        state?: ContextStore | undefined
      ) => {
        onChange(e ?? '');
      }}
      aria-readonly={readOnly}
      hideToolbar={readOnly}
      height={400}
      commands={[...commands.getCommands(), tableCommand]}
    />
  );
};

export default MdEditor;
