import emptyFunction from 'helpers/empty-function-helper';
import StyledLinkRRD from './route-link-styled';
import { ClickEvent } from 'types/common-types';

interface RouteLinkBlack {
  url: string;
  name: string;
  handleClick?: (e: ClickEvent) => void;
}

const RouteLinkBlack = ({
  url,
  name,
  handleClick = emptyFunction
}: RouteLinkBlack) => {
  return (
    <StyledLinkRRD to={url} onClick={(e) => handleClick(e)}>
      {name}
    </StyledLinkRRD>
  );
};

export default RouteLinkBlack;
