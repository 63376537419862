import { Box, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import UnitItem from 'components/form/unit-item';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import StyledModalTooltip from './styled-modal-tooltip';

export type Placement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

interface TooltipModalPropTypes {
  data: string;
  height?: string;
  width?: string;
  placement?: Placement;
}

const TooltipQuestionGuide = ({
  data,
  height = '50vh',
  width = '47.7vw',
  placement = 'left'
}: TooltipModalPropTypes) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ marginLeft: 1 }}>
        <StyledModalTooltip
          placement={placement}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Box
              sx={{
                width: width,
                height: height,
                overflow: 'auto'
              }}
            >
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: data
                  }}
                ></Typography>
              </UnitItem>
            </Box>
          }
        >
          <InfoIcon
            fontSize="small"
            onClick={handleTooltipOpen}
            sx={{ marginTop: '-10px' }}
          />
        </StyledModalTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default TooltipQuestionGuide;
