import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/system/Box';
import fieldLabel from 'assets/constants/fieldLabel';
import { StyledMenu } from 'components/action/styled-menu';
import RouteLinkBlack from 'components/link/route-link-black';
import { useState } from 'react';
import StatusAlertBox from './status-alert-box';
import { OpportunityBrokerageUserListEntity } from 'types/opportunity-brokerage-user-types';
import { ClickEvent } from 'types/common-types';

const ActionDropdown = ({
  data
}: {
  data: OpportunityBrokerageUserListEntity;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: ClickEvent): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <IconButton
          aria-label="more"
          id={`action_button-${data.id}`}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="primary"
          onMouseOver={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id={`style_action_button-${data.id}`}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <StatusAlertBox data={data} handleClose={handleClose} />

          {data.brokerage_transaction_role.name === 'negotiator' &&
            data.primary_user === 1 &&
            data.status !== 'inactive' && (
              <MenuItem>
                <RouteLinkBlack
                  key="view"
                  url={`/opportunities/${data.opportunity_id}/opportunity-brokerage-users/${data.id}/edit/replace`}
                  name={fieldLabel.replaceUser}
                />
              </MenuItem>
            )}
        </StyledMenu>
      </Box>
    </>
  );
};

export default ActionDropdown;
