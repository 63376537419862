import app from 'config/app';
import {
  CreateResponse,
  DeleteResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  VendorServiceDeleteResponse,
  VendorServiceEntity,
  VendorServiceListEntity,
  VendorServicePayloadEntity
} from 'types/vendor-service-types';

import httpService from './http-service';

const vendorService = {
  url: `/${app.crmApiVersion1}/services`,

  getList: async (
    query: string
  ): Promise<GetListResponse<VendorServiceListEntity>> => {
    const result = await httpService.list<VendorServiceListEntity>(
      `${vendorService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<VendorServiceEntity>> => {
    const result = await httpService.get<VendorServiceEntity>(
      `${vendorService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: VendorServicePayloadEntity
  ): Promise<CreateResponse<VendorServiceEntity>> => {
    const result = await httpService.post<
      VendorServiceEntity,
      VendorServicePayloadEntity
    >(`${vendorService.url}`, payload);
    return result;
  },

  update: async (
    id: string,
    payload: VendorServicePayloadEntity
  ): Promise<UpdateResponse<VendorServiceEntity>> => {
    const result = await httpService.put<
      VendorServiceEntity,
      VendorServicePayloadEntity
    >(`${vendorService.url}/${id}`, payload);
    return result;
  },

  delete: async (
    id: string
  ): Promise<DeleteResponse<VendorServiceDeleteResponse>> => {
    const result = await httpService.del<VendorServiceDeleteResponse>(
      `${vendorService.url}/${id}`
    );
    return result;
  }
};

export default vendorService;
