import HomeIcon from '@mui/icons-material/Home';
import StyledLinkRRD from 'components/link/route-link-styled';

const BreadcrumbItemHome = () => {
  return (
    <StyledLinkRRD to="/">
      <HomeIcon />
    </StyledLinkRRD>
  );
};

export default BreadcrumbItemHome;
