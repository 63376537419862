import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import tasksService from 'services/tasks-service';
import { TaskFilterEntity, TaskListEntity } from 'types/task-types';
import initialTasksListFilter from 'state/task/initial-tasks-list-filter';
import Filters from './filters';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import defaultSort from 'assets/list/task/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import taskColumn from 'assets/list/task/column';
import useRouteName from 'pages/route-outlet-context';
import { GetListResponse } from 'types/api-response-types';
import { ChangeEvent } from 'types/common-types';

const TasksList = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();

  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<TaskFilterEntity>(
    initialTasksListFilter
  );

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: ChangeEvent): void => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getTasks = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<TaskListEntity>> => {
    const filterInfo =
      opportunity_id === undefined ? filter : { ...filter, opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await tasksService.getList(queryString);

    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <React.Fragment>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
      />

      <DataGrid<TaskListEntity>
        getData={getTasks}
        tableColumns={taskColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default TasksList;
