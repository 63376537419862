import { ObjectType } from 'types';

const knowledgeBaseStatus: ObjectType = {
  Draft: 'Draft',
  Expired: 'Expired',
  In_Review: 'In Review',
  published_private: 'Private',
  published_public: 'Public'
};

export default knowledgeBaseStatus;
