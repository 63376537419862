import envConfig from 'config/env';
import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  SQSEntity,
  SQSListEntity,
  SQSPayloadEntity,
  SQSRetriggerMessagePayloadEntity
} from 'types/sqs-types';

import httpService from './http-service';

const sqsService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/sqs-messages`,

  getList: async (query: string): Promise<GetListResponse<SQSListEntity>> => {
    const result = await httpService.list<SQSEntity>(
      `${sqsService.url}${query}`
    );
    return result;
  },

  get: async (id: string): Promise<GetResponse<SQSEntity>> => {
    const result = await httpService.get<SQSEntity>(`${sqsService.url}/${id}`);
    return result;
  },

  create: async (
    payload: SQSPayloadEntity | SQSRetriggerMessagePayloadEntity
  ): Promise<CreateResponse<SQSEntity>> => {
    const result = await httpService.post<
      SQSEntity,
      SQSPayloadEntity | SQSRetriggerMessagePayloadEntity
    >(`${sqsService.url}`, payload);
    return result;
  },

  update: async (
    id: string,
    payload: SQSPayloadEntity
  ): Promise<UpdateResponse<SQSEntity>> => {
    const result = await httpService.put<SQSEntity, SQSPayloadEntity>(
      `${sqsService.url}/${id}`,
      payload
    );
    return result;
  }
};

export default sqsService;
