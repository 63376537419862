export const initialRetradeRequest = {
  opportunity_status_c: '',
  retrade_reason: '',
  contract_price_c: '',
  due_diligence_end_c: '',
  close_date_c: '',
  forecasted_dd_end_date: '',
  forecasted_close_date: '',
  negotiator_sent_retrade_amount: '',
  purchase_price_c: 0,
  buyer_requested_retrade_amount: ''
};
