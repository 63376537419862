import { PropertiesFilterEntity } from 'types/dashboard-type';

export const offer_date_c_range = (
  value: string,
  _: any,
  filter: PropertiesFilterEntity
) => {
  let result = ``;

  if (!value || value.length === 0) return ``;

  if (filter.offer_date_c && filter.offer_date_c.length !== 0) {
    result += `&filter[offer_date_c][operator]=${value}`;
  }

  return result;
};
