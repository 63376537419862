import EVENTS from "assets/constants/events"
import eventBus from "helpers/event-bus-helper"

export const toastSuccess = (message: string) => {
    eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: false,
        message
    })
}

export const toastError = (message: string) => {
    eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: true,
        message
    })
}