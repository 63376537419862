import PrimaryButton from 'components/button/button-primary';
import SendIcon from '@mui/icons-material/Send';
import buttonText from 'assets/constants/button';
import { ClickEvent } from 'types/common-types';

interface ButtonInterface {
  onClick: (e: ClickEvent) => void;
  disabled?: boolean;
}

const SendButton = ({
  disabled = false,

  ...props
}: ButtonInterface) => {
  return (
    <PrimaryButton
      variant="contained"
      size="medium"
      name="btn-send"
      color={'info'}
      onClick={(e: ClickEvent) => {
        if (props.onClick) props.onClick(e);
      }}
      startIcon={<SendIcon />}
      disabled={disabled}
    >
      {buttonText.send}
    </PrimaryButton>
  );
};

export default SendButton;
