enum offerBlockedReasons {
  missing_seller_name = 'Missing Data - Seller Name',
  missing_listing_agent_email = 'Missing Data - Listing Agent Email Address',
  non_standard_offer_submission_instructions = 'Non-Standard Offer Submission Instructions',
  non_standard_deal_type = 'Non-Standard Deal Type',
  missing_data_other = 'Missing Data - Other',
  pending_awaiting_offer_signature = 'Pending Send - Awaiting Offer Signature',
  other = 'Other'
}

export default offerBlockedReasons;
