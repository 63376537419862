import { DocumentRevisionPresignUrlEntity } from 'types/document-revision-types';
import envConfig from 'config/env';
import { GetListResponse, GetResponse } from 'types/api-response-types';
import httpService from './http-service';
import {
  DocumentRevisionEntity,
  DocumentRevisionListEntity
} from 'types/document-revision-types';

const documentRevisionService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/document_revision`,

  getList: async (
    query: string
  ): Promise<GetListResponse<DocumentRevisionListEntity>> => {
    const result = await httpService.list<DocumentRevisionListEntity>(
      `${documentRevisionService.url}${query}`
    );

    return result;
  },
  get: async (id: string): Promise<GetResponse<DocumentRevisionEntity>> => {
    const result = await httpService.get<DocumentRevisionEntity>(
      `${documentRevisionService.url}/${id}`
    );
    return result;
  },
  getPresignUrl: async (
    id: string
  ): Promise<GetResponse<DocumentRevisionPresignUrlEntity>> => {
    const response = await httpService.get<DocumentRevisionPresignUrlEntity>(
      `${documentRevisionService.url}/${id}/presigned_url`
    );
    return response;
  }
};

export default documentRevisionService;
