import { ObjectType } from 'types';

const serviceType: ObjectType = {
  general_inspection_1: 'General 1',
  general_inspection_2: 'General 2',
  pest_inspection: 'Pest Inspection',
  roof_inspection: 'Roof Inspection',
  sewer_inspection: 'Sewer Inspection',
  follow_up_inspection: 'Follow Up Inspection',
  pool_inspection: 'Pool Inspection',
  occupancy_inspection: 'Occupancy Inspection',
  pre_bid_inspection: 'Pre Bid Inspection',
  move_out_inspection: 'Move Out Inspection',
  radon_inspection: 'Radon Inspection',
  septic_well_inspection: 'Septic/Well Inspection',
  foundation_inspection: 'Foundation Inspection'
};

export default serviceType;
