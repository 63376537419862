import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'calls' || routeName === 'calls-create') {
      return await menuHelper.getItems(appModel.call, appPermission.list);
    }

    return [];
  }
};

export default sideManuHelper;
