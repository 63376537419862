import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { v4 as uuid } from 'uuid';

/** @TODO add Types */

const listBrokerageUser = {
  label: 'List',
  url: () => `/market-preferences-brokerage-users`,
  labelIcon: ListAltIcon,
  model: 'Market_Preferences_Brokerage_User',
  permission: 'list',
  nodeId: uuid()
};

const viewBrokerageUser = {
  label: 'View',
  url: (id: string) => `/market-preferences-brokerage-users/${id}/view`,
  labelIcon: ListAltIcon,
  model: 'Market_Preferences_Brokerage_User',
  permission: 'view',
  nodeId: uuid()
};

const viewChangeLogBrokerageUser = {
  label: 'View Changelog',
  url: (id: string) => `/market-preferences-brokerage-users/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: 'Market_Preferences_Brokerage_User',
  permission: 'view',
  nodeId: uuid()
};

const marketPreferenceBrokerageUserMenuItems = {
  list: [listBrokerageUser],
  view: [listBrokerageUser, viewBrokerageUser, viewChangeLogBrokerageUser]
};

export default marketPreferenceBrokerageUserMenuItems;
