import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import documentRevisionService from 'services/document-revision-service';
import initialRevision from 'state/document/intial-revision';
import { DocumentRevisionEntity } from 'types/document-revision-types';
import CircularLoader from 'components/dog-loader/dog-lodar';
import RecordView from './record-view';

const RevisionView = ({ routeTag }: { routeTag: string }) => {
  const { revision_id } = useParams<{
    revision_id: string;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revision, setRevision] =
    useState<DocumentRevisionEntity>(initialRevision);

  const { setRouteName } = useRouteName();

  const loadRevision = async (revision_id: string): Promise<void> => {
    setIsLoading(true);
    const result = await documentRevisionService.get(revision_id);
    setIsLoading(false);

    setRevision(result.data);
  };

  useEffect(() => {
    if (revision_id) loadRevision(revision_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  if (isLoading) return <CircularLoader />;

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView revision={revision} />
      </PaperBoxContent>
    </PaperBox>
  );
};

export default RevisionView;
