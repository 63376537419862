import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { RolePayloadEntity } from 'types/role-types';

export const validateRole = (
  data: RolePayloadEntity
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField('Name')]
      }
    };
  }

  if (isEmpty(data.description)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        description: [missingField('Description')]
      }
    };
  }

  return validation;
};
