import { v4 as uuid } from 'uuid';
import ListAltIcon from '@mui/icons-material/ListAlt';

const listTransactionHistory = {
  label: 'List',
  url: () => `/transaction-histories`,
  labelIcon: ListAltIcon,
  model: 'TRANSACTION_HISTORY',
  permission: 'list',
  nodeId: uuid()
};

const transactionHistoryMenuItems = {
  list: [listTransactionHistory],
  view: [listTransactionHistory]
};

export default transactionHistoryMenuItems;
