import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import DateUtility from 'helpers/date-helper';
import initialHoaManagementCompany from 'state/hoa-management-company/initial-hoa-management-company';
import initialPartyList from 'state/party/initial-party-list';
import initialProperityServiceProvider from 'state/property-service-provider/initial-property-service-provider';
import {
  EnterDueDiligenceEntity,
  EnterDueDiligencePayloadEntity
} from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';
import { PartyListEntity } from 'types/party-types';

export const getInitialState = (
  opportunity: OpportunityEntity,
  parties: PartyListEntity[]
): EnterDueDiligenceEntity => {
  const listAgent = parties.find(
    (x) => x.id == opportunity?.parties_listing_agent_id
  );
  const escrowCompany = parties.find(
    (x) => x.id == opportunity?.parties_escrow_company_id
  );
  const seller = parties.find(
    (x) => x.type == 'seller' && x.id == opportunity?.seller_id
  );

  return {
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period || '',
    contract_execution_date_c: opportunity?.contract_execution_date_c || '',
    contract_price_c: opportunity?.contract_price_c || '',
    offer_price_c: opportunity?.offer_price_c || '',
    balance_to_close_c: opportunity?.balance_to_close_c,
    escalation_clause_amount: opportunity?.escalation_clause_amount || '',
    due_diligence_days_confirm: opportunity?.due_diligence_days_confirm || '',
    due_diligence_start_c: opportunity?.due_diligence_start_c || '',
    due_diligence_end_c: opportunity?.due_diligence_end_c || '',
    close_date_c: opportunity?.close_date_c || '',
    option_period_days_c: opportunity?.option_period_days_c || '',
    option_days_type_c: opportunity?.option_days_type_c || '',
    earnest_money_due_date_c: opportunity?.earnest_money_due_date_c || '',
    earnest_amount_verified: opportunity?.earnest_amount_verified || '',
    option_fee_verified: opportunity?.option_fee_verified || '',
    earnest_amount_c: opportunity?.earnest_amount_c || '',
    hoa_addendum_received: opportunity?.hoa_addendum_received || '',
    list_agent: listAgent ? listAgent : initialPartyList[0],
    parties_escrow_company_id: opportunity?.parties_escrow_company_id || '',
    escrow_company:
      escrowCompany !== undefined ? escrowCompany : initialPartyList[0],
    additional_access_information_c:
      opportunity?.additional_access_information_c || '',
    has_post_occupancy: opportunity?.has_post_occupancy || '',
    lease_type: opportunity?.lease_type || '',
    lease_end_date: opportunity?.lease_end_date || '',
    deposit_amount: opportunity?.deposit_amount,
    rental_amount: opportunity?.rental_amount || '',
    hvac_age: opportunity?.hvac_age || '',
    lockbox_code: opportunity?.lockbox_code || '',
    access_type: opportunity?.access_type || '',
    seller_name_c: seller?.name || '',
    hoa_management_company:
      opportunity.hoa_management_company != undefined
        ? opportunity.hoa_management_company
        : initialHoaManagementCompany,
    hoa_url: opportunity?.hoa_url || '',
    property_utility_provider:
      opportunity.property_utility_provider != undefined
        ? opportunity.property_utility_provider
        : initialProperityServiceProvider,
    mo_photos_required: opportunity?.mo_photos_required || 0,
    sellers_disclosure_received: opportunity?.sellers_disclosure_received || 0,
    agree_switch: 0,
    initial_due_diligence_end: opportunity?.initial_due_diligence_end
  };
};

export const prepareRequestBody = (
  data: EnterDueDiligenceEntity
): EnterDueDiligencePayloadEntity => {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
    contract_execution_date_c: data.contract_execution_date_c,
    contract_price_c: data.contract_price_c,
    offer_price_c: data.offer_price_c,
    balance_to_close_c: data.balance_to_close_c,
    escalation_clause_amount: data.escalation_clause_amount,
    due_diligence_days_confirm: data.due_diligence_days_confirm,
    due_diligence_start_c: DateUtility.convertTimeZoneToUTC(
      data.due_diligence_start_c
    ),
    due_diligence_end_c: DateUtility.convertTimeZoneToUTC(
      data.due_diligence_end_c
    ),
    close_date_c: data.close_date_c,
    option_period_days_c: data.option_period_days_c,
    option_days_type_c: data.option_days_type_c,
    earnest_money_due_date_c: data.earnest_money_due_date_c,
    earnest_amount_verified: data.earnest_amount_verified,
    option_fee_verified: data.option_fee_verified,
    earnest_amount_c: data.earnest_amount_c,
    hoa_addendum_received: data.hoa_addendum_received,
    parties_escrow_company_id: data.parties_escrow_company_id,
    additional_access_information_c: data.additional_access_information_c,
    has_post_occupancy: data.has_post_occupancy,
    lease_type: data.lease_type,
    lease_end_date: data.lease_end_date,
    deposit_amount: data.deposit_amount,
    rental_amount: data.rental_amount,
    hvac_age: data.hvac_age,
    lockbox_code: data.lockbox_code,
    access_type: data.access_type,
    hoa_url: data.hoa_url,
    mo_photos_required: data.mo_photos_required,
    sellers_disclosure_received: data.sellers_disclosure_received,
    initial_due_diligence_end: data.initial_due_diligence_end,
    seller_name_c: data.seller_name_c,
    hoa_management_company_name: data.hoa_management_company.name,
    hoa_management_company_company_phone:
      data.hoa_management_company.company_phone,
    hoa_management_company_contact_name:
      data.hoa_management_company.contact_name,
    hoa_management_company_is_self_managed:
      data.hoa_management_company.is_self_managed,
    hoa_management_company_contact_email:
      data.hoa_management_company.contact_email,
    hoa_management_company_contact_phone:
      data.hoa_management_company.contact_phone,
    hoa_management_company_contact_address:
      data.hoa_management_company.contact_address,
    electric_provider: data.property_utility_provider.electric_provider,
    water_well_provider: data.property_utility_provider.water_well_provider,
    sewer_provider: data.property_utility_provider.sewer_provider,
    gas_provider: data.property_utility_provider.gas_provider,
    trash_provider: data.property_utility_provider.trash_provider,
    is_septic: data.property_utility_provider.is_septic,
    is_well: data.property_utility_provider.is_well,
    is_electric: data.property_utility_provider.is_electric
  };
};

export const getValidationFields = (): string[] => {
  return [
    'contract_execution_date_c',
    'contract_price_c',
    'offer_price_c',
    'due_diligence_start_c',
    'due_diligence_end_c',
    'earnest_money_due_date_c',
    'option_period_days_c',
    'option_days_type_c',
    'earnest_amount_c',
    'lease_end_date',
    'deposit_amount',
    'rental_amount',
    'seller_name_c',
    'hoa_addendum_received',
    'close_date_c',
    'has_post_occupancy',
    'lease_type'
  ];
};
