import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import DateUtility from 'helpers/date-helper';

export const initialRejectOffer = {
  opportunity_status_c: oppurtunityStatusList.offer_rejected,
  reject_reason: '',
  reject_date: DateUtility.getTodayDateString(),
  competing_offer_count: '',
  competing_offer_highest_price: '',
  reject_note: '',
  offer_date_c: ''
};
