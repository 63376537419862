import { useNavigate, useParams } from 'react-router-dom';
import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { useState } from 'react';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import sessionHelper from 'helpers/session-helper';
import { deleteContract } from 'helpers/pdf-template/pdf-template-action-helper';
import { toastError, toastSuccess } from 'event/toast-event';
import { isEmptyObject } from 'helpers/misc-helper';

const PdfTemplateDelete = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { pdf_template_id } = useParams<{ pdf_template_id: string }>();
  const pdfTemplate = sessionHelper.getRecord(pdf_template_id);
  const navigate = useNavigate();

  if (isEmptyObject(pdfTemplate)) {
    toastError(fieldLabel.contarctDeleteFailure);
    window.history.back();
    return <></>;
  }

  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    open: true,
    title: fieldLabel.deleteContract,
    text: MessageDeleteRecordConfirmation(pdfTemplate.name, 'Contract', true),
    proceed: async () => {
      const result = await deleteContract(pdfTemplate.id);
      if (result.isError) {
        toastError(result.errorMessage.message);
        window.history.back();
      }

      if (result.isSuccess) {
        toastSuccess(fieldLabel.contractDeleteSuccess);
        navigate(`/pdf-templates`);
      }
    },
    cancel: () => {
      setConfirmModal(initialConfirmModalState);
      window.history.back();
    }
  });

  return <>{confirmModal.open && <ConfirmBox {...confirmModal} />}</>;
};
export default PdfTemplateDelete;
