import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { KBCategoriesListEntity } from 'types/kb-category-types';
import DateUtility from 'helpers/date-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';

const Action = ({ kbCategories }: { kbCategories: KBCategoriesListEntity }) => {
  return (
    <>
      <RouteEditIconLink
        url={`/knowledge-base-categories/${kbCategories.id}/edit`}
      />
      <RouteOpenNewIconLink
        url={`/knowledge-base-categories/${kbCategories.id}/view`}
      />
    </>
  );
};

const kbCategoriesColumn: GridColDef[] = [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: (params: GridRenderCellParams<KBCategoriesListEntity>) => (
      <Action kbCategories={params.row} />
    ),
    flex: 1
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams<KBCategoriesListEntity>) => (
      <RouteLink
        url={`/knowledge-base-categories/${params.row.id}/view`}
        name={params.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.parentKnowledgeBaseCategory,
    field: 'parent_knowledge_base_category',
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams<KBCategoriesListEntity>) => (
      <span>{params.row.parent_knowledge_base_category?.name}</span>
    )
  },
  {
    headerName: fieldLabel.createdDate,
    field: 'date_entered',
    sortable: true,
    renderCell: ({ row }: GridRenderCellParams<KBCategoriesListEntity>) => (
      <>{DateUtility.convertUTCtoTimeZone(row.date_entered)}</>
    ),
    flex: 1
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams<KBCategoriesListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default kbCategoriesColumn;
