import eventBus from 'helpers/event-bus-helper';
import emailService from 'services/email-service';
import { ObjectType } from 'types';
import { ReducerActionType } from 'types/common-types';
import { toastError } from 'event/toast-event';
import { GetListResponse } from 'types/api-response-types';
import { OpportunityBrokerageUserListEntity } from 'types/opportunity-brokerage-user-types';
import emailConfig from 'config/email';
import { isEmpty } from 'helpers/misc-helper';

const deleteEmail = async (email_id: string): Promise<void> => {
  const response = await emailService.delete(email_id);

  if (response.isError) {
    toastError(response.errorMessage.message);
  }

  if (response.isSuccess) {
    eventBus.dispatch(`email_draft_refresh`, {});
  }
};

const savedAttachementReducer = (
  state: ObjectType[],
  action: ReducerActionType
): ObjectType[] => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.payload.file];
    case 'REMOVE':
      return [...state].filter((file, index) => index !== action.payload.index);
    default:
      return state;
  }
};

const unSavedAttachementReducer = (
  state: File[],
  action: ReducerActionType
): File[] => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.payload.file];
    case 'REMOVE':
      return [...state].filter((file, index) => index !== action.payload.index);
    default:
      return state;
  }
};

const getFromReplyToEmailFields = (result: GetListResponse<OpportunityBrokerageUserListEntity>, isInboundEmailEnabled: number, opportunity_id: string): { from: string, reply_to: string } => {

  if (result.isError) {
    return {
      from: emailConfig!.SENDER_FROM_EMAIL,
      reply_to: emailConfig!.ENTERA_EMAIL_FAILSAFE_ENTERA_REALTY_COM
    }
  }


  const user = result.data?.data[0];
  let email = '';
  if (!isEmpty(user?.brokerage_user.email)) {
    email = `${user?.brokerage_user?.email.split('@')[0]}@${user?.brokerage_user?.email.split('@')[1]
      }`;

    if (isInboundEmailEnabled == 1) {
      email = `${user?.brokerage_user?.email.split('@')[0]}+${opportunity_id
        }@${user?.brokerage_user?.email.split('@')[1]}`;
    }
  }
  return {
    from: user?.brokerage_user?.email || emailConfig!.SENDER_FROM_EMAIL,
    reply_to: !isEmpty(email)
      ? email
      : emailConfig!.ENTERA_EMAIL_FAILSAFE_ENTERA_REALTY_COM
  }


}

export { deleteEmail, savedAttachementReducer, unSavedAttachementReducer, getFromReplyToEmailFields };
