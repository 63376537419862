import propertiesClosingStateOfClosing from 'assets/constants/properties-closing-state-of-closing';
import stateOfOffers from 'assets/constants/state-of-offers';
import stateOfPreOffers from 'assets/constants/state-of-preoffers';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import filterService from 'services/filter-service';
import { PropertiesFilterEntity } from 'types/dashboard-type';

type FunctionType = (query: string, filter: PropertiesFilterEntity) => string;

type PropertiesFilterHelper = {
  closing: FunctionType;
  new: FunctionType;
  offers: FunctionType;
  preOffers: FunctionType;
};

const propertiesFilterHelper: PropertiesFilterHelper = {
  closing: (query: string, filter: PropertiesFilterEntity): string => {
    let filterUrl = '';
    if (isEmpty(query)) {
      filterUrl = filterService(filter, 'properties');

      if (!filterUrl.includes('&filter[opportunity_status_c]')) {
        for (const el of getObjectEntriesAsArray(
          propertiesClosingStateOfClosing
        )) {
          filterUrl += `&filter[opportunity_status_c][]=${el.value}`;
        }
      }
    } else {
      filterUrl = query;
    }

    return filterUrl;
  },

  new: (query: string, filter: PropertiesFilterEntity): string => {
    let filterUrl = '';
    if (isEmpty(query)) {
      filterUrl = filterService(filter, 'properties');
      if (!filterUrl.includes('&filter[opportunity_status_c]')) {
        filterUrl += `&filter[opportunity_status_c][]=new`;
      }
    } else {
      filterUrl = query;
    }

    return filterUrl;
  },

  offers: (query: string, filter: PropertiesFilterEntity): string => {
    let filterUrl = '';
    if (isEmpty(query)) {
      filterUrl = filterService(filter, 'properties');
      if (!filterUrl.includes('&filter[opportunity_status_c]')) {
        for (const el of getObjectEntriesAsArray(stateOfOffers)) {
          filterUrl += `&filter[opportunity_status_c][]=${el.value}`;
        }
      }
    } else {
      filterUrl = query;
    }

    return filterUrl;
  },

  preOffers: (query: string, filter: PropertiesFilterEntity): string => {
    let filterUrl = '';
    if (isEmpty(query)) {
      filterUrl = filterService(filter, 'properties');
      if (!filterUrl.includes('&filter[opportunity_status_c]')) {
        for (const el of getObjectEntriesAsArray(stateOfPreOffers)) {
          filterUrl += `&filter[opportunity_status_c][]=${el.value}`;
        }
      }
    } else {
      filterUrl = query;
    }

    return filterUrl;
  }
};

export default propertiesFilterHelper;
