import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import marketPreferenceColumn from 'assets/list/market-preference/column';
import defaultSort from 'assets/list/market-preference/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import marketPreferencesService from 'services/market-preference-service';
import initialMarketPreferenceListFilter from 'state/market-preference/initial-market-preference-list-filter';
import { ObjectType } from 'types';
import {
  MarketPreferenceFilterEntity,
  MarketPreferencesListEntity
} from 'types/market-preference-types';

import Filters from './filters';
import { ChangeEvent } from 'types/common-types';
import { GetListResponse } from 'types/api-response-types';

const MarketPreferencesList = ({ routeTag }: { routeTag: string }) => {
  const { email_template_id } = useParams<ObjectType>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<MarketPreferenceFilterEntity>(
    initialMarketPreferenceListFilter
  );
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = (e: ChangeEvent | ObjectType): void => {
    if (e.target) {
      setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
    } else {
      setFilter(Object.assign({}, filter, e));
    }
  };

  const getMarketPreferences = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<MarketPreferencesListEntity>> => {
    const filterInfo =
      email_template_id === undefined
        ? filter
        : { ...filter, email_template_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await marketPreferencesService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
      />
      <DataGrid<MarketPreferencesListEntity>
        getData={getMarketPreferences}
        tableColumns={marketPreferenceColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="market_preference"
      />
    </>
  );
};

export default MarketPreferencesList;
