export enum RolePermissionLabels {
  account_buyer_name = 'Account Buyer Name',
  call = 'Call',
  checklist = 'Checklist',
  contract = 'Contract',
  customer_account = 'Customer Account',
  customer_user = 'Customer User',
  document = 'Document',
  email = 'Email',
  email_template = 'Email Template',
  failsafe = 'Failsafe',
  federal_holiday_calendar = 'Federal Holiday Calendar',
  hoa_management_company = 'HOA Management Company',
  market = 'Market',
  market_preference = 'Market Preference',
  market_preference_brokerage_user = 'Market Preference Brokerage User',
  message = 'Message',
  negotiator_note = 'Negotiator Note',
  note = 'Note',
  opportunity = 'Opportunity',
  opportunity_brokerage_note = 'Opportunity Brokerage Note',
  opportunity_brokerage_user = 'Opportunity Brokerage User',
  opportunity_commission = 'Opportunity Commission',
  opportunity_counter_offer = 'Opportunity Counter Offer',
  opportunity_service_provider = 'Opportunity Service Provider',
  party = 'Party',
  property_utility_provider = 'Property Utility Provider',
  report = 'Report',
  service = 'Service',
  service_provider = 'Service Provider',
  sqs_message = 'SQS Message',
  task = 'Task',
  transaction_history = 'Transaction History',
  transaction_note = 'Transaction Note',
  user = 'User'
}
