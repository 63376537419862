import envConfig from 'config/env';
import { put } from 'services/client-service';
import httpService from 'services/http-service';
import { GetResponse, UpdateResponse } from 'types/api-response-types';
import { BrokerageActionResponseType } from 'types/brokerage-action';
import {
  BuyerCounterOfferConfimationEntity,
  BuyerCounterOfferConfimationPayloadEntity
} from 'types/brokerage-action-types';
import { CounterofferType } from 'types/counter-offer';
import { OpportunityEntity } from 'types/opportunity-types';

export const counterOfferService = {
  receipt: async (counterOfferId: string, counterOffer: CounterofferType) => {
    const response: BrokerageActionResponseType = await put(
      `/${envConfig.REACT_APP_API_VERSION_1}/actions/counter_offer_receipt/${counterOfferId}`,
      counterOffer
    );
    return response;
  },

  buyerConfirmation: async (
    counterOfferId: string,
    counterOffer: Partial<BuyerCounterOfferConfimationPayloadEntity>
  ): Promise<
    UpdateResponse<BuyerCounterOfferConfimationEntity | OpportunityEntity>
  > => {
    const result = await httpService.put<OpportunityEntity>(
      `/${envConfig.REACT_APP_API_VERSION_1}/actions/buyer_counteroffer_confirmation/${counterOfferId}`,
      counterOffer
    );
    return result;
  },

  get: async (
    id: string
  ): Promise<GetResponse<BuyerCounterOfferConfimationEntity>> => {
    const result = await httpService.get<BuyerCounterOfferConfimationEntity>(
      `/${envConfig.REACT_APP_API_VERSION_1}/opportunity_counteroffers/${id}`
    );
    return result;
  }
};
