import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ObjectType } from 'types';
import { recordCountDefault } from 'state/dashboard/negotiator';
import DashboradTabLabel from 'components/form/unit-dashboard-tab';
import fieldLabel from 'assets/constants/fieldLabel';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import useRouteName from '../route-outlet-context';
import DASHBOARDS from 'assets/constants/dashboards';
import DashboardHOC from 'components/dashboard';
import DashboardHOC2 from 'components/dashboard/index2';

const NegotiatorDashboard: React.FC = () => {
  const { setRouteName } = useRouteName();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [recordCount, setRecordCount] = useState(recordCountDefault);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };
  const updateCount = (data: ObjectType) => {
    setRecordCount((prevRecordCount) => ({ ...prevRecordCount, ...data }));
  };

  const tabId = 'negotiator-tab';

  const tabItems = () => {
    return {
      offer_make_offer: (
        <DashboradTabLabel
          title={fieldLabel.makeOffer}
          count={recordCount.NEGOTIATOR_DASHBOARD_OFFER_MAKE_OFFER}
        />
      ),
      seller_sent_received: (
        <DashboradTabLabel
          title={fieldLabel.sellerSentReceived}
          count={recordCount.NEGOTIATOR_DASHBOARD_SELLER_SENT_RECEIVED}
        />
      ),
      counter_offer: (
        <DashboradTabLabel
          title={fieldLabel.counterOffer}
          count={recordCount.NEGOTIATOR_DASHBOARD_COUNTERED_OFFER}
        />
      ),
      accepted_offer: (
        <DashboradTabLabel
          title={fieldLabel.acceptedOffer}
          count={recordCount.NEGOTIATOR_DASHBOARD_ACCEPTED_OFFER}
        />
      ),
      dd_ending: (
        <DashboradTabLabel
          title={fieldLabel.ddEnding}
          count={recordCount.NEGOTIATOR_DASHBOARD_DD_ENDING}
        />
      ),
      sale_pending: (
        <DashboradTabLabel
          title={fieldLabel.salePending}
          count={recordCount.NEGOTIATOR_DASHBOARD_SALE_PENDING}
        />
      ),
      termination: (
        <DashboradTabLabel
          title={fieldLabel.termination}
          count={recordCount.NEGOTIATOR_DASHBOARD_TERMINATION}
        />
      )
    };
  };

  useEffect(() => {
    setRouteName('negotiator');
  }, []);

  return (
    <>
      <HorizontalTabs
        value={activeTabIndex}
        handleChange={handleChange}
        tabItems={tabItems()}
        tabId={tabId}
      />

      <TabArea index={0} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_OFFER_MAKE_OFFER}
          currentTab={activeTabIndex}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={1} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_SELLER_SENT_RECEIVED}
          currentTab={activeTabIndex}
          tabIndex={1}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={2} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_COUNTERED_OFFER}
          currentTab={activeTabIndex}
          tabIndex={2}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={3} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_ACCEPTED_OFFER}
          currentTab={activeTabIndex}
          tabIndex={3}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={4} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_DD_ENDING}
          currentTab={activeTabIndex}
          tabIndex={4}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={5} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_SALE_PENDING}
          currentTab={activeTabIndex}
          tabIndex={5}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={6} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.NEGOTIATOR_DASHBOARD_TERMINATION}
          currentTab={activeTabIndex}
          tabIndex={6}
          updateCount={updateCount}
        />
      </TabArea>
      <Outlet />
    </>
  );
};

export default NegotiatorDashboard;
