import EVENTS from 'assets/constants/events';
import fieldLabel from 'assets/constants/fieldLabel';
import {
  sqsRetriggerMessageOfferAccepted,
  sqsRetriggerMessageOfferMade,
  successsqsRetriggerMessage
} from 'assets/constants/sqs-message-retrigger';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import envConfig from 'config/env';
import eventBus from 'helpers/event-bus-helper';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import sqsMessageService from 'services/sqs-service';
import { ObjectType } from 'types';
import { SQSRetriggerMessagePayloadEntity } from 'types/sqs-types';

const SQSReTriggerMessages = ({
  opportunity_status_c
}: {
  opportunity_status_c: string;
}): JSX.Element => {
  const { opportunity_id } = useParams<ObjectType>();
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    open: true,
    title: 'ALERT',
    text:
      opportunity_status_c === fieldLabel.offerMakeOffer
        ? sqsRetriggerMessageOfferMade
        : sqsRetriggerMessageOfferAccepted,
    proceed: async () => {
      const requestBody: SQSRetriggerMessagePayloadEntity = {
        name: opportunity_status_c,
        queue: envConfig.REACT_APP_ENTERA_QUEUE,
        status: fieldLabel.pending,
        type: fieldLabel.integration,
        opportunity_id: opportunity_id,
        opportunity_status_c: opportunity_status_c
      };
      setConfirmModal(initialConfirmModalState);
      await retriggerMessage(requestBody);
    },
    cancel: () => {
      setConfirmModal(initialConfirmModalState);
      window.history.back();
    }
  });

  const retriggerMessage = async (
    payload: SQSRetriggerMessagePayloadEntity
  ) => {
    const response = await sqsMessageService.create(payload);
    if (response.isError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: true,
        message: response.validationMessage.opportunity_status_c[0]
      });
    }
    if (response.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: false,
        message: successsqsRetriggerMessage
      });
    }
    window.history.back();
  };

  return <>{confirmModal.open && <ConfirmBox {...confirmModal} />}</>;
};
export default SQSReTriggerMessages;
