import { CallsListEntity } from 'types/calls-types';
import envConfig from 'config/env';
import httpService from './http-service';
import { GetListResponse } from 'types/api-response-types';

const callsService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/calls`,

  getList: async (query: string): Promise<GetListResponse<CallsListEntity>> => {
    const result = await httpService.list<CallsListEntity>(
      `${callsService.url}${query}`
    );
    return result;
  }
};

export default callsService;
