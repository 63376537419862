import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';

const changeLogHelper = {
  convertDateTimeoToUserTimezone: (text: string): string => {
    if (isEmpty(text)) {
      return text;
    }

    const datetimePattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g;
    const matches: string[] | null = text.match(datetimePattern);

    if (!matches) {
      return text; // No matches found, return the original text
    }

    const replacementValues: string[] = matches.map((match: string) => {
      const dateTimeUTC = DateUtility.convertUTCtoTimeZone(
        DateUtility.getDateTimeUTCIso8601(match)
      );
      return DateUtility.getDateTimeString(dateTimeUTC);
    });

    let replacementIndex = 0;
    const replacerCallback = () => {
      const currentReplacement = replacementValues[replacementIndex];
      replacementIndex = (replacementIndex + 1) % replacementValues.length;
      return currentReplacement;
    };

    return text.replace(datetimePattern, replacerCallback);
  }
};
export default changeLogHelper;
