const initialMarketPreferenceBrokerageUser = {
  id: '',
  name: '',
  status: '',
  brokerage_user_id: '',
  brokerage_user_name: '',
  brokerage_transaction_role: { id: '', name: '' },
  brokerage_user: {
    id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    title: ''
  },
  market_preference: { id: '', name: '' },
  last_activated_at: '',
  last_deactivated_at: '',
  date_entered: '',
  date_modified: '',
  market_preference_name: '',
  brokerage_transaction_role_id: '',
  brokerage_transaction_role_name: '',
  market_preference_id: '',
  replace_brokerage_user_id: '',
  replace_brokerage_user_name: ''
};

export default initialMarketPreferenceBrokerageUser;
