import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpportunityName from 'features/opportunity-name';
import React from 'react';
import RouteLink from 'components/link/route-link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { OpportunityEntity } from 'types/opportunity-types';

interface HeaderPropType {
  action_name: string;
  oppurtunity: OpportunityEntity;
}

const OpportunityTitle = (props: HeaderPropType) => {
  const { action_name, oppurtunity } = props;

  const title = OpportunityName(oppurtunity, action_name);

  return (
    <>
      {title}
      <RouteLink
        url={`/opportunities/${oppurtunity.id}/view`}
        name={
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        }
        target={true}
      />
      <RouteLink
        url={`/opportunities/${oppurtunity.id}/edit`}
        name={
          <IconButton>
            <EditIcon />
          </IconButton>
        }
        target={true}
      />
    </>
  );
};

export default OpportunityTitle;
