import app from 'config/app';
import httpService from './http-service';
import { GetListResponse } from 'types/api-response-types';
import { FederalHolidayCalenderListEntity } from 'types/federal-holiday-calender';


const federalHolidayCalenderService = {
    url: `/${app.crmApiVersion1}/federal_holiday_calendar`,

    getList: async (
        query: string
    ): Promise<GetListResponse<FederalHolidayCalenderListEntity>> => {
        const result = await httpService.list<FederalHolidayCalenderListEntity>(
            `${federalHolidayCalenderService.url}${query}`
        );

        return result;
    },

}


export default federalHolidayCalenderService;