import reportMenuItems from 'assets/menus/side-menu/report';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'reports') {
      return reportMenuItems.list;
    }

    if (routeName === 'reports-view') {
      return reportMenuItems.view;
    }
    return [];
  }
};

export default sideManuHelper;
