import fieldLabel from 'assets/constants/fieldLabel';
import DashboradTabLabel from 'components/form/unit-dashboard-tab';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { recordCountDefault } from 'state/dashboard/transaction';
import { ObjectType } from 'types';
import useRouteName from '../route-outlet-context';
import DASHBOARDS from 'assets/constants/dashboards';
import DashboardHOC from 'components/dashboard';
import DashboardHOC2 from 'components/dashboard/index2';

const TransactionDashboard: React.FC = () => {
  const { setRouteName } = useRouteName();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [recordCount, setRecordCount] = useState(recordCountDefault);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const updateCount = (data: ObjectType) => {
    setRecordCount((prevRecordCount) => ({ ...prevRecordCount, ...data }));
  };

  const tabId = 'transaction-tab';

  const tabItems = () => {
    return {
      earnest_money: (
        <DashboradTabLabel
          title={fieldLabel.earnestMoney}
          count={recordCount.TRANSACTION_DASHBOARD_EARNEST_MONEY}
        />
      ),
      option_fee: (
        <DashboradTabLabel
          title={fieldLabel.optionFee}
          count={recordCount.TRANSACTION_DASHBOARD_OPTION_FEE}
        />
      ),
      dd_ending: (
        <DashboradTabLabel
          title={fieldLabel.ddEnding}
          count={recordCount.TRANSACTION_DASHBOARD_DD_ENDING}
        />
      ),
      review_contract: (
        <DashboradTabLabel
          title={fieldLabel.reviewContract}
          count={recordCount.TRANSACTION_DASHBOARD_REVIEW_CONTRACT}
        />
      ),
      forecasted_coe: (
        <DashboradTabLabel
          title={fieldLabel.forecastedCOE}
          count={recordCount.TRANSACTION_DASHBOARD_FORCSTED_COE}
        />
      ),
      closing_today: (
        <DashboradTabLabel
          title={fieldLabel.closingToday}
          count={recordCount.TRANSACTION_DASHBOARD_CLOSING_TODAY}
        />
      ),
      termination_pending: (
        <DashboradTabLabel
          title={fieldLabel.terminationPending}
          count={recordCount.TRANSACTION_DASHBOARD_TERMINATION_PENDING}
        />
      )
    };
  };

  useEffect(() => {
    setRouteName('transaction');
  }, []);

  return (
    <>
      <HorizontalTabs
        value={activeTabIndex}
        handleChange={handleChange}
        tabItems={tabItems()}
        tabId={tabId}
      />
      <TabArea index={0} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_EARNEST_MONEY}
          currentTab={activeTabIndex}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={1} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_OPTION_FEE}
          currentTab={activeTabIndex}
          tabIndex={1}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={2} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_DD_ENDING}
          currentTab={activeTabIndex}
          tabIndex={2}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={3} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_REVIEW_CONTRACT}
          currentTab={activeTabIndex}
          tabIndex={3}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={4} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_FORCSTED_COE}
          currentTab={activeTabIndex}
          tabIndex={4}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={5} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_CLOSING_TODAY}
          currentTab={activeTabIndex}
          tabIndex={5}
          updateCount={updateCount}
        />
      </TabArea>
      <TabArea index={6} value={activeTabIndex} border={false} tabId={tabId}>
        <DashboardHOC2
          dashboard={DASHBOARDS.TRANSACTION_DASHBOARD_TERMINATION_PENDING}
          currentTab={activeTabIndex}
          tabIndex={6}
          updateCount={updateCount}
        />
      </TabArea>
      <Outlet />
    </>
  );
};

export default TransactionDashboard;
