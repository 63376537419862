import ListAltIcon from '@mui/icons-material/ListAlt';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

const reportList = {
  label: fieldLabel.list,
  url: () => `/reports`,
  labelIcon: ListAltIcon,
  model: appModel.reports,
  permission: appPermission.list,
  nodeId: uuid()
};

const reportMenuItems = {
  list: [reportList],
  view: [reportList]
};

export default reportMenuItems;
