import { missingField } from 'assets/validation-template';
import { isEmpty } from './misc-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import { ComposeMailTypeEntity } from 'types/email-types';
import { ErrorValidation } from 'types/error-types';
import { toastError } from 'event/toast-event';

const emailHelper = {
  validate: (data: ComposeMailTypeEntity): {
    valid: boolean;
    errors: ErrorValidation
  } => {
    let validation: { valid: boolean; errors: ErrorValidation } = {
      valid: true,
      errors: {}
    };

    if (isEmpty(data.from)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,
          from: [missingField(fieldLabel.from)]
        }
      };
    }

    if (isEmpty(data.reply_to)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,
          reply_to: [missingField(fieldLabel.replyTo)]
        }
      };
    }

    if (isEmpty(data.to)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,

          to: [missingField(fieldLabel.to)]
        }
      };
    }

    if (isEmpty(data.subject)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,
          subject: [missingField(fieldLabel.subject)]
        }
      };
    }

    if (isEmpty(data.body_html)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,
          body_html: [missingField(fieldLabel.body)]
        }
      };
    }

    if (isEmpty(data.status) && isEmpty(data.opportunity_id)) {
      validation = {
        ...validation,
        valid: false,
        errors: {
          ...validation.errors,
          opportunity_id: [missingField(fieldLabel.opportunity)]
        }
      };
    }

    return validation;
  },

  checkFileSize: (files: File[]) => {
    const result = [];
    for (const file of files) {
      const size = file.size / 1048576;
      if (size > 15) {
        result.push(`${file.name}`);
      }
    }

    if (result.length) {

      toastError(`Add Links  for following files: ${result.join(', ')}`);
    }

    return result.length == 0 ? true : false;
  },

  removeExtraSpacing: (content: string): string => {
    return `<head><style type="text/css">p {margin: 0;}</style></head> ${content}`
  }
};

export default emailHelper;
