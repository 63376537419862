const userTypes: string[] = [
  'created_by',
  'offer_submitted_by',
  'save_for_singnature_by',
  'offer_blocked_resolved_by',
  'congrats_letter_sent_by',
  'access_requested_by',
  'benefitting_negotiator_changed_by_id',
  'benefitting_negotiator_id',
  'corrected_negotiator_assignment_id',
  'active_primary_negotiator_user_id',
  'primary_tr_id',
  'modified_user_id',
  'assigned_user_id',
  'leaseback_3_sent_by',
  'leaseback_1_sent_by',
  'submitting_user_code',
  'nego_at_offer_sent',
  'nego_at_offer_accept',
  'nego_at_closing',
  'primary_tc',
  'primary_tc_at_diligence',
  'primary_tc_at_sale_pending',
  'primary_tc_at_closing',
  'primary_logistics',
  'primary_logistics_at_diligence',
  'primary_logistics_at_sale_pending',
  'primary_logistics_at_closing',
  'corrected_negotiator_assignment_id'
];

export default userTypes;
