import { v4 as uuid } from 'uuid';
import ListAltIcon from '@mui/icons-material/ListAlt';

const listKnowledgeBase = {
  label: 'Knowledge Base',
  url: () => `/knowledge-bases`,
  labelIcon: ListAltIcon,
  model: 'Knowledge_Base',
  permission: 'list',
  nodeId: uuid()
};

const listKnowledgeBaseCategory = {
  label: 'Knowledge Base Category',
  url: () => `/knowledge-base-categories`,
  labelIcon: ListAltIcon,
  model: 'Knowledge_Base',
  permission: 'list',
  nodeId: uuid()
};

const knowledgeBaseMenuItems = {
  list: [listKnowledgeBase, listKnowledgeBaseCategory],
  edit: [listKnowledgeBase, listKnowledgeBaseCategory],
  create: [listKnowledgeBase, listKnowledgeBaseCategory],
  view: [listKnowledgeBase, listKnowledgeBaseCategory]
};

export default knowledgeBaseMenuItems;
