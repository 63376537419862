import DASHBOARDS from 'assets/constants/dashboards';
import Filter from './filter';
import PropertiesHoc from 'components/properties/properties-hoc';
import React from 'react';
import propertiesFilterHelper from 'pages/properties/helper';

const ClosingProperties = () => {
  return (
    <>
      <PropertiesHoc
        dashboard={DASHBOARDS.PROPERTIES_CLOSING}
        Filter={Filter}
        prepareFilterString={propertiesFilterHelper.closing}
      />
    </>
  );
};

export default ClosingProperties;
