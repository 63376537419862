import KBCategoriesList from 'features/kb-categories/kb-categories-list';
import KBCategoryCreate from 'features/kb-categories/kb-categories-create';
import KBCategoryPage from 'pages/kb-category/page'
import KBCategoryView from 'features/kb-categories/kb-categories-view';

const KBCategoriesRoutes = {
  path: 'knowledge-base-categories',
  element: <KBCategoryPage />,
  children: [
    { path: '', element: <KBCategoriesList routeTag="kb-categories" /> },
    {
      path: 'create',
      element: <KBCategoryCreate routeTag="kb-categories-create" />
    },
    {
      path: ':kbCategory_id/edit',
      element: <KBCategoryCreate routeTag="kb-categories-edit" />
    },
    {
      path: ':kbCategory_id/view',
      element: <KBCategoryView routeTag="kb-categories-view" />
    }
  ]
};

export default KBCategoriesRoutes;
