import menuHelper from 'helpers/menu-helper';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import { MenuItemType } from 'types/menu-types';

const sideManuHelper: {
  getMenuItems: (routeName: string) => Promise<MenuItemType[]>;
} = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'documents') {
      return await menuHelper.getItems(appModel.documents, appPermission.list);
    }

    if (
      routeName === 'documents-view' ||
      routeName === 'documents-revisions' ||
      routeName === 'documents-revisions-view'
    ) {
      return await menuHelper.getItems(appModel.documents, appPermission.view);
    }

    if (routeName === 'documents-edit') {
      return await menuHelper.getItems(appModel.documents, appPermission.edit);
    }

    if (routeName === 'documents-create') {
      return await menuHelper.getItems(
        appModel.documents,
        appPermission.create
      );
    }

    return [];
  }
};

export default sideManuHelper;
