import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { OpportunityEntity } from 'types/opportunity-types';

export function getInitialRetradeComplete(opportunity: OpportunityEntity) {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
    retrade_reason: opportunity.retrade_reason,
    contract_price_c: opportunity.contract_price_c || '',
    due_diligence_end_c: opportunity.due_diligence_end_c || '',
    close_date_c: opportunity.close_date_c,
    actual_rehab_c: opportunity.actual_rehab_c,
    negotiator_sent_retrade_amount: opportunity.negotiator_sent_retrade_amount,
    actual_retrade_amount: opportunity.actual_retrade_amount || '',
    purchase_price_c:
      Number(opportunity.contract_price_c) -
      Number(opportunity.actual_retrade_amount || '0')
  };
}
