const initialKBCategories = {
  id: '',
  name: '',
  description: '',
  parent_id: '',
  parent_knowledge_base_category: {
    id: '',
    name: ''
  },
  date_entered: '',
  date_modified: ''
};

export default initialKBCategories;
