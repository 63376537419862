import {
  AccountMsaAssociationEntity,
  AccountEntity,
  AccountListEntity
} from 'types/account-types';
import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';

const accountService = {
  url: `/${app.crmApiVersion1}/accounts`,

  getList: async (
    query: string
  ): Promise<GetListResponse<AccountListEntity>> => {
    const result = await httpService.list<AccountListEntity>(
      `${accountService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<AccountEntity>> => {
    const result = await httpService.get<AccountEntity>(
      `${accountService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: AccountEntity
  ): Promise<CreateResponse<AccountEntity>> => {
    const result = await httpService.post<AccountEntity>(
      `${accountService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: AccountEntity
  ): Promise<UpdateResponse<AccountEntity>> => {
    const result = await httpService.put<AccountEntity>(
      `${accountService.url}/${id}`,
      payload
    );
    return result;
  },

  associateMSA: async (
    id: string,
    payload: AccountMsaAssociationEntity
  ): Promise<UpdateResponse<AccountMsaAssociationEntity>> => {
    const result = await httpService.post<AccountMsaAssociationEntity>(
      `${accountService.url}/${id}/msa`,
      payload
    );
    return result;
  },

  deleteAssociatedMSA: async (account_id: string, msa_id: string) => {
    const result = await httpService.del(
      `${accountService.url}/${account_id}/msa/${msa_id}`
    );

    return result;
  },
};

export default accountService;
