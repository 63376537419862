import documentSubType from 'assets/constants/document-sub-type';
import envConfig from 'config/env';
import DateUtility from 'helpers/date-helper';
import { isEmpty, replaceStringCharacters } from 'helpers/misc-helper';
import { isAmendmentsOrAddendumsDocType } from 'helpers/validation/document-helper';
import { DocumentUploadItem } from 'types/document-types';
import { OpportunityEntity } from 'types/opportunity-types';
import { OptionType } from 'types/option-type';

export const createDocumentName = (
  document: DocumentUploadItem,
  opportunity: OpportunityEntity
) => {
  const opportunityIsCreatedAfterExtThreshholdDate =
    DateUtility.isFirstDateGreaterThenSecondDate(
      opportunity.date_entered,
      envConfig.REACT_APP_OPPORTUNITY_IS_CREATED_AFTER_EXT_THRESHHOLD_DATE
    );

  const extension = document.file.name.split('.').pop();
  const document_name = `${replaceStringCharacters(
    opportunity.name,
    ' ',
    '_'
  )}${
    !isEmpty(document.sub_type) &&
    document.category_id != 'misc' &&
    document.sub_type != 'client_name_pof_date_received'
      ? `_${replaceStringCharacters(
          getSubTypeOptions(
            document.category_id,
            document.sub_type,
            opportunityIsCreatedAfterExtThreshholdDate
          ).find((x: OptionType) => x.value == document.sub_type)?.label,
          ' ',
          '_'
        )}`
      : ``
  }${
    document.category_id === 'misc'
      ? `${document.sub_type ? '_' : ''}${
          replaceStringCharacters(document.sub_type, ' ', '_') ?? ''
        }`
      : ''
  }${
    document.category_id === 'amendments' &&
    !opportunityIsCreatedAfterExtThreshholdDate &&
    ['coe_ext', 'dd_ext'].includes(document.sub_type)
      ? `_${document.revision}`
      : ''
  }${
    isAmendmentsOrAddendumsDocType(document.category_id) &&
    opportunityIsCreatedAfterExtThreshholdDate
      ? `_${document.document_extension}`
      : ''
  }${
    document.category_id === 'proof_of_funds' &&
    ['client_name_pof_date_received'].includes(document.sub_type)
      ? `_${replaceStringCharacters(
          opportunity.account.name,
          ' ',
          '_'
        )}_POF_${DateUtility.getTodayDateWithFormatString('DD-MM-YYYY')}`
      : ''
  }${
    !isEmpty(document.state) && document.state !== 'NA'
      ? `_${document.state}`
      : ''
  }.${extension} `;
  return document_name;
};

export const getSubTypeOptions = (
  categoryID: string,
  subTypeId?: string,
  opportunityIsCreatedAfterExtThreshholdDate?: boolean
) => {
  if (categoryID === 'purchase_contract' && subTypeId !== 'psa_spd_hoa') {
    return documentSubType[categoryID].filter(
      (option: OptionType) => option['value'] !== 'psa_spd_hoa'
    );
  }
  if (
    opportunityIsCreatedAfterExtThreshholdDate &&
    isAmendmentsOrAddendumsDocType(categoryID)
  ) {
    if (categoryID === 'amendments') {
      return [
        { label: 'COE Ext', value: 'coe_ext' },
        { label: 'Name Change Ext', value: 'name_change' },
        { label: 'Address Correction Ext', value: 'address_correction' },
        { label: 'DD Ext', value: 'dd_ext' },
        { label: 'Price Reduction Ext', value: 'price_reduction' },
        { label: 'EMD Period Ext', value: 'emd_period_ext' },
        { label: 'Misc Amendment Ext', value: 'misc_amendment' }
      ];
    } else {
      return [
        { label: 'Buyer Add Ext', value: 'buyer_add' },
        { label: 'Misc Add Ext', value: 'misc_add' },
        { label: 'LBP Add Ext', value: 'lbp_add' }
      ];
    }
  }
  return documentSubType[categoryID] || [];
};
