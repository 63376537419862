import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

/** @TODO add Types */

const inbox = {
  label: fieldLabel.inbox,
  url: () => `/email/inbox`,
  labelIcon: ListAltIcon,
  model: appModel.email,
  permission: appPermission.list,
  nodeId: uuid()
};

const compose = {
  label: fieldLabel.compose,
  url: () => `/email/compose`,
  labelIcon: CreateIcon,
  model: appModel.email,
  permission: appPermission.edit,
  nodeId: uuid()
};

const draft = {
  label: fieldLabel.draft,
  url: () => `/email/draft`,
  labelIcon: ListAltIcon,
  model: appModel.email,
  permission: appPermission.edit,
  nodeId: uuid()
};

const emailMenuItems = {
  list: [compose, inbox, draft]
};

export default emailMenuItems;