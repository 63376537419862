import fieldLabel from 'assets/constants/fieldLabel';
import partyType from 'assets/constants/party-type';
import FormContainer from 'components/form/container';
import UnitEmpty from 'components/form/unit-empty';
import UnitRead from 'components/form/unit-read';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import emptyFunction from 'helpers/empty-function-helper';
import { getItem } from 'helpers/local-storage-helper';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import userFullName from 'helpers/user-name-helper';
import { ChangeEvent } from 'react';
import { ErrorValidation } from 'types/error-types';
import { OptionType } from 'types/option-type';
import { PartyEntity } from 'types/party-types';

interface recordViewType {
  party: PartyEntity;
  validation?: ErrorValidation;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  subTypeOption?: OptionType[];
  isEdit?: boolean;
}

const RecordView = ({
  party,
  validation,
  onChange,
  readOnly = false,
  subTypeOption,
  isEdit
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};
  const user = JSON.parse(getItem('user') || 'false');

  let assignedUserId;
  let assignedUserName;
  if (user && isEdit === false) {
    assignedUserName = `${user.user.first_name} ${user.user.last_name}`;
    assignedUserId = `${user.user.id}`;
  }

  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.email}
        name="email"
        value={party.email ?? ''}
        onChange={handleChange}
        error={valMessages['email'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.name}
        name="name"
        value={party.name ?? ''}
        onChange={handleChange}
        error={valMessages['name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitSelect
        name="type"
        label={fieldLabel.type}
        records={getObjectEntriesAsArray(partyType)}
        value={party.type ?? ''}
        onChange={handleChange}
        required
        error={valMessages['type'] ?? ''}
        readOnly={readOnly}
        disabled={isEdit ? true : false}
      />

      <UnitText
        label={fieldLabel.name2}
        name="name_2"
        value={party.name_2 ?? ''}
        onChange={handleChange}
        error={valMessages['name_2'] ?? ''}
        readOnly={readOnly}
      />

      <UnitSelect
        name="sub_type"
        label={fieldLabel.subType}
        records={subTypeOption}
        value={party.sub_type ?? ''}
        onChange={handleChange}
        error={valMessages['sub_type'] ?? ''}
        readOnly={readOnly}
        disabled={isEdit && !party.is_empty_subtype ? true : false}
      />

      <UnitEmpty />

      <UnitText
        label={fieldLabel.company}
        name="company"
        value={party.company ?? ''}
        onChange={handleChange}
        error={valMessages['company'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.mobile}
        name="mobile"
        value={party.mobile ?? ''}
        onChange={handleChange}
        error={valMessages['mobile'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.address}
        name="address"
        value={party.address ?? ''}
        onChange={handleChange}
        error={valMessages['address'] ?? ''}
        readOnly={readOnly}
        multiline={true}
        rows={4}
      />

      <UnitEmpty />

      <UnitText
        label={fieldLabel.license}
        name="license"
        value={party.license ?? ''}
        onChange={handleChange}
        error={valMessages['license'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.brokerageLicense}
        name="brokerage_license"
        value={party.brokerage_license ?? ''}
        onChange={handleChange}
        error={valMessages['brokerage_license'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.description}
        name="description"
        value={party.description ?? ''}
        onChange={handleChange}
        error={valMessages['description'] ?? ''}
        readOnly={readOnly}
        multiline={true}
        rows={4}
      />

      <UnitRead
        label={fieldLabel.assignedTo}
        value={
          !isEmpty(party.assigned_user_id)
            ? userFullName(party.assigned_user)
            : assignedUserName
        }
      />
    </FormContainer>
  );
};

export default RecordView;
