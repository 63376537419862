import eventBus from 'helpers/event-bus-helper';
import marketPreferenceService from 'services/market-preference-service';
import opportunityService from 'services/oppurtunity-service';

const removeAssociatedDocument = async (
  market_preference_id: string,
  document_id: string
) => {
  await marketPreferenceService.deleteAssocationDocument(
    market_preference_id,
    document_id
  );
  eventBus.dispatch(`document_refresh`, {});
};

const removeAssociatedOpportunityDocument = async (
  opportunity_id: string,
  document_id: string
) => {
  await opportunityService.deleteAssociatedDocument(
    opportunity_id,
    document_id
  );
  eventBus.dispatch(`document_refresh`, {});
};

export { removeAssociatedDocument, removeAssociatedOpportunityDocument };
