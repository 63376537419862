const initialMarketPreferenceBrokerageUserListFilter = {
  market_preference_name: '',
  market_preference_id: '',
  brokerage_user_name: '',
  brokerage_user_id: '',
  brokerage_transaction_role_name: '',
  brokerage_transaction_role_id: '',
  status: ''
};

export default initialMarketPreferenceBrokerageUserListFilter;
