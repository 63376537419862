import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { isChecked } from 'components/form/unit-switch';
import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';
import { PartyListEntity } from 'types/party-types';

export function getInitialAcceptOffer(
  opportunity: OpportunityEntity,
  parties: PartyListEntity[]
) {
  const seller = parties.find(
    (x) => x.type == 'seller' && x.id == opportunity.seller_id
  );
  return {
    opportunity_status_c: oppurtunityStatusList.offer_accepted,
    contract_received_date:
      opportunity.contract_received_date || DateUtility.getTodayDateString(),
    contract_execution_date_c:
      opportunity.contract_execution_date_c || DateUtility.getTodayDateString(),
    offer_date_c: opportunity.offer_date_c || '',
    tax_id_c: opportunity.tax_id_c || '',
    new_construction_no_tax_id: isChecked(
      opportunity.new_construction_no_tax_id
    )
      ? opportunity.new_construction_no_tax_id
      : 0,
    seller_name_c: seller?.name || '',
    has_post_occupancy: opportunity.has_post_occupancy || '',
    lease_type: opportunity.lease_type || '',
    lease_agreement_received: isChecked(opportunity.lease_agreement_received)
      ? 1
      : 0,
    occupancy_status_c: opportunity.occupancy_status_c || '',
    initial_commission_percentage: opportunity.initial_commission_percentage
      ? opportunity.initial_commission_percentage
      : 0,
    due_diligence_end_c: opportunity.due_diligence_end_c || ''
  };
}
