import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { KnowledgeBaseListEntity } from 'types/knowledge-base-types';
import DateUtility from 'helpers/date-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import knowledgeBaseStatus from 'assets/constants/knowledge-base-status-type';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';

const Action = ({
  knowledgeBase
}: {
  knowledgeBase: KnowledgeBaseListEntity;
}) => {
  return (
    <>
      <RouteEditIconLink url={`/knowledge-bases/${knowledgeBase.id}/edit`} />
      <RouteOpenNewIconLink url={`/knowledge-bases/${knowledgeBase.id}/view`} />
    </>
  );
};

const knowledgeBaseColumn: GridColDef[] = [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: (params: GridRenderCellParams<KnowledgeBaseListEntity>) => (
      <Action knowledgeBase={params.row} />
    ),
    flex: 1
  },
  {
    headerName: fieldLabel.title,
    field: 'name',
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams<KnowledgeBaseListEntity>) => (
      <RouteLink
        url={`/knowledge-bases/${params.row.id}/view`}
        name={params.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.knowledgeBaseCategory,
    field: 'knowledge_base_category',
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams<KnowledgeBaseListEntity>) => (
      <span>{params.row.knowledge_base_category?.name}</span>
    )
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<KnowledgeBaseListEntity>) => {
      return getObjectKeyValue(knowledgeBaseStatus, row.status);
    }
  },
  {
    headerName: fieldLabel.createdDate,
    field: 'date_entered',
    sortable: true,
    renderCell: ({ row }: GridRenderCellParams<KnowledgeBaseListEntity>) => (
      <>{DateUtility.convertUTCtoTimeZone(row.date_entered)}</>
    ),
    flex: 1
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams<KnowledgeBaseListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default knowledgeBaseColumn;
