import {
  OpportunityBrokerageUserEntity,
  OpportunityBrokerageUserListEntity,
  OpportunityBrokerageUserPayloadEntity
} from 'types/opportunity-brokerage-user-types';

import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';

const opportunityBrokerageUserService = {
  url: `/${app.crmApiVersion1}/opportunity_brokerage_users`,

  getList: async (
    query: string
  ): Promise<GetListResponse<OpportunityBrokerageUserListEntity>> => {
    const result = await httpService.list<OpportunityBrokerageUserListEntity>(
      `${opportunityBrokerageUserService.url}${query}`
    );
    return result;
  },

  get: async (
    id: string
  ): Promise<GetResponse<OpportunityBrokerageUserEntity>> => {
    const result = await httpService.get<OpportunityBrokerageUserEntity>(
      `${opportunityBrokerageUserService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: OpportunityBrokerageUserPayloadEntity
  ): Promise<CreateResponse<OpportunityBrokerageUserEntity>> => {
    const result = await httpService.post<
      OpportunityBrokerageUserEntity,
      OpportunityBrokerageUserPayloadEntity
    >(`${opportunityBrokerageUserService.url}`, payload);
    return result;
  },

  update: async (
    id: string,
    payload: OpportunityBrokerageUserPayloadEntity
  ): Promise<UpdateResponse<OpportunityBrokerageUserEntity>> => {
    const result = await httpService.put<
      OpportunityBrokerageUserEntity,
      OpportunityBrokerageUserPayloadEntity
    >(`${opportunityBrokerageUserService.url}/${id}`, payload);
    return result;
  },

  replace: async (
    id: string,
    payload: OpportunityBrokerageUserPayloadEntity
  ): Promise<UpdateResponse<OpportunityBrokerageUserEntity>> => {
    const result = await httpService.put<
      OpportunityBrokerageUserEntity,
      OpportunityBrokerageUserPayloadEntity
    >(`${opportunityBrokerageUserService.url}/${id}/replace`, payload);
    return result;
  },

  refresh: async (
    id: string
  ): Promise<GetResponse<OpportunityBrokerageUserEntity>> => {
    const result = await httpService.get<OpportunityBrokerageUserEntity>(
      `${opportunityBrokerageUserService.url}/refresh/opportunities/${id}`
    );

    return result;
  }
};

export default opportunityBrokerageUserService;
