import { ObjectType } from 'types';
import { commission_seller_rep } from './commission-seller-rep';

export const parties_seller_representative_name = {

  handleChange: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    let result = {
      commission_seller_rep: commission_seller_rep.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
