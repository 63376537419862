import fieldLabel from 'assets/constants/fieldLabel';
import UnitRead from 'components/form/unit-read';
import {
  getTableBodyCell,
  getTableHeadCell,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow
} from 'components/table/table-component';
import { toastError } from 'event/toast-event';
import React, { useEffect, useState } from 'react';
import portfolioService from 'services/portfolio-service';
import { tokens } from 'theme';
import { PortfolioEntity } from 'types/portfolio-types';

import { useTheme } from '@mui/material';

interface PortfolioInterface {
  opportunityId: string;
}

const PortfolioList = ({ opportunityId }: PortfolioInterface) => {
  const [portfolios, setPortfolios] = useState<PortfolioEntity[]>([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getPorfolios = async () => {
    const result = await portfolioService.getList(
      `?opportunity_id=${opportunityId}`
    );

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    setPortfolios(result.data.data);
  };

  const TableHeadCell = getTableHeadCell(colors);
  const TableBodyCell = getTableBodyCell(colors);

  useEffect(() => {
    if (opportunityId) getPorfolios();
  }, []);

  return (
    <UnitRead
      grid={{ sm: 6, xs: 12 }}
      label={fieldLabel.groupingNamesAndIds}
      value={
        <>
          <Table width={'100px'}>
            <TableHead>
              <TableHeadRow sx={{ backgroundColor: colors.primary[400] }}>
                <TableHeadCell>{fieldLabel.id}</TableHeadCell>
                <TableHeadCell>{fieldLabel.name}</TableHeadCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {portfolios.map((portfolio: PortfolioEntity, index: number) => (
                <TableBodyRow>
                  <TableBodyCell>{portfolio.entera_portfolio_id}</TableBodyCell>
                  <TableBodyCell>
                    {portfolio.entera_portfolio_name}
                  </TableBodyCell>
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </>
      }
    />
  );
};

export default PortfolioList;
