import { CallsFilterEntity } from 'types/calls-types';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import ClearButton from 'components/form/button-clear';
import FormContainer from 'components/form/container';
import initialCallListFilter from 'state/call/initial-calls-list-filter';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import SearchButton from 'components/form/button-search';
import StackRow from 'components/stack/stack-row';
import UnitItem from 'components/form/unit-item';
import UnitText from 'components/form/unit-text';

interface FilterProps {
  filter: CallsFilterEntity;
  updateFilter: (val: ChangeEvent) => void;
  setIsFilterChanged: DispatchSetState<boolean>;
  setFilter: DispatchSetState<CallsFilterEntity>;
  isLoading?: boolean;
}

const Filters = (props: FilterProps) => {
  const { filter, updateFilter, setIsFilterChanged, setFilter, isLoading } =
    props;

  return (
    <PaperBox>
      <PaperBoxContent>
        {' '}
        <FormContainer>
          <UnitText
            label="Subject"
            name="subject_name"
            value={filter.subject_name}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 3 }}
          />
          <UnitItem grid={{ xs: 12, sm: 6, md: 4 }}>
            <StackRow isUnitItem>
              <SearchButton
                onClick={() => {
                  setIsFilterChanged(true);
                }}
                disabled={isLoading}
              />
              <ClearButton
                onClick={() => {
                  setFilter(initialCallListFilter);
                  setIsFilterChanged(true);
                }}
              />
            </StackRow>
          </UnitItem>
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
