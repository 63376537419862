import { GetListResponse } from 'types/api-response-types';
import { ViewChangelogEntity } from 'types/view-changelog-types';

import httpService from './http-service';

const service = {
  url: `/${process.env.REACT_APP_API_VERSION_1}`,

  getList: async (
    action: string,
    query: string
  ): Promise<GetListResponse<ViewChangelogEntity>> => {
    const result = await httpService.list<ViewChangelogEntity>(
      `${service.url}/${action}${query}`
    );
    return result;
  }
};

export default service;
