import React, { useEffect, useState } from 'react';
import ViewChangelog from 'features/view-changelog';
import useRouteName from 'pages/route-outlet-context';
import { useParams } from 'react-router-dom';

const PartyViewChangelog = ({ routeTag }: { routeTag: string }) => {
  const { party_id } = useParams<{ party_id: string }>();
  const [filter, setFilter] = useState({ party_id: party_id! });
  const { setRouteName } = useRouteName();

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <>
      <ViewChangelog
        action="party_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="parties"
      />
    </>
  );
};

export default PartyViewChangelog;
