import { ObjectType } from 'types';

enum yesNo {
  yes = 'Yes',
  no = 'No'
}

export const yesNoNumeric: ObjectType = {
  1: 'Yes',
  0: 'No'
};

export default yesNo;
