import {
  KnowledgeBaseFilterEntity,
  KnowledgeBaseListEntity
} from 'types/knowledge-base-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/party/default-sort';
import Filters from './filter';
import initialKnowledgeBaseListFilter from 'state/knowledge-base/initital-knowledge-base-list-filter';
import knowledgeBaseColumn from 'assets/list/knowledge-base/column';
import knowledgeBaseService from 'services/knowledge-base-service';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';

const KnowledgeBaseList = ({ routeTag }: { routeTag: string }) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [filter, setFilter] = useState<KnowledgeBaseFilterEntity>(
    initialKnowledgeBaseListFilter
  );

  const getKnowledgeBase = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<KnowledgeBaseListEntity>> => {
    const filterInfo =
      opportunity_id === undefined ? filter : { ...filter, opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await knowledgeBaseService.getList(queryString);

    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <React.Fragment>
      <Filters
        filter={filter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
      />

      <DataGrid<KnowledgeBaseListEntity>
        getData={getKnowledgeBase}
        tableColumns={knowledgeBaseColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default KnowledgeBaseList;
