import { ComposeMailTypeEntity, ContentType } from 'types/email-types';
import {
  DispatchSetState,
  InputChangeEvent,
  ReducerActionType
} from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import AttachmentPanel from './attachment-panel';
import ComposeForm from './compose-form';
import emptyFunction from 'helpers/empty-function-helper';
import FormContainer from 'components/form/container';
import React from 'react';
import UnitItem from 'components/form/unit-item';
import initialContent from 'state/email/initial-content';
import { ErrorValidation } from 'types/error-types';

interface RecordViewPropsType {
  mail: ComposeMailTypeEntity;
  setMail?: DispatchSetState<ComposeMailTypeEntity>;
  validation?: ErrorValidation;
  onChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
  content?: ContentType;
  setContent?: DispatchSetState<ContentType>;
  savedAttachments?: ObjectType[];
  unSavedAttachments?: File[];
  dispatch?: React.Dispatch<ReducerActionType>;
  dispatch2?: React.Dispatch<ReducerActionType>;
  send?: () => void;
  draft?: () => void;
  loading?: boolean;
}

const RecordView = ({
  mail,
  setMail = emptyFunction,
  validation,
  onChange,
  readOnly = false,
  content = initialContent,
  setContent = emptyFunction,
  savedAttachments = [],
  unSavedAttachments = [],
  dispatch,
  dispatch2,
  send = emptyFunction,
  draft = emptyFunction,
  loading = false
}: RecordViewPropsType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  const [sidePane, setSidePane] = React.useState('');

  const updateSidePane = (value: string) => {
    setSidePane((preSidePane) => (preSidePane !== value ? value : ''));
  };

  return (
    <FormContainer>
      <UnitItem
        grid={{
          xs: 12,
          sm: 12,
          md: readOnly ? 12 : !isEmpty(sidePane) ? 6 : 11,
          lg: readOnly ? 12 : !isEmpty(sidePane) ? 6 : 11
        }}
        sx={{ height: '90%' }}
      >
        <ComposeForm
          mail={mail}
          setMail={setMail}
          handleChange={handleChange}
          valMessages={valMessages}
          readOnly={readOnly}
          draft={draft}
          send={send}
          dispatch={dispatch}
          dispatch2={dispatch2}
          content={content}
          setContent={setContent}
          savedAttachments={savedAttachments}
          unSavedAttachments={unSavedAttachments}
          loading={loading}
        />
      </UnitItem>
      {!readOnly && (
        <UnitItem
          grid={{
            xs: 12,
            sm: 12,
            md: !isEmpty(sidePane) ? 6 : 1,
            lg: !isEmpty(sidePane) ? 6 : 1
          }}
          sx={{ height: '90%' }}
        >
          <AttachmentPanel
            updateSidePane={updateSidePane}
            valMessages={valMessages}
            mail={mail}
            setMail={setMail}
            sidePane={sidePane}
            readOnly={readOnly}
            handleChange={handleChange}
            setContent={setContent}
          />
        </UnitItem>
      )}
    </FormContainer>
  );
};

export default RecordView;
