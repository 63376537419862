//@ts-nocheck
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SideNavPanel from 'components/side-nav-panel';
import TopNavMenu from 'components/top-nav-menu';
import LoginForm from 'features/login/login-form';
import { redirectToLogin } from 'helpers/auth-helper';

import { AuthContext } from 'hooks/auth/auth-context';
import useAuthenticated from 'hooks/auth/use-authenticated';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import LayoutProvider from './layout-provider-context';
import { getItem, setItem } from 'helpers/local-storage-helper';
import layoutConfig from 'config/layout';
import { convertNumber } from 'helpers/misc-helper';

const CommonLayout = () => {
  const isAuthenticated = useAuthenticated();
  const { state, dispatch } = useContext(AuthContext);

  const [sideNavMenuItems, setSideNavMenuItems] = useState({});
  const [isRecentViewOpen, setIsRecentViewOpen] = useState(false);

  const [mobileSideNavOpen, toggleMobileSideNav] = useState(false);
  const [mobileTopNavOpen, toggleMobileTopNav] = useState(false);

  const [topBar, setTopBar] = useState(false);

  const [sidePanelWidth, setSidePanelWidth] = useState(
    layoutConfig.sideNavPanelWidth
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const toggleRecentView = () => {
    setIsRecentViewOpen(!isRecentViewOpen);
  };

  const handleMobileSideNav = () => {
    toggleMobileSideNav(!mobileSideNavOpen);
  };

  const handleMobileTopNav = () => {
    toggleMobileTopNav(!mobileTopNavOpen);
  };

  if (!isAuthenticated) {
    redirectToLogin();
  }

  useEffect(() => {
    if (!matches) {
      setSidePanelWidth(20);
      setItem('side-panel-width', `20`);
      toggleMobileSideNav(false);
    }
  }, [matches]);

  useEffect(() => {
    setSidePanelWidth(
      convertNumber(
        getItem('side-panel-width')
          ? getItem('side-panel-width')
          : layoutConfig.sideNavPanelWidth
      )
    );
  }, []);

  if (isAuthenticated) {
    return (
      <>
        <LayoutProvider.Provider
          value={{
            mobileTopNavOpen,
            mobileSideNavOpen,
            handleMobileSideNav,
            handleMobileTopNav,
            sideNavMenuItems,
            setSideNavMenuItems,
            isRecentViewOpen,
            toggleRecentView,
            topBar,
            setTopBar,
            sidePanelWidth,
            setSidePanelWidth
          }}
        >
          {!state.isPermissionLoading && (
            <>
              <TopNavMenu />

              <SideNavPanel items={sideNavMenuItems} />

              <Box
                component="main"
                sx={{
                  position: 'fixed',
                  right: 0,
                  top: '7vh',
                  width:
                    matches === false
                      ? '100%'
                      : `calc(100% - ${sidePanelWidth}px)`
                }}
              >
                <Outlet />
              </Box>
            </>
          )}
        </LayoutProvider.Provider>
      </>
    );
  }

  return <LoginForm />;
};

export default CommonLayout;
