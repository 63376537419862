import { IconButton } from '@mui/material';
import Tooltip from 'components/tooltip';
import useColorToken from 'hooks/useColorToken';
import React from 'react';

interface PropsType {
  Icon: any;
  onClick: () => void;
  active?: boolean;
  title?: string;
  id?: string;
}

const UnitButtonIcon = ({
  Icon,
  onClick,
  active = false,
  title = '',
  id
}: PropsType) => {
  const colors = useColorToken();

  return (
    <Tooltip title={title}>
      <IconButton
        aria-label="toggle-opportunity"
        onClick={onClick}
        style={{
          color: colors.grey[900],
          backgroundColor: active ? colors.white[500] : 'inherit'
        }}
        size="large"
        id={`${id}`}
      >
        <Icon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default UnitButtonIcon;
