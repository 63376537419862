import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'market-preferences-brokerage-users') {
      return await menuHelper.getItems(
        'Market_Preferences_Brokerage_User',
        'list'
      );
    }

    if (routeName === 'market-preferences-brokerage-users-view') {
      return await menuHelper.getItems(
        'Market_Preferences_Brokerage_User',
        'view'
      );
    }

    if (routeName === 'market-preferences-brokerage-users-change-log') {
      return await menuHelper.getItems(
        'Market_Preferences_Brokerage_User',
        'view'
      );
    }

    return [];
  }
};

export default sideManuHelper;
