import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';

export const initialBuyerCountOfferConfirmation = {
  opportunity_status_c: oppurtunityStatusList.Offer_counter_updated,
  counter_offer_price_c: '',
  counter_offer: '',
  selected_categories: '',
  option_period_days_c: '',
  option_days_type_c: '',
  option_amount_c: '',
  exclusion: '',
  inclusion_status: '',
  inclusion: '',
  earnest_amount_c: '',
  close_date_c: '',
  lease_end_date: '',
  deposit_amount: '',
  rental_amount: '',
  lease_type: '',
  additional_terms_comments_c: '',
  solar_panel_leased_or_owned: '',
  solar_panel_payoff_amount: '',
  solar_panel_lease_date_expiration: '',
  other_counter_offer_terms: '',
  receipt_date: '',
  received_from: '',
  response_amount: '',
  response_terms: '',
  notes: '',
  buyer_notes: '',
  due_diligence_fee_status: '',
  due_diligence_fee: '',
  preferred_title_status: '',
  preferred_title: '',
  counter_offer_price_status: '',
  counter_offer_status: '',
  due_diligence_end_c: '',
  option_period_status: '',
  option_days_type_status: '',
  option_amount_status: '',
  commission_status: '',
  initial_commission_percentage: '',
  lease_end_date_status: '',
  deposit_amount_status: '',
  rental_amount_status: '',
  lease_type_status: '',
  additional_terms_comment_status: '',
  leased_or_owned_status: '',
  payoff_amount_status: '',
  lease_date_expiration_status: '',
  other_counter_offer_terms_status: '',
  close_date_status: '',
  earnest_amount_status: '',
  exclusion_status: ''
};
