import { DispatchSetState } from 'types/common-types';
import { GetListResponse } from 'types/api-response-types';
import { isEmpty } from 'helpers/misc-helper';
import defaultSort from 'assets/list/dashboard/default-sort';
import React, { useEffect, useState } from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel
} from '@mui/x-data-grid';
import DataGrid from 'components/data-grid';
import eventBus from 'helpers/event-bus-helper';

interface DataGridComponentPropTypes<U> {
  getData: (
    pagination: GridPaginationModel,
    sorting: GridSortModel
  ) => Promise<U>;
  tableColumns: GridColDef[];
  isFilterChanged?: boolean;
  setIsFilterChanged?: DispatchSetState<boolean>;
  initialLoad: boolean;
  listTitle?: string;
  setInitialLoad: DispatchSetState<boolean>;
  isLoading: boolean;
  refreshEvent?: string;
}

const DataGridHOC = (DGComponent: (params: any) => JSX.Element) => {
  function DataGridComponent<T>({
    getData,
    tableColumns,
    isFilterChanged = undefined,
    setIsFilterChanged,
    listTitle,
    initialLoad,
    setInitialLoad,
    isLoading,
    refreshEvent
  }: DataGridComponentPropTypes<GetListResponse<T>>) {
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState<T[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [rowCountState, setRowCountState] = React.useState(0);

    const [paginationModel, setPaginationModel] =
      React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0
      });

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
      defaultSort
    ]);

    const updatePagiantion = (param: GridPaginationModel) => {
      setPaginationModel(Object.assign({}, paginationModel, param));
    };

    const updateSorting = (param: GridSortModel) => {
      setSortModel(param);
    };

    const updateRefresh = () => setRefresh((prevRefresh) => prevRefresh + 1);

    const loadData = async () => {
      const result: GetListResponse<T> = await getData(
        paginationModel,
        sortModel
      );

      if (result.isError) {
        setErrorMessage(result.errorMessage.message);
        return;
      }
      if (setIsFilterChanged) setIsFilterChanged(false);

      setData(result.data.data);
      setRowCountState(result.data.meta.total);
    };

    useEffect(() => {
      if (!initialLoad) loadData();
      setInitialLoad(true);
    }, []);

    useEffect(() => {
      if (!initialLoad) return;
      loadData();
    }, [refresh]);

    useEffect(() => {
      if (!initialLoad) return;

      loadData();
    }, [paginationModel.page]);

    useEffect(() => {
      if (!initialLoad) return;

      if (paginationModel.page === 0) {
        loadData();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [paginationModel.pageSize]);

    useEffect(() => {
      if (!initialLoad) return;

      if (paginationModel.page === 0) {
        loadData();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [JSON.stringify(sortModel)]);

    useEffect(() => {
      if (!isFilterChanged) return;
      if (!initialLoad) return;
      if (isFilterChanged) loadData();
    }, [isFilterChanged]);

    useEffect(() => {
      if (!isEmpty(listTitle)) {
        eventBus.on(
          `${listTitle}_UPDATE_PAGINATION`,
          (data: GridPaginationModel) => {
            updatePagiantion(data);
          }
        );
      }

      if (!isEmpty(refreshEvent)) {
        eventBus.on(`${refreshEvent}_refresh`, () => {
          updateRefresh();
        });
      }
    }, []);

    return (
      <DGComponent
        rows={data}
        columns={tableColumns}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        onPaginationModelChange={updatePagiantion}
        sortModel={sortModel}
        onSortModelChange={updateSorting}
        loading={isLoading}
        error={errorMessage}
      />
    );
  }
  return DataGridComponent;
};

export default DataGridHOC(DataGrid);
