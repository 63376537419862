import React from 'react';
import { tintMcConfig } from './config';
import TinyMceEditor from './tiny-mc-editor';
import { Editor } from '@tinymce/tinymce-react';

interface PropsTypes {
  value: string;
  onChange: (val: any) => void;
  readOnly: boolean;
  theme: string;
  height?: string;
  updateHTMLEditorRef?: (e: any) => any;
}

const HtmlEditor = (props: PropsTypes) => {
  let { value, onChange, readOnly, updateHTMLEditorRef } = props;

  return (
    <React.Fragment>
      <TinyMceEditor
        onInit={(editor: Editor) => {
          if (updateHTMLEditorRef) {
            updateHTMLEditorRef(editor);
          }
        }}
        initialValue=""
        value={value}
        disabled={readOnly}
        readOnly={readOnly}
        toolbar={readOnly ? '' : tintMcConfig.toolbar}
        init={tintMcConfig}
        onEditorChange={(content: string) => {
          onChange({ html: content });
        }}
      />
    </React.Fragment>
  );
};

export default HtmlEditor;
