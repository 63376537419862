import { offer_blocked_reasons_at } from './offer-blocked-reason-at';
import { ObjectType } from 'types';

export const offer_blocked_reasons = {
  handleChange: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    const result = {
      offer_blocked_reasons_at: offer_blocked_reasons_at.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
