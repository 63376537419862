const initialKnowledgeBase = {
  id: '',
  name: '',
  status: 'Draft',
  description: '',
  knowledge_base_category_id: '',
  knowledge_base_category: {
    id: '',
    name: ''
  },
  date_entered: '',
  date_modified: ''
};

export default initialKnowledgeBase;
