import {
  EmailTemplateEntity,
  EmailTemplateListEntity,
  EmailTemplatePayloadEntity,
  MustacheFieldsEntity,
  MustacheTemplateEntity,
  TemplatePreviewEntity,
  EmailTemplateDeleteResponse
} from 'types/email-template-types';
import httpService from './http-service';
import app from 'config/app';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';
import { ObjectType } from 'types';

const emailTemplateService = {
  url: `/${app.crmApiVersion1}/email_templates`,

  getList: async (
    query: string
  ): Promise<GetListResponse<EmailTemplateListEntity>> => {
    const result = await httpService.list<EmailTemplateListEntity>(
      `${emailTemplateService.url}${query}`
    );
    return result;
  },

  get: async (id: string): Promise<GetResponse<EmailTemplateEntity>> => {
    const result = await httpService.get<EmailTemplateEntity>(
      `${emailTemplateService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: EmailTemplatePayloadEntity
  ): Promise<CreateResponse<EmailTemplateEntity>> => {
    const result = await httpService.post<
      EmailTemplateEntity,
      EmailTemplatePayloadEntity
    >(`${emailTemplateService.url}`, payload);
    return result;
  },

  update: async (
    id: string,
    payload: EmailTemplatePayloadEntity
  ): Promise<UpdateResponse<EmailTemplateEntity>> => {
    const result = await httpService.put<
      EmailTemplateEntity,
      EmailTemplatePayloadEntity
    >(`${emailTemplateService.url}/${id}`, payload);
    return result;
  },

  getMustachFields: async (): Promise<GetResponse<MustacheFieldsEntity>> => {
    const result = await httpService.get<MustacheFieldsEntity>(
      `${emailTemplateService.url}/fields`
    );
    return result;
  },

  convertToMustach: async (
    id: string
  ): Promise<UpdateResponse<MustacheTemplateEntity>> => {
    const result = await httpService.put<MustacheTemplateEntity, {}>(
      `${emailTemplateService.url}/${id}/convert-to-mustache`,
      {}
    );
    return result;
  },
  getMustachePreView: async (
    payload: ObjectType
  ): Promise<CreateResponse<TemplatePreviewEntity>> => {
    const result = await httpService.post<TemplatePreviewEntity>(
      `${emailTemplateService.url}/preview`,
      payload
    );
    return result;
  },
  delete: async (
    id: string
  ): Promise<DeleteResponse<EmailTemplateDeleteResponse>> => {
    const result = await httpService.del<EmailTemplateDeleteResponse>(
      `${emailTemplateService.url}/${id}`
    );
    return result;
  }
};

export default emailTemplateService;
