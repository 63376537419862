import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import LayoutProvider from './common-layout/layout-provider-context';
import ContainerRight from 'components/container/right';

const HomePage = () => {
  const { setSideNavMenuItems } = useContext(LayoutProvider);
  const [routeName, setRouteName] = useState<string>('');

  const getMenus = async () => {
    setSideNavMenuItems([]);
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <ContainerRight>
      <Outlet context={{ routeName, setRouteName }} />
    </ContainerRight>
  );
};

export default HomePage;
