const initialOpportunityBrokerageUser = {
  id: '',
  name: '',
  opportunity_name: '',
  market_preference_name: '',
  brokerage_transaction_role_name: '',
  brokerage_user_name: '',
  last_activated_at: '',
  last_deactivated_at: '',
  date_entered: '',
  date_modified: '',
  description: '',
  status: '',
  brokerage_user_id: '',
  opportunity_id: '',
  brokerage_transaction_role_id: '',
  market_preference_id: '',
  primary_user: 0,
  replace_brokerage_user_name: '',
  replace_brokerage_user_id: '',
  market_preference: { name: '', id: '' },
  brokerage_user: { first_name: '', last_name: '', email: '' },
  brokerage_transaction_role: { id: '', name: '' },
  opportunity: { name: '' }
};

export default initialOpportunityBrokerageUser;
