import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import DateUtility from 'helpers/date-helper';
import initialPartyList from 'state/party/initial-party-list';
import { OptionEarnestFeeStatusEntity } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';
import { PartyListEntity } from 'types/party-types';

export const getInitialState = (
  opportunity: OpportunityEntity,
  parties: PartyListEntity[]
): OptionEarnestFeeStatusEntity => {
  const escrowCompany = parties.find(
    (x) => x.id == opportunity.parties_escrow_company_id
  );

  return {
    opportunity_status_c: oppurtunityStatusList.closing_diligence_period || '',
    option_fee_status_c: opportunity.option_fee_status_c || '',
    option_fee_delivery_date_c: opportunity.option_fee_delivery_date_c || '',
    option_amount_c: opportunity.option_amount_c || '',
    earnest_money_status_c: opportunity.earnest_money_status_c || '',
    emd_requested_confirmed:
      opportunity.emd_requested_confirmed ?? DateUtility.getTodayDateString(),
    earnest_amount_c: opportunity.earnest_amount_c,
    em_delivery_date_c:
      opportunity.em_delivery_date_c ?? DateUtility.getTodayDateString(),
    escrow_company:
      escrowCompany !== undefined ? escrowCompany : initialPartyList[0]
  };
};
