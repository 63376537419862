import ReportList from 'features/report/report-list';
import ReportView from 'features/report/report-view';
import ReportPage from 'pages/report';
import { RouteObject } from 'react-router-dom';

const reportRoutes: RouteObject = {
  path: 'reports',
  element: <ReportPage />,
  children: [
    {
      path: '',
      element: <ReportList routeTag="reports" />
    },
    { path: ':report_id/view', element: <ReportView routeTag="reports-view" /> }
  ]
};

export default reportRoutes;
