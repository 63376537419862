const initialParty = {
  id: '',
  email: '',
  name: '',
  type: '',
  name_2: '',
  sub_type: '',
  company: '',
  mobile: '',
  address: '',
  license: '',
  brokerage_license: '',
  description: '',
  assigned_user_id: '',
  assigned_user: {
    id: '',
    first_name: '',
    last_name: ''
  },
  assigned_user_name: '',
  is_empty_subtype: false,
  date_entered: ''
};

export default initialParty;
