import axios from 'axios';
import eventBus from 'helpers/event-bus-helper';
import { isEmpty } from 'helpers/misc-helper';
import envConfig from 'config/env';
import { getItem } from 'helpers/local-storage-helper';
import authService from './auth-service';

export const baseURL = envConfig.REACT_APP_BACKEND;

const axiosClient = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {
    Accept: 'application/json'
  }
});

axiosClient.interceptors.request.use(
  function (config: any) {
    const token = getItem('accessToken');
    if (token) {
      config['headers']['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    let originalRequest = error.config;

    if (error.code === 'ECONNABORTED') {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = getItem('refreshToken');
      if (isEmpty(refreshToken)) {
        eventBus.dispatch('LOGOUT', {});
        window.location.replace('/login');
      }
      await authService.refreshToken();
      const token = getItem('accessToken');

      if (!token) {
        window.location.replace('/login');
      }
      originalRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest?.headers,
          Authorization: 'Bearer ' + token
        }
      };

      return axiosClient(originalRequest);
    }

    if (error.response.status === 404) {
      error = {
        ...error,
        response: {
          ...error.response,
          data: { data: {} }
        }
      };
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
