import { DispatchSetState } from 'types/common-types';
import { KnowledgeBaseFilterEntity } from 'types/knowledge-base-types';
import { ObjectType } from 'types';
import { OptionType } from 'types/option-type';
import { useNavigate } from 'react-router-dom';
import ClearButton from 'components/form/button-clear';
import CreateIcon from '@mui/icons-material/Create';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import initialKnowledgeBaseListFilter from 'state/knowledge-base/initital-knowledge-base-list-filter';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import SearchButton from 'components/form/button-search';
import SecondaryButton from 'components/button/button-secondary';
import StackRow from 'components/stack/stack-row';
import UnitItem from 'components/form/unit-item';
import UnitKBCategories from 'components/form/unit-kb-categories';
import UnitKnowledgeBaseTitle from 'components/form/unit-knowledge-base-title';

interface FilterProps {
  filter: KnowledgeBaseFilterEntity;
  setIsFilterChanged: DispatchSetState<boolean>;
  setFilter: DispatchSetState<KnowledgeBaseFilterEntity>;
  isLoading?: boolean;
}

const Filters = (props: FilterProps) => {
  const { filter, setIsFilterChanged, setFilter, isLoading } = props;

  const navigate = useNavigate();

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <UnitKnowledgeBaseTitle
            value={{
              label: filter.name,
              value: filter.id
            }}
            label={fieldLabel.title}
            grid={{ xs: 12, sm: 3 }}
            onChange={(value: OptionType) => {
              setFilter((prevFilter) => ({
                ...prevFilter,
                id: value?.value,
                name: value?.label
              }));
            }}
          />
          <UnitKBCategories
            label={fieldLabel.knowledgeBaseCategory}
            value={{
              label: filter.knowledge_base_category_name,
              value: filter.knowledge_base_category_id
            }}
            grid={{ xs: 12, sm: 3 }}
            onChange={(value: OptionType | ObjectType) => {
              setFilter((prevFilter) => ({
                ...prevFilter,
                knowledge_base_category_id: value?.value,
                knowledge_base_category_name: value?.label
              }));
            }}
          />
          <UnitItem grid={{ xs: 12, sm: 6, md: 4 }}>
            <StackRow isUnitItem>
              <SearchButton
                onClick={() => {
                  setIsFilterChanged(true);
                }}
                disabled={isLoading}
              />
              <ClearButton
                onClick={() => {
                  setFilter(initialKnowledgeBaseListFilter);
                  setIsFilterChanged(true);
                }}
              />
              <SecondaryButton
                variant="contained"
                id="btn-loadall"
                onClick={() => {
                  navigate('create');
                }}
                startIcon={<CreateIcon />}
              >
                Create
              </SecondaryButton>
            </StackRow>
          </UnitItem>
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
