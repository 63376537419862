const initialVendorService = {
  id: '',
  name: '',
  inspection_type: '',
  inspection_date: '',
  inspection_date_hours: '',
  inspection_date_minutes: '',
  inspection_date_meridiem: '',
  request_info: '',
  inspection_scheduled: '',
  requested_at: '',
  requested_by: '',
  requested_by_name: '',
  confirmation_at: '',
  confirmation_by: '',
  confirmation_by_name: '',
  closing_notes: '',
  inspection_notes: '',
  opportunity_id_1: '',
  opportunity: {
    id: '',
    name: '',
    due_diligence_end_c: ''
  },
  requested_by_user: {
    id: '',
    first_name: '',
    last_name: ''
  },
  confirmation_by_user: {
    id: '',
    first_name: '',
    last_name: ''
  }
};

export default initialVendorService;
