import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { useParams } from 'react-router-dom';
import Documents from './documents';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import React from 'react';
import TemplateBox from './template-box';
import UnitButtonIcon from 'components/form/button-icon';
import UnitItem from 'components/form/unit-item';
import UnitOpportunitySelect from 'components/form/unit-opportunity-select';
import { ComposeMailTypeEntity, ContentType } from 'types/email-types';
import { DispatchSetState, InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

interface PropsTypes {
  valMessages: ErrorValidation;
  mail: ComposeMailTypeEntity;
  sidePane: string;
  readOnly: boolean;
  updateSidePane: (value: string) => void;
  handleChange: (e: InputChangeEvent) => void;
  setContent: DispatchSetState<ContentType>;
  setMail: DispatchSetState<ComposeMailTypeEntity>;
}

const AttachmentPanel = ({
  updateSidePane,
  valMessages,
  mail,
  sidePane,
  readOnly,
  handleChange,
  setContent,
  setMail
}: PropsTypes) => {
  const { opportunity_id } = useParams<{
    opportunity_id: string;
  }>();

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <UnitItem
            grid={{ xs: 12, sm: 12, md: 2, lg: 1.4 }}
            p={0}
            sx={{
              textAlign: 'center'
            }}
          >
            {!opportunity_id && (
              <UnitButtonIcon
                Icon={LightbulbIcon}
                onClick={() => updateSidePane('1')}
                active={sidePane === '1'}
                title={fieldLabel.opportunity}
                id="opportunity"
              />
            )}

            <UnitButtonIcon
              Icon={LibraryBooksIcon}
              onClick={() => updateSidePane('2')}
              active={sidePane === '2'}
              title={fieldLabel.template}
              id="template"
            />

            {!readOnly && !isEmpty(mail?.opportunity_id) && (
              <UnitButtonIcon
                Icon={InsertDriveFileIcon}
                onClick={() => updateSidePane('3')}
                active={sidePane === '3'}
                title={fieldLabel.documents}
                id="documents"
              />
            )}
          </UnitItem>

          {!isEmpty(sidePane) && (
            <UnitItem grid={{ xs: 12, sm: 12, md: 10, lg: 10.6 }}>
              {sidePane == '1' && !opportunity_id && (
                <UnitOpportunitySelect
                  value={{
                    label: mail?.opportunity_name || '',
                    value: mail?.opportunity_id || ''
                  }}
                  multiple={false}
                  onChange={(val: ObjectType) => {
                    setMail((prevMail) => ({
                      ...prevMail,
                      opportunity_name: val?.label || '',
                      opportunity_id: val?.value || ''
                    }));
                  }}
                  grid={{ xs: 12, sm: 12 }}
                  readOnly={false}
                  error={valMessages['opportunity_id']}
                />
              )}

              {sidePane == '2' && (
                <TemplateBox
                  setContent={setContent}
                  handleChange={handleChange}
                  mail={mail}
                  setMail={setMail}
                />
              )}
              {sidePane == '3' &&
                !readOnly &&
                !isEmpty(mail.opportunity_id) && (
                  <Documents opportunity_id={mail.opportunity_id} />
                )}
            </UnitItem>
          )}
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default AttachmentPanel;
