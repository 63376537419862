import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

const listOpportunityBrokerageUser = {
  label: fieldLabel.list,
  url: () => `/opportunity-brokerage-users`,
  labelIcon: ListAltIcon,
  model: appModel.opportunityBrokerageUser,
  permission: appPermission.list,
  nodeId: uuid()
};

const viewOpportunityBrokerageUser = {
  label: fieldLabel.view,
  url: (id: string) => `/opportunity-brokerage-users/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.opportunityBrokerageUser,
  permission: appPermission.view,
  nodeId: uuid()
};

const viewChangeLogOpportunityBrokerageUser = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/opportunity-brokerage-users/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.opportunityBrokerageUser,
  permission: appPermission.view,
  nodeId: uuid()
};

const opportunityBrokerageUserMenuItems = {
  list: [listOpportunityBrokerageUser],
  view: [
    listOpportunityBrokerageUser,
    viewOpportunityBrokerageUser,
    viewChangeLogOpportunityBrokerageUser
  ]
};

export default opportunityBrokerageUserMenuItems;
