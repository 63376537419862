import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

/** @TODO add Types */

const listSQS = {
  label: fieldLabel.list,
  url: () => `/sqs`,
  labelIcon: ListAltIcon,
  model: appModel.sqs,
  permission: appPermission.list,
  nodeId: uuid()
};

const viewSQS = {
  label: fieldLabel.view,
  url: (id: string) => `/sqs/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.sqs,
  permission: appPermission.view,
  nodeId: uuid()
};

const editSQS = {
  label: fieldLabel.edit,
  url: (id: string) => `/sqs/${id}/edit`,
  labelIcon: CreateIcon,
  model: appModel.sqs,
  permission: appPermission.edit,
  nodeId: uuid()
};

const sqsMenuItems = {
  list: [listSQS],
  edit: [listSQS, viewSQS],
  view: [listSQS, editSQS]
};

export default sqsMenuItems;
