import { RetradeRequestEntity } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export function getInitialRetradeRequest(
  opportunity: OpportunityEntity
): RetradeRequestEntity {
  return {
    opportunity_status_c: opportunity.opportunity_status_c || '',
    retrade_reason: opportunity.retrade_reason || '',
    contract_price_c: opportunity.contract_price_c || '',
    due_diligence_end_c: opportunity.due_diligence_end_c,
    close_date_c: opportunity.close_date_c || '',
    forecasted_dd_end_date: opportunity.forecasted_dd_end_date || '',
    forecasted_close_date: opportunity.forecasted_close_date || '',
    negotiator_sent_retrade_amount:
      opportunity.negotiator_sent_retrade_amount || '',
    purchase_price_c: opportunity.purchase_price_c,
    buyer_requested_retrade_amount:
      opportunity.buyer_requested_retrade_amount || ''
  };
}
