type FieldOptionPropertiesType = {
  name: string;
  title: string;
  checked: boolean;
};

type FieldOptionType = {
  forecasted_dd_end_date: FieldOptionPropertiesType;
  forecasted_close_date: FieldOptionPropertiesType;
};

export const fieldOptions: FieldOptionType = {
  forecasted_dd_end_date: {
    name: 'forecasted_dd_end_date',
    title: 'Forecasted DD End Date',
    checked: false
  },
  forecasted_close_date: {
    name: 'forecasted_close_date',
    title: 'Forecasted Close Date',
    checked: false
  }
};
