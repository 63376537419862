import { Alert, AlertTitle, Grid } from '@mui/material';
import {
  Navigate,
  isRouteErrorResponse,
  useLocation,
  useRouteError
} from 'react-router-dom';

const RouterErrorBoundary = () => {
  const url = useLocation();
  const error = useRouteError();
  let message = 'Something went wrong';

  if (url.pathname.includes('app')) {
    let urlPath = url.pathname.replace('app/', '');
    urlPath = urlPath.replace('app', '');
    return <Navigate to={urlPath} replace />;
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      message = "This page doesn't exist!";
    }

    if (error.status === 401) {
      message = "You aren't authorized to see this";
    }

    if (error.status === 503) {
      message = 'Looks like our API is down';
    }

    if (error.status === 418) {
      message = '🫖';
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '30vh' }}
    >
      <Grid item xs={3}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {message}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default RouterErrorBoundary;
