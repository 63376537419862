import { ObjectType } from 'types';

const feeAdvanceTypes: ObjectType = {
    inspection: 'Inspection',
    wdi_inspect: 'WDI Inspect',
    pool_inspect: 'Pool Inspect',
    option_fee: 'Option Fee',
    earnest_money: 'Earnest Money',
    multiple_types: 'Multiple Types'
};

export default feeAdvanceTypes;

