import envConfig from 'config/env';
import { GetResponse } from 'types/api-response-types';
import { FieldsEntity } from 'types/view-changelog-types';

import httpService from './http-service';

const service = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/`,

  getFields: async (action: string): Promise<GetResponse<FieldsEntity>> => {
    const result = await httpService.get<FieldsEntity>(
      `${service.url}${action}/fields`
    );
    return result;
  }
};

export default service;
