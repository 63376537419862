import React, { createContext, ReactNode, useState } from 'react';

interface CopyToClipboardContextType {
  copyText: (text: string) => void;
  isCopied: boolean;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CopyToClipboardContext = createContext<CopyToClipboardContextType>(
  {
    copyText: () => {},
    isCopied: false,
    setIsCopied: () => {}
  }
);

interface CopyToClipboardProviderProps {
  children: ReactNode;
}

export function CopyToClipboardProvider({
  children
}: CopyToClipboardProviderProps) {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text ?? '');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const contextValue = { copyText, isCopied, setIsCopied };

  return (
    <CopyToClipboardContext.Provider value={contextValue}>
      {children}
    </CopyToClipboardContext.Provider>
  );
}
