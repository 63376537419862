import React from 'react';
import Chip from '@mui/material/Chip';

const DashboradTabLabel = ({
  title,
  count
}: {
  title: string;
  count?: number;
}) => {
  return (
    <span style={{ display: 'inline' }}>
      {title} {count ? <Chip label={count} /> : ''}
    </span>
  );
};

export default DashboradTabLabel;
