interface EllipsisProps {
  str: string;
  limit?: number;
}

const Ellipsis = (props: EllipsisProps) => {
  const { str = '', limit = 100 } = props;

  return (
    <>{str.length > limit ? str.substring(0, limit) + '...' : str ?? ''}</>
  );
};

export default Ellipsis;
