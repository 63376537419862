import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { MarketPreferenceBrokerageUserListEntity } from 'types/market-preference-brokerage-user-types';
import ActionDropdown from 'features/market-preference-brokerage-users/action';
import brokerageTransactionRole from 'assets/constants/brokerage-transaction-roles';
import DateUtility from 'helpers/date-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import marketPreferenceBrokerageUserStatus from 'assets/constants/market-preference-brokerage-user-status';
import RouteLink from 'components/link/route-link';
import RouteOpenNewIconLink from 'components/link/route-open-new-icon-link';
import userFullName from 'helpers/user-name-helper';

const Action = ({
  marketPreferenceBrokerageUser
}: {
  marketPreferenceBrokerageUser: MarketPreferenceBrokerageUserListEntity;
}) => {
  return (
    <>
      <ActionDropdown data={marketPreferenceBrokerageUser} />

      <RouteOpenNewIconLink
        url={`/market-preferences-brokerage-users/${marketPreferenceBrokerageUser.id}/view`}
      />
    </>
  );
};

const marketPreferenceBrokerageUserColumn: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    renderCell: (
      param: GridRenderCellParams<MarketPreferenceBrokerageUserListEntity>
    ) => <Action marketPreferenceBrokerageUser={param.row} />,
    flex: 0.7,
    minWidth: 120
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: false,
    flex: 3,
    renderCell: (
      param: GridRenderCellParams<MarketPreferenceBrokerageUserListEntity>
    ) => (
      <RouteLink
        url={`/market-preferences-brokerage-users/${param.row.id}/view`}
        name={param.row.name}
      />
    )
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: true,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>) => {
      return getObjectKeyValue(marketPreferenceBrokerageUserStatus, row.status);
    }
  },
  {
    headerName: fieldLabel.brokerageRole,
    field: 'brokerage_role',
    sortable: false,
    flex: 1,
    valueGetter: (
      params: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>
    ) => {
      if (params.row.brokerage_transaction_role) {
        return `${
          brokerageTransactionRole[
            params.row.brokerage_transaction_role.name
          ] ?? params.row.brokerage_transaction_role.name
        }`;
      } else {
        return ``;
      }
    }
  },
  {
    headerName: fieldLabel.brokerageUser,
    field: `brokerage_user`,
    sortable: false,
    flex: 1,
    valueGetter: (
      param: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>
    ) => {
      return `${userFullName(param.row.brokerage_user)}`;
    }
  },
  {
    headerName: fieldLabel.marketPreferenceName,
    field: 'market_preference_name',
    sortable: false,
    flex: 1,
    renderCell: ({
      row
    }: GridRenderCellParams<MarketPreferenceBrokerageUserListEntity>) => (
      <RouteLink
        url={`/market-preferences/${row?.market_preference?.id}/view`}
        name={row?.market_preference?.name}
        target={true}
      />
    )
  },
  {
    headerName: fieldLabel.lastActivatedAt,
    field: 'last_activated_at',
    sortable: true,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.last_activated_at);
    }
  },
  {
    headerName: fieldLabel.lastDeactivatedAt,
    field: 'last_deactivated_at',
    sortable: true,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.last_deactivated_at);
    }
  },
  {
    headerName: fieldLabel.dateCreated,
    field: 'date_entered',
    sortable: true,
    renderCell: ({
      row
    }: GridRenderCellParams<MarketPreferenceBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    },

    flex: 1
  },
  {
    headerName: fieldLabel.dateModified,
    field: 'date_modified',
    sortable: true,
    flex: 1,
    valueGetter: ({
      row
    }: GridValueGetterParams<MarketPreferenceBrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_modified);
    }
  }
];

export default marketPreferenceBrokerageUserColumn;
