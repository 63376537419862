import { Grid, useTheme } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { tokens } from 'theme';
import MdEditor from 'components/md-editor';
import ValidationError from 'components/errors/validation-error';
import RequiredFieldAsterisk from './required-field-asterisk';

interface TextUnitInterface {
  grid?: {
    xs: number;
    sm: number;
  };
  value: string;
  onChange: (e: string) => void;
  label: string;
  height?: number;
  readOnly?: boolean;
  pt?: number;
  pb?: number;
  error?: string[];
  required?: boolean;
}

const UnitMdEditor = (props: TextUnitInterface) => {
  let {
    value,
    onChange,
    grid = { xs: 12, sm: 6 },
    label,
    readOnly = false,
    pt = 1,
    pb = 1,
    error = [],
    required = false
  } = props;

  const themePalette = useTheme();
  const colors = tokens(themePalette.palette.mode);
  return (
    <Grid item xs={grid.xs} sm={grid.sm} pt={pt} pb={pb}>
      <FormLabel
        style={{
          color: `${colors.grey[400]}`,
          fontWeight: '700'
        }}
      >
        {label}
        {required && <RequiredFieldAsterisk />}
      </FormLabel>
      <MdEditor value={value} onChange={onChange} readOnly={readOnly} />
      <ValidationError data={error} />
    </Grid>
  );
};
export default UnitMdEditor;
