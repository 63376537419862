import earnestMoneyStatus from 'assets/constants/earnest-money-status';
import fieldLabel from 'assets/constants/fieldLabel';
import optionFeeStatus from 'assets/constants/option-fee-status';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitEmpty from 'components/form/unit-empty';
import UnitItem from 'components/form/unit-item';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import FieldChooser from 'features/dashboards/field-chooser/field-chooser';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ObjectType } from 'types';
import { fieldOptions } from './field-chooser/fields';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import { ErrorValidation } from 'types/error-types';
import { CheckboxChangeEvent, InputChangeEvent } from 'types/common-types';
import {
  ActionRecordViewPropTypes,
  OptionEarnestFeeStatusEntity,
  OptionEarnestFeeStatusPayLoadEntity
} from 'types/brokerage-action-types';
import brokerageActionService from 'services/brokerage-action-service';
import { brokerageActionEvent } from 'event/brokerage-action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import emptyFunction from 'helpers/empty-function-helper';
import { getInitialOptionEarnestFeeStatus } from 'state/brokerage-actions/option-earnest-fee-status';
import partyService from 'services/party-service';
import { getInitialState } from './helper';

const RecordView = ({
  opportunity,
  onClose = emptyFunction,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<OptionEarnestFeeStatusEntity>(
    getInitialOptionEarnestFeeStatus(opportunity)
  );

  const [fields, setFields] = useState<ObjectType>(fieldOptions);

  const handleChange = (e: InputChangeEvent): void => {
    if (e.target) {
      setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
    } else {
      setData(Object.assign({}, data, e));
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const reqBody: Partial<OptionEarnestFeeStatusPayLoadEntity> = {};

    if (fields.option_fee_status_c.checked) {
      reqBody['option_fee_status_c'] = data.option_fee_status_c;
      reqBody['option_fee_delivery_date_c'] = data.option_fee_delivery_date_c;
    }
    if (fields.earnest_money_status_c.checked) {
      reqBody['earnest_money_status_c'] = data.earnest_money_status_c;
      reqBody['emd_requested_confirmed'] = data.emd_requested_confirmed;
      reqBody['em_delivery_date_c'] = data.em_delivery_date_c;
    }

    setIsLoading(true);

    const result = await brokerageActionService.update<
      Partial<OptionEarnestFeeStatusPayLoadEntity>
    >(opportunity.id, reqBody, 'option_earnest_fee_status');

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.option_earnest_fee_status();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity();
      }
    }
  };

  const handleCheck = (e: CheckboxChangeEvent): void => {
    setFields(() => {
      for (const field of Object.values(fields)) {
        if (field.name.includes(e.target.id)) {
          field.checked = e.target.checked;
        }
      }
      return { ...fields };
    });
  };

  const loadParties = async () => {
    const partyList = await partyService.getList(
      `?page=1&filter[opportunity_id]=${opportunity.id}`
    );

    setData(getInitialState(opportunity, partyList.data.data));
  };

  useEffect(() => {
    if (!loadingOpportunity) {
      loadParties();
    }

    setData((prevOppurtunity) => ({
      ...prevOppurtunity,
      ...getInitialOptionEarnestFeeStatus(opportunity)
    }));
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitItem grid={{ xs: 12, sm: 3 }}>
              <FieldChooser data={fields} setFields={handleCheck} />
            </UnitItem>

            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <StackRowWithDivider />
            </UnitItem>

            {fields.option_fee_status_c &&
              fields.option_fee_status_c.checked && (
                <>
                  <UnitSelect
                    name="option_fee_status_c"
                    label={fieldLabel.optionStatus}
                    records={getObjectEntriesAsArray(optionFeeStatus)}
                    value={data.option_fee_status_c ?? ''}
                    onChange={handleChange}
                    error={validation['option_fee_status_c'] ?? ''}
                  />

                  <UnitDate
                    label={fieldLabel.optionFeeDeliveryDate}
                    name="option_fee_delivery_date_c"
                    value={data.option_fee_delivery_date_c ?? ''}
                    onChange={(e: string) =>
                      handleChange({
                        target: {
                          name: 'option_fee_delivery_date_c',
                          value: e
                        }
                      })
                    }
                    error={validation['option_fee_delivery_date_c'] ?? ''}
                  />

                  <UnitPriceFormatter
                    label={fieldLabel.optionFee}
                    name="option_amount_c"
                    value={data.option_amount_c ?? ''}
                    onChange={handleChange}
                    error={validation['option_amount_c'] ?? ''}
                    disabled={true}
                  />

                  <UnitEmpty />
                </>
              )}

            {fields.earnest_money_status_c &&
              fields.earnest_money_status_c.checked && (
                <>
                  <UnitSelect
                    name="earnest_money_status_c"
                    label={fieldLabel.earnestStatus}
                    records={getObjectEntriesAsArray(earnestMoneyStatus)}
                    value={data.earnest_money_status_c ?? ''}
                    onChange={handleChange}
                    error={validation['earnest_money_status_c'] ?? ''}
                  />

                  <UnitDate
                    label={fieldLabel.emdRequestedConfirmed}
                    name="emd_requested_confirmed"
                    value={data.emd_requested_confirmed ?? ''}
                    onChange={(e: string) =>
                      handleChange({
                        target: {
                          name: 'emd_requested_confirmed',
                          value: e
                        }
                      })
                    }
                    error={validation['emd_requested_confirmed'] ?? ''}
                  />

                  <UnitPriceFormatter
                    label={fieldLabel.earnestFee}
                    name="earnest_amount_c"
                    value={data.earnest_amount_c ?? ''}
                    onChange={handleChange}
                    error={validation['earnest_amount_c'] ?? ''}
                    disabled={true}
                  />

                  <UnitDate
                    label={fieldLabel.emdDeliveryData}
                    name="em_delivery_date_c"
                    value={data.em_delivery_date_c ?? ''}
                    onChange={(e: string) =>
                      handleChange({
                        target: { name: 'em_delivery_date_c', value: e }
                      })
                    }
                    error={validation['em_delivery_date_c'] ?? ''}
                  />
                </>
              )}

            <UnitText
              label={fieldLabel.escrowCompanyName}
              name="escrow_company_name_c"
              value={data.escrow_company.company ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_name_c'] ?? ''}
              disabled={true}
            />

            <UnitText
              label={fieldLabel.escrowCompanyAddress}
              name="escrow_company_address_c"
              value={data.escrow_company.address ?? ''}
              multiline
              rows={2}
              onChange={handleChange}
              error={validation['escrow_company_address_c'] ?? ''}
              disabled={true}
            />

            <UnitText
              label={fieldLabel.escrowCompanyEmail}
              name="escrow_company_email_c"
              value={data.escrow_company.email ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_email_c'] ?? ''}
              disabled={true}
            />

            <UnitText
              label={fieldLabel.escrowCompanyPhone}
              name="escrow_company_phone_c"
              value={data.escrow_company.mobile ?? ''}
              onChange={handleChange}
              error={validation['escrow_company_phone_c'] ?? ''}
              disabled={true}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
