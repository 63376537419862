import ListAltIcon from '@mui/icons-material/ListAlt';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

/** @TODO add Types */

const listCall = {
  label: fieldLabel.list,
  url: () => `/calls`,
  labelIcon: ListAltIcon,
  model: appModel.call,
  permission: appPermission.list,
  nodeId: uuid()
};

const callMenuItems = {
  list: [listCall]
};

export default callMenuItems;
