import { due_diligence_end_c } from 'services/validation/due_diligence_end_c';
import { ObjectType } from 'types';
import {
  BuyerCounterOfferConfimationEntity,
  BuyerCounterOfferConfimationPayloadEntity
} from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export const getVisibleFormFields = (fields: string): string[] => {
  let visibleFormFields: string[] = [
    'receipt_date',
    'received_from',
    'response_amount',
    'response_terms',
    'notes',
    'buyer_notes',
    'due_diligence_fee_status',
    'due_diligence_fee',
    'preferred_title_status',
    'preferred_title'
  ];

  const priceFields = [
    'counter_offer_price_status',
    'counter_offer_price_c',
    'counter_offer',
    'counter_offer_status'
  ];

  const diligencePeriodFields = [
    'option_period_status',
    'option_period_days_c',
    'option_days_type_status',
    'option_days_type_c',
    'option_amount_status',
    'option_amount_c',
    'commission_status',
    'initial_commission_percentage',
    'due_diligence_end_c'
  ];

  const exclusionInclusionFields = [
    'exclusion_status',
    'exclusion',
    'inclusion_status',
    'inclusion'
  ];

  const earnestMoneyFields = ['earnest_amount_status', 'earnest_amount_c'];

  const closeDateFields = ['close_date_status', 'close_date_c'];

  const postOccupancyFields = [
    'lease_end_date_status',
    'lease_end_date',
    'deposit_amount_status',
    'deposit_amount',
    'rental_amount_status',
    'rental_amount',
    'lease_type_status',
    'lease_type'
  ];

  const specialProvisionsFields = [
    'additional_terms_comment_status',
    'additional_terms_comments_c',
    'leased_or_owned_status',
    'solar_panel_leased_or_owned',
    'payoff_amount_status',
    'solar_panel_payoff_amount',
    'lease_date_expiration_status',
    'solar_panel_lease_date_expiration'
  ];

  const otherFields = [
    'other_counter_offer_terms_status',
    'other_counter_offer_terms'
  ];

  if (fields.includes('price')) {
    visibleFormFields = [...visibleFormFields, ...priceFields];
  }

  if (fields.includes('diligence_period')) {
    visibleFormFields = [...visibleFormFields, ...diligencePeriodFields];
  }

  if (fields.includes('exclusions_inclusions')) {
    visibleFormFields = [...visibleFormFields, ...exclusionInclusionFields];
  }

  if (fields.includes('earnest_money')) {
    visibleFormFields = [...visibleFormFields, ...earnestMoneyFields];
  }

  if (fields.includes('close_date')) {
    visibleFormFields = [...visibleFormFields, ...closeDateFields];
  }

  if (fields.includes('post_occupancy')) {
    visibleFormFields = [...visibleFormFields, ...postOccupancyFields];
  }

  if (fields.includes('special_provisions')) {
    visibleFormFields = [...visibleFormFields, ...specialProvisionsFields];
  }

  if (fields.includes('other')) {
    visibleFormFields = [...visibleFormFields, ...otherFields];
  }

  return visibleFormFields;
};

export const prepareRequestBody = (
  data: BuyerCounterOfferConfimationEntity,
  opportunity: OpportunityEntity
): Partial<BuyerCounterOfferConfimationPayloadEntity> => {
  const visibleFormFields: string[] = getVisibleFormFields(
    data.selected_categories
  );

  const dueDiligenceEndDate = due_diligence_end_c.calculate(
    {
      contract_execution_date_c: opportunity?.contract_execution_date_c,
      option_days_type_c: data?.option_days_type_c,
      option_period_days_c: data?.option_period_days_c
    },
    ''
  );

  const requestBody: Partial<BuyerCounterOfferConfimationPayloadEntity> = {
    ...visibleFormFields.reduce((acc: ObjectType, field: string) => {
      acc[field] = data[field as keyof typeof data];
      return acc;
    }, {}),
    due_diligence_end_c: dueDiligenceEndDate
  };

  return requestBody;
};
