import { isEmpty, isValueChanged } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import DateUtility from 'helpers/date-helper';

export const offer_blocked_reasons_at = {
  calculate: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    if (isEmpty(opportunity.offer_blocked_reasons)) {
      return null;
    }
    if (
      !isEmpty(opportunity.offer_blocked_reasons) &&
      isValueChanged(opportunity, originalOpportunity, 'offer_blocked_reasons')
    ) {
      return DateUtility.getTodayDateTimeString();
    }
    return originalOpportunity.offer_blocked_reasons_at;
  }
};
