import { ObjectType } from 'types';
import envConfig from 'config/env';
import { UtilityProviderEntity, UtilityProviderPayloadEntity } from 'types/utility-provider-types';
import { GetListResponse, GetResponse } from 'types/api-response-types';
import httpService from './http-service';
import initialUtilityProvider from 'state/utility-provider/initial-utility-provider';

const service = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/property_utility_providers`,

  create: async (data: UtilityProviderPayloadEntity): Promise<GetResponse<UtilityProviderEntity>> => {
    const result = await httpService.post<UtilityProviderEntity, UtilityProviderPayloadEntity>(`${service.url}`, data);
    return result;
  },

  update: async (id: string, data: UtilityProviderPayloadEntity): Promise<GetResponse<UtilityProviderEntity>> => {
    const result = await httpService.put<UtilityProviderEntity, UtilityProviderPayloadEntity>(`${service.url}/${id}`, data);
    return result;
  },

  getList: async (query: string): Promise<GetListResponse<UtilityProviderEntity>> => {
    const result = await httpService.list<UtilityProviderEntity>(`${service.url}${query}`);
    return result;
  },

  getUtilityProviderDetails: (data: UtilityProviderEntity[]): UtilityProviderEntity => {
    if (data[0]?.id) {
      return data[0];
    } else return initialUtilityProvider;
  }
};

export default service;
