import { KnowledgeBaseEntity } from 'types/knowledge-base-types';
import { ObjectType } from 'types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import initialKnowledgeBase from 'state/knowledge-base/initial-knowledge-base';
import knowledgeBaseService from 'services/knowledge-base-service';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import useRouteName from 'pages/route-outlet-context';

interface PropType {
  routeTag: string;
  knowledge_base_id?: string;
}

const KnowledgeBaseView = (props: PropType) => {
  const { setRouteName } = useRouteName();
  const { knowledge_base_id } = useParams<ObjectType>();
  const [knowledgeBase, setKnowledgeBase] =
    useState<KnowledgeBaseEntity>(initialKnowledgeBase);

  const loadKnowledgeBase = async (knowledge_base_id: string) => {
    const response = await knowledgeBaseService.get(knowledge_base_id);

    if (response.isError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: true,
        message: response.errorMessage
      });
      return;
    }
    
    setKnowledgeBase(response.data);
  };

  useEffect(() => {
    if (props.knowledge_base_id) loadKnowledgeBase(props.knowledge_base_id);
  }, [props.knowledge_base_id]);

  useEffect(() => {
    if (knowledge_base_id) loadKnowledgeBase(knowledge_base_id);
  }, []);

  useEffect(() => {
    setRouteName(props.routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView knowledgeBase={knowledgeBase} readOnly={true} />
      </PaperBoxContent>
    </PaperBox>
  );
};

export default KnowledgeBaseView;
