import { moduleLabels } from 'assets/list/tracker/constant';
import ErrorComponent from 'components/errors/error-component';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import { isEmpty } from 'helpers/misc-helper';
import userFullName from 'helpers/user-name-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import vendorService from 'services/vendor-service';
import trackerService from 'services/tracker-service';
import initialVendorService from 'state/service/initial-vendor-service';
import { VendorServiceEntity } from 'types/vendor-service-types';

import RecordView from '../record-view';

const ServiceView = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { setRouteName } = useRouteName();
  const { service_id } = useParams<{ service_id: string }>();
  const [services, setServices] =
    useState<VendorServiceEntity>(initialVendorService);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadServices = async (service_id: string): Promise<void> => {
    const response = await vendorService.get(service_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    const data: VendorServiceEntity = {
      ...response.data,
      requested_by_name: response.data.requested_by_user
        ? `${userFullName(response.data.requested_by_user)}`
        : ``,
      confirmation_by_name: response.data.confirmation_by_user
        ? `${userFullName(response.data.confirmation_by_user)}`
        : ``
    };
    setServices(data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Services.label,
      item_id: service_id,
      item_summary: response.data.inspection_type,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (service_id) loadServices(service_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView service={services} readOnly={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default ServiceView;
