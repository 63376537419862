import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { CardHeader, CardContent, Card } from '@mui/material';
import { EditContainer } from '../record-view/container';
import { InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { toastSuccess } from 'event/toast-event';
import { UtilityProviderEntity } from 'types/utility-provider-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelButton from 'components/form/button-cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import React, { useState } from 'react';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitButtonIcon from 'components/form/button-icon';
import UnitItem from 'components/form/unit-item';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import UtilityProviderService from 'services/utility-provider-service';

interface UtilityProvidesProps {
  data: UtilityProviderEntity;
  opportunity: ObjectType;
  updateOpportunity?: (value: ObjectType) => void;
}

const UtilityProviders = ({
  data,
  opportunity,
  updateOpportunity = emptyFunction
}: UtilityProvidesProps) => {
  const [editMode, setEditMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const [isDataChanged, setIsDataChanged] = useState(false);

  const [utilityProvider, setUtilityProvider] = useState<UtilityProviderEntity>(
    {
      ...data
    }
  );

  const handleChange = (e: InputChangeEvent) => {
    setUtilityProvider(
      Object.assign({}, utilityProvider, {
        [e.target.name]: e.target.value
      })
    );
    setIsDataChanged(true);
  };

  const save = async () => {
    let reqBody = {
      opportunity_id: opportunity?.id,
      name: opportunity?.name,
      electric_provider: utilityProvider.electric_provider,
      gas_provider: utilityProvider.gas_provider,
      is_electric: utilityProvider.is_electric,
      water_well_provider: utilityProvider.water_well_provider,
      is_well: utilityProvider.is_well,
      sewer_provider: utilityProvider.sewer_provider,
      is_septic: utilityProvider.is_septic,
      trash_provider: utilityProvider.trash_provider
    };

    let response;

    if (!isEmpty(utilityProvider.id)) {
      response = await UtilityProviderService.update(data?.id, reqBody);
    } else {
      response = await UtilityProviderService.create(reqBody);
    }

    if (response.isSuccess) {
      toastSuccess(`${fieldLabel.utilityProvider} Saved`);
      updateOpportunity({
        utility_provider: response.data
      });

      setUtilityProvider(response.data);
      setEditMode(!editMode);
    }
  };

  const toggleConfirmBox = () => {
    setConfirmModal({
      open: true,
      title: fieldLabel.areYouSure,
      text: fieldLabel.changesWillDiscarded,
      proceed: () => {
        setEditMode(!editMode);
        setConfirmModal(initialConfirmModalState);
        setUtilityProvider(data);
        updateOpportunity({
          utility_provider_id: data.id,
          utility_provider: data
        });
        setIsDataChanged(false);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (isDataChanged) {
        toggleConfirmBox();
      } else {
        setEditMode(false);
        setIsDataChanged(false);
      }
    } else {
      setEditMode(true);
      setIsDataChanged(false);
    }
  };

  return (
    <React.Fragment>
      <Card variant="outlined">
        <CardHeader
          action={
            <UnitButtonIcon
              Icon={
                editMode
                  ? CloseIcon
                  : !isEmpty(data?.id)
                  ? EditIcon
                  : AddCircleIcon
              }
              onClick={() => {
                toggleEditMode();
              }}
            />
          }
          title={fieldLabel.utilityProvider}
        />
        <CardContent>
          <EditContainer>
            <FormContainer>
              <UnitText
                label={fieldLabel.electricProvider}
                name="electric_provider"
                value={utilityProvider.electric_provider ?? ''}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
              />

              <UnitText
                label={fieldLabel.waterProvider}
                name="water_well_provider"
                value={utilityProvider.water_well_provider ?? ''}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 8, xs: 8 }}
              />

              <UnitSwitch
                value={utilityProvider.is_well ?? 0}
                onChange={handleChange}
                name="is_well"
                label={fieldLabel.isWell}
                disabled={!editMode}
                grid={{ sm: 4, xs: 4 }}
              />

              <UnitText
                label={fieldLabel.sewerProvider}
                name="sewer_provider"
                value={utilityProvider.sewer_provider ?? ''}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 8, xs: 8 }}
              />

              <UnitSwitch
                value={utilityProvider.is_septic ?? 0}
                onChange={handleChange}
                name="is_septic"
                label={fieldLabel.isSeptic}
                disabled={!editMode}
                grid={{ sm: 4, xs: 4 }}
              />

              <UnitText
                label={fieldLabel.gasProvider}
                name="gas_provider"
                value={utilityProvider.gas_provider ?? ''}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 8, xs: 8 }}
              />

              <UnitSwitch
                value={utilityProvider.is_electric ?? 0}
                onChange={handleChange}
                name="is_electric"
                label={fieldLabel.isElectric}
                disabled={!editMode}
                grid={{ sm: 4, xs: 4 }}
              />

              <UnitText
                label={fieldLabel.trashProvider}
                name="trash_provider"
                value={utilityProvider.trash_provider ?? ''}
                onChange={handleChange}
                readOnly={!editMode}
                grid={{ sm: 12, xs: 12 }}
              />
              {editMode ? (
                <UnitItem grid={{ sm: 12, xs: 12 }}>
                  <StackRowWithDivider>
                    <SaveButton onClick={save} />
                    <CancelButton onClick={() => toggleEditMode()} />
                  </StackRowWithDivider>
                </UnitItem>
              ) : (
                <></>
              )}
            </FormContainer>
          </EditContainer>
        </CardContent>
      </Card>
      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </React.Fragment>
  );
};

export default UtilityProviders;
