import fieldLabel from 'assets/constants/fieldLabel';
import { isEmpty } from 'helpers/misc-helper';
import React from 'react';

const MessageDeleteRecordConfirmation = (
  message: string | null,
  module: string,
  isDelete?: boolean
) => {
  return (
    <>
      {isDelete
        ? fieldLabel.areYouSureYouWantToDelete
        : fieldLabel.areYouSureYouWantToRemove}{' '}
      "<b>{!isEmpty(message) ? message : ''}</b>" {module}?
    </>
  );
};

export default MessageDeleteRecordConfirmation;
