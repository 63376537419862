import { ObjectType } from 'types';

const offerGrade: ObjectType = {
  a: 'A',
  b: 'B',
  c: 'C',
  f: 'F'
};

export default offerGrade;
