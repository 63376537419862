import {
  KBCategoriesFilterEntity,
  KBCategoriesListEntity
} from 'types/kb-category-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'helpers/misc-helper';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/party/default-sort';
import Filters from './filter';
import initialKBCategoriesListFilter from 'state/kb-categories/initial-kb-categories-list-filter';
import kbCategoriesColumn from 'assets/list/kb-categories/column';
import kbCategoryService from 'services/kb-category-service';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';

const KBCategoriesList = ({ routeTag }: { routeTag: string }) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [filter, setFilter] = useState<KBCategoriesFilterEntity>(
    initialKBCategoriesListFilter
  );

  const getKBCategories = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<KBCategoriesListEntity>> => {
    const filterInfo = isEmpty(opportunity_id)
      ? filter
      : { ...filter, opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await kbCategoryService.getList(queryString);

    setIsLoading(false);

    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  });

  return (
    <React.Fragment>
      <Filters
        filter={filter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
      />

      <DataGrid<KBCategoriesListEntity>
        getData={getKBCategories}
        tableColumns={kbCategoriesColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default KBCategoriesList;
