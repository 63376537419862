import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import { OpportunityEntity } from 'types/opportunity-types';

export const rental_amount = {
  validate: function (oppurtunity: OpportunityEntity, status: string,) {
    let errors: string[] = [];

    if (status === 'edit') {
      if (convertNumber(oppurtunity?.rental_amount) < 0) {
        errors.push('No negative values allowed');
      }
    }

    if (status === 'action') {
      if (
        oppurtunity?.has_post_occupancy !== 'no' &&
        isEmpty(oppurtunity?.rental_amount)
      ) {
        errors.push(missingField(fieldLabel.rentalAmount));
      }
    }

    return errors;
  }
};
