import { Box } from '@mui/system';
import { ReactNode } from 'react';

const ContainerRight = ({
  p = 1,
  children
}: {
  p?: number;
  children: ReactNode;
}) => {
  return (
    <Box
      p={p}
      sx={{
        height: 'calc(100vh - 14vh)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerRight;
