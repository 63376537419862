const initialMsa = {
  id: '',
  name: '',
  entera_market_id: '',
  code: '',
  initial_commission: '',
  state: '',
  mls_code: '',
  has_municipal_inspections: 0
};

export default initialMsa;
