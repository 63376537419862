import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import DateUtility from 'helpers/date-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import { BrokerageUserListEntity } from 'types/user';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import userStatus from 'assets/constants/user-status';
import userType from 'assets/constants/user-type';
import UnitButtonIcon from 'components/form/button-icon';
import EditIcon from '@mui/icons-material/Edit';

const usersColumn = ({
  onEdit
}: {
  onEdit: (val: string) => void;
}): GridColDef[] => [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<BrokerageUserListEntity>) => (
      <UnitButtonIcon Icon={EditIcon} onClick={() => onEdit(row.id)} />
    ),
    flex: 0.3,
    minWidth: 120
  },
  {
    headerName: fieldLabel.firstName,
    field: 'first_name',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.lastName,
    field: 'last_name',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.userName,
    field: 'user_name',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.email,
    field: 'email',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.mobile,
    field: 'phone_mobile',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.status,
    field: 'status',
    sortable: true,
    valueGetter: (param: GridValueGetterParams<BrokerageUserListEntity>) => {
      return getObjectKeyValue(userStatus, param.row.status);
    },
    flex: 1
  },

  {
    headerName: fieldLabel.brokerageUserType,
    field: 'crm_user_type',
    sortable: true,
    valueGetter: (param: GridValueGetterParams<BrokerageUserListEntity>) => {
      return getObjectKeyValue(userType, param.row.crm_user_type);
    },
    flex: 1
  },
  {
    headerName: fieldLabel.dateCreated,
    field: 'date_entered',
    sortable: true,
    flex: 0.5,
    valueGetter: ({ row }: GridRenderCellParams<BrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  }
];

export default usersColumn;
