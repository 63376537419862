import { AccountEntity } from 'types/account-types';
import { moduleLabels } from 'assets/list/tracker/constant';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import accountService from 'services/account-service';
import initialAccount from 'state/account/initial-account';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import trackerService from 'services/tracker-service';
import useRouteName from 'pages/route-outlet-context';
import RecordView from '../record-view';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const AccountsView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();

  const { account_id } = useParams<{ account_id: string }>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [account, setAccount] = useState<AccountEntity>(initialAccount);

  const loadAccount = async (account_id: string) => {
    const response = await accountService.get(account_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setAccount(response.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Accounts.label,
      item_id: account_id,
      item_summary: response.data.name,
      action: 'detailview'
    });
  };

  useEffect(() => {
    if (account_id) loadAccount(account_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView account={account} readOnly={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default AccountsView;
