import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'pdf-templates')
      return await menuHelper.getItems(
        appModel.AOSPdfTemplates,
        appPermission.list
      );

    if (routeName === 'pdf-templates-view')
      return await menuHelper.getItems(
        appModel.AOSPdfTemplates,
        appPermission.view
      );

    if (
      [
        'pdf-templates-edit',
        'pdf-templates-pdf-view',
        'pdf-templates-readable-pdf-view'
      ].includes(routeName)
    )
      return await menuHelper.getItems(
        appModel.AOSPdfTemplates,
        appPermission.edit
      );

    if (routeName === 'pdf-templates-create')
      return await menuHelper.getItems(
        appModel.AOSPdfTemplates,
        appPermission.create
      );

    if (routeName === 'pdf-templates-delete')
      return await menuHelper.getItems(
        appModel.AOSPdfTemplates,
        appPermission.delete
      );

    return [];
  }
};

export default sideManuHelper;
