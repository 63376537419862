const initialEmailTemplate = {
  id: '',
  name: '',
  description: '',
  assigned_user_id: '',
  assigned_user_name: '',
  subject: '',
  related_event: '',
  body: '',
  body_html: '',
  type: '',
  email_inspection: '',
  variable_module: '',
  variable_name: '',
  variable_text: '',
  date_entered: '',
  date_modified: '',
  template_type: 'crm',
  mustache_variable_module: 'opportunity',
  mustache_variable_name: '',
  mustache_subject: '',
  mustache_body_html: '',
  modified_user: {
    id: '',
    first_name: '',
    last_name: ''
  },
  created_by_user: {
    id: '',
    first_name: '',
    last_name: ''
  }
};

export default initialEmailTemplate;
