import menuHelper from 'helpers/menu-helper';
import { SideMenuHelperType } from 'types/side-menu-item-type';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'kb-categories') {
      return await menuHelper.getItems('Knowledge_Base_Category', 'list');
    }

    if (routeName === 'kb-categories-view') {
      return await menuHelper.getItems('Knowledge_Base_Category', 'view');
    }

    if (routeName === 'kb-categories-edit') {
      return await menuHelper.getItems('Knowledge_Base_Category', 'edit');
    }

    if (routeName === 'kb-categories-create') {
      return await menuHelper.getItems('Knowledge_Base_Category', 'create');
    }

    return [];
  }
};

export default sideManuHelper;
