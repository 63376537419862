import { KBCategoryEntity } from 'types/kb-category-types';
import { ObjectType } from 'types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import initialKBCategories from 'state/kb-categories/initial-kb-categories';
import kbCategoryService from 'services/kb-category-service';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import useRouteName from 'pages/route-outlet-context';

const KBCategoryView = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();

  const { kbCategory_id } = useParams<ObjectType>();

  const [kbCategory, setKbCategory] =
    useState<KBCategoryEntity>(initialKBCategories);

  const getKbCategory = async (kbCategory_id: string) => {
    const response = await kbCategoryService.get(kbCategory_id);

    if (response.isError) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: true,
        message: response.errorMessage
      });
      return;
    }

    setKbCategory(response.data);
  };

  useEffect(() => {
    if (kbCategory_id) getKbCategory(kbCategory_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView kbCategories={kbCategory} readOnly={true} />
      </PaperBoxContent>
    </PaperBox>
  );
};

export default KBCategoryView;
