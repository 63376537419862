import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';

export const validateOpportunityBrokerageUser = (
  data: ObjectType,
  routeTag: string
): ObjectType => {
  let validation: ObjectType = {
    status: true
  };

  if (isEmpty(data.brokerage_transaction_role_id)) {
    validation = {
      ...validation,
      status: false,
      brokerage_transaction_role_id: [missingField(fieldLabel.brokerageRole)]
    };
  }

  if (isEmpty(data.brokerage_user_id)) {
    validation = {
      ...validation,
      status: false,
      brokerage_user_id: [missingField(fieldLabel.brokerageUser)]
    };
  }

  if (isEmpty(data.status)) {
    validation = {
      ...validation,
      status: false,
      Status: [missingField(fieldLabel.status)]
    };
  }

  if (isEmpty(data.market_preference?.id)) {
    validation = {
      ...validation,
      status: false,
      market_preference_name: [missingField(fieldLabel.marketPreference)]
    };
  }

  if (
    isEmpty(data.replace_brokerage_user_id) &&
    routeTag === 'opportunities-brokerage-users-edit-replace'
  ) {
    validation = {
      ...validation,
      status: false,
      replace_brokerage_user_id: [missingField(fieldLabel.replaceBrokerageUser)]
    };
  }

  return validation;
};
