import { convertNumber, isValueChanged } from "helpers/misc-helper";
import { ObjectType } from "types";
import { OpportunityEntity } from "types/opportunity-entity";

export const balance_to_close_c = {
  calculate: (opportunity: ObjectType, originalOpportunity: ObjectType) => {

    if (convertNumber(opportunity.offer_price_c) > 0) {
      return (
        convertNumber(opportunity.offer_price_c) -
        convertNumber(opportunity.earnest_amount_c) -
        convertNumber(opportunity.financing_amount_c) -
        convertNumber(opportunity.option_amount_c)
      );
    }

    return opportunity.balance_to_close_c;

  }
};
