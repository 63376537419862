import { OptionType } from 'types/option-type';

export const offer_blocked_reasons = (
  value: OptionType | OptionType[],
  action: string
) => {
  let result = ``;

  if (!value) return ``;

  if (action === 'dashboard' && !Array.isArray(value)) {
    result += `&filter[offer_blocked_reasons]=${value.value}`;
  }

  return result;
};
