import { ObjectType } from 'types';

const variableConfig: ObjectType = {
  DUE_DILIGENCE_END_START_TIME:
    process.env.REACT_APP_DUE_DILIGENCE_END_START_TIME,
  opportunitiesNotesTermsProvisionOfferGradeTag:
    'opportunities_notes_terms_provision_offer_grade',
  opportunityCommissionCalculationGuideTag: 'opportunities_commission_calculation_guide'
};

export default variableConfig;
