import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';
import moment from 'moment';
import variableConfig from 'config/variable';

export function getInitialExtensionRequest(opportunity: OpportunityEntity) {
  return {
    opportunity_status_c: opportunity.opportunity_status_c || '',
    due_diligence_end_c:
      opportunity.due_diligence_end_c ||
      DateUtility.addHours(
        moment(DateUtility.getTodayDateTimeString()),
        variableConfig.DUE_DILIGENCE_END_START_TIME
      ),
    close_date_c: opportunity.close_date_c || '',
    forecasted_dd_end_date: opportunity.forecasted_dd_end_date || '',
    forecasted_close_date: opportunity.forecasted_close_date || '',
    extension_request_reason: opportunity.extension_request_reason || '',
    addendum_no: opportunity.addendum_no || '',
    addendum_notes: opportunity.addendum_notes || '',
    original_close_of_escrow_date: DateUtility.getTodayDateString()
  };
}
