import buttonText from 'assets/constants/button';
import AddIcon from '@mui/icons-material/Add';
import PrimaryButton from 'components/button/button-primary';
import CircularProgress from '@mui/material/CircularProgress';
import { ClickEvent } from 'types/common-types';

interface UploadButtonInterface {
  onClick: (e: ClickEvent) => void;
  disabled?: boolean;
}

const UploadButton = (props: UploadButtonInterface) => {
  return (
    <PrimaryButton
      disabled={props.disabled === true}
      variant="contained"
      size="medium"
      name="btn-upload"
      onClick={props.onClick}
      startIcon={
        props.disabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : (
          <AddIcon />
        )
      }
    >
      {buttonText.upload}
    </PrimaryButton>
  );
};

export default UploadButton;
