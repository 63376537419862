import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';
import fieldLabel from 'assets/constants/fieldLabel';
import RouteLink from 'components/link/route-link';
import Tooltip from 'components/tooltip';
import TooltipModal from 'components/tooltip/tooltip-modal';
import eventBus from 'helpers/event-bus-helper';
import React from 'react';

const columns: GridColDef[] = [
  {
    headerName: fieldLabel.action,
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>
        <TooltipModal
          data={
            row.template_type === 'mustache'
              ? row.mustache_body_html
              : row.body_html
          }
        />

        <IconButton
          color="primary"
          onClick={() => {
            eventBus.dispatch('LOAD_TEMPLATE_CONTENT', {
              target: {
                value: row.id,
                name: 'template',
                subject:
                  row.template_type == 'mustache'
                    ? row.mustache_subject
                    : row.subject,
                template_type: row.template_type,
                html:
                  row.template_type == 'mustache'
                    ? row.mustache_body_html
                    : row.body_html
              }
            });
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    ),
    flex: 0.3,
    minWidth: 90,
    maxWidth: 90
  },

  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1,
    minWidth: 250,
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <Tooltip
          title={
            <>
              <Typography
                color="inherit"
                variant="subtitle2"
                fontWeight={'bold'}
                style={{ paddingTop: '3px', paddingBottom: '3px' }}
              >
                {row.name}
              </Typography>
              <Typography
                component={'span'}
                variant="caption"
                style={{ paddingTop: '3px', paddingBottom: '3px' }}
              >
                {row.template_type == 'mustache'
                  ? row.mustache_subject
                  : row.subject}
              </Typography>
            </>
          }
          placement="top"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <RouteLink
              url={'#'}
              name={row.name}
              style={{ paddingTop: '3px', paddingBottom: '3px' }}
            />
            <Typography
              component={'span'}
              variant="caption"
              sx={{ paddingTop: '3px', paddingBottom: '3px' }}
            >
              {row.template_type == 'mustache'
                ? row.mustache_subject
                : row.subject}
            </Typography>
          </Box>
        </Tooltip>
      );
    }
  }
];

export default columns;
