import DocumentList from 'features/documents/document-list';
import PDFTemplatesList from '../pdf-template-list';

const GenerateContact = () => {
  return (
    <>
      <DocumentList
        routeTag="opportunities-contracts"
        isFilterDisplay={false}
      />
      <PDFTemplatesList routeTag="opportunities-contracts" />
    </>
  );
};

export default GenerateContact;
