import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';

import { RejectOfferEntity } from 'types/brokerage-action-types';

export function getInitialRejectOffer(
  opportunity: OpportunityEntity
): RejectOfferEntity {
  return {
    opportunity_status_c: oppurtunityStatusList.offer_rejected,
    reject_reason: opportunity.reject_reason || '',
    reject_date: DateUtility.getTodayDateString(),
    competing_offer_count: opportunity.competing_offer_count || '',
    competing_offer_highest_price:
      opportunity.competing_offer_highest_price || '',
    reject_note: opportunity.reject_note || '',
    offer_date_c: opportunity.offer_date_c || ''
  };
}
