import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';

export function getInitialTerminationRequest(opportunity: OpportunityEntity) {
  return {
    opportunity_status_c:
      oppurtunityStatusList.closing_termination_pending || '',
    buyer_contract_name_c: opportunity?.buyer_contract_name_c || '',
    contract_price_c: opportunity?.contract_price_c || '',
    due_diligence_end_c: opportunity?.due_diligence_end_c || '',
    contract_terminated_date:
      opportunity?.contract_terminated_date || DateUtility.getTodayDateString(),
    contract_termination_reasons:
      opportunity?.contract_termination_reasons || '',
    earnest_amount_c: opportunity?.earnest_amount_c || '',
    termination_reason_subcategory:
      opportunity?.termination_reason_subcategory || '',

    internal_termination_feedback:
      opportunity?.internal_termination_feedback || ''
  };
}
