
enum propertiesClosingStateOfClosing {
  closing_diligence_period = 'Closing: Dilligence Period',
  closing_diligence_amended = 'Closing: Dilligence Amended',
  closing_sale_pending = 'Closing: Sale Pending',
  closing_sale_pending_amended = 'Closing: Sale Pending Amended',
  closing_clear_to_close = 'Closing: Clear to Close',
  closing_cancel_contract = 'Closing: Cancelled',
  closed_purchased = 'Closing: Purchased',
  closing_termination_pending = 'Closing: Termination Pending'
}

export default propertiesClosingStateOfClosing;
