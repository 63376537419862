import ViewChangelog from 'features/view-changelog';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

const MarketPreferenceViewChangelog = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { market_preference_id } = useParams<{
    market_preference_id: string;
  }>();
  const [filter, setFilter] = useState<{ market_preference_id: string }>({
    market_preference_id: market_preference_id!
  });
  const { setRouteName } = useRouteName();

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="market_preference_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="market_preferences"
      />
    </>
  );
};

export default MarketPreferenceViewChangelog;
