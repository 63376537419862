import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';

export function getInitialDueDiligenceFees(opportunity: OpportunityEntity) {
  return {
    opportunity_status_c: opportunity.opportunity_status_c,
    service_provider_id:
      opportunity.opportunity_service_provider?.service_provider_id || '',
    service_type: opportunity.opportunity_service_provider?.service_type || '',
    service_amount:
      opportunity.opportunity_service_provider?.service_amount ||
      opportunity.option_amount_c,
    service_date:
      opportunity.opportunity_service_provider?.service_date ||
      DateUtility.getTodayDateString(),
    dd_fee_payment_tracking_number:
      opportunity.dd_fee_payment_tracking_number || ''
  };
}
