import {
  CreateResponse,
  GetListResponse,
  GetResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  KBCategoryEntity,
  KBCategoriesListEntity,
  KBCategoryPayloadEntity
} from 'types/kb-category-types';
import envConfig from 'config/env';
import httpService from './http-service';

const kbCategoryService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/knowledge-base-categories`,

  getList: async (
    query: string
  ): Promise<GetListResponse<KBCategoriesListEntity>> => {
    const response = await httpService.list<KBCategoriesListEntity>(
      `${kbCategoryService.url}${query}`
    );
    return response;
  },

  get: async (id: string): Promise<GetResponse<KBCategoryEntity>> => {
    const response = await httpService.get<KBCategoryEntity>(
      `${kbCategoryService.url}/${id}`
    );
    return response;
  },

  create: async (data: KBCategoryPayloadEntity): Promise<CreateResponse<KBCategoryEntity>> => {
    const response = await httpService.post<KBCategoryEntity, KBCategoryPayloadEntity>(
      `${kbCategoryService.url}`,
      data
    );
    return response;
  },

  update: async (
    id: string,
    data: KBCategoryPayloadEntity
  ): Promise<UpdateResponse<KBCategoryEntity>> => {
    const response = await httpService.put<KBCategoryEntity, KBCategoryPayloadEntity>(
      `${kbCategoryService.url}/${id}`,
      data
    );
    return response;
  }
};

export default kbCategoryService;
