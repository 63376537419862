import ListAltIcon from '@mui/icons-material/ListAlt';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import fieldLabel from 'assets/constants/fieldLabel';
import { v4 as uuid } from 'uuid';

const taskList = {
  label: fieldLabel.list,
  url: () => `/tasks`,
  labelIcon: ListAltIcon,
  model: appModel.task,
  permission: appPermission.list,
  nodeId: uuid()
};

const taskMenuItems = {
  list: [taskList]
};

export default taskMenuItems;
