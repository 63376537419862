import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import buttonText from 'assets/constants/button';
import { column as columnOptions } from 'assets/constants/column-options';
import DASHBOARDS from 'assets/constants/dashboards';
import fieldLabel from 'assets/constants/fieldLabel';
import SecondaryButton from 'components/button/button-secondary';
import CircularLoader from 'components/dog-loader/dog-lodar';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import UnitSwitch from 'components/form/unit-switch';
import ModalComponent from 'components/modal';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import StackRow from 'components/stack/stack-row';
import eventBus from 'helpers/event-bus-helper';
import React, { useEffect, useState } from 'react';
import userPreferenceService from 'services/user-preference-service';
import { ColumnOptionsEntity } from 'types/column-options-type';
import { DispatchSetState } from 'types/common-types';
import { UserPreferenceEntityResponse } from 'types/user-preference-type';
import { v4 as uuid4 } from 'uuid';

const ColumnChooserComponent = ({
  title,
  dashboard,
  toggleModal,
  showModal,
  defaultColumns = [],
  setIsLoading,
  isLoading
}: {
  title: string;
  dashboard: string;
  toggleModal: DispatchSetState<boolean>;
  showModal: boolean;
  defaultColumns?: string[];
  setIsLoading: DispatchSetState<boolean>;
  isLoading: boolean;
}) => {
  defaultColumns = defaultColumns.concat([
    'action',
    'name',
    'property_address_c',
    'account',
    'market'
  ]);
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(defaultColumns);

  const isSelected = (name: string) => {
    return [...selectedColumns].includes(name);
  };

  const invertSelection = (name: string) => {
    if (!isSelected(name)) {
      const columns = [...selectedColumns, name];
      setSelectedColumns(columns);
    } else {
      const columns = [...selectedColumns].filter(
        (column: string) => column !== name
      );
      setSelectedColumns(columns);
    }
  };

  const saveColumns = async () => {
    const data = {
      category: dashboard,
      subcategory: DASHBOARDS.SELECTED_COLUMNS,
      contents: selectedColumns
    };

    const result: UserPreferenceEntityResponse<string[]> =
      await userPreferenceService.save(data);

    if (result.isSuccess) {
      eventBus.dispatch(`${dashboard}_refresh_columns`, {});
      toggleModal(false);
    }
  };

  const getTableColumnsToShow = async () => {
    setIsLoading(true);
    const response = await userPreferenceService.getItem<string[]>({
      category: dashboard,
      subcategory: DASHBOARDS.SELECTED_COLUMNS,
      module: fieldLabel.opportunities
    });

    setIsLoading(false);
    if (response.isSuccess && response.data) {
      setSelectedColumns(() => [...(response.data?.contents || [])]);
    }
  };

  useEffect(() => {
    if (!showModal) return;
    getTableColumnsToShow();
  }, [showModal]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxHeader
          value={
            <>
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                <ModalHeader title={title} onClose={() => toggleModal(false)} />
              </UnitItem>
            </>
          }
          sx={{ border: 'none' }}
        />

        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 35vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer spacing={1}>
            {isLoading ? (
              <CircularLoader />
            ) : (
              columnOptions.map((column: ColumnOptionsEntity) => (
                <UnitSwitch
                  key={uuid4()}
                  value={isSelected(column.name) ? 1 : 0}
                  onChange={() => {
                    invertSelection(column.name);
                  }}
                  name={column.name}
                  label={column.title}
                  disabled={column.disable || false}
                  isInlineLabel={true}
                  grid={{ xs: 6, sm: 4 }}
                />
              ))
            )}
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={() => saveColumns()} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

const ColumnChooser = ({
  title,
  dashboard,
  defaultColumns
}: {
  title: string;
  dashboard: string;
  defaultColumns?: string[];
}) => {
  const [showModal, toggleModal] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <React.Fragment>
      <SecondaryButton
        variant="contained"
        size="medium"
        name="btn-columns"
        onClick={() => toggleModal(!showModal)}
        startIcon={<ToggleOnIcon fontSize={'small'} />}
      >
        {buttonText.columnChooser}
      </SecondaryButton>

      {showModal && (
        <ModalComponent
          title={title}
          Component={ColumnChooserComponent}
          data={{
            title,
            dashboard,
            showModal,
            toggleModal,
            defaultColumns,
            setIsLoading,
            isLoading
          }}
          onClose={() => {
            toggleModal(false);
          }}
          loading={false}
          size="lg"
        />
      )}
    </React.Fragment>
  );
};

export default ColumnChooser;
