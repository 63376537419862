import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Switch,
  ListSubheader,
  Skeleton
} from '@mui/material';
import { Action, Permissions, RoleEntity } from 'types/role-types';
import { toastError, toastSuccess } from 'event/toast-event';
import rolePermissionService from 'services/role-permission-service';
import { RolePermissionLabels } from 'assets/constants/role-permission';

interface Props {
  role: RoleEntity;
}

const PermissionList: React.FC<Props> = ({ role }) => {
  const [permissions, setPermissions] = useState<Permissions>({});
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = role.id === '1';

  const getAssociatedPermissions = async () => {
    if (!role.id) return;
    setIsLoading(true);

    const result = await rolePermissionService.getAssociatedPermissionList(
      role.id
    );
    setIsLoading(false);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }
    if (result.isSuccess) {
      setPermissions(result.data);
    }
  };

  const handleTogglePermission = async (
    permissionId: string,
    module: string,
    action: Action
  ) => {
    const permission = permissions[module][action];
    const newHasRole = !permission.has_role;

    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [module]: {
        ...prevPermissions[module],
        [action]: {
          ...permission,
          has_role: newHasRole
        }
      }
    }));

    try {
      const response = await rolePermissionService.updatePermissionAssociation(
        role.id,
        permissionId
      );

      if (response.isError) {
        toastError('Error while updating role permission');
        throw new Error('Error while updating role permission');
      }

      if (response.isSuccess) {
        const roleName =
          RolePermissionLabels[module as keyof typeof RolePermissionLabels] ??
          module;

        const actionMsg = newHasRole ? 'been added' : 'been removed';

        const message = `${roleName} ${action} permission has ${actionMsg} successfully.`;

        toastSuccess(message);
      }
    } catch (error) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [module]: {
          ...prevPermissions[module],
          [action]: {
            ...permission,
            has_role: !newHasRole
          }
        }
      }));
      console.error('Error updating role permission:', error);
    }
  };

  useEffect(() => {
    if (role.id) {
      getAssociatedPermissions();
    }
  }, [role.id]);

  return (
    <>
      {role.id && (
        <>
          <ListSubheader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              color: 'black'
            }}
          >
            <Typography variant="body1" fontWeight={700}>
              Permissions for Role: {role.name}
            </Typography>
          </ListSubheader>
          <TableContainer sx={{ maxHeight: '78vh' }} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
                    Module
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>List</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Create</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Update</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Read</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    {Array.from(new Array(15)).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="80%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="24px"
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="24px"
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="24px"
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="24px"
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="24px"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  Object.entries(permissions).map(([module, permissionSet]) => (
                    <TableRow key={module}>
                      <TableCell sx={{ width: '25%' }}>
                        {RolePermissionLabels[
                          module as keyof typeof RolePermissionLabels
                        ] ?? module}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={permissionSet.list.has_role}
                          onChange={() =>
                            handleTogglePermission(
                              permissionSet.list.id,
                              module,
                              'list'
                            )
                          }
                          disabled={isAdmin}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={permissionSet.create.has_role}
                          onChange={() =>
                            handleTogglePermission(
                              permissionSet.create.id,
                              module,
                              'create'
                            )
                          }
                          disabled={isAdmin}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={permissionSet.edit.has_role}
                          onChange={() =>
                            handleTogglePermission(
                              permissionSet.edit.id,
                              module,
                              'edit'
                            )
                          }
                          disabled={isAdmin}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={permissionSet.read.has_role}
                          onChange={() =>
                            handleTogglePermission(
                              permissionSet.read.id,
                              module,
                              'read'
                            )
                          }
                          disabled={isAdmin}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={permissionSet.delete.has_role}
                          onChange={() =>
                            handleTogglePermission(
                              permissionSet.delete.id,
                              module,
                              'delete'
                            )
                          }
                          disabled={isAdmin}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default PermissionList;
