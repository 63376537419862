export const tintMcConfig = {
    branding: false,
    statusbar: false,
    height: 500,
    menubar: false,
    plugins: [
        "advlist",
        "autolink",
        "lists",
        "link",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "code",
    ],
    toolbar:
        "undo redo | blocks | fontselect | " +
        "bold italic underline forecolor  | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "strikethrough | blockquote | code | removeformat",
    content_style: `
            body { font-family:Helvetica,Arial,sans-serif; font-size:14px } 
            p {
              margin: 0;
              padding: 0;
            }
            `,
}
