import menuHelper from 'helpers/menu-helper';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import { SideMenuHelperType } from 'types/menu-types';

const sideManuHelper: SideMenuHelperType = {
  getMenuItems: async (routeName: string) => {
    if (routeName === 'inbox-emails')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    if (routeName === 'compose-emails')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    if (routeName === 'draft-emails')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    if (routeName === 'emails-view')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    if (routeName === 'emails-draft-view')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    if (routeName === 'emails-action')
      return await menuHelper.getItems(appModel.email, appPermission.list);

    return [];
  }
};

export default sideManuHelper;
