const initialProfile = {
  crm_user_type: '',
  email: '',
  employee_status: '',
  first_name: '',
  id: '',
  is_admin: 0,
  last_name: '',
  phone_mobile: '',
  phone_work: '',
  photo: '',
  status: 'Active',
  title: '',
  user_name: '',
  enable_brokerage_frontend: 0,
  allow_login_with_crm_cred: 0,
  allow_login_with_google: 0,
  password: ''
};

export default initialProfile;
