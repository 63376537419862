import ActionName from 'assets/constants/action-name';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import OpportunityTitle from 'features/opportunity-title';
import emptyFunction from 'helpers/empty-function-helper';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import opportunityService from 'services/opportunity-service';
import { initialOpportunity } from 'state/opportunity/initial-opportunity';
import { ActionPropTypes } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

import BrokerageActinHeader from '../brokerage-action-header';
import NegotiatorNotes from '../negotiator-notes';
import RecordView from './record-view';

const RejectOffer = ({
  routeTag,
  onClose = emptyFunction,
  isModal = false
}: ActionPropTypes) => {
  const { opportunity_id } = useParams<{
    opportunity_id: string;
  }>();

  const [opportunity, setOpportunity] =
    useState<OpportunityEntity>(initialOpportunity);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabId = 'reject-offer';
  const outletContext = useRouteName();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const getOpportunity = async () => {
    const result = await opportunityService.get(opportunity_id!);
    setLoading(false);
    setOpportunity(result.data);
  };

  const tabItems = () => {
    return {
      action: fieldLabel.actions,
      negotiator_notes: fieldLabel.negotiationNotes
    };
  };

  useEffect(() => {
    getOpportunity();

    if (!isEmpty(outletContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <PaperBox evelationValue={0} sx={{ bgcolor: '#eff1f6' }}>
        {isModal && (
          <BrokerageActinHeader
            loading={loading}
            title={
              <OpportunityTitle
                oppurtunity={opportunity}
                action_name={ActionName.reject_opportunity}
              />
            }
            onClose={onClose}
          />
        )}

        <PaperBoxContent sx={{ p: 0 }}>
          <FormContainer spacing={0}>
            <UnitItem grid={{ xs: 12, sm: 12 }}>
              <HorizontalTabs
                value={activeTab}
                handleChange={handleTabChange}
                tabItems={tabItems()}
                sx={{ ml: -1 }}
                tabId={tabId}
              />
            </UnitItem>

            <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
              <TabArea index={0} value={activeTab} border={false} tabId={tabId}>
                <RecordView
                  onClose={onClose}
                  opportunity={opportunity}
                  loadingOpportunity={loading}
                  isModal={isModal}
                />
              </TabArea>
              <TabArea index={1} value={activeTab} border={false} tabId={tabId}>
                <NegotiatorNotes
                  id={opportunity_id}
                  opportunity={opportunity}
                />
              </TabArea>
            </UnitItem>
          </FormContainer>
        </PaperBoxContent>
      </PaperBox>
    </React.Fragment>
  );
};

export default RejectOffer;
