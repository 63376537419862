import { MenuItemType } from 'types/menu-types';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContainerRight from 'components/container/right';
import Breadcrumbs from 'features/breadcrumbs';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import adminMenuItems from 'assets/menus/side-menu/admin';
import { getItem } from 'helpers/local-storage-helper';

const AdminPage = (): JSX.Element => {
  const user = JSON.parse(getItem('user') || '{}');

  const navigate = useNavigate();

  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const [routeName, setRouteName] = useState<string>('');

  const getMenus = async (): Promise<void> => {
    setSideNavMenuItems(adminMenuItems.list);
  };

  useEffect(() => {
    getMenus();
  }, []);

  if (!user.user.is_admin) {
    navigate(`/properties`);
    return <></>;
  }

  return (
    <>
      {user.user.is_admin && (
        <ContainerRight>
          <Outlet context={{ routeName, setRouteName }} />
        </ContainerRight>
      )}
    </>
  );
};

export default AdminPage;
