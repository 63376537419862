import fieldLabel from 'assets/constants/fieldLabel';
import marketPreference from 'assets/constants/market-preference';
import ClearButton from 'components/form/button-clear';
import SearchButton from 'components/form/button-search';
import FormContainer from 'components/form/container';
import UnitAccount from 'components/form/unit-account';
import UnitMsa from 'components/form/unit-msa';
import TextUnit from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRow from 'components/stack/stack-row';
import { useEffect, useState } from 'react';
import userPreferenceService from 'services/user-preference-service';
import initialMarketPreferenceListFilter from 'state/market-preference/initial-market-preference-list-filter';
import { ObjectType } from 'types';
import { UserPreferenceEntityResponse } from 'types/user-preference-type';
import { MarketPreferenceFilterEntity } from 'types/market-preference-types';
import { OptionType } from 'types/option-type';

interface FilterProps {
  filter: MarketPreferenceFilterEntity;
  updateFilter: (val: ObjectType) => void;
  setIsFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: (val: MarketPreferenceFilterEntity) => void;
}

const Filters = (props: FilterProps): JSX.Element => {
  const { filter, updateFilter, setIsFilterChanged, setFilter } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveFilters = async (value: MarketPreferenceFilterEntity) => {
    setFilter(value);
    setIsFilterChanged(true);

    setIsLoading(true);

    await userPreferenceService.save({
      category: marketPreference.marketPreferenceList,
      subcategory: marketPreference.filter,
      contents: value,
      module: marketPreference.module
    });

    setIsLoading(false);
  };

  const loadFilter = async () => {
    const response: UserPreferenceEntityResponse<MarketPreferenceFilterEntity> =
      await userPreferenceService.getItem<MarketPreferenceFilterEntity>({
        category: marketPreference.marketPreferenceList,
        subcategory: marketPreference.filter,
        module: marketPreference.module
      });

    if (response.isSuccess && response.data) {
      setFilter(response.data.contents);
    }
    setIsFilterChanged(true);
  };

  useEffect(() => {
    loadFilter();
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <TextUnit
            label={fieldLabel.name}
            name="name"
            value={filter.name ?? ''}
            onChange={updateFilter}
            grid={{ xs: 12, sm: 4 }}
          />

          <UnitAccount
            value={{
              label: filter.account_name ?? '',
              value: filter.account_id ?? ''
            }}
            multiple={false}
            onChange={(val: OptionType) => {
              updateFilter({
                account_id: val?.value ?? '',
                account_name: val?.label ?? ''
              });
            }}
            grid={{ xs: 12, sm: 4 }}
          />

          <UnitMsa
            value={{
              label: filter.msa_name ?? '',
              value: filter.msa_id ?? ''
            }}
            multiple={false}
            onChange={(val: OptionType) => {
              updateFilter({
                msa_id: val?.value ?? '',
                msa_name: val?.label ?? ''
              });
            }}
            grid={{ xs: 12, sm: 4 }}
          />

          <StackRow>
            <SearchButton
              onClick={() => {
                saveFilters(filter);
                setIsFilterChanged(true);
              }}
              disabled={isLoading}
            />
            <ClearButton
              onClick={() => {
                saveFilters(initialMarketPreferenceListFilter);
                setFilter(initialMarketPreferenceListFilter);
                setIsFilterChanged(true);
              }}
            />
          </StackRow>
        </FormContainer>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
