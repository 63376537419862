import ViewChangelog from 'features/view-changelog';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

const OpportunityBrokerageUserViewChangelog = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { opportunity_brokerage_user_id } = useParams<{
    opportunity_brokerage_user_id: string;
  }>();
  const [filter, setFilter] = useState<{
    opportunity_brokerage_user_id: string;
  }>({ opportunity_brokerage_user_id: opportunity_brokerage_user_id! });
  const { setRouteName } = useRouteName();

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="opportunity_brokerage_user_change_logs"
        filter={filter}
        setFilter={setFilter}
        fieldAction="opportunity_brokerage_users"
      />
    </>
  );
};

export default OpportunityBrokerageUserViewChangelog;
