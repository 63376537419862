import app from 'config/app';
import httpService from './http-service';
import { AccountBuyersNameListEntity } from 'types/account-buyer-name-types';
import { GetListResponse } from 'types/api-response-types';

const accountBuyerNameService = {
  url: `/${app.crmApiVersion1}/account_buyer_name`,

  getList: async (
    query: string
  ): Promise<GetListResponse<AccountBuyersNameListEntity>> => {
    const result = await httpService.list<AccountBuyersNameListEntity>(
      `${accountBuyerNameService.url}${query}`
    );
    return result;
  }
};

export default accountBuyerNameService;
