import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import fieldLabel from 'assets/constants/fieldLabel';
import Tooltip from 'components/tooltip';
import React, { useContext, useEffect, useState } from 'react';

import { CopyToClipboardContext } from './copy-to-clipboard-context';

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const { copyText, isCopied, setIsCopied } = useContext(
    CopyToClipboardContext
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyClick = () => {
    copyText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const resetIsCopied = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => {
        clearTimeout(resetIsCopied);
      };
    }
  }, [isCopied]);

  return (
    <Tooltip
      title={isCopied ? fieldLabel.copied : fieldLabel.copiedToCliboard}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
    >
      <IconButton
        onClick={handleCopyClick}
        onMouseEnter={() => setTooltipOpen(true)}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
