import eventBus from 'helpers/event-bus-helper';
import emailTemplateService from 'services/email-template-service';
import marketPreferenceService from 'services/market-preference-service';

const deleteEmailTemplate = async (email_template_id: string) => {
  const result = await emailTemplateService.delete(email_template_id);
  eventBus.dispatch(`email_template_refresh`, {});
  return result;
};

const removeAssociatedEmailTemplate = async (
  market_preference_id: string,
  email_template_id: string
): Promise<void> => {
  await marketPreferenceService.deleteEmailTemplateAssociation(
    market_preference_id,
    email_template_id
  );
  eventBus.dispatch(`email_template_refresh`, {});
};

const associateEmailTemplate = async (
  id: string,
  email_template_id: string
): Promise<void> => {
  await marketPreferenceService.associateEmailTemplate(id, {
    email_template_id: [email_template_id]
  });

  eventBus.dispatch('email_template_refresh', {});
};

export {
  removeAssociatedEmailTemplate,
  associateEmailTemplate,
  deleteEmailTemplate
};
