import { Box } from '@mui/material';
import { CloseModal } from 'types/common-types';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import ModalHeader from 'components/modal/modal-header';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import UnitItem from 'components/form/unit-item';
import Skeleton from '@mui/material/Skeleton';

const BrokerageActinHeader = ({
  title,
  onClose,
  loading
}: {
  title: ReactNode;
  onClose?: CloseModal;
  loading: boolean;
}) => {
  const { action } = useParams<{
    action: string;
  }>();

  return (
    <>
      {action ? (
        <PaperBoxHeader
          value={
            <>
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <ModalHeader title={title} onClose={onClose} />
                )}
              </UnitItem>
            </>
          }
        />
      ) : (
        <Box sx={{ mt: -1 }}></Box>
      )}
    </>
  );
};

export default BrokerageActinHeader;
