import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';

export const due_diligence_start_c = {
  validate: function (oppurtunity: any, status: string) {
    let errors: any = [];
    if (
      oppurtunity?.opportunity_status_c ===
        oppurtunityStatusList.closing_diligence_period &&
      isEmpty(oppurtunity?.due_diligence_start_c)
    ) {
      errors.push(missingField('Due Diligence Start Date'));
    }

    if (
      !isEmpty(oppurtunity?.due_diligence_start_c) &&
      !isEmpty(oppurtunity?.due_diligence_end_c) &&
      DateUtility.getDateUnix(oppurtunity?.due_diligence_start_c) >=
        DateUtility.getDateUnix(oppurtunity?.due_diligence_end_c)
    ) {
      errors.push(
        'Due Diligence Start Date and Time must be less than Due Diligence End Date and Time'
      );
    }

    return errors;
  }
};
