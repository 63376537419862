import { getAuthToken } from 'helpers/auth-helper';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContainerRight from 'components/container/right';
import ContentArea from 'components/content-area';
import envConfig from 'config/env';
import Iframe from 'react-iframe';
import useRouteName from 'pages/route-outlet-context';

const ReportView = ({ routeTag }: { routeTag: string }) => {
  const token = getAuthToken();
  const { report_id } = useParams<{ report_id: string }>();

  const { setRouteName } = useRouteName();

  const url = `${envConfig.REACT_APP_CRM_URL}/index.php?module=AOR_Reports&action=DetailView&record=${report_id}&source=${token}`;

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ContainerRight>
        <ContentArea>
          <Iframe
            url={url}
            width="100%"
            height="600px"
            id=""
            className=""
            display="block"
            position="relative"
          />
        </ContentArea>
      </ContainerRight>
    </>
  );
};

export default ReportView;
