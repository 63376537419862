import { ChangeEvent } from 'types/common-types';
import { KBCategoryEntity } from 'types/kb-category-types';
import { ObjectType } from 'types';
import { OptionType } from 'types/option-type';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitKBCategories from 'components/form/unit-kb-categories';
import UnitText from 'components/form/unit-text';

interface recordViewType {
  kbCategories: KBCategoryEntity;
  validation?: ObjectType;
  onChange?: (e: ChangeEvent | ObjectType) => void;
  readOnly?: boolean;
}

const RecordView = ({
  kbCategories,
  validation,
  onChange,
  readOnly = false
}: recordViewType) => {
  const handleChange = onChange ?? emptyFunction;
  const valMessages = validation ?? {};

  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.name}
        name="name"
        value={kbCategories.name ?? ''}
        onChange={handleChange}
        error={valMessages['name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitKBCategories
        value={{
          label: kbCategories.parent_knowledge_base_category?.name,
          value: kbCategories.parent_knowledge_base_category?.id
        }}
        label={fieldLabel.parentKnowledgeBaseCategory}
        readOnly={readOnly}
        onChange={(value: OptionType | ObjectType) => {
          handleChange({
            target: {
              name: 'parent_knowledge_base_category',
              value: {
                name: value?.label ?? '',
                id: value?.value ?? ''
              }
            }
          });
        }}
      />

      <UnitText
        label={fieldLabel.description}
        name="description"
        value={kbCategories.description ?? ''}
        onChange={handleChange}
        error={valMessages['description'] ?? ''}
        readOnly={readOnly}
        multiline
        rows={8}
      />
    </FormContainer>
  );
};

export default RecordView;
