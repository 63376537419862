import { MsaEntity, MsaListEntity } from 'types/msa-types';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';
import app from 'config/app';

const msaService = {
  url: `/${app.crmApiVersion1}/msa`,

  getList: async (query: string): Promise<GetListResponse<MsaListEntity>> => {
    const result = await httpService.list<MsaListEntity>(
      `${msaService.url}${query}`
    );
    return result;
  },

  get: async (id: string): Promise<GetResponse<MsaEntity>> => {
    const result = await httpService.get<MsaEntity>(`${msaService.url}/${id}`);
    return result;
  },

  create: async (payload: MsaEntity): Promise<CreateResponse<MsaEntity>> => {
    const result = await httpService.post<MsaEntity>(
      `${msaService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: MsaEntity
  ): Promise<UpdateResponse<MsaEntity>> => {
    const result = await httpService.put(`${msaService.url}/${id}`, payload);
    return result;
  }
};

export default msaService;
