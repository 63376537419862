import ContainerRight from 'components/container/right';
import Breadcrumbs from 'features/breadcrumbs';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

const ProfilePage = () => {
  const [routeName, setRouteName] = useState('');
  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const getMenus = async () => {
    setSideNavMenuItems([]);
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <>
      <Breadcrumbs />
      <ContainerRight>
        <Outlet context={{ routeName, setRouteName }} />
      </ContainerRight>
    </>
  );
};

export default ProfilePage;
