import { convertNumber, isEmpty } from 'helpers/misc-helper';
import { counterOfferService } from 'services/brokerage-acitons/counter-offer-service';
import { due_diligence_end_c } from 'services/validation/due_diligence_end_c';
import { prepareRequestBody } from './helper';
import { useNavigate } from 'react-router-dom';
import buyerCounterSelectStatus from 'assets/constants/buyer-counter-select-status';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import InfoMessage from 'components/errors/info-component';
import leaseType from 'assets/constants/lease-type';
import moment from 'moment-timezone';
import opportunityHelper from 'helpers/opportunity-helper';
import optionDaysType from 'assets/constants/option-days-type';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import React, { useEffect, useState } from 'react';
import SaveButton from 'components/form/button-save';
import solarPanelLeaseOwned from 'assets/constants/solar-panel-lease-owned';
import StackRow from 'components/stack/stack-row';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitHeading from 'components/form/unit-heading';
import UnitItem from 'components/form/unit-item';
import UnitLabel from 'components/form/unit-label';
import UnitPercentageFormatter from 'components/form/unit-percentage-formatter';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import variableConfig from 'config/variable';
import {
  ActionRecordViewPropTypes,
  BuyerCounterOfferConfimationEntity
} from 'types/brokerage-action-types';
import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { refreshOpportunity } from 'event/opportunity-event';
import { toastError } from 'event/toast-event';
import { initialBuyerCountOfferConfirmation } from 'state/brokerage-actions/buyer-counter-offer-confirmation';

const RecordView = ({
  opportunity,
  onClose = emptyFunction,
  isModal = false,
  loadingOpportunity
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BuyerCounterOfferConfimationEntity>(
    initialBuyerCountOfferConfirmation
  );

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = () => {
    const { isValid, errors } = validateBrokerageAction(data, [
      'counter_offer_price_c',
      'counter_offer'
    ]);

    setValidation(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody = prepareRequestBody(data, opportunity);

    setIsLoading(true);

    const result = await counterOfferService.buyerConfirmation(
      opportunity.id,
      requestBody
    );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.buyer_counter_offer();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity();
      }
    }
  };

  const loadCounterOfferData = async () => {
    const response = await counterOfferService.get(
      opportunity.opportunity_counteroffer_id
    );

    if (response.isSuccess) {
      setData(response.data);
    }
  };

  const closeAction = () => {
    toastError(
      fieldLabel.youAreAttemptingCounterOfferConfirmationBeforeReceipt
    );

    if (isModal) {
      onClose();
    } else {
      window.history.back();
    }
  };

  const getValidDate = (date: string, field: string, label: string) => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        label,
        field === 'close_date_c'
          ? true
          : opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
              data?.option_days_type_c
            )
      );

    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}_federal_holiday_validation_message`]: message
    }));

    return DateUtility.getFormattedDateString(validDate);
  };

  useEffect(() => {
    if (isEmpty(opportunity?.contract_execution_date_c)) {
      return;
    }

    const newDueDiligenceEndValue = due_diligence_end_c.calculate(
      {
        ...data,
        contract_execution_date_c: opportunity?.contract_execution_date_c
      },
      {}
    );

    const result = getValidDate(
      newDueDiligenceEndValue,
      'due_diligence_end_c',
      fieldLabel.dueDiligenceEndDate
    );

    setData((prevData: BuyerCounterOfferConfimationEntity) => ({
      ...prevData,
      due_diligence_end_c: DateUtility.addHours(
        moment(result),
        variableConfig.DUE_DILIGENCE_END_START_TIME
      )
    }));
  }, [data?.option_days_type_c, data?.option_period_days_c]);

  useEffect(() => {
    if (loadingOpportunity) return;

    if (!isEmpty(opportunity?.opportunity_counteroffer_id)) {
      loadCounterOfferData();
    } else {
      closeAction();
    }
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitPriceFormatter
              name="initial_offer_price"
              label={fieldLabel.initialOfferPrice}
              value={convertNumber(opportunity?.initial_offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitPriceFormatter
              name="offer_price_c"
              label={fieldLabel.offerPrice}
              value={convertNumber(opportunity?.offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />

            <UnitPriceFormatter
              name="max_offer_price_c"
              label={fieldLabel.maxOfferPrice}
              value={convertNumber(opportunity?.max_offer_price_c)}
              grid={{ xs: 12, sm: 2 }}
              onChange={emptyFunction}
              readOnly={true}
              prefix="$"
            />
          </FormContainer>

          <StackRowWithDivider />

          <FormContainer>
            <UnitHeading title="Price" />
            <UnitItem>
              <FormContainer>
                <UnitLabel
                  label="Counter Offer Price:"
                  grid={{ xs: 8, sm: 8 }}
                />

                <UnitSelect
                  name="counter_offer_price_status"
                  records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                  value={data.counter_offer_price_status ?? ''}
                  onChange={handleChange}
                  grid={{ xs: 4, sm: 4 }}
                />
              </FormContainer>

              <UnitPriceFormatter
                name="counter_offer_price_c"
                value={data.counter_offer_price_c}
                onChange={handleChange}
                grid={{ xs: 12, sm: 12 }}
                error={validation['counter_offer_price_c'] ?? ''}
                label=""
              />
            </UnitItem>

            <UnitItem>
              <FormContainer>
                <UnitLabel
                  label="Actual Counter Content:"
                  grid={{ xs: 8, sm: 8 }}
                />

                <UnitSelect
                  name="counter_offer_status"
                  value={data.counter_offer_status}
                  onChange={handleChange}
                  records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                  grid={{ xs: 4, sm: 4 }}
                />
              </FormContainer>

              <UnitText
                name="counter_offer"
                value={data.counter_offer}
                onChange={handleChange}
                grid={{ xs: 12, sm: 12 }}
                error={validation['counter_offer'] ?? ''}
                multiline
                rows={3}
                label=""
              />
            </UnitItem>
          </FormContainer>

          <FormContainer>
            <UnitHeading title="Buyer Counter Offer" />
            <UnitDate
              label={fieldLabel.buyerCounterofferReceiptDate}
              name="receipt_date"
              value={data.receipt_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'receipt_date', value: e }
                })
              }
              error={validation['receipt_date'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.buyerCounterofferReceivedFrom}
              name="received_from"
              value={data.received_from}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitPriceFormatter
              label={fieldLabel.buyerCounterofferResponseAmount}
              name="response_amount"
              value={data.response_amount}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.counterofferResponseTerms}
              name="response_terms"
              value={data.response_terms}
              onChange={handleChange}
              multiline
              rows={3}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.counterofferNotes}
              name="notes"
              value={data.notes}
              onChange={handleChange}
              multiline
              rows={3}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.buyerNotes}
              name="buyer_notes"
              value={data.buyer_notes}
              onChange={handleChange}
              multiline
              rows={3}
              grid={{ xs: 12, sm: 4 }}
            />
          </FormContainer>

          {data?.selected_categories &&
          data?.selected_categories.includes('diligence_period') ? (
            <FormContainer>
              <UnitHeading title="Dilligence Period" />

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel label="Option Period:" grid={{ xs: 8, sm: 6 }} />

                  <UnitSelect
                    name="option_period_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.option_period_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['option_period_status'] ?? ''}
                  />
                </FormContainer>

                <UnitText
                  name="option_period_days_c"
                  value={data.option_period_days_c}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel
                    label="Option Days Type:"
                    grid={{ xs: 8, sm: 6 }}
                  />

                  <UnitSelect
                    name="option_days_type_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.option_days_type_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['option_days_type_status'] ?? ''}
                  />
                </FormContainer>

                <UnitSelect
                  name="option_days_type_c"
                  records={getObjectEntriesAsArray(optionDaysType)}
                  value={data.option_days_type_c ?? ''}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  error={validation['option_days_type_c'] ?? ''}
                />
              </UnitItem>
              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <UnitDateTime
                  label={fieldLabel.dueDiligenceEndDate}
                  name="due_diligence_end_c"
                  value={data.due_diligence_end_c || ''}
                  onChange={emptyFunction}
                  error={validation['due_diligence_end_c'] ?? ''}
                  grid={{ sm: 12, xs: 12 }}
                  disabled
                />
                {!isEmpty(
                  validation?.due_diligence_end_c_federal_holiday_validation_message
                ) && (
                  <InfoMessage
                    message={
                      validation?.due_diligence_end_c_federal_holiday_validation_message ??
                      ''
                    }
                  />
                )}
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel label="Option Amount:" grid={{ xs: 8, sm: 6 }} />

                  <UnitSelect
                    name="option_amount_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.option_amount_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['option_amount_status'] ?? ''}
                  />
                </FormContainer>

                <UnitPriceFormatter
                  name="option_amount_c"
                  value={data.option_amount_c}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel label="Commissions:" grid={{ xs: 8, sm: 6 }} />

                  <UnitSelect
                    name="commission_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.commission_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['commission_status'] ?? ''}
                  />
                </FormContainer>

                <UnitPercentageFormatter
                  name="initial_commission_percentage"
                  value={data.initial_commission_percentage}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel
                    label="Due Diligence Fees:"
                    grid={{ xs: 8, sm: 6 }}
                  />

                  <UnitSelect
                    name="due_diligence_fee_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.due_diligence_fee_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['due_diligence_fee_status'] ?? ''}
                  />
                </FormContainer>

                <UnitPriceFormatter
                  name="due_diligence_fee"
                  value={data.due_diligence_fee}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }}>
                <FormContainer>
                  <UnitLabel label="Preferred Title:" grid={{ xs: 8, sm: 6 }} />

                  <UnitSelect
                    name="preferred_title_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.preferred_title_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['preferred_title_status'] ?? ''}
                  />
                </FormContainer>

                <UnitText
                  name="preferred_title"
                  value={data.preferred_title}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>
            </FormContainer>
          ) : (
            <FormContainer>
              <UnitItem grid={{ xs: 12, sm: 4 }} sx={{ marginTop: 1 }}>
                <FormContainer>
                  <UnitLabel
                    label="Due Diligence Fees:"
                    grid={{ xs: 8, sm: 6 }}
                  />

                  <UnitSelect
                    name="due_diligence_fee_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.due_diligence_fee_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['due_diligence_fee_status'] ?? ''}
                  />
                </FormContainer>

                <UnitPriceFormatter
                  name="due_diligence_fee"
                  value={data.due_diligence_fee}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>

              <UnitItem grid={{ xs: 12, sm: 4 }} sx={{ marginTop: 1 }}>
                <FormContainer>
                  <UnitLabel label="Preferred Title:" grid={{ xs: 8, sm: 6 }} />

                  <UnitSelect
                    name="preferred_title_status"
                    records={getObjectEntriesAsArray(buyerCounterSelectStatus)}
                    value={data.preferred_title_status ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 4, sm: 6 }}
                    error={validation['preferred_title_status'] ?? ''}
                  />
                </FormContainer>

                <UnitText
                  name="preferred_title"
                  value={data.preferred_title}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 12 }}
                  label=""
                />
              </UnitItem>
            </FormContainer>
          )}

          {data?.selected_categories &&
            data?.selected_categories.includes('exclusions_inclusions') && (
              <FormContainer>
                <UnitHeading title="Exclusions/Inclusions" />

                <UnitItem>
                  <FormContainer>
                    <UnitLabel label="Exclusion:" grid={{ xs: 8, sm: 8 }} />

                    <UnitSelect
                      name="exclusion_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.exclusion_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 4 }}
                      error={validation['exclusion_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitText
                    name="exclusion"
                    value={data.exclusion}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    multiline
                    rows={3}
                    label=""
                  />
                </UnitItem>

                <UnitItem>
                  <FormContainer>
                    <UnitLabel label="Inclusion:" grid={{ xs: 8, sm: 8 }} />

                    <UnitSelect
                      name="inclusion_status"
                      value={data.inclusion_status ?? ''}
                      onChange={handleChange}
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      grid={{ xs: 4, sm: 4 }}
                      error={validation['inclusion_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitText
                    name="inclusion"
                    value={data.inclusion}
                    onChange={handleChange}
                    multiline
                    rows={3}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                  />
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('earnest_money') && (
              <FormContainer>
                <UnitHeading title="Earnest Money" />

                <UnitItem>
                  <FormContainer>
                    <UnitLabel
                      label="Earnest Amount:"
                      grid={{ xs: 8, sm: 8 }}
                    />

                    <UnitSelect
                      name="earnest_amount_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.earnest_amount_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 4 }}
                      error={validation['earnest_amount_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitPriceFormatter
                    name="earnest_amount_c"
                    value={data.earnest_amount_c}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                  />
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('close_date') && (
              <FormContainer>
                <UnitHeading title="Close Date" />

                <UnitItem>
                  <FormContainer>
                    <UnitLabel label="Close Date:" grid={{ xs: 8, sm: 8 }} />

                    <UnitSelect
                      name="close_date_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.close_date_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 4 }}
                      error={validation['close_date_status'] ?? ''}
                    />
                  </FormContainer>
                  <UnitItem grid={{ xs: 12, sm: 12 }}>
                    <UnitDate
                      label=""
                      name="close_date_c"
                      value={data.close_date_c ?? ''}
                      onChange={(value: string) => {
                        setData((prevData) => ({
                          ...prevData,
                          close_date_c: getValidDate(
                            value,
                            'close_date_c',
                            fieldLabel.closeDate
                          )
                        }));
                      }}
                      error={validation['close_date_c'] ?? ''}
                      grid={{ xs: 12, sm: 12 }}
                    />
                    {!isEmpty(
                      validation?.close_date_c_federal_holiday_validation_message
                    ) && (
                      <InfoMessage
                        message={
                          validation?.close_date_c_federal_holiday_validation_message ??
                          ''
                        }
                      />
                    )}
                  </UnitItem>
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('post_occupancy') && (
              <FormContainer>
                <UnitHeading title="Post Occupancy" />

                <UnitItem grid={{ xs: 12, sm: 3 }}>
                  <FormContainer>
                    <UnitLabel
                      label="Lease End Date:"
                      grid={{ xs: 8, sm: 6 }}
                    />

                    <UnitSelect
                      name="lease_end_date_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.lease_end_date_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['lease_end_date_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitDate
                    label=""
                    name="lease_end_date"
                    value={data.lease_end_date ?? ''}
                    onChange={(e: string) =>
                      handleChange({
                        target: { name: 'lease_end_date', value: e }
                      })
                    }
                    error={validation['lease_end_date'] ?? ''}
                    grid={{ xs: 12, sm: 12 }}
                  />
                </UnitItem>

                <UnitItem grid={{ xs: 12, sm: 3 }}>
                  <FormContainer>
                    <UnitLabel
                      label="Deposit Amount:"
                      grid={{ xs: 8, sm: 6 }}
                    />

                    <UnitSelect
                      name="deposit_amount_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.deposit_amount_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['deposit_amount_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitPriceFormatter
                    name="deposit_amount"
                    value={data.deposit_amount}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                  />
                </UnitItem>

                <UnitItem grid={{ xs: 12, sm: 3 }}>
                  <FormContainer>
                    <UnitLabel label="Rental Amount:" grid={{ xs: 8, sm: 6 }} />

                    <UnitSelect
                      name="rental_amount_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.rental_amount_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['rental_amount_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitPriceFormatter
                    name="rental_amount"
                    value={data.rental_amount}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                  />
                </UnitItem>

                <UnitItem grid={{ xs: 12, sm: 3 }}>
                  <FormContainer>
                    <UnitLabel label="Lease Type:" grid={{ xs: 8, sm: 6 }} />

                    <UnitSelect
                      name="lease_type_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.lease_type_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['lease_type_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitSelect
                    name="lease_type"
                    records={getObjectEntriesAsArray(leaseType)}
                    value={data.lease_type ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    error={validation['lease_type'] ?? ''}
                  />
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('special_provisions') && (
              <FormContainer>
                <UnitHeading title="Special Provisions" />

                <UnitItem grid={{ xs: 12, sm: 12 }}>
                  <FormContainer>
                    <UnitLabel
                      label="Terms/Comments:"
                      grid={{ xs: 8, sm: 8 }}
                    />

                    <UnitSelect
                      name="additional_terms_comment_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.additional_terms_comment_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 4 }}
                      error={
                        validation['additional_terms_comment_status'] ?? ''
                      }
                    />
                  </FormContainer>

                  <UnitText
                    name="additional_terms_comments_c"
                    value={data.additional_terms_comments_c}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                    multiline
                    rows={5}
                  />
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('solar_panels') && (
              <FormContainer>
                <UnitHeading title="Solar Panels" />

                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <FormContainer>
                    <UnitLabel label="Leased Owned:" grid={{ xs: 8, sm: 6 }} />

                    <UnitSelect
                      name="leased_or_owned_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.leased_or_owned_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['leased_or_owned_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitSelect
                    name="solar_panel_leased_or_owned"
                    records={getObjectEntriesAsArray(solarPanelLeaseOwned)}
                    value={data.solar_panel_leased_or_owned ?? ''}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    error={validation['solar_panel_leased_or_owned'] ?? ''}
                  />
                </UnitItem>

                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <FormContainer>
                    <UnitLabel label="Payoff Amount:" grid={{ xs: 8, sm: 6 }} />

                    <UnitSelect
                      name="payoff_amount_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.payoff_amount_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['payoff_amount_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitPriceFormatter
                    name="solar_panel_payoff_amount"
                    value={data.solar_panel_payoff_amount}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                  />
                </UnitItem>

                <UnitItem grid={{ xs: 12, sm: 4 }}>
                  <FormContainer>
                    <UnitLabel
                      label="Lease Date Expiration:"
                      grid={{ xs: 8, sm: 6 }}
                    />

                    <UnitSelect
                      name="lease_date_expiration_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.lease_date_expiration_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 6 }}
                      error={validation['lease_date_expiration_status'] ?? ''}
                    />
                  </FormContainer>

                  <UnitDate
                    label=""
                    name="solar_panel_lease_date_expiration"
                    value={data.solar_panel_lease_date_expiration ?? ''}
                    onChange={(e: string) =>
                      handleChange({
                        target: {
                          name: 'solar_panel_lease_date_expiration',
                          value: e
                        }
                      })
                    }
                    error={
                      validation['solar_panel_lease_date_expiration'] ?? ''
                    }
                    grid={{ xs: 12, sm: 12 }}
                  />
                </UnitItem>
              </FormContainer>
            )}

          {data?.selected_categories &&
            data?.selected_categories.includes('other') && (
              <FormContainer>
                <UnitHeading title="Other Counter Offer Terms" />

                <UnitItem>
                  <FormContainer>
                    <UnitLabel
                      label="Other Counter Offer Terms:"
                      grid={{ xs: 8, sm: 8 }}
                    />

                    <UnitSelect
                      name="other_counter_offer_terms_status"
                      records={getObjectEntriesAsArray(
                        buyerCounterSelectStatus
                      )}
                      value={data.other_counter_offer_terms_status ?? ''}
                      onChange={handleChange}
                      grid={{ xs: 4, sm: 4 }}
                      error={
                        validation['other_counter_offer_terms_status'] ?? ''
                      }
                    />
                  </FormContainer>

                  <UnitText
                    name="other_counter_offer_terms"
                    value={data.other_counter_offer_terms}
                    onChange={handleChange}
                    grid={{ xs: 12, sm: 12 }}
                    label=""
                    multiline
                    rows={5}
                  />
                </UnitItem>
              </FormContainer>
            )}
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
