import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import {
  Card,
  CardContent,
  CardHeader,
  FormLabel,
  IconButton
} from '@mui/material';
import { EditContainer } from '../record-view/container';
import { InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { OpportunityServiceProviderEntity } from 'types/opportunity-service-provider-types';
import { OptionType } from 'types/option-type';
import { toastSuccess } from 'event/toast-event';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddVendor from 'features/brokerage-actions/due-diligence-fees/add-vendor';
import CancelButton from 'components/form/button-cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import ModalComponent from 'components/modal';
import opportunityServiceProvidersService from 'services/opportunity-service-providers';
import React, { useEffect, useState } from 'react';
import SaveButton from 'components/form/button-save';
import serviceProviderService from 'services/service-provider-service';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitButtonIcon from 'components/form/button-icon';
import UnitDate from 'components/form/unit-date';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import { GetResponse } from 'types/api-response-types';
import expenseTypes from 'assets/constants/expense-types';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import { ServiceProviderListEntity } from 'types/service-provider-types';

interface ExpenseProps {
  data: OpportunityServiceProviderEntity;
  opportunity: ObjectType;
  vendors: OptionType[];
  index: number;
  updateOpportunity: (value: ObjectType) => void;
  toggleVendorModal: () => void;
}

const ExpenseForm = ({
  data,
  opportunity,
  updateOpportunity,
  vendors,
  toggleVendorModal,
  index
}: ExpenseProps) => {
  const [editMode, setEditMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const serviceTypeOptions = [
    ...getObjectEntriesAsArray(expenseTypes, data?.service_type)
  ];

  const [isDataChanged, setIsDataChanged] = useState(false);

  const [expense, setExpense] = useState<OpportunityServiceProviderEntity>({
    ...data
  });

  const handleChange = (e: InputChangeEvent) => {
    setExpense(
      Object.assign({}, expense, {
        [e.target.name]: e.target.value
      })
    );
    setIsDataChanged(true);
  };

  const save = async () => {
    let requestBody = {
      name: opportunity.name,
      opportunity_id: opportunity.id,
      service_provider_id: expense.service_provider_id,
      service_amount: expense.service_amount,
      service_type: expense.service_type,
      service_date: expense.service_date
    };

    let response: GetResponse<OpportunityServiceProviderEntity>;

    if (!isEmpty(expense.id)) {
      response = await opportunityServiceProvidersService.update(
        expense.id,
        requestBody
      );
    } else {
      response = await opportunityServiceProvidersService.create(requestBody);
    }

    if (response.isSuccess) {
      toastSuccess(`${fieldLabel.expense} Saved`);
      updateOpportunity({
        expense: [...opportunity.expense].map(
          (x: OpportunityServiceProviderEntity, i: number) =>
            i === index ? response.data : x
        )
      });
      setExpense(response.data);
      setEditMode(!editMode);
    }

    setEditMode(!editMode);
    setIsDataChanged(false);
  };

  const toggleConfirmBox = () => {
    setConfirmModal({
      open: true,
      title: fieldLabel.areYouSure,
      text: fieldLabel.changesWillDiscarded,
      proceed: () => {
        setEditMode(!editMode);
        setConfirmModal(initialConfirmModalState);
        setExpense(data);
        updateOpportunity({
          expense: [...opportunity.expense].map(
            (x: OpportunityServiceProviderEntity, i: number) =>
              i === index ? data : x
          )
        });
        setIsDataChanged(false);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (isDataChanged) {
        toggleConfirmBox();
      } else {
        setEditMode(false);
        setIsDataChanged(false);
      }
    } else {
      setEditMode(true);
      setIsDataChanged(false);
    }
  };

  return (
    <React.Fragment>
      <FormLabel>
        {fieldLabel.expense} {index + 1}
      </FormLabel>

      <UnitButtonIcon
        Icon={
          editMode ? CloseIcon : !isEmpty(expense.id) ? EditIcon : AddCircleIcon
        }
        onClick={() => {
          toggleEditMode();
        }}
      />
      <EditContainer>
        <FormContainer>
          <UnitSelect
            name="service_provider_id"
            label={fieldLabel.vendor}
            value={expense.service_provider_id}
            onChange={handleChange}
            records={vendors}
            readOnly={!editMode}
            grid={{ sm: 5, xs: 5 }}
          />

          <UnitItem grid={{ sm: 1, xs: 1 }}>
            {editMode ? (
              <IconButton
                aria-label="add"
                color="success"
                onClick={() => toggleVendorModal()}
              >
                <AddCircleIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </UnitItem>

          <UnitSelect
            name="service_type"
            label={fieldLabel.type}
            records={serviceTypeOptions}
            value={expense.service_type}
            onChange={handleChange}
            readOnly={!editMode}
          />

          <UnitPriceFormatter
            label={fieldLabel.amount}
            name="service_amount"
            onChange={handleChange}
            value={expense.service_amount}
            readOnly={!editMode}
          />

          <UnitDate
            label={fieldLabel.date}
            name="service_date"
            value={expense.service_date ?? ''}
            onChange={(date: string) => {
              handleChange({
                target: { name: 'service_date', value: date }
              });
            }}
            readOnly={!editMode}
          />

          {editMode ? (
            <UnitItem grid={{ sm: 6, xs: 12 }}>
              <StackRowWithDivider>
                <SaveButton onClick={save} />
                <CancelButton onClick={() => toggleEditMode()} />
              </StackRowWithDivider>
            </UnitItem>
          ) : (
            <></>
          )}
        </FormContainer>
      </EditContainer>

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </React.Fragment>
  );
};

export const Expense = ({
  opportunity,
  updateOpportunity
}: {
  opportunity: ObjectType;
  updateOpportunity: (value: ObjectType) => void;
}) => {
  const [vendorForm, showVendorForm] = useState<boolean>(false);
  const [vendors, setVendors] = useState<OptionType[]>([]);

  const toggleVendorModal = () => {
    showVendorForm(!vendorForm);
  };

  const loadVendors = async () => {
    const queryString = listQueryString({
      pagination: {
        page: 0,
        pageSize: 500
      },
      sort: prepareSort([{ field: 'name', sort: 'asc' }], {}),
      filter: {
        type: 'expense'
      }
    });

    let result = await serviceProviderService.getList(queryString);

    if (result.isSuccess) {
      let vendorsOptions = result.data.data.map(
        (x: ServiceProviderListEntity) => ({
          value: x.id,
          label: x.name
        })
      );

      setVendors(vendorsOptions);
    }
  };

  useEffect(() => {
    loadVendors();
  }, []);

  return (
    <React.Fragment>
      <Card variant="outlined">
        <CardHeader title={fieldLabel.expense} />
        <CardContent>
          <ExpenseForm
            data={opportunity.expense?.[0]}
            opportunity={opportunity}
            vendors={vendors}
            toggleVendorModal={toggleVendorModal}
            updateOpportunity={updateOpportunity}
            index={0}
          />
          <ExpenseForm
            data={opportunity.expense?.[1]}
            opportunity={opportunity}
            vendors={vendors}
            toggleVendorModal={toggleVendorModal}
            updateOpportunity={updateOpportunity}
            index={1}
          />
        </CardContent>
        {vendorForm && (
          <ModalComponent
            title={fieldLabel.createNewServiceProvider}
            Component={AddVendor}
            data={{
              type: 'expense',
              reloadVendor: () => {
                loadVendors();
                showVendorForm(false);
              }
            }}
            onClose={() => {
              showVendorForm(false);
            }}
            size="sm"
            isServiceCreateBox={true}
          ></ModalComponent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Expense;
