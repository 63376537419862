import { OpportunityEntity } from 'types/opportunity-types';

export function getInitialTerminationApproval(opportunity: OpportunityEntity) {
  return {
    opportunity_status_c: 'closing_termination_pending',
    earnest_amount_c: opportunity?.earnest_amount_c || '',
    earnest_recovered_by_buyer: opportunity?.earnest_recovered_by_buyer || '',
    earnest_recovery_amount: opportunity?.earnest_recovery_amount || '',
    earnest_paid_by_entera: opportunity?.earnest_paid_by_entera || '',
    earnest_paid_by_entera_amount:
      opportunity?.earnest_paid_by_entera_amount || ''
  };
}
