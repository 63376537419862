import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';

export const initialAcceptOffer = {
  opportunity_status_c: oppurtunityStatusList.offer_accepted,
  contract_received_date: '',
  contract_execution_date_c: '',
  offer_date_c: '',
  tax_id_c: '',
  new_construction_no_tax_id: 0,
  seller_name_c: '',
  has_post_occupancy: '',
  lease_type: '',
  lease_agreement_received: 0,
  occupancy_status_c: '',
  initial_commission_percentage: 0,
  due_diligence_end_c: ''
};
