import UnitItem from 'components/form/unit-item';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect } from 'react';
import DocumentsUploadRevision from './revision';
import { OpportunityEntity } from 'types/opportunity-types';
import { DispatchSetState } from 'types/common-types';

const UploadDocuments = ({
  routeTag,
  opportunity_id,
  action,
  onClose,
  setActiveTab,
  opportunity
}: {
  routeTag?: string;
  opportunity_id?: string;
  action?: string;
  onClose?: () => void;
  setActiveTab?: DispatchSetState<number>;
  opportunity?: OpportunityEntity;
}) => {
  const outletContext = useRouteName();

  useEffect(() => {
    if (!isEmpty(useContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <PaperBox>
      {action ? (
        <PaperBoxHeader
          sx={{ pb: 3 }}
          value={
            <>
              <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                <ModalHeader title="" onClose={onClose} />
              </UnitItem>
            </>
          }
        />
      ) : (
        <></>
      )}

      <PaperBoxContent>
        <DocumentsUploadRevision
          routeTag={routeTag || ''}
          opportunityId={opportunity_id || ''}
          action={action || ''}
          onClose={onClose}
          setActiveTab={setActiveTab}
          opportunity={opportunity}
        />
      </PaperBoxContent>
    </PaperBox>
  );
};

export default UploadDocuments;
