import { offer_blocked_resolved_by } from './offer-blocked-resolved-by';
import { is_offer_blocked_resolved_at } from './is-offer-blocked-resolved-at';
import { ObjectType } from 'types';

export const is_offer_blocked_resolved = {
  handleChange: (opportunity: ObjectType, originalOpportunity: ObjectType) => {
    const result = {
      offer_blocked_resolved_by: offer_blocked_resolved_by.calculate(
        opportunity,
        originalOpportunity
      ).id,
      offer_blocked_resolved_user_name: offer_blocked_resolved_by.calculate(
        opportunity,
        originalOpportunity
      ).name,
      is_offer_blocked_resolved_at: is_offer_blocked_resolved_at.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
