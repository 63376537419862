import { AsyncSelect } from 'components/async-select/async-select';
import { FormControl, Grid } from '@mui/material';
import { KBCategoriesListEntity } from 'types/kb-category-types';
import { ObjectType } from 'types';
import { OptionType } from 'types/option-type';
import FieldLabel from './field-label';
import kbCategoryService from 'services/kb-category-service';
import React, { ChangeEvent, useState } from 'react';
import ValidationError from 'components/errors/validation-error';

interface KBCategoriesUnitInterface {
  grid?: {
    xs: number;
    sm: number;
  };
  label: string;
  value: OptionType;
  fullWidth?: boolean;
  onChange: (e: ChangeEvent | ObjectType) => void;
  error?: [];
  multiple?: boolean;
  readOnly?: boolean;
}

const UnitKBCategories = ({
  value,
  label,
  onChange,
  grid = { xs: 12, sm: 6 },
  fullWidth = true,
  error = [],
  readOnly = false,
  multiple
}: KBCategoriesUnitInterface) => {
  const [options, setOptions] = useState<OptionType[]>([]);

  const onChangeHandle = async (value: string) => {
    if (value && value.length > 2) {
      const result = await kbCategoryService.getList(
        `?sort[field]=name&sort[direction]=asc&filter[name]=${value}`
      );

      if (result.isSuccess) {
        const kbCategories = result.data.data;
        setOptions(
          kbCategories.map((kbCategory: KBCategoriesListEntity) => ({
            label: kbCategory.name,
            value: kbCategory.id
          }))
        );
      }
    }
  };

  if (readOnly) {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth={fullWidth}>
          <AsyncSelect
            multiple={multiple || false}
            options={options}
            value={value || null}
            id="kbCategories"
            label=""
            name="kbCategories"
            setOptions={setOptions}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
          />
        </FormControl>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth>
          <AsyncSelect
            multiple={multiple || false}
            options={options}
            value={value || null}
            id="kbCategories"
            label=""
            name="kbCategories"
            setOptions={setOptions}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
          />
        </FormControl>
        {error.length > 0 && <ValidationError data={error} />}
      </Grid>
    );
  }
};
export default UnitKBCategories;
