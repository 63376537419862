import KnowledgeBaseCreate from 'features/knowledge-base/knowledge-base-create';
import KnowledgeBaseList from 'features/knowledge-base/knowledge-base-list';
import KnowledgeBasePage from 'pages/knowledge-base';
import KnowledgeBaseView from 'features/knowledge-base/knowledge-base-view';

const knowledgeBaseRoutes = {
  path: 'knowledge-bases',
  element: <KnowledgeBasePage />,
  children: [
    { path: '', element: <KnowledgeBaseList routeTag="knowledge-bases" /> },
    {
      path: 'create',
      element: <KnowledgeBaseCreate routeTag="knowledge-bases-create" />
    },
    {
      path: ':knowledge_base_id/edit',
      element: <KnowledgeBaseCreate routeTag="knowledge-bases-edit" />
    },
    {
      path: ':knowledge_base_id/view',
      element: <KnowledgeBaseView routeTag="knowledge-bases-view" />
    }
  ]
};

export default knowledgeBaseRoutes;
