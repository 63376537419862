enum stateOfOffers {
  offer_make_offer = 'Offer: Make Offer',
  offer_sent_to_seller = 'Offer: Sent to Seller',
  offer_seller_received = 'Offer: Seller Received',
  offer_seller_countered = 'Offer: Seller Countered',
  Offer_counter_updated = 'Offer: Counter Updated',
  offer_rejected = 'Offer: Rejected',
  offer_canceled = 'Offer: Canceled',
  offer_lost_deal = 'Offer: Lost',
  offer_accepted = 'Offer: Accepted',
  offer_short_sale_offer_accepted = 'Offer: Short Sale Offer Accepted'
}

export default stateOfOffers;
