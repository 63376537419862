import fieldLabel from 'assets/constants/fieldLabel';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import { toastError, toastSuccess } from 'event/toast-event';
import { deleteEmailTemplate } from 'helpers/email-template/email-template-action-helper';
import { isEmptyObject } from 'helpers/misc-helper';
import sessionHelper from 'helpers/session-helper';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EmailTemplateDelete = (): JSX.Element => {
  const { email_template_id } = useParams<{ email_template_id: string }>();
  const emailTemplate = sessionHelper.getRecord(email_template_id);
  const navigate = useNavigate();

  if (isEmptyObject(emailTemplate)) {
    toastError(fieldLabel.emailTemplateDeleteFailure);
    window.history.back();
    return <></>;
  }

  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    open: true,
    title: fieldLabel.deleteEmailTemplate,
    text: MessageDeleteRecordConfirmation(
      emailTemplate.name,
      'Email Template',
      true
    ),
    proceed: async () => {
      const result = await deleteEmailTemplate(emailTemplate.id);
      if (result.isError) {
        toastError(result.errorMessage.message);
        window.history.back();
      }

      if (result.isSuccess) {
        toastSuccess(fieldLabel.emailTemplateDeleteSuccess);
        navigate(`/email-templates`);
      }
    },
    cancel: () => {
      setConfirmModal(initialConfirmModalState);
      window.history.back();
    }
  });

  return <>{confirmModal.open && <ConfirmBox {...confirmModal} />}</>;
};
export default EmailTemplateDelete;
