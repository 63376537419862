const initialRoleAssociatedUserList = [
  {
    id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    roles: [],
    has_role: false
  }
];

export default initialRoleAssociatedUserList;
