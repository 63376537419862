import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';

export const validateMarketPreference = (data: ObjectType): ObjectType => {
  let validation: ObjectType = {
    hasValidationError: false
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      hasValidationError: true,
      name: [missingField('Name')]
    };
  }

  if (isEmpty(data.account_id)) {
    validation = {
      ...validation,
      hasValidationError: true,
      account_id: [missingField('Account')]
    };
  }

  if (isEmpty(data.msa_id)) {
    validation = {
      ...validation,
      hasValidationError: true,
      msa_id: [missingField('MSA')]
    };
  }

  return validation;
};
