import {
  KnowledgeBaseEntity,
  KnowledgeBasePayloadEntity
} from 'types/knowledge-base-types';
import { InputChangeEvent } from 'types/common-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateKnowledgeBase } from 'helpers/validation/knowledge-base-helper';
import CancelButton from 'components/form/button-cancel';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import initialKnowledgeBase from 'state/knowledge-base/initial-knowledge-base';
import knowledgeBaseService from 'services/knowledge-base-service';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';

interface PropType {
  routeTag: string;
  knowledge_base_id?: string;
  setEditMode?: (value: boolean) => void;
  updateKnowledgeBase?: () => void;
}

const KnowledgeBaseCreate = (props: PropType) => {
  const { setRouteName } = useRouteName();
  let { knowledge_base_id } = useParams<{ knowledge_base_id: string }>();
  const [knowledgeBase, setKnowledgeBase] =
    useState<KnowledgeBaseEntity>(initialKnowledgeBase);
  const [validation, setValidation] = useState<{ [key: string]: string[] }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (props.knowledge_base_id) {
    knowledge_base_id = props.knowledge_base_id;
  }

  const handleChange = (e: InputChangeEvent) => {
    setKnowledgeBase((preKnowledgeBase) => ({
      ...preKnowledgeBase,
      [e.target.name]: e.target.value
    }));
  };

  const loadKnowledgeBase = async (knowledge_base_id: string) => {
    setIsLoading(true);
    const response = await knowledgeBaseService.get(knowledge_base_id);

    setIsLoading(false);
    setKnowledgeBase(response.data);
  };

  const handleSubmit = async () => {
    const { status, errors } = validateKnowledgeBase(knowledgeBase);

    setValidation(errors);

    if (!status) return;
    setIsLoading(true);

    const reqBody: KnowledgeBasePayloadEntity = {
      name: knowledgeBase.name,
      status: knowledgeBase.status,
      description: knowledgeBase.description,
      knowledge_base_category_id: knowledgeBase.knowledge_base_category?.id
    };

    let response;

    if (knowledge_base_id) {
      response = await knowledgeBaseService.update(knowledge_base_id, reqBody);
    } else {
      response = await knowledgeBaseService.create(reqBody);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage ?? {});
      return;
    }

    if (response.isSuccess) {
      eventBus.dispatch(EVENTS.SHOW_TOAST, {
        isError: false,
        message: 'Saved Successfully'
      });
      if (props.updateKnowledgeBase) {
        props.updateKnowledgeBase();
        return;
      }
      window.history.back();
    }
  };

  useEffect(() => {
    setRouteName(props.routeTag);
  }, []);

  useEffect(() => {
    if (knowledge_base_id !== undefined) loadKnowledgeBase(knowledge_base_id);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <RecordView
          knowledgeBase={knowledgeBase}
          validation={validation}
          onChange={handleChange}
        />

        <StackRowWithDivider>
          <SaveButton onClick={handleSubmit} disabled={isLoading} />
          <CancelButton
            onClick={() => {
              if (props.setEditMode) {
                props.setEditMode(false);
              } else {
                window.history.back();
              }
            }}
          />
        </StackRowWithDivider>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default KnowledgeBaseCreate;
