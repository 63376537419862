import {
  ComposeMailTypeEntity,
  ContentType,
  EmailEntity
} from 'types/email-types';
import { InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { OpportunityContext } from 'pages/opportunity/Context';
import { useNavigate, useParams } from 'react-router-dom';
import brokerageTransactionRoleService from 'services/brokerage-transaction-role-service';
import emailConfig from 'config/email';
import emailHelper from 'helpers/email-helper';
import eventBus from 'helpers/event-bus-helper';
import initialComposeMail from 'state/email/initial-compose-mail';
import mailService from 'services/email-service';
import opportunityBrokerageUserService from 'services/opportunity-brokerage-user-service';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import RecordView from '../record-view';
import useRouteName from 'pages/route-outlet-context';
import {
  getFromReplyToEmailFields,
  savedAttachementReducer,
  unSavedAttachementReducer
} from 'helpers/email/email-action-helper';
import initialContent from 'state/email/initial-content';
import { ErrorValidation } from 'types/error-types';
import { getFilterString } from 'helpers/query-string-helper';
import { toastError } from 'event/toast-event';
import { DocumentsListEntity } from 'types/document-types';

const initialAttachmentList: ObjectType[] = [];
const initialDocumentList: File[] = [];

const ComposeEmail = ({ routeTag }: { routeTag: string }) => {
  const navigate = useNavigate();
  const { opportunity_id, email_id, action } = useParams<ObjectType>();
  const { marketPreference } = useContext(OpportunityContext);
  const outletContext = useRouteName();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [savedAttachments, dispatch] = useReducer(
    savedAttachementReducer,
    initialAttachmentList
  );
  const [unSavedAttachments, dispatch2] = useReducer(
    unSavedAttachementReducer,
    initialDocumentList
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const [mail, setMail] = useState<ComposeMailTypeEntity>({
    ...initialComposeMail,
    from_name: emailConfig.SENDER_FROM_NAME,
    opportunity_id
  });
  const [content, setContent] = useState<ContentType>(initialContent);

  const handleChange = (event: InputChangeEvent) => {
    if (event.target) {
      setMail(
        Object.assign({}, mail, { [event.target.name]: event.target.value })
      );
    } else {
      setMail((prevMail) => ({ ...prevMail, ...event }));
    }
  };

  const preparePayload = (): FormData => {
    const requestBody = new FormData();

    requestBody.append('from', mail.from);
    requestBody.append('to', mail.to);
    if (!isEmpty(mail.cc)) {
      requestBody.append('cc', mail.cc);
    }
    if (!isEmpty(mail.bcc)) {
      requestBody.append('bcc', mail.bcc);
    }

    requestBody.append('reply_to', mail.reply_to);
    requestBody.append('body_html', content.html);
    requestBody.append('subject', mail.subject);
    requestBody.append('opportunity_id', mail.opportunity_id);

    if (!isEmpty(mail.template)) {
      requestBody.append('email_template_id', mail.template);
    }

    requestBody.append('from_name', mail.from_name);

    if (mail.status === 'draft') {
      requestBody.append('email_id', mail.email_id);
    }
    for (const doc of savedAttachments) {
      requestBody.append('doc_ids[]', doc.id);
    }

    for (const doc of unSavedAttachments) {
      requestBody.append('files[]', doc);
    }

    for (const note of mail.notes) {
      requestBody.append('note_ids[]', note.id);
    }

    for (const noteId of mail.remove_note_ids) {
      requestBody.append('remove_note_ids[]', noteId);
    }

    return requestBody;
  };

  const draftMail = async () => {
    const { valid, errors } = emailHelper.validate({
      ...mail,
      body_html: content.html
    });

    setValidation(errors);

    if (!valid) return;
    const requestBody = preparePayload();
    requestBody.append('type', 'draft');

    setLoading(true);
    const result = await mailService.draftMail(requestBody);
    setLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (opportunity_id) {
      navigate(`/opportunities/${opportunity_id}/email/draft`);
    } else {
      navigate(`/email/draft`);
    }
  };

  const send = async () => {
    const { valid, errors } = emailHelper.validate({
      ...mail,
      body_html: content.html
    });
    setValidation(errors);

    if (!valid) return;

    const requestBody = preparePayload();
    requestBody.append('type', 'send');

    setLoading(true);
    const result = await mailService.sendEmail(requestBody);
    setLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (opportunity_id) {
      navigate(`/opportunities/${opportunity_id}/email/inbox`);
    } else {
      navigate(`/email/inbox`);
    }
  };

  const updateComposeFormToAction = (emailItem: EmailEntity) => {
    const subject = emailItem.name;

    if (action === 'replyAll') {
      const ccAddress = `${emailItem.email_body.to_addrs}${
        !isEmpty(emailItem.email_body!.cc_addrs)
          ? `,${emailItem.email_body!.cc_addrs}`
          : ''
      }`;
      setMail((prevMail) => ({
        ...prevMail,
        subject: `RE: ${subject}`,
        to: emailItem.email_body.from_addr,
        cc: ccAddress
      }));
      setContent((prevContent) => ({
        ...prevContent,
        html: emailItem.email_body.description_html,
        text: emailItem.email_body.description
      }));
    }

    if (action === 'forward') {
      setMail((prevMail) => ({
        ...prevMail,
        subject: `Fwd: ${subject}`
      }));
      setContent((prevContent) => ({
        ...prevContent,
        html: emailItem.email_body.description_html,
        text: emailItem.email_body.description
      }));
    }
  };

  const loadEmail = async (email_id: string) => {
    setLoading(true);
    const result = await mailService.get(email_id);
    setLoading(false);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isEmpty(action)) {
        setMail((prevMail) => ({
          ...prevMail,
          to: result.data.email_body.to_addrs,
          cc: result.data.email_body.cc_addrs,
          bcc: result.data.email_body.bcc_addrs,
          subject: result.data.name,
          template: result.data.email_template_id || '',
          email_id: result.data.id,
          notes: result.data.notes,
          from: result.data.email_body.from_addr,
          reply_to: result.data.email_body.reply_to_addr,
          status: result.data.status
        }));

        setContent((prevContent) => ({
          ...prevContent,
          html: result.data.email_body.description_html,
          text: result.data.email_body.description
        }));
      } else {
        setMail((prevMail) => ({
          ...prevMail,
          template: result.data.email_template_id || '',
          email_id: result.data.id,
          notes: result.data.notes,
          from: result.data.email_body.from_addr,
          reply_to: result.data.email_body.reply_to_addr,
          status: result.data.status
        }));

        updateComposeFormToAction(result.data);
      }
    }
  };

  const attachDoc = (doc: DocumentsListEntity) => {
    dispatch({
      type: 'ADD',
      payload: {
        file: {
          id: doc.id,
          name: doc.document_name,
          category_id: doc.category_id,
          document_subtype: doc.document_subtype,
          document_tag: doc.document_tag
        }
      }
    });
  };

  const loadOpportunityBrokerageUsers = async (opportunity_id: string) => {
    const response =
      await brokerageTransactionRoleService.getTransactionRolesByName(
        'brokerage_shared_inbox'
      );
    if (response.isSuccess) {
      const brokerageTransactionRole = response.data.data[0];
      if (!isEmpty(brokerageTransactionRole.id)) {
        const queryString = getFilterString({
          opportunity_id: opportunity_id,
          brokerage_transaction_role_id: brokerageTransactionRole.id,
          status: 'active'
        });
        setLoading(true);
        const result = await opportunityBrokerageUserService.getList(
          queryString
        );
        setLoading(false);

        const { from, reply_to } = getFromReplyToEmailFields(
          result,
          marketPreference.enable_inbound_email,
          opportunity_id
        );

        setMail((prevMail) => ({
          ...prevMail,
          from,
          reply_to
        }));
      }
    }
  };

  useEffect(() => {
    if (mail.opportunity_id && isEmpty(action)) {
      loadOpportunityBrokerageUsers(mail.opportunity_id);
    }
  }, [mail.opportunity_id]);

  useEffect(() => {
    if (!isEmpty(email_id)) {
      loadEmail(email_id);
    }
  }, []);

  useEffect(() => {
    eventBus.on('ATTACH_DOCS', (data: DocumentsListEntity) => attachDoc(data));
  }, []);

  useEffect(() => {
    if (!isEmpty(outletContext))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <React.Fragment>
      <RecordView
        mail={mail}
        setMail={setMail}
        onChange={handleChange}
        content={content}
        setContent={setContent}
        savedAttachments={savedAttachments}
        unSavedAttachments={unSavedAttachments}
        dispatch={dispatch}
        dispatch2={dispatch2}
        send={send}
        draft={draftMail}
        validation={validation}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default ComposeEmail;
