import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { ErrorValidation } from 'types/error-types';

export const validateMarketPreferenceBrockrageUser = (
  data: ObjectType
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.brokerage_transaction_role_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        brokerage_transaction_role_id: [missingField('Brokerage Transaction')]
      }
    };
  }

  if (isEmpty(data.brokerage_user_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        brokerage_user_id: [missingField('Brockerage User')]
      }
    };
  }

  if (isEmpty(data.status)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        Status: [missingField('Status')]
      }
    };
  }

  return validation;
};
