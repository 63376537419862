import ErrorComponent from 'components/errors/error-component';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import { isEmpty } from 'helpers/misc-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sqsService from 'services/sqs-service';
import initialSQS from 'state/sqs/initial-sqs';
import { SQSEntity } from 'types/sqs-types';

import RecordView from '../record-view';

const SQSView = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { setRouteName } = useRouteName();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { sqs_id } = useParams<{ sqs_id: string }>();

  const [sQS, setSQS] = useState<SQSEntity>(initialSQS);

  const loadSQS = async (sqs_id: string): Promise<void> => {
    const sQS = await sqsService.get(sqs_id);

    if (sQS.isError) {
      setErrorMessage(sQS.errorMessage.message);
      return;
    }

    setSQS(sQS.data);
  };

  useEffect(() => {
    if (sqs_id) loadSQS(sqs_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView sqs={sQS} readOnly={true} isView={true} />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default SQSView;
