import { PropertiesFilterEntity } from 'types/dashboard-type';
export const next_seller_contact_date_to = (
  value: string,
  _: any,
  filter: PropertiesFilterEntity
) => {
  let result = ``;

  if (!value || value.length === 0) return ``;

  if (filter.next_seller_contact_date_range === 'date-range') {
    result += `&filter[next_seller_contact_date][to]=${value}`;
  }

  return result;
};
