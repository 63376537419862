import DateUtility from 'helpers/date-helper';
import { OpportunityEntity } from 'types/opportunity-types';
import initialPartyList from 'state/party/initial-party-list';

export function getInitialOptionEarnestFeeStatus(
  opportunity: OpportunityEntity
) {
  return {
    opportunity_status_c: 'closing_diligence_period',
    option_fee_status_c: opportunity.option_fee_status_c,
    option_fee_delivery_date_c: opportunity.option_fee_delivery_date_c,
    option_amount_c: opportunity.option_amount_c,
    earnest_money_status_c: opportunity.earnest_money_status_c,
    emd_requested_confirmed:
      opportunity.emd_requested_confirmed ?? DateUtility.getTodayDateString(),
    earnest_amount_c: opportunity.earnest_amount_c,
    em_delivery_date_c:
      opportunity.em_delivery_date_c ?? DateUtility.getTodayDateString(),
    escrow_company: initialPartyList[0]
  };
}
