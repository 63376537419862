import { DocumentRevisionPresignUrlEntity } from 'types/document-revision-types';
import { isEmpty } from './misc-helper';



const imageExtType = ['png', 'jpeg', 'jpg'];
const documentExtType = ['pdf'];
const fileExtTypes = [...imageExtType, ...documentExtType];

export const getFileType = (fileExt: string): string => {
  if (!isEmpty(fileExt) && imageExtType.includes(fileExt)) {
    return 'image';
  } else if (!isEmpty(fileExt) && documentExtType.includes(fileExt)) {
    return 'pdf';
  } else if (!isEmpty(fileExt) && !fileExtTypes.includes(fileExt)) {
    return 'download';
  } else {
    return '';
  }
};

const fileDownloadHelper = (data: DocumentRevisionPresignUrlEntity) => {
  const fileUrl = data.url;
  const anchor = document.createElement('a');
  anchor.href = fileUrl;
  anchor.download = `${data.file_name}`;
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(data.url);
};

export default fileDownloadHelper;
