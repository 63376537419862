import React from 'react';

export interface LayoutProviderInterface {
  sideNavMenuItems: {};
  mobileSideNavOpen: boolean;
  mobileTopNavOpen: boolean;
  topBar: React.ReactNode;
  isRecentViewOpen: boolean;
  sidePanelWidth: number;
  setSideNavMenuItems: React.Dispatch<React.SetStateAction<{}>>;
  handleMobileSideNav: () => void;
  handleMobileTopNav: () => void;
  toggleRecentView: () => void;
  setTopBar: (value: React.ReactNode) => void;
  setSidePanelWidth: React.Dispatch<React.SetStateAction<number>>;
}

const LayoutProvider = React.createContext<LayoutProviderInterface>({
  sideNavMenuItems: {},
  mobileSideNavOpen: false,
  mobileTopNavOpen: false,
  isRecentViewOpen: false,
  sidePanelWidth: 230,
  topBar: <></>,
  setSidePanelWidth: () => ({}),
  setSideNavMenuItems: () => ({}),
  handleMobileSideNav: () => ({}),
  handleMobileTopNav: () => ({}),
  toggleRecentView: () => ({}),
  setTopBar: () => ({})
});

export default LayoutProvider;
