import fieldLabel from 'assets/constants/fieldLabel';
import retradeRequest from 'assets/constants/retrade-request';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitDateTimePicker from 'components/form/unit-date-time';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import { actionPerform } from 'event/action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import emptyFunction from 'helpers/empty-function-helper';
import { convertNumber } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { initialRetradeRequest } from 'state/brokerage-actions/retrade-request';
import {
  ActionRecordViewPropTypes,
  RetradeRequestEntity,
  RetradeRequestPayloadEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

import { getInitialRetradeRequest } from './helper';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<RetradeRequestEntity>(initialRetradeRequest);

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const fieldTovalidate = [
      'negotiator_sent_retrade_amount',
      'retrade_reason'
    ];

    const { isValid, errors } = validateBrokerageAction(data, fieldTovalidate);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    const reqBody = {
      retrade_reason: data.retrade_reason,
      negotiator_sent_retrade_amount: data.negotiator_sent_retrade_amount,
      buyer_requested_retrade_amount: data.buyer_requested_retrade_amount,
      purchase_price_c: data.purchase_price_c
    };

    setIsLoading(true);

    const result =
      await brokerageActionService.update<RetradeRequestPayloadEntity>(
        opportunity.id,
        reqBody,
        'retrade_request'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        actionPerform.request_price_adjustment();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity({});
      }
    }
  };

  const updatePurchasePrice = (): void => {
    const contractPrice = data.contract_price_c;
    const negotiatorSentRetradeAmount = data.negotiator_sent_retrade_amount;
    const purchasePrice =
      convertNumber(contractPrice) - convertNumber(negotiatorSentRetradeAmount);

    setData((preData) => ({
      ...preData,
      purchase_price_c: purchasePrice
    }));
  };

  useEffect(() => {
    updatePurchasePrice();
  }, [data.negotiator_sent_retrade_amount]);

  useEffect(() => {
    setData((preData) => ({
      ...preData,
      ...getInitialRetradeRequest(opportunity)
    }));
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitSelect
              name="retrade_reason"
              label={fieldLabel.retradeReason}
              records={getObjectEntriesAsArray(retradeRequest)}
              value={data.retrade_reason ?? ''}
              onChange={handleChange}
              error={validation['retrade_reason'] ?? ''}
              required
            />

            <UnitPriceFormatter
              name={'contract_price_c'}
              label={fieldLabel.contractPrice}
              value={data.contract_price_c ?? ''}
              onChange={handleChange}
              disabled
            />

            <UnitDateTimePicker
              label={fieldLabel.ddEndDate}
              name="due_diligence_end_c"
              value={data.due_diligence_end_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'due_diligence_end_c', value: e }
                })
              }
              error={validation['due_diligence_end_c'] ?? ''}
              disabled
            />

            <UnitDate
              label={fieldLabel.closeDate}
              name="close_date_c"
              value={data.close_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'close_date_c', value: e }
                })
              }
              error={validation['close_date_c'] ?? ''}
              disabled
            />

            <UnitDate
              label={fieldLabel.forecastedDDEndDate}
              name="forecasted_dd_end_date"
              value={data.forecasted_dd_end_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_dd_end_date', value: e }
                })
              }
              error={validation['forecasted_dd_end_date'] ?? ''}
              disabled
            />

            <UnitDate
              label={fieldLabel.forecastedCloseDate}
              name="forecasted_close_date"
              value={data.forecasted_close_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'forecasted_close_date', value: e }
                })
              }
              error={validation['forecasted_close_date'] ?? ''}
              disabled
            />

            <UnitPriceFormatter
              name={'negotiator_sent_retrade_amount'}
              label={`${fieldLabel.negotiatorSentRetradeAmount}`}
              value={data.negotiator_sent_retrade_amount ?? ''}
              onChange={handleChange}
              error={validation['negotiator_sent_retrade_amount'] ?? ''}
              required
            />

            <UnitPriceFormatter
              name={'purchase_price_c'}
              label={fieldLabel.proposedPurchasePrice}
              value={data.purchase_price_c ?? ''}
              onChange={handleChange}
              disabled
            />

            <UnitPriceFormatter
              name={'buyer_requested_retrade_amount'}
              label={fieldLabel.buyerRequestedRetradeAmount}
              value={data.buyer_requested_retrade_amount ?? ''}
              onChange={handleChange}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ p: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
