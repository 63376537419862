//@ts-nocheck

import urlElements from 'assets/constants/url-elements';
import { Location } from 'react-router-dom';
import sessionHelper from 'helpers/session-helper';
import { isEmpty } from './misc-helper';

const setUrlMetadata = (location: Location) => {
  const urlParts = location.pathname.split('/');

  urlParts.shift();

  const urlMetadata = urlParts.map((urlPart: string, index: number) => {
    let element, type, url, label, id, service, skipBreadcrumbs;

    if (urlElements[urlPart]) {
      type = 'list';
    } else if (urlElements['action'][urlPart]) {
      type = 'action';
    } else {
      type = 'entity';
    }

    if (type === 'list') {
      element = urlPart;
      label = urlElements[element][type]['label'];
      skipBreadcrumbs = urlElements[element][type]['skipBreadcrumbs'];
    }

    if (type === 'entity') {
      let previousUrlPart = urlParts[index - 1];
      element = previousUrlPart;
      id = urlPart;
      service = urlElements[element][type]['service'];
    }

    if (type === 'action') {
      element = 'action';
      id = urlPart;
      label = urlElements[element][id]['label'];
      skipBreadcrumbs = urlElements[element][id]['skipBreadcrumbs'];
    }

    url = urlParts.slice(0, index + 1).join('/');

    return {
      element,
      id,
      type,
      label,
      service,
      url: `/${url}`,
      skipBreadcrumbs
    };
  });

  return urlMetadata;
};

export const getDataForBreadcrumbsUrl = (urlMetadata) => {
  const promises = urlMetadata.map(async (urlElement) => {
    if (urlElement['type'] === 'entity') {
      let record = sessionHelper.getRecord(urlElement.id);
      const service = urlElement['service'];
      if (JSON.stringify(record) === '{}') {
        const result = await service.get(urlElement.id);
      }
    }
  });
  return Promise.all(promises);
};

export const getBreadcrumbsMetadata = (urlMetadata) => {
  const breadcrumbsMetadata = urlMetadata.map((urlElement) => {
    let to;
    let label;
    let type = urlElement['type'];

    if (type === 'entity') {
      to = `${urlElement.url}/view`;
      const record = sessionHelper.getRecord(urlElement.id);
      label = record.name;
    }

    if (type === 'list') {
      to = urlElement.url;
      label = urlElement.label;
    }

    if (type === 'action') {
      label = urlElement.label;
      to = urlElement.url;
    }

    return {
      type,
      to,
      label,
      skipBreadcrumbs: urlElement.skipBreadcrumbs
    };
  });

  return breadcrumbsMetadata;
};

export default setUrlMetadata;
