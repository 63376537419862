import { isEmpty } from 'helpers/misc-helper';
import { CopyToClipboardProvider } from './copy-to-clipboard-context';
import CopyButton from './copy-button';

interface CopyToClipboardProps {
  value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ value }) => {
  return (
    <>
      {!isEmpty(value) && (
        <CopyToClipboardProvider>
          <CopyButton text={value} />
        </CopyToClipboardProvider>
      )}
    </>
  );
};

export default CopyToClipboard;
