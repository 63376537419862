import variableConfig from 'config/variable';

const initialKnowledgeBaseHelper = {
  id: '',
  name: '',
  description: '',
  tag: variableConfig.opportunitiesNotesTermsProvisionOfferGradeTag
};

export default initialKnowledgeBaseHelper;
