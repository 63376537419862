import envConfig from 'config/env';
import { list, post } from './client-service';
import {
  UserPreferenceEntityResponse,
  UserPreferenceListTypeResponse,
  UserPreferencePayload
} from 'types/user-preference-type';

const userPreferenceService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/user-preferences`,

  getItem: async <T>(data: UserPreferencePayload<T>): Promise<UserPreferenceEntityResponse<T>> => {
    const response: UserPreferenceListTypeResponse<T> =
      await userPreferenceService.getList(data);

    return {
      data: response?.data?.data[0] ? response?.data?.data[0] : null,
      isSuccess: response.isSuccess,
      isError: response.isSuccess,
      errorMessage: response.errorMessage
    };
  },

  getList: async <T>(data: UserPreferencePayload<T>): Promise<UserPreferenceListTypeResponse<T>> => {
    const url = `${userPreferenceService.url}?filter[category]=${data.category}&filter[subcategory]=${data.subcategory}&filter[module]=${data.module}`;
    const result: UserPreferenceListTypeResponse<T> = await list(url);
    return result;
  },

  save: async <T>(data: UserPreferencePayload<T>) => {
    const result: UserPreferenceEntityResponse<T> = await post(
      `${userPreferenceService.url}`,
      data
    );
    return result;
  }
};

export default userPreferenceService;