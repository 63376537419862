import envConfig from 'config/env';
import httpService from './http-service';
import { PortfolioEntity } from 'types/portfolio-types';
import { GetListResponse } from 'types/api-response-types';

const portfolioService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/portfolios`,

  getList: async (query: string): Promise<GetListResponse<PortfolioEntity>> => {
    const result = await httpService.list<PortfolioEntity>(
      `${portfolioService.url}${query}`
    );
    return result;
  }
};

export default portfolioService;
