import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import defaultSort from 'assets/list/account/default-sort';
import { emailDraftColumns } from 'assets/list/email-draft/column';
import DataGrid from 'components/data-grid/hoc-datagrid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MailService from 'services/email-service';
import { ObjectType } from 'types';
import { GetListResponse } from 'types/api-response-types';
import { EmailListEntity } from 'types/email-types';

const Draft = ({ routeTag }: { routeTag: string }) => {
  const { setRouteName } = useRouteName();

  const { opportunity_id } = useParams<ObjectType>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const getEmails = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<EmailListEntity>> => {
    const filterInfo =
      opportunity_id === undefined
        ? { status: 'draft' }
        : { status: 'draft', opportunity_id };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await MailService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <React.Fragment>
      <DataGrid<EmailListEntity>
        getData={getEmails}
        tableColumns={emailDraftColumns}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="email_draft"
      />
    </React.Fragment>
  );
};

export default Draft;
