import {
  PdfTemplateDeleteResponse,
  PdfTemplateEntity,
  PdfTemplateListEntity,
  PdfTemplatePayloadEntity
} from 'types/pdf-template-types';
import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse
} from 'types/api-response-types';

const pdfTemplateService = {
  url: `/${app.crmApiVersion1}/contracts`,

  getList: async (
    query: string
  ): Promise<GetListResponse<PdfTemplateListEntity>> => {
    const result = await httpService.list<PdfTemplateListEntity>(
      `${pdfTemplateService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<PdfTemplateEntity>> => {
    const result = await httpService.get<PdfTemplateEntity>(
      `${pdfTemplateService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: PdfTemplatePayloadEntity
  ): Promise<CreateResponse<PdfTemplateEntity>> => {
    const result = await httpService.post<
      PdfTemplateEntity,
      PdfTemplatePayloadEntity
    >(`${pdfTemplateService.url}`, payload);
    return result;
  },

  update: async (
    id: string,
    payload: PdfTemplatePayloadEntity
  ): Promise<UpdateResponse<PdfTemplateEntity>> => {
    const result = await httpService.put<
      PdfTemplateEntity,
      PdfTemplatePayloadEntity
    >(`${pdfTemplateService.url}/${id}`, payload);
    return result;
  },

  delete: async (
    id: string
  ): Promise<DeleteResponse<PdfTemplateDeleteResponse>> => {
    const result = await httpService.del<PdfTemplateDeleteResponse>(
      `${pdfTemplateService.url}/${id}`
    );
    return result;
  }
};

export default pdfTemplateService;
