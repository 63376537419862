import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import pdfTemplateColumn from 'assets/list/pdf-template/column';
import defaultSort from 'assets/list/pdf-template/default-sort';
import DataGrid from 'components/data-grid/hoc-datagrid';
import { isEmpty } from 'helpers/misc-helper';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import pdfTemplatesService from 'services/pdf-template-service';
import initialPDFTemplateListFilter from 'state/contracts/initial-pdf-template-list-filter';
import { ObjectType } from 'types';
import { GetListResponse } from 'types/api-response-types';
import { ChangeEvent } from 'types/common-types';
import {
  PdfTemplateFilterEntity,
  PdfTemplateListEntity
} from 'types/pdf-template-types';

import Filters from './filters';
import ErrorComponent from 'components/errors/error-component';
import fieldLabel from 'assets/constants/fieldLabel';

const PDFTemplatesList = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { market_preference_id, opportunity_id } = useParams<{
    market_preference_id: string;
    opportunity_id: string;
  }>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<PdfTemplateFilterEntity>(
    initialPDFTemplateListFilter
  );

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const { oppurtunity } = useContext(OpportunityContext);

  const updateFilter = (e: ChangeEvent): void => {
    if (e.target) {
      setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
    } else {
      setFilter(Object.assign({}, filter, e));
    }
  };

  const location = useLocation();

  const getPDFTemplates = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<PdfTemplateListEntity>> => {
    let filterInfo: ObjectType;
    if (opportunity_id) {
      filterInfo = { market_preference_id: oppurtunity?.market_preference_id };
    } else if (!location.pathname.includes('selection-list')) {
      filterInfo =
        market_preference_id === undefined
          ? filter
          : { ...filter, market_preference_id };
    } else {
      filterInfo = filter;
    }

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result = await pdfTemplatesService.getList(queryString);

    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  if (opportunity_id && isEmpty(oppurtunity?.market_preference_id)) {
    return (
      <ErrorComponent message={fieldLabel.marketPreferenceNotAssociated} />
    );
  }

  return (
    <>
      {isEmpty(opportunity_id) && (
        <Filters
          filter={filter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          setFilter={setFilter}
          isLoading={isLoading}
        />
      )}

      <DataGrid<PdfTemplateListEntity>
        getData={getPDFTemplates}
        tableColumns={pdfTemplateColumn}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="pdf_template"
      />
    </>
  );
};

export default PDFTemplatesList;
