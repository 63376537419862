import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import useRouteName from 'pages/route-outlet-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import opportunityBrokerageUserService from 'services/opportunity-brokerage-user-service';
import initialOpportunityBrokerageUser from 'state/opportunity-brokerage-user/initial-opportunity-brokerage-user';
import { OpportunityBrokerageUserEntity } from 'types/opportunity-brokerage-user-types';

import RecordView from '../record-view';
import { isEmpty } from 'helpers/misc-helper';
import ErrorComponent from 'components/errors/error-component';

const OpportunityBrokerageUserView = ({
  routeTag
}: {
  routeTag: string;
}): JSX.Element => {
  const { opportunity_brokerage_user_id } = useParams<{
    opportunity_brokerage_user_id: string;
  }>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [opportunityBrokerageUser, setOpportunityBrokerageUser] =
    useState<OpportunityBrokerageUserEntity>(initialOpportunityBrokerageUser);

  const { setRouteName } = useRouteName();

  const loadOpportunityBrokerageUser = async (
    opportunity_brokerage_user_id: string
  ): Promise<void> => {
    const response = await opportunityBrokerageUserService.get(
      opportunity_brokerage_user_id
    );

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setOpportunityBrokerageUser(response.data);
  };

  useEffect(() => {
    if (opportunity_brokerage_user_id)
      loadOpportunityBrokerageUser(opportunity_brokerage_user_id);
  }, []);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <RecordView
            opportunityBrokerageUser={opportunityBrokerageUser}
            readOnly={true}
            isView={true}
          />
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default OpportunityBrokerageUserView;
