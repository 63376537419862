import fieldLabel from './fieldLabel';

export const column = [
  { name: 'action', title: 'Action', disable: true },
  { name: 'name', title: 'Closing Name', disable: true },
  { name: 'account', title: 'Account', disable: true },
  { name: 'market', title: 'MSA', disable: true },
  { name: 'entera_opportunity_id', title: 'Entera Opportunity ID' },
  { name: 'buyer_contract_name_c', title: 'Buyer Contract Name' },
  { name: 'opportunity_status_c', title: 'State Of Closing' },
  {
    name: 'offer_blocked_reasons',
    title: fieldLabel.offerBlockedReason
  },
  { name: 'primary_negotiator_name', title: 'Primary Negotiator' },
  {
    name: 'real_estate_coordinator_name',
    title: fieldLabel.realEstateCoordinator
  },
  { name: 'mls_c', title: 'MLS #' },
  { name: 'mls_status_c', title: 'MLS Status' },
  { name: 'list_date_c', title: 'List Date' },
  { name: 'close_date_c', title: 'Close Date' },
  {
    name: 'contract_execution_date_c',
    title: 'Contract Execution Date'
  },
  { name: 'due_diligence_end_c', title: 'Due Diligence Date' },
  { name: 'list_price_c', title: 'List Price' },
  { name: 'revised_list_price_c', title: 'Revised List Price' },
  { name: 'offer_price_c', title: 'Offer Price' },
  { name: 'offer_to_list_c', title: 'Offer to List %' },
  { name: 'offer_date_c', title: 'Offer Date' },
  { name: 'reject_reason', title: 'Reject Reason' },
  { name: 'counter_offer', title: 'Counter Offer' },
  { name: 'seller_offer_response', title: 'Seller Response' },
  {
    name: 'next_seller_contact_date',
    title: 'Next Seller Contact Date'
  },
  { name: 'cancel_reason', title: 'Cancel Reason' },
  {
    name: 'customer_accepted_status_verified',
    title: 'Customer Accepted Status Verified'
  },
  { name: 'earnest_amount_c', title: 'Earnest Amount' },
  { name: 'earnest_money_status_c', title: 'Earnest Money Status' },
  { name: 'earnest_money_due_date_c', title: 'Earnest Money Due Date' },
  { name: 'escrow_company_name_c', title: 'Escrow Company Name' },
  { name: 'escrow_company_contact_c', title: 'Escrow Company Contact' },
  {
    name: 'congrats_letter_sent_date',
    title: 'Congrats Letter Sent Date'
  },
  { name: 'congrats_letter_sent_by', title: 'Congrats Letter Sent By' },
  { name: 'list_agent_name', title: 'List Agent Name' },
  { name: 'list_agent_email', title: 'List Agent Email' },
  { name: 'list_agent_phone', title: 'List Agent Phone' },
  { name: 'max_offer_price_c', title: 'Max Offer Price' },
  { name: 'date_entered', title: 'Date Created' },
  { name: 'contract_price_c', title: 'Contract Price' },
  { name: 'latest_negotiator_note', title: 'Negotiator Notes' },
  { name: 'forecasted_close_date', title: 'Forecasted Close Date' },
  { name: 'option_amount_c', title: 'Option Amount' },
  { name: 'option_fee_delivery_date_c', title: 'Option Delivery Date' },
  { name: 'contract_review_complete', title: 'Contract Review Complete' },
  { name: 'date_modified', title: 'Date Modified' },
  { name: 'closing_attorney', title: 'Closing Attorney' },
  { name: 'sellers_disclosure_received', title: 'Sellers Disclosure Received' },
  { name: 'special_instructions', title: 'Special Instructions' },
  { name: 'contract_terminated_date', title: 'Contract Termination Date' },
  { name: 'initial_commission_amount', title: 'Initial Commission $' },
  { name: 'contract_termination_reasons', title: 'Contract Termination Reason' }
];
