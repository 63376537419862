import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { SQSEntity } from 'types/sqs-types';

export const validateSQS = (
  data: SQSEntity
): { status: boolean; errors: ErrorValidation } => {
  let validation: { status: boolean; errors: ErrorValidation } = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        name: [missingField(fieldLabel.name)]
      }
    };
  }

  if (isEmpty(data.queue)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        queue: [missingField(fieldLabel.queue)]
      }
    };
  }

  if (isEmpty(data.origin)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        origin: [missingField(fieldLabel.messageOrigin)]
      }
    };
  }

  if (isEmpty(data.status)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        status: [missingField(fieldLabel.status)]
      }
    };
  }

  if (isEmpty(data.message_id)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        message_id: [missingField(fieldLabel.sqsMessageID)]
      }
    };
  }

  if (isEmpty(data.receipt_handle)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        receipt_handle: [missingField(fieldLabel.receiptHandle)]
      }
    };
  }

  if (isEmpty(data.message)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        message: [missingField(fieldLabel.message)]
      }
    };
  }

  return validation;
};
