import ViewChangelog from 'features/view-changelog';
import useRouteName from 'pages/route-outlet-context';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ObjectType } from 'types';

const SqsViewChangelog = ({ routeTag }: { routeTag: string }): JSX.Element => {
  const { sqs_id } = useParams<{ sqs_id: string }>();
  const [filter, setFilter] = useState<ObjectType>({});
  const { setRouteName } = useRouteName();

  const filterInfo = sqs_id === undefined ? filter : { ...filter, sqs_id };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <ViewChangelog
        action="sqs_change_logs"
        filter={filterInfo}
        setFilter={setFilter}
        fieldAction="sqs-messages"
      />
    </>
  );
};

export default SqsViewChangelog;
