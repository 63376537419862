const initialListFilter = {
  document_name: '',
  document_type: '',
  account_id: '',
  account_name: '',
  msa_id: '',
  msa_name: ''
};

export default initialListFilter;
