import { Box } from '@mui/material';
import fieldLabel from 'assets/constants/fieldLabel';
import ErrorComponent from 'components/errors/error-component';
import UnitItem from 'components/form/unit-item';
import RouteLink from 'components/link/route-link';
import fileDownloadHelper, { getFileType } from 'helpers/file-download-helper';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import documentRevisionService from 'services/document-revision-service';
import appConfig from '../../../config/app';
import initialDocumentViewer from 'state/document/initial-document-viewer';
import { DocumentRevisionPresignUrlEntity } from 'types/document-revision-types';

const DocumentViewer = () => {
  const { document_revision_id } = useParams<{
    document_revision_id: string;
  }>();

  const [data, setData] = useState<DocumentRevisionPresignUrlEntity>(
    initialDocumentViewer
  );
  const [errorMessage, setErrorMessage] = useState('');

  const loadPreSignUrl = async (id: string): Promise<void> => {
    const result = await documentRevisionService.getPresignUrl(id);

    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (getFileType(result.data.file_ext) === 'download') {
        fileDownloadHelper(result.data);
      }
      setData(result.data);
    }
  };
  useEffect(() => {
    if (document_revision_id) loadPreSignUrl(document_revision_id);
  }, []);

  return (
    <>
      {errorMessage ? (
        <ErrorComponent message={errorMessage} />
      ) : (
        <>
          {getFileType(data.file_ext) === 'image' && (
            <Box sx={{ width: '100%', height: 'auto', textAlign: 'center' }}>
              <img src={data.url} title={data.file_name} loading="lazy" />
            </Box>
          )}
          {getFileType(data.file_ext) === 'pdf' && (
            <iframe
              src={`${
                appConfig.publicURL
              }/pdfjs-dist/web/viewer.html?file=${encodeURIComponent(
                data.url
              )}&downloadFileName=${encodeURIComponent(data.file_name)}`}
              width="100%"
              height="100%"
            ></iframe>
          )}
          {getFileType(data.file_ext) === 'download' && (
            <>
              <UnitItem style={{ textAlign: 'center' }}>
                {fieldLabel.ifYourDownloadDoesNotStartAfterSeconds}{' '}
                <RouteLink
                  url={data.url}
                  name={fieldLabel.clickHereDownloadManually}
                  style={{ textDecoration: 'underline' }}
                />
              </UnitItem>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DocumentViewer;
